/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { NavigationItemName, Page } from '../../routing/pages';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { hoverSideNavigationItem, selectSideNavigationItem } from '../../store/features/navigation-bar-slice';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import {
    navigation_menu__list_items__item,
    navigation_menu__list_items__item__icon,
    navigation_menu__list_items__item__text,
} from './desktop-navbar.styles';
import { Link } from 'react-router-dom';

interface NavbarMenuItemProps {
    item: Page;
    navbarOpened: boolean;
    onMenuItemClick?: () => void;
    isMobile?: boolean;
}

export const NavbarMenuItem = ({ item, onMenuItemClick, navbarOpened, isMobile }: NavbarMenuItemProps) => {
    const { selectedSideNavigationItem, hoveredSideNavigationItem } = useAppSelector(state => state.navigationBar);
    const dispatch = useAppDispatch();

    const onNavigationItemClick = (itemName: NavigationItemName) => {
        dispatch(selectSideNavigationItem(itemName));
        onMenuItemClick && onMenuItemClick();
        item.onClickCallback && item.onClickCallback();
    };

    const onNavigationItemMouseLeave = () => {
        dispatch(hoverSideNavigationItem(undefined));
    };
    const onNavigationItemMouseOver = (item: NavigationItemName) => {
        dispatch(hoverSideNavigationItem(item));
    };

    const isSelected = item.name === selectedSideNavigationItem;
    const isHovered = item.name === hoveredSideNavigationItem;
    const onMouseOver = () => onNavigationItemMouseOver(item.name);
    const onNavigationItemCLick = () => onNavigationItemClick(item.name);

    return (
        <ListItem
            css={ navigation_menu__list_items__item(isSelected) }
            disablePadding
            onMouseOver={ onMouseOver }
            onMouseLeave={ onNavigationItemMouseLeave }
        >
            <ListItemButton
                component={ Link }
                to={ item.route }
                css={ css({ justifyContent: 'center' }) }
                onClick={ onNavigationItemCLick }
            >
                <ListItemIcon css={ navigation_menu__list_items__item__icon(isSelected) }>
                    { isHovered ? item.hoverIcon : item.icon }
                </ListItemIcon>
                { (navbarOpened || isMobile) &&
                    <ListItemText
                        css={ navigation_menu__list_items__item__text(isSelected) }
                        primary={ item.name }
                    />
                }
            </ListItemButton>
        </ListItem>
    );
};
