/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {jsx} from '@emotion/react';
import React from 'react';
import {ErrorCode, useDropzone} from 'react-dropzone';
import {file_uploader__frame_hover, file_uploader__dropzone__description, file_uploader__dropzone__description_reject, file_uploader__dropzone__error_explanation, file_uploader__dropzone__title, file_uploader__dropzone__title_accept, file_uploader__dropzone__title_reject, file_uploader__frame_reject, file_uploader__frame_accept} from './file-uploader-dropzone.styles';
import {file_uploader__frame} from './file-uploader.styles';

interface FileUploaderDropzoneProps {
    onDropAccepted: (files: File[]) => void
    dropzoneTitle?: string
    dropzoneDescription?: string
}

export const FileUploaderDropzone = (props: FileUploaderDropzoneProps) => {
    const {onDropAccepted: onDropHandler, dropzoneTitle, dropzoneDescription} = props;
    const {getRootProps, getInputProps, isDragAccept, isDragReject, fileRejections} = useDropzone({
        onDropAccepted: onDropHandler,
        onDragEnter: () => {fileRejections.length = 0},
        multiple: false,
        maxSize: 524288,
        accept: {'image/jpeg': ['.jpeg', '.jpg'], 'image/png': [], 'image/img': [], 'image/svg': [], 'image/ico': [], }
    })
    const isFileReject = isDragReject || fileRejections.length
    const isFileTooLarge = fileRejections?.[0]?.errors?.[0]?.code === ErrorCode.FileTooLarge;
    return (
        <React.Fragment>
            <div
                {...getRootProps()}
                css={[file_uploader__frame,
                    isFileReject ? file_uploader__frame_reject :
                        isDragAccept ? file_uploader__frame_accept : 
                        file_uploader__frame_hover
                ]}
            >
                <input {...getInputProps()}/>
                <div>
                    <p
                        className='file-uploader__dropzone__title'
                        css={[
                            file_uploader__dropzone__title,
                            isFileReject ? file_uploader__dropzone__title_reject :
                                isDragAccept ? file_uploader__dropzone__title_accept : null
                        ]}
                        key={1}
                    >
                        {dropzoneTitle}
                    </p>
                    <p
                        css={[
                            file_uploader__dropzone__description,
                            isFileReject ? file_uploader__dropzone__description_reject : null
                        ]}
                        key={2}
                    >
                        {dropzoneDescription}
                    </p>
                </div>
            </div>
            {isFileTooLarge && <p css={file_uploader__dropzone__error_explanation}>File size limit of 500 kb exceeded</p>}
        </React.Fragment>
    )
}
