export const ALL_FILTER_PRESET = 'ALL';

export type FilterPreset<T> = T | typeof ALL_FILTER_PRESET;

export interface FilterItem<T> {
    name: string;
    value: FilterPreset<T>;
}

export const ratingFilter: FilterPreset<number>[] = [ALL_FILTER_PRESET, 1, 2, 3, 4, 5];
