export const AccountHover = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <g>
                <path fill="currentColor" fillRule="evenodd"
                      d="M3 4.995C3 3.893 3.893 3 4.995 3H12a5 5 0 1 0 0 10 5 5 0 0 0 0-10h7.005C20.107 3 21 3.893 21 4.995v14.01A1.995 1.995 0 0 1 19.005 21H4.995A1.995 1.995 0 0 1 3 19.005V4.995ZM7 17v-2h10v2H7Z"
                      clipRule="evenodd"/>
                <path fill="currentColor" fillRule="evenodd"
                      d="M12 15a7 7 0 1 0 0-14 7 7 0 0 0 0 14Zm2.246-11.519A5.123 5.123 0 0 0 12.072 3C9.271 3 7 5.239 7 8s2.27 5 5.072 5c.752 0 1.495-.164 2.174-.481A5.053 5.053 0 0 0 16 11.165L12.79 8 16 4.835a5.052 5.052 0 0 0-1.754-1.354ZM11.534 4.72a.766.766 0 0 1 1.076 0 .745.745 0 0 1 0 1.06.766.766 0 0 1-1.076 0 .745.745 0 0 1 0-1.06Z"
                      clipRule="evenodd"/>
            </g>
        </svg>
    );
};