import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { toggleMobileNavbar } from "../../store/features/navigation-bar-slice";
import { Drawer } from "@mui/material";
import { DrawerHeader } from "./drawer-header";
import { NavbarMenu } from "./navbar-menu";
import React from "react";
import { DARK_BASIC } from "../../common-styles/color-palette";

export const MobileNavbar = () => {
    const { mobileNavbarOpened } = useAppSelector(state => state.navigationBar);
    const dispatch = useAppDispatch();

    const container = window !== undefined ? () => window.document.body : undefined;

    const handleMobileNavbarToggle = () => {
        dispatch(toggleMobileNavbar(!mobileNavbarOpened));
    };

    const handleMobileMenuItemClick = () => {
        dispatch(toggleMobileNavbar(false));
    };

    return (
        <Drawer
            container={ container }
            variant="temporary"
            open={ mobileNavbarOpened }
            onClose={ handleMobileNavbarToggle }
            ModalProps={ {
                keepMounted: true, // Better open performance on mobile.
            } }
            sx={ {
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                    backgroundColor: DARK_BASIC,
                    boxSizing: "border-box",
                    width: '100%',
                },
            } }
            anchor="top"
        >
            <DrawerHeader/>
            <NavbarMenu opened={ mobileNavbarOpened } mobileClickHandler={ handleMobileMenuItemClick } isMobile/>
        </Drawer>
    );
};
