function fillMissedZeros(partOfTime: number) {
    return partOfTime <= 9 ? '0' : '';
}

export const formatDateToDateTime = (x: number) => {
    const date = new Date(x);
    const month = `${(fillMissedZeros(date.getMonth() + 1))}${date.getMonth() + 1}`;
    const dayOfMonth = `${fillMissedZeros(date.getDate())}${date.getDate()}`;
    const hours = `${fillMissedZeros(date.getHours())}${date.getHours()}`;
    const minutes = `${fillMissedZeros(date.getMinutes())}${date.getMinutes()}`;
    const seconds = `${fillMissedZeros(date.getSeconds())}${date.getSeconds()}`;
    return `${dayOfMonth}/${month} ${hours}:${minutes}:${seconds}`
};

export const MILLIS_IN_DAY = 86400000;

export const isDailyTimeRange = (from: number, to: number) => to - from < MILLIS_IN_DAY;
