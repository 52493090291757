import { css } from '@emotion/react';
import { WHITE } from '../../common-styles/color-palette';
import { PAGE_CONTAINER_BOX_SHADOW } from '../../common-styles/shadows';
import { mobileDevices } from '../../common-styles/adaptive-breakpoints';

export const team_page = css({
    display: 'flex',
    backgroundColor: WHITE,
    borderRadius: '0.75em',
    boxShadow: PAGE_CONTAINER_BOX_SHADOW,
    padding: '1.5em',

    '@media (min-width: 1400px)': {
        height: '100%',
    },

    '@media (max-width: 1000px)': {
        flexDirection: 'column'
    },

    [mobileDevices]: {
        padding: '1 0.5em 0.5em',
        borderRadius: 'unset',
    },
});

export const team_page__layout = css({
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
});
export const team_configuration_form__container = css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
});
export const team_qr__container = css({
    display: 'flex',
    width: '100%',
    height: '100%',
});
