import { css, SerializedStyles } from '@emotion/react';
import { GREY_BASIC, GREY_LIGHT, WHITE } from '../../common-styles/color-palette';
import { BORDER_RADIUS_EXTRA_LARGE, BORDER_RADIUS_SMALL } from '../../common-styles/borders';
import { FONT_SIZE_MOBILE_SEMI_EXTRA } from '../../common-styles/font-sizes';

export const widget_preview__container: SerializedStyles = css({
    flex: '1',
    flexShrink: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderLeft: `1px solid ${ GREY_BASIC }`,
    borderRadius: `0 ${ BORDER_RADIUS_SMALL } ${ BORDER_RADIUS_SMALL } 0`,
    padding: '0 2em 1em',
    backgroundColor: WHITE,
});

export const widget_preview__header: SerializedStyles = css({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1em'
});

export const widget_preview__widget: SerializedStyles = css({
    height: '95%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '24em',
    minHeight: '42em',
    maxHeight: '50em',
    border: `8px solid ${ GREY_LIGHT }`,
    borderRadius: BORDER_RADIUS_EXTRA_LARGE,

    'a': {
        textDecoration: 'none',
    },

    'span': {
        fontSize: FONT_SIZE_MOBILE_SEMI_EXTRA,
    },
});

export const widget_preview__header__team_select = css({
    marginRight: '0.75em',
    width: '9em',
    paddingTop: '1em',
});
