import { AppDispatch } from "../../../store/store";
import { NavigateFunction } from "react-router-dom";
import { requestHandlerWrapper } from "../../../utils/request-handler-wrapper";
import { MagictapResponse } from "../../../models/magictap-response";
import { CreateTableRequest, CreateTableResponse } from "../../../models/api/api";
import { GENERIC_ERROR_MESSAGE } from "../../../models/common-constants";
import { NotificationType, showNotification } from "../../../store/features/notification-bar-slice";
import { createTable } from '../../request-services/tables';
import { loadTable } from '../../../store/features/team-table-slice';

const createTableFailure = (_: MagictapResponse<unknown>, dispatch: AppDispatch) => {
    dispatch(showNotification({ title: GENERIC_ERROR_MESSAGE, type: NotificationType.ERROR }));
};

const createTableSuccess = (response: MagictapResponse<CreateTableResponse>, dispatch: AppDispatch) => {
    dispatch(showNotification({ title: 'Saved successfully', type: NotificationType.SUCCESS }));
    loadTable(response.content!!);
};

export const createTableRequestHandler = (request: CreateTableRequest, dispatch: AppDispatch, navigate: NavigateFunction): void => {
    requestHandlerWrapper(
        createTable,
        request,
        createTableSuccess,
        createTableFailure,
        dispatch,
        navigate
    );
};
