import {AgGridColumnProps} from "ag-grid-react";
import {ICellRendererParams} from "ag-grid-community";
import dayjs from "dayjs";

export const teamsColumnDefinitions: AgGridColumnProps[] = [
  {
    field: 'name',
    headerName: 'team name',
    sortable: true,
    flex: 2,
    //TODO: enable after restyling
    //filter: 'agTextColumnFilter',
  },
  {
    field: 'description',
      headerName: 'description',
    flex: 2,
    sortable: true,
    //TODO: enable after restyling
    //filter: 'agTextColumnFilter',
  },
  {
    field: 'location',
    flex: 3,
    sortable: true,
  },
  {
    field: 'modifiedAt',
    headerName: 'update',
    sortable: true,
    flex: 2,
    cellRenderer: (x: ICellRendererParams) => dayjs(x.value).format('DD MMM, YYYY, HH:mm'),
  },
  {
    field: 'status',
    sortable: true,
    flex: 2,
    //TODO: enable after restyling
    //filter: 'agTextColumnFilter',
    cellRenderer: (x: ICellRendererParams) => x.value.toString(),
  },
];