/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { container__full_width, flex_container__vertical__full_height } from '../../../common-styles/containers';
import {
    page,
    page__container,
    page__container__header_row,
    page__header_preloader,
    page__header_row,
} from '../../../common-styles/pages';
import { getTeamTableConfigurationRoute } from "../../../routing/routes";
import { setTeamListLoader } from '../../../store/features/team-list-slice';
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { PrimaryButton } from '../../common/buttons/primary-button';
import { TeamTablesTable } from './team-tables-table';
import { NEW_ID } from '../../../models/common-constants';
import { team_list__create_team_button, team_list__header__container } from './team-tables-list.styles';
import { TeamListPreloader } from '../team-list/team-list-preloader';
import { selectTeamTablesList } from '../../../store/selectors/team-selectors';
import { getAllTablesRequestHandler } from '../../../services/request-handlers/team/tables-list-request-handler';
import { clearTablesForm } from '../../../store/features/team-table-slice';
import { PageNotFound } from '../../common/not-found-page/page-not-found';

export const TeamTablesList = () => {
    const { isDataLoading } = useAppSelector(selectTeamTablesList);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();

    const teamId = searchParams.get('teamId') || '';

    useEffect(() => {
        dispatch(setTeamListLoader(true));
        if(teamId) getAllTablesRequestHandler(teamId, dispatch, navigate);
    }, []);

    const onCreateNewTable = () => {
        navigate(getTeamTableConfigurationRoute(teamId, NEW_ID));
        dispatch(clearTablesForm());
    };

    if (!teamId) {
        return (
            <PageNotFound/>
        );
    }
    return (
        <div css={ page__container }>
            <div css={ page__container__header_row }>
                <h2>One table - one QR code</h2>
            </div>
            <div css={ page }>
                <div css={ [flex_container__vertical__full_height, container__full_width] }>
                    <div css={ [page__header_row, container__full_width, team_list__header__container] }>
                        <h2 css={ isDataLoading ? page__header_preloader : null }>
                            { !isDataLoading ? "Create and manage team's table" : null }
                        </h2>
                        <div css={ team_list__create_team_button }>
                            <PrimaryButton
                                label="Create new table"
                                onClick={ onCreateNewTable }
                                disabled={ isDataLoading }
                            />
                        </div>
                    </div>
                    { isDataLoading && <TeamListPreloader/> }
                    { (!isDataLoading) && <TeamTablesTable/> }
                </div>
            </div>
        </div>
    );
};
