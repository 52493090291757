import { css } from '@emotion/react';
import { BORDER_RADIUS_SMALL } from './borders';
import { WHITE } from './color-palette';
import { PAGE_CONTAINER_BOX_SHADOW } from './shadows';
import { skeleton__preloader } from './skeleton';
import { mobileDevices } from './adaptive-breakpoints';

export const page__loading_indicator = css({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

export const page__container = css({
    padding: '0 1.5em 1.5em',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: '1 1 auto',

    [mobileDevices]: {
        padding: 'unset',
    },
});

export const page__container__header_row = css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1.5em 0 1.5em 0.75em',
    h2: {
        margin: '0',
    },

    [mobileDevices]: {
        alignItems: 'flex-start',
        margin: 'unset',
        padding: '0.75em 0.5em',
    },
});

export const page = css({
    backgroundColor: WHITE,
    borderRadius: '0.75em',
    boxShadow: PAGE_CONTAINER_BOX_SHADOW,
    padding: '1.5em',
    display: 'flex',
    flex: '1 1 auto',

    [mobileDevices]: {
        margin: 'unset',
        padding: '1em 0.5em',
        borderRadius: 'unset',
    },
});

export const page__header_row = page__container__header_row;

export const page__header_preloader = css([
    skeleton__preloader, {
        minWidth: '10em',
        width: '10em',
        minHeight: '2.27rem',
        borderRadius: BORDER_RADIUS_SMALL,
    },
]);
