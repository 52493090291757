import { NavigateFunction } from "react-router-dom";
import { AccountDataResponse } from "../../../models/api/api";
import { GENERIC_ERROR_MESSAGE } from "../../../models/common-constants";
import { MagictapResponse } from "../../../models/magictap-response";
import { getAccount } from "../../request-services/account";
import { setAccountConfig, setAccountConfigLoading, setAccountConfigEdited } from "../../../store/features/account-config-slice";
import { NotificationType, showNotification } from "../../../store/features/notification-bar-slice";
import { AppDispatch } from "../../../store/store";
import { requestHandlerWrapper } from "../../../utils/request-handler-wrapper";

export const getAccountFailure = (_: any, dispatch: AppDispatch) => {
    dispatch(showNotification({ title: GENERIC_ERROR_MESSAGE, type: NotificationType.ERROR }));
};

function getAccountSuccess(accountResponse: MagictapResponse<AccountDataResponse>, dispatch: AppDispatch, navigate: NavigateFunction) {
    dispatch(setAccountConfig(accountResponse.content!!))
    dispatch(setAccountConfigLoading(false))
    dispatch(setAccountConfigEdited(false))
}
export const getAccountRequestHandler = (dispatch: AppDispatch, navigate: NavigateFunction) => {
    requestHandlerWrapper(
        getAccount,
        [],
        getAccountSuccess,
        getAccountFailure,
        dispatch,
        navigate,
    );
};
