/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from "@emotion/react"
import { Box, ClickAwayListener, Popper } from "@mui/material"
import { useState } from "react"
import { HexColorPicker } from "react-colorful"
import { GREY_BASIC } from "../../../../common-styles/color-palette"
import { ArrowUp } from "../../../../icons/ArrowUp"
import { ColorPickerIcon } from "../../../../icons/ColorPicker"
import { TeamTextInput } from "../../configuration/inputs/team-text-input"
import { color_picker, color_picker__icon, color_picker__palette } from "./color-picker.styles"

interface ColorPickerProps {
    onChangeColor: (newColor: string) => void
    label?: string,
    color?: string,
    disabled?: boolean,
    isLoading?: boolean
}

//TODO MO PR3: align & adjust according Figma
export const ColorPicker = ({ label, color, onChangeColor, disabled, isLoading }: ColorPickerProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleOnChangeColorTextValue = (value: string) => onChangeColor(sanitizeInput(value))
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (!disabled) {
            setAnchorEl(anchorEl ? null : event.currentTarget);
        }
    }
    const handleClickAway = () => {
        setAnchorEl(null);
    };
    const isOpen = !!anchorEl
    const id = isOpen ? 'color-picker-popper' : undefined;
    return (
        <ClickAwayListener onClickAway={ handleClickAway }>
            <div css={ color_picker }>
                <TeamTextInput
                    label={ label }
                    value={ color || "" }
                    disabled={ disabled }
                    onChange={ handleOnChangeColorTextValue }
                    isLoading={ isLoading }
                />
                { !isLoading && (
                    <div css={ color_picker__icon } onClick={ handleClick }>
                        { isOpen && <ArrowUp/> }
                        { !isOpen && <ColorPickerIcon fill={ color } stroke={ GREY_BASIC }/> }
                    </div>
                ) }
                <Popper id={ id } open={ isOpen } anchorEl={ anchorEl }>
                    <Box sx={ color_picker__palette }>
                        <HexColorPicker color={ color } onChange={ onChangeColor }/>
                    </Box>
                </Popper>
            </div>
        </ClickAwayListener>
    )
}

const sanitizeInput = (input: string): string => {
    return "#" + (input.replace(/[^0-9a-fA-F]/g, "").substring(0, 6).toUpperCase())
}
