export const ContactUs = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <g>
                <path fill="currentColor"
                      d="M6.455 19 2 22.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H6.455ZM7 10a5 5 0 1 0 10 0h-2a3 3 0 0 1-6 0H7Z"/>
            </g>

        </svg>
    );
};
