import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";
import {endOfMonth, endOfWeek, startOfMonth, startOfWeek, subDays} from "date-fns";
import {DateRangePreset} from "../../components/common/date-picker/date-range-picker";
import {DateRange} from "../../models/date-range";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc)

export interface ScorePeriodState {
    selectedDateRange: DateRange;
    selectedMonth: number;

    fromInputValue: string;
    toInputValue: string;
    isCalendarOpened: boolean;

    selectedDateRangePreset?: DateRangePreset;
}

const initialState: ScorePeriodState = {
    selectedDateRange: {
        from: dayjs().subtract(7, 'days').startOf('day').toDate().getTime(),
        to: dayjs().endOf('day').toDate().getTime(),
    },
    selectedMonth: dayjs().toDate().getTime(),

    fromInputValue: '',
    toInputValue: '',
    isCalendarOpened: false,
};

export const scorePeriodSlice = createSlice({
    name: 'scorePeriod',
    initialState,
    reducers: {
        changeDateRange: (state: Draft<ScorePeriodState>, {payload}: PayloadAction<DateRange | undefined>) => {
            console.log('From: ' + payload?.from);
            console.log('To: ' + payload?.to);
            state.selectedDateRangePreset = undefined;
            state.selectedDateRange = {...payload};
        },
        toggleCalendarPopup: (state: Draft<ScorePeriodState>, action: PayloadAction<boolean>) => {
            state.isCalendarOpened = action.payload;
        },
        // todo: write tests for that reducer
        changeDateRangePreset: (state: Draft<ScorePeriodState>, action: PayloadAction<DateRangePreset>) => {
            state.selectedDateRangePreset = action.payload;
            const today: Date = dayjs.utc().toDate();
            switch (action.payload) {
                case DateRangePreset.TODAY: {
                    const from = dayjs.utc().startOf('day').toDate().getTime();
                    const to = dayjs.utc().endOf('day').toDate().getTime();
                    state.selectedDateRange = {from, to};
                    break;
                }
                case DateRangePreset.THIS_WEEK: {
                    const from = startOfWeek(today, {weekStartsOn: 1}).getTime();
                    const to = endOfWeek(today, {weekStartsOn: 1}).getTime();
                    state.selectedDateRange = {from, to};
                    break;
                }
                case DateRangePreset.PREVIOUS_WEEK: {
                    const previousSunday = subDays(startOfWeek(today, {weekStartsOn: 1}), 1);
                    const from = startOfWeek(previousSunday, {weekStartsOn: 1}).getTime();
                    const to = previousSunday.getTime();
                    state.selectedDateRange = {from, to};
                    break;
                }
                case DateRangePreset.THIS_MONTH: {
                    const from = startOfMonth(today).getTime();
                    const to = endOfMonth(today).getTime();
                    state.selectedDateRange = {from, to};
                    break;
                }
            }
        },
    }
});

export const {
    changeDateRange,
    toggleCalendarPopup,
    changeDateRangePreset,
} = scorePeriodSlice.actions

export default scorePeriodSlice.reducer
