/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { Fragment } from "react";
import { HeaderPreloader } from "../skeleton-preloaders/header-preloader";
import { block__header } from "./block-header.styles";

export const BlockHeader = ({ isLoading, title }: { isLoading: boolean, title: string, }) => {
    return (
        <Fragment>
            { isLoading ? <HeaderPreloader/> : <h2 css={ block__header }>{ title }</h2> }
        </Fragment>
    );
};
