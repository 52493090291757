/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { TeamListState } from '../../../store/features/team-list-slice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { Team } from '../../../models/team';
import { setTeamDataLoader } from '../../../store/features/team-config-slice';
import { getTeamConfigurationRoute } from '../../../routing/routes';
import { TeamListSortFunctions } from '../../../models/sort-options';
import { CardsList } from '../../common/cards-list';
import { TeamCard } from './team-card';
import { CardElement } from '../../common/card-element';
import { selectTeamList } from '../../../store/selectors/team-selectors';

interface TeamListProps {
    teams: Team[];
}

export const TeamListCards = ({ teams }: TeamListProps) => {
    const { sortBy }: TeamListState = useAppSelector(selectTeamList);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const onRowClicked = (row: Team) => {
        dispatch(setTeamDataLoader(true));
        navigate(getTeamConfigurationRoute(row.id));
    };

    const cards = [...teams]
        .sort(TeamListSortFunctions[sortBy])
        .map(x => (
            <CardElement
                key={ x.id }
                onElementCLicked={ () => onRowClicked(x) }
                element={ <TeamCard data={ x }/> }
            />
        ));

    return (
        <CardsList cards={ cards }/>
    );
};
