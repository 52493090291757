import ArrowUp from "../static/icons/arrow-up-line.svg";
import ArrowDown from "../static/icons/arrow-down-line.svg";

export const getTrendIcon = (trend: number) => {
    if (trend > 0) {
        return ArrowUp;
    } else if (trend < 0) {
        return ArrowDown;
    }
    return ''
};
