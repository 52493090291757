/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import {
    happiness_score__gauge__trend,
    happiness_score__gauge__trend__icon,
    happiness_score__gauge__trend__percentage,
} from './happiness-score-gauge-chart.styles';
import { getTrendIcon } from '../../../utils/trend-icon-utils';

export const Trend = ({ trend, precision }: { trend: number, precision?: number }) => {
    return (
        <div css={ happiness_score__gauge__trend }>
            <img css={ happiness_score__gauge__trend__icon } src={ getTrendIcon(trend) } alt={ '' }/>
            <p css={ happiness_score__gauge__trend__percentage(trend) }>{ trend.toFixed(precision) }%</p>
        </div>
    );
};
