import { css } from "@emotion/react";
import { WHITE } from "../../../common-styles/color-palette";
import { BORDER_RADIUS_MEDIUM } from "../../../common-styles/borders";
import { PAGE_CONTAINER_BOX_SHADOW } from "../../../common-styles/shadows";
import { mobileDevices } from '../../../common-styles/adaptive-breakpoints';

export const happiness_score__container = css({
    width: '20%',
    backgroundColor: WHITE,
    borderRadius: BORDER_RADIUS_MEDIUM,
    boxShadow: PAGE_CONTAINER_BOX_SHADOW,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '0.5em',

    [mobileDevices]: {
        width: 'unset',
        padding: '0.5em 0.5em 1em',
        borderRadius: 'unset',
    },
});

export const happiness_score__comments_button = css({
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'flex-start',
    gap: '0.25em',
    alignItems: 'center',
});
