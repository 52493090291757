import {css} from "@emotion/react";
import {BORDER_RADIUS_EXTRA_SMALL} from "../../../common-styles/borders";
import {GREY_BASIC, WHITE} from "../../../common-styles/color-palette";
import { mobileDevices } from '../../../common-styles/adaptive-breakpoints';

export const drag_and_drop__container = css({
    paddingTop: '1.5em',
    paddingBottom: '1em',
    overflowX: 'hidden',

    [mobileDevices]: {
        maxHeight: '17em',
    },
});

export const drag_and_drop__card = (isDragging: boolean) => css({
    backgroundColor: WHITE,
    height: '3.5em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderRadius: BORDER_RADIUS_EXTRA_SMALL,
    paddingLeft: '1.5em',
    borderBottom: `${GREY_BASIC} 1px solid`,
    cursor: 'move',
    boxShadow: isDragging ? '0px 24px 56px rgba(14, 28, 40, 0.08)' : 'none',
    position: 'relative',

    '&:last-of-type': {
        borderBottom: 'none',
    },
});

export const drag_and_drop__delete_icon = css({
    position: 'absolute',
    right: '1em',
    top: '0.5em',
});
