/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {css, jsx} from '@emotion/react';
import {useEffect} from "react";
import {PageNotFoundSpaceman} from "./page-not-found-spaceman";
import {animatePageNotFoundImage} from "./animation-utils";

export const PageNotFound = () => {
    useEffect(animatePageNotFoundImage, []);

    return (
        <div css={css({width: '100%'})} className="container">
            <div className="row">
                <div
                    className="col-md-6 align-self-center"
                    css={css({
                        maxWidth: '700px',
                        maxHeight: '600px',
                    })}
                >
                    <PageNotFoundSpaceman/>
                </div>
                <div
                    css={css({
                        display: "flex",
                        flexDirection: 'column',
                        justifyContent: 'start',
                        textAlign: 'center',
                    })}
                >
                    <div
                        css={css({
                            fontSize: '8em'
                        })}
                    >
                        404
                    </div>
                    <div
                        css={css({
                            fontSize: '24px',
                            fontWeight: '700',
                        })}
                    >
                        OOPS! Something went wrong.
                    </div>
                    <p>The page you are looking for does not exist</p>
                </div>
            </div>
        </div>
    )
}