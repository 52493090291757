/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { css } from '@emotion/react';
import { GREEN_BASIC, GREY_LIGHT } from '../../../common-styles/color-palette';
import { BORDER_RADIUS_EXTRA_SMALL } from '../../../common-styles/borders';

export const SingleBarChart = ({ percent }: { percent: number }) => (
    <div css={ css({
        backgroundColor: GREY_LIGHT,
        height: '1em',
        borderRadius: BORDER_RADIUS_EXTRA_SMALL,
        width: `100%`,
    }) }>
        <div css={ css({
            backgroundColor: GREEN_BASIC,
            height: '1em',
            borderRadius: BORDER_RADIUS_EXTRA_SMALL,
            width: `${ percent }%`,
        }) }/>
    </div>
);
