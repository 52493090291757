import {API_PATH} from "../request-path-constants";
import {apiHost} from "../api-host-resolver";

export const resetPassword = async (request: { email: string }) => {
    return await fetch(`${apiHost}${API_PATH}/passwords/reset`, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    })
        .then(resp => resp.json().then(x => x))
        .catch(e => console.error('Unexpected error during reset password request: ' + e));
}

export const updatePassword = async (request: { password: string, hash: string }) => {
    return await fetch(`${apiHost}${API_PATH}/passwords/${request.hash}`, {
        method: 'POST',
        body: JSON.stringify({ password: request.password }),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    })
        .then(resp => resp.json().then(x => x))
        .catch(e => console.error('Unexpected error during reset password request: ' + e));
}
