import React from 'react';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {Provider} from 'react-redux';
import {store} from "./store/store";
import {App} from "./App";
import {createRoot} from "react-dom/client";


const rootContainer = document.getElementById('root');
const root = createRoot(rootContainer!);

root.render(
    // todo: Should be StrictMode.
    //       Shouldn't be?
    <React.Fragment>
        <BrowserRouter>
            <Provider store={store}>
                    <App/>
            </Provider>
        </BrowserRouter>
    </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
