import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";

interface HappinessScoreState {
    happinessScore: number;
    happinessScoreTrend: number;
    numberOfResponses: number;
    happinessScoreIsLoading: boolean;
}

const initialState: HappinessScoreState = {
    happinessScore: 0,
    happinessScoreTrend: 0,
    numberOfResponses: 0,
    happinessScoreIsLoading: false,
};

export const happinessScoreSlice = createSlice({
    name: 'happinessScore',
    initialState,
    reducers: {
        changeHappinessScore: (state: Draft<HappinessScoreState>, action: PayloadAction<number>) => {
            state.happinessScore = action.payload;
        },
        changeHappinessScoreTrend: (state: Draft<HappinessScoreState>, action: PayloadAction<number>) => {
            state.happinessScoreTrend = action.payload;
        },
        changeNumberOfResponses: (state: Draft<HappinessScoreState>, action: PayloadAction<number>) => {
            state.numberOfResponses = action.payload;
        },
        changeHappinessScoreDataLoader: (state: Draft<HappinessScoreState>, action: PayloadAction<boolean>) => {
            state.happinessScoreIsLoading = action.payload;
        },
    }
});

export const {
    changeHappinessScore,
    changeHappinessScoreTrend,
    changeNumberOfResponses,
    changeHappinessScoreDataLoader,
} = happinessScoreSlice.actions

export default happinessScoreSlice.reducer
