import {css, keyframes} from "@emotion/react";
import {GREY_BASIC, GREY_LIGHT} from "./color-palette";

const skeleton_animation = keyframes`
  to {
    background-position-x: -200%;
  }
`;

export const skeleton__preloader = css({
    backgroundColor: GREY_BASIC,
    background: `linear-gradient(110deg, ${GREY_BASIC} 8%, ${GREY_LIGHT} 18%, ${GREY_BASIC} 33%)`,
    backgroundSize: '200% 100%',
    animation: `${skeleton_animation} 1s linear infinite`,
});

export const skeleton__preloader__histogram = css({
    backgroundColor: GREY_BASIC,
    background: `linear-gradient(95deg, ${GREY_BASIC} 8%, ${GREY_LIGHT} 18%, ${GREY_BASIC} 33%)`,
    backgroundSize: '200% 100%',
    animation: `${skeleton_animation} 1s linear infinite`,
});
