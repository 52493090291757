import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getFeedbacks } from '../services/request-services/feedbacks';
import dayjs from 'dayjs';
import { ALL_FILTER_PRESET } from '../models/filters';
import {
    changeCategoriesFilter,
    changeRatingFilter,
    changeTeamFilter,
    loadDetailedFeedbacks,
    openDetailedFeedbacks,
} from '../store/features/detailed-feedbacks-slice';
import { MagictapResponse } from '../models/magictap-response';
import { handleApiCall } from '../utils/request-handler-saga-wrapper';
import { feedbacksFilterSelector, FeedbacksFilterSelectorState } from '../store/selectors/detailed-feedbacks.selector';
import { changeDateRange, changeDateRangePreset } from '../store/features/score-period-slice';
import { DetailedFeedbackRequest, DetailedFeedbackResponse } from '../models/api/api';
import { loadAllTeamsShortInfo } from '../store/features/team-list-slice';
import { navigateTo } from '../store/features/navigation.slice';
import { DETAILED_FEEDBACK_ROUTE } from '../routing/routes';

function* fetchFeedbacks() {
    const {
        teamIds,
        ratingFilter,
        categoriesFilter,
        from,
        to,
    }: FeedbacksFilterSelectorState = yield select(feedbacksFilterSelector);
    const request: DetailedFeedbackRequest = {
        teamIds: teamIds === ALL_FILTER_PRESET ? undefined : [...teamIds],
        from: +((from || dayjs().subtract(1, 'year').toDate().getTime())).toFixed(),
        to: +((to || dayjs().add(1, 'year').toDate().getTime())).toFixed(),
        rating: ratingFilter === ALL_FILTER_PRESET ? undefined : [...ratingFilter],
        categories: categoriesFilter === ALL_FILTER_PRESET ? undefined : [...categoriesFilter],
    };
    const apiCall = () => handleApiCall(getFeedbacks, request);
    const feedbacks: MagictapResponse<DetailedFeedbackResponse[]> = yield call(apiCall);

    yield put(loadDetailedFeedbacks(feedbacks?.content || []));
}

function* openFeedbacks() {
    yield put(navigateTo(DETAILED_FEEDBACK_ROUTE));
}

function* detailedFeedbacksSaga() {
    yield takeLatest([
        loadAllTeamsShortInfo,
        changeTeamFilter,
        changeRatingFilter,
        changeDateRangePreset,
        changeDateRange,
        changeCategoriesFilter,
    ], fetchFeedbacks);

    yield takeLatest(openDetailedFeedbacks, openFeedbacks);
}

export default detailedFeedbacksSaga;