/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {css, jsx, keyframes} from '@emotion/react';
import {BLUE_BASIC, GREEN_BASIC} from "../../common-styles/color-palette";

const preloader_ellipsis = css({
    display: 'inline-block',
    position: 'relative',
    width: '80px',
    height: '100%',
});

const preloader_ellipsis_1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const preloader_ellipsis_2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
`;

const preloader_ellipsis_3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

const preloader_ellipsis__circle = css({
    position: 'absolute',
    top: '45%',
    width: '13px',
    height: '13px',
    borderRadius: '50%',
    background: GREEN_BASIC,
    animationTimingFunction: 'cubic-bezier(0, 1, 1, 0)',

    ':nth-of-type(1)': {
        left: '8px',
        animation: `${preloader_ellipsis_1} 0.65s infinite`,
    },
    ':nth-of-type(2)': {
        left: '8px',
        animation: `${preloader_ellipsis_2} 0.65s infinite`,
    },
    ':nth-of-type(3)': {
        left: '32px',
        backgroundColor: BLUE_BASIC,
        animation: `${preloader_ellipsis_2} 0.65s infinite`,
    },
    ':nth-of-type(4)': {
        left: '56px',
        animation: `${preloader_ellipsis_2} 0.65s infinite`,
    },
    ':nth-of-type(5)': {
        left: '80px',
        animation: `${preloader_ellipsis_3} 0.65s infinite`,
    },
});

export const Preloader = () => {
    return (
        <div css={preloader_ellipsis}>
            <div css={preloader_ellipsis__circle}></div>
            <div css={preloader_ellipsis__circle}></div>
            <div css={preloader_ellipsis__circle}></div>
            <div css={preloader_ellipsis__circle}></div>
            <div css={preloader_ellipsis__circle}></div>
        </div>
    )
};
