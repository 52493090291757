import {css} from "@emotion/react";
import {BLUE_BASIC, BLUE_BASIC_OPAQUE, DARK_LIGHT_OPAQUE, GREY_DARK, WHITE} from "../../common-styles/color-palette";
import {BORDER_RADIUS_SMALL} from "../../common-styles/borders";
import {REGULAR_FONT} from "../../common-styles/font-styles";

export const navigation_menu__list_items__item__text = (isSelected: boolean) => css({
    marginLeft: '1em',
    color: isSelected ? WHITE : 'unset',
    '& .MuiTypography-root': {
        fontFamily: REGULAR_FONT,
    },
});

export const navigation_menu__list_items__dark_theme = css({
    color: GREY_DARK,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 1em',
    width: 'auto',

});

export const navigation_menu__list_items__item = (isSelected: boolean) => css({
    justifyContent: 'center',
    backgroundColor: isSelected ? BLUE_BASIC_OPAQUE : 'unset',
    borderRadius: BORDER_RADIUS_SMALL,
    height: '3em',
    ':hover': {
        color: WHITE,
        backgroundColor: BLUE_BASIC_OPAQUE,
        '& .MuiListItemIcon-root': {
            color: WHITE,
        },
        '& .MuiListItemButton-root': {
            color: WHITE,
        },
    },
    '& .MuiListItemIcon-root': {
        minWidth: '24px',
    }
});

export const navigation_menu__list_items__item__icon = (isSelected: boolean) => css({
    color: isSelected ? BLUE_BASIC : GREY_DARK,
    width: '1.5em',
});

export const navigation_bar__toggle_button = css({
    ':hover': {
        color: WHITE,
        backgroundColor: DARK_LIGHT_OPAQUE,
    },
});
