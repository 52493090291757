/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { changeSortOrder, TeamListState } from '../../../store/features/team-list-slice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import {
    sortTeamListOptions,
    TeamListSortOption,
    TeamListSortOptionsStringRepresentation,
} from '../../../models/sort-options';
import { Select } from '../../common/dropdowns/select';
import { MenuItem } from '@mui/material';
import { selectTeamList } from '../../../store/selectors/team-selectors';

export const TeamSortSelector = () => {
    const { sortBy }: TeamListState = useAppSelector(selectTeamList);
    const dispatch = useAppDispatch();

    const onSortOrderChange = (e: SelectChangeEvent<unknown>) => {
        dispatch(changeSortOrder(e.target.value as TeamListSortOption));
    };

    return (
        <div css={ css({ height: '2.5em', width: '100%', margin: '0.5em 0' }) }>
            <Select
                value={ sortBy }
                onChange={ onSortOrderChange }
            >
                { sortTeamListOptions.map((x, i) => (
                    <MenuItem key={ i } value={ x }>{ TeamListSortOptionsStringRepresentation.get(x) }</MenuItem>
                )) }
            </Select>
        </div>
    );
};
