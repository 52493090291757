/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import React, { ChangeEvent } from "react";
import { TextInput } from "./text-input";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton } from "../buttons/icon-button";
import {
    DARK_BASIC,
    GREEN_BASIC,
    GREEN_DARK,
    GREY_MEDIUM,
    RED_BASIC,
    RED_LIGHT,
} from "../../../common-styles/color-palette";
import { IconButtonProps } from "@mui/material";

const IconButtonPassword = (props: IconButtonProps & { haserror?: string }) => {
    return <IconButton
        { ...props }
        css={ css({
            color: !!props.haserror ? RED_LIGHT : DARK_BASIC,
            ':hover': {
                color: props.haserror ? RED_BASIC : GREEN_DARK,
                backgroundColor: 'unset',
            },
            ':disabled': {
                color: GREY_MEDIUM,
            },
        }) }
    />;
};

export interface PasswordProps {
    label: string;
    onChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    onShowPasswordClick: () => void;
    passwordVisible: boolean;
    disabled?: boolean;
    autocompleteName?: string;
    autocomplete?: string;
    onBlur?: () => void;
    hasError?: boolean;
    errorMessage?: string;
}

export const PasswordInput =
    ({
         label,
         errorMessage,
         onBlur,
         hasError,
         onChange,
         autocomplete,
         autocompleteName,
         onShowPasswordClick,
         passwordVisible,
         disabled,
     }: PasswordProps) => {
        return (
            <div
                css={ css({
                    width: '100%',
                    position: 'relative',
                    ':hover': {
                        '& .MuiFilledInput-input:not(focus)': {
                            border: `${ GREEN_BASIC } 1px solid`,
                        },
                    },
                }) }
            >
                <TextInput
                    type={ passwordVisible ? "text" : "password" }
                    label={ label }
                    required={ true }
                    onBlur={ onBlur }
                    onChange={ onChange }
                    autocompleteName={ autocompleteName }
                    autocomplete={ autocomplete }
                    hasError={ hasError }
                    disabled={ disabled }
                    errorMessage={ errorMessage }
                />
                <div css={ css({
                    position: 'absolute',
                    top: '0',
                    right: '0.5em',
                    pointerEvents: disabled ? 'none' : 'auto',
                }) }>
                    <IconButtonPassword
                        onClick={ onShowPasswordClick }
                        haserror={ hasError ? "true" : undefined }
                        disabled={ disabled }
                    >
                        { passwordVisible ? <VisibilityOff/> : <Visibility/> }
                    </IconButtonPassword>
                </div>
            </div>
        );
    };
