import {AppDispatch} from "../../../store/store";
import {NavigateFunction} from "react-router-dom";
import {register} from "../../request-services/signup";
import {changeRegistrationLoadingIndicator, registrationRequestFailed} from "../../../store/features/signup-slice";
import {WIZARD_VERIFICATION_ROUTE} from "../../../routing/routes";
import {requestHandlerWrapper} from "../../../utils/request-handler-wrapper";
import {ErrorCode, MagictapResponse} from "../../../models/magictap-response";
import {GENERIC_ERROR_MESSAGE} from "../../../models/common-constants";
import {SIGNUP_EMAIL_LS_KEY, SIGNUP_STEP_LS_KEY} from "../../../models/localstorage-keys";
import {SignupStep} from "../../../models/signup-step";

const registerFailure = (response: MagictapResponse<unknown>, dispatch: AppDispatch) => {
    if (response.errorCode === ErrorCode.ACCOUNT_ALREADY_EXIST) {
        dispatch(registrationRequestFailed("This email is already registered!"));
    } else {
        dispatch(registrationRequestFailed(GENERIC_ERROR_MESSAGE));
    }
    dispatch(changeRegistrationLoadingIndicator(false));
};

const registerSuccess = (email: string, response: MagictapResponse<null>, dispatch: AppDispatch, navigate: NavigateFunction) => {
    dispatch(changeRegistrationLoadingIndicator(false));
    localStorage.setItem(SIGNUP_STEP_LS_KEY, SignupStep.VERIFICATION);
    localStorage.setItem(SIGNUP_EMAIL_LS_KEY, email);
    navigate(`/${WIZARD_VERIFICATION_ROUTE}`);
};

export const registerRequestHandler = (email: string, dispatch: AppDispatch, navigate: NavigateFunction): void => {
    requestHandlerWrapper(
        register,
        {email},
        (response: MagictapResponse<null>, dispatch: AppDispatch, navigate: NavigateFunction) =>
            registerSuccess(email, response, dispatch, navigate),
        registerFailure,
        dispatch,
        navigate
    );
};
