/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { useAppSelector } from "../../../store/hooks";
import { container__full_height } from "../../../common-styles/containers";
import { top_rated_categories__container, top_rated_categories__scores_container } from "./top-rated-categories.styles";
import { CategoryScoreCard } from "./category-score-card";
import { BlockHeader } from "../../common/headers/block-header";
import { mobileDevices } from '../../../common-styles/adaptive-breakpoints';
import { byMentionsDesc } from '../../../utils/functors';
import { CategoriesEmptyState } from './categories-empty-state';

export const TopRatedCategories = () => {
    const { categories, dashboardDataLoading } = useAppSelector(state => state.dashboard);

    return (
        <div css={ top_rated_categories__container }>
            <div css={ [container__full_height, css({
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '0.5em',

                [mobileDevices]: {
                    height: 'unset',
                    padding: '1em 0.5em',
                    marginBottom: 'unset',
                },
            })] }>
                <BlockHeader title={ "Rated Categories" } isLoading={ dashboardDataLoading }/>
                { !categories.length && <CategoriesEmptyState/> }
                { !!categories.length && (
                    <div css={ top_rated_categories__scores_container }>
                        {
                            categories
                                .filter(x => !!x.data.category)
                                .sort(byMentionsDesc)
                                .slice(0, 4)
                                .map((category, index) =>
                                    <CategoryScoreCard category={ category } key={ index }/>,
                                )
                        }
                    </div>
                ) }
            </div>
        </div>
    );
};
