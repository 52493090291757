import { AppDispatch } from "../../store/store";
import { NavigateFunction } from "react-router-dom";
import { login } from "../request-services/login";
import { changeRequestError, changeRequestIsProcessing } from "../../store/features/login-slice";
import { setAuthorised } from "../../store/features/authorisation-slice";
import { requestHandlerWrapper } from "../../utils/request-handler-wrapper";
import { MagictapResponse } from "../../models/magictap-response";
import { selectSideNavigationItem } from '../../store/features/navigation-bar-slice';
import { NavigationItemName } from '../../routing/pages';

const successfulLogin = (response: MagictapResponse<null>, dispatch: AppDispatch, navigate: NavigateFunction) => {
    console.log('Logged in!');
    dispatch(setAuthorised(true));
    dispatch(changeRequestIsProcessing(false));
    dispatch(selectSideNavigationItem(NavigationItemName.DASHBOARD));
    navigate('/');
};

const failedLogin = (response: MagictapResponse<unknown>, dispatch: AppDispatch) => {
    dispatch(changeRequestError('Incorrect credentials'));
    dispatch(changeRequestIsProcessing(false));
};

export const loginRequestHandler = (email: string, password: string, dispatch: AppDispatch, navigate: NavigateFunction) => {
    requestHandlerWrapper(
        login,
        {
            email,
            password: btoa(password),
        },
        successfulLogin,
        failedLogin,
        dispatch,
        navigate
    );
};
