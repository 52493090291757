/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { useDropzone } from 'react-dropzone';
import { skeleton__preloader } from '../../../../../common-styles/skeleton';
import { FileUploaderDropzone } from './file-uploader-dropzone';
import { FileUploaderPreview } from './file-uploader-preview';
import {file_uploader__preview__container} from './file-uploader-preview.styles';
import { file_uploader } from './file-uploader.styles';
import { toBase64 } from './to-base64';

interface FileUploaderProps {
    value?: string;
    onChange: (base64Upload?: string) => void;
    emptyStateTitle: string,
    emptyStateDescription: string,
    isLoading?: boolean
    //TODO: enablePreview prop. False will render icon with name and type instead of preview
}

export const FileUploader = (props: FileUploaderProps) => {
    const { fileRejections } = useDropzone({ onDragEnter: () => fileRejections.length = 0 })
    const { value, onChange, emptyStateTitle, emptyStateDescription, isLoading } = props;

    const onDropAcceptedHandler = (acceptedFiles: File[]) => {
        toBase64(acceptedFiles[0]).then((base64upload?: string) => {
            onChange(base64upload)
        })
    }

    const onRemoveUpload = () => {
        onChange(undefined)
    }

    return <div css={file_uploader}>
        {isLoading && <div css={css([file_uploader__preview__container, skeleton__preloader])}/>}
        {!isLoading && value && <FileUploaderPreview value={value} onRemoveUpload={onRemoveUpload}/>}
        {!isLoading && !value && <FileUploaderDropzone
            onDropAccepted={onDropAcceptedHandler}
            dropzoneTitle={emptyStateTitle}
            dropzoneDescription={emptyStateDescription}
        />}
    </div>
}
