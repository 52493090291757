export const sum = (a: number, b: number) => a + b;
export const one = () => 1;
export const byMentionsDesc: (a: any, b: any) => number = (a, b) => b.data.mentions - a.data.mentions;

export const stringCompareAsc: (a: string, b: string) => number = (a, b) => {
    if (a < b) {
        return -1;
    }

    if (a > b) {
        return 1;
    }

    // names must be equal
    return 0;
}

export const numberCompareDesc = (a: number, b: number): number => b - a;
