export const QrHover = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g>
                <path fill="currentColor" fillRule="evenodd"
                      d="M5 3H3v5h2V5h3V3H5Zm14 18h2v-5h-2v3h-3v2h3Zm2-16V3h-5v2h3v3h2V5ZM3 19v2h5v-2H5v-3H3v3Z"
                      clipRule="evenodd"/>
                <path fill="currentColor" d="M7 13h4v4H7zm0-6h4v4H7zm6 0h4v4h-4zm0 10v-2h2v2zm2-2v-2h2v2z"/>
            </g>

        </svg>
    );
};
