/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    page__container,
    page__container__header_row,
    page__header_preloader,
    page__header_row,
} from '../../common-styles/pages';
import { getTeamRequestHandler } from '../../services/request-handlers/team/get-team-request-handler';
import { setTeamDataLoader } from "../../store/features/team-config-slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { PageNotFound } from "../common/not-found-page/page-not-found";
import { TeamConfigurationForm } from "./configuration/team-configuration-form";
import { team_configuration_form__container, team_page, team_qr__container } from './team-page.styles';
import { QrCodeConfiguration } from './qr-code-configuration';
import { NEW_ID } from '../../models/common-constants';

export const TeamPage = () => {
    const { isDataLoading, name, qrHash } = useAppSelector(state => state.teamConfig);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get('teamId') || NEW_ID;
    const isNewTeam = !id || id === NEW_ID;
    useEffect(() => {
        if(!isNewTeam) {
            dispatch(setTeamDataLoader(true));
            getTeamRequestHandler(id, dispatch, navigate);
        } else {
            dispatch(setTeamDataLoader(false));
        }
    }, [id]);

    if(!id) {
        return (
            <PageNotFound/>
        );
    }
    return (
        <div css={ page__container }>
            <div css={ page__container__header_row }>
                <h2>One team - one QR code</h2>
            </div>
            <div css={ team_page }>
                <div css={ team_configuration_form__container }>
                    <div css={ [page__header_row, isDataLoading ? page__header_preloader : null] }>
                        <h2>{ isDataLoading ? "" : isNewTeam ? "Create team" : "Edit team" }</h2>
                    </div>
                    <TeamConfigurationForm/>
                </div>
                <div css={ team_qr__container }>
                    <QrCodeConfiguration id={ id } name={ name } isDataLoading={ isDataLoading } qrHash={ qrHash }/>
                </div>
            </div>

        </div>
    );
};
