import { AUTHORIZATION_LS_KEY } from "../../models/localstorage-keys";
import { apiHost } from "../api-host-resolver";
import { API_PATH } from "../request-path-constants";
import { MagictapResponse } from "../../models/magictap-response";
import { DetailedFeedbackResponse, DetailedFeedbackRequest } from "../../models/api/api";

export const getFeedbacks: (req: DetailedFeedbackRequest) => Promise<MagictapResponse<DetailedFeedbackResponse[]>> =
    async ({
               from,
               rating,
               teamIds,
               to,
               categories,
           }: DetailedFeedbackRequest) => {
        const authUserToken = localStorage.getItem(AUTHORIZATION_LS_KEY) || '';
        const teamIdsQueryParam: RequestInfo = teamIds ? `teamIds=[${ teamIds }]&` : '';
        const categoriesQueryParams: RequestInfo = categories ? `categories=[${ categories }]` : '';
        const ratingQueryParams: RequestInfo = rating ? `rating=[${ rating }]&` : '';
        const queryParams: RequestInfo = `${ teamIdsQueryParam }from=${ from }&to=${ to }&${ ratingQueryParams }${ categoriesQueryParams }`;
        return await fetch(`${ apiHost }${ API_PATH }/aggregations/reviews?${ queryParams }`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'auth_user_session': authUserToken,
            },
        })
            .then(resp => resp.json().then(x => x))
            .catch(e => console.log(e));
    };
