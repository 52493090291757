import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { Category, FeedbackRecipientType, WorkspaceResponse } from "../../models/api/api";
import update from "immutability-helper";

export interface WorkspaceState {
    workspaceLogo: string | null;
    welcomeMessage: string;
    categories: Category[];
    enableMessageInput: boolean;
    feedbackFor: FeedbackRecipientType;

    isDataLoading: boolean;

    isConfigurationEdited: boolean;
    newCategoryEmoji?: string;
    newCategoryLabel?: string;
    isPickerVisible: boolean;

    isPromoteToSocialEnabled: boolean;
    thankYouPageLogo: string | null;
    minimalFeedbackScoreForPromoteToSocial?: number;

    widgetConfigurationTab: number;
    isThankYouPagePreview: boolean;
}

const initialState: WorkspaceState = {
    welcomeMessage: '',
    workspaceLogo: null,
    categories: [],
    enableMessageInput: false,
    feedbackFor: FeedbackRecipientType.TEAM,

    isDataLoading: false,

    isConfigurationEdited: false,
    newCategoryEmoji: '✋',
    newCategoryLabel: '',
    isPickerVisible: false,

    isPromoteToSocialEnabled: false,
    thankYouPageLogo: '',

    widgetConfigurationTab: 0,
    isThankYouPagePreview: false,
};

export const workspaceSlice = createSlice({
    name: 'workspaceConfig',
    initialState,
    reducers: {
        changeWidgetDataLoader: (state: Draft<WorkspaceState>, action: PayloadAction<boolean>) => {
            state.isDataLoading = action.payload;
        },
        changeWorkspaceLogo: (state: Draft<WorkspaceState>, action: PayloadAction<string | undefined>) => {
            state.workspaceLogo = action.payload || null;
            state.isConfigurationEdited = true;
        },
        changeWelcomeMessage: (state: Draft<WorkspaceState>, action: PayloadAction<string>) => {
            state.welcomeMessage = action.payload;
            state.isConfigurationEdited = true;
        },
        changeEnableMessageInput: (state: Draft<WorkspaceState>, action: PayloadAction<boolean>) => {
            state.enableMessageInput = action.payload;
            state.isConfigurationEdited = true;
        },
        changePickerVisibility: (state: Draft<WorkspaceState>, action: PayloadAction<boolean>) => {
            state.isPickerVisible = action.payload;
        },
        changeNewCategoryEmoji: (state: Draft<WorkspaceState>, action: PayloadAction<string>) => {
            state.newCategoryEmoji = action.payload;
        },
        changeNewCategoryLabel: (state: Draft<WorkspaceState>, action: PayloadAction<string>) => {
            state.newCategoryLabel = action.payload;
        },
        workspaceConfigurationSaved: (state: Draft<WorkspaceState>) => {
            state.isConfigurationEdited = false;
        },
        loadWorkspaceConfiguration: (state: Draft<WorkspaceState>, { payload }: PayloadAction<WorkspaceResponse>) => {
            state.workspaceLogo = payload.workspaceLogo;
            state.welcomeMessage = payload.welcomeMessage || '';
            state.categories = [...payload.categories]
                .filter(x => x.isActive)
                .sort((x, y) => x.order - y.order) || [];
            state.feedbackFor = payload.feedbackFor;
            state.enableMessageInput = payload.isCommentariesEnabled;
            state.isPromoteToSocialEnabled = payload.isPromoteToSocialEnabled;
            state.thankYouPageLogo = payload.thankYouPageLogo;
            state.minimalFeedbackScoreForPromoteToSocial = payload.minimalFeedbackScoreForPromoteToSocial || undefined;
        },
        changeCategoriesOrder: (state: Draft<WorkspaceState>,
                                { payload: { source, target } }: PayloadAction<{ source: number, target: number }>) => {
            const currentCategories = [...state.categories];
            const newCategories = update(currentCategories, {
                $splice: [
                    [source, 1],
                    [target, 0, currentCategories[source]],
                ],
            });

            let order = 0;
            state.categories = [...newCategories
                .map(x => ({
                    ...x,
                    order: order++,
                })),
            ];
            state.isConfigurationEdited = true;
        },
        removeCategory: (state: Draft<WorkspaceState>, action: PayloadAction<number>) => {
            const newCategories = update([...state.categories], {
                $splice: [
                    [action.payload, 1],
                ],
            });
            let order = 0;
            state.categories = [...newCategories
                .map(x => ({
                    ...x,
                    order: order++,
                })),
            ];
            state.isConfigurationEdited = true;
        },
        appendCategory: (state: Draft<WorkspaceState>, action: PayloadAction<Category>) => {
            state.categories = [...state.categories, action.payload];
            state.isConfigurationEdited = true;
        },
        togglePromoteToSocial: (state: Draft<WorkspaceState>, action: PayloadAction<boolean>) => {
            state.isPromoteToSocialEnabled = action.payload;
            // make default minimal score 4 if option is enabled
            state.minimalFeedbackScoreForPromoteToSocial = action.payload ? 4 : undefined;
            state.isConfigurationEdited = true;
        },
        changeThankYouPageLogo: (state: Draft<WorkspaceState>, action: PayloadAction<string | undefined>) => {
            state.thankYouPageLogo = action.payload || null;
            state.isConfigurationEdited = true;
        },
        changeMinimalFeedbackScoreForPromoteToSocial: (state: Draft<WorkspaceState>, action: PayloadAction<number>) => {
            state.minimalFeedbackScoreForPromoteToSocial = action.payload;
            state.isConfigurationEdited = true;
        },
        changeWidgetConfigurationTab: (state: Draft<WorkspaceState>, action: PayloadAction<number>) => {
            state.widgetConfigurationTab = action.payload;
            state.isThankYouPagePreview = action.payload === 1;
        },
    },
});

export const {
    changeWidgetDataLoader,
    changeCategoriesOrder,
    removeCategory,
    appendCategory,
    changeWorkspaceLogo,
    changeWelcomeMessage,
    changeEnableMessageInput,
    changePickerVisibility,
    changeNewCategoryEmoji,
    changeNewCategoryLabel,
    loadWorkspaceConfiguration,
    workspaceConfigurationSaved,
    togglePromoteToSocial,
    changeMinimalFeedbackScoreForPromoteToSocial,
    changeThankYouPageLogo,
    changeWidgetConfigurationTab,
} = workspaceSlice.actions;

export default workspaceSlice.reducer;
