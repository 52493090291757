/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useEffect } from "react";
import {
    container__full_height,
    container__full_width,
    flex_container__vertical__full_height,
} from "../../common-styles/containers";
import { DashboardHeader } from "./dashboard-header";
import { changeHappinessScoreDataLoader } from "../../store/features/happiness-score-slice";
import { dashboardRequestHandler } from "../../services/request-handlers/dashboard/dashboard-charts-request-handler";
import { ReviewsStackedHistogram } from "./histogram/reviews-stacked-histogram";
import { getAllTeamsRequestHandler } from "../../services/request-handlers/team/team-list-request-handler";
import { useNavigate } from "react-router-dom";
import { TopRatedCategories } from "./categories/top-rated-categories";
import { HappinessScoreCard } from "./happiness-score/happiness-score-card";
import { dashboard__container, dashboard__happiness_score_charts_area } from "./dashboard.styles";
import { changeDashboardDataLoader } from "../../store/features/dashboard-slice";
import { changeRatingFilter } from '../../store/features/detailed-feedbacks-slice';
import { ALL_FILTER_PRESET } from '../../models/filters';
import { mobileDevices } from '../../common-styles/adaptive-breakpoints';
import { page__container } from '../../common-styles/pages';

export const Dashboard = () => {
    const { selectedDateRange } = useAppSelector(state => state.scorePeriod);
    const { teamsFilter } = useAppSelector(state => state.detailedFeedbacks);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        getAllTeamsRequestHandler(dispatch, navigate);
    }, []);

    useEffect(() => {
        // todo: remove this after race condition on backend will be fixed
        setTimeout(() => {
            dispatch(changeHappinessScoreDataLoader(true));
            dispatch(changeDashboardDataLoader(true));
            dashboardRequestHandler(selectedDateRange, teamsFilter, dispatch, navigate);
        }, 20);
        dispatch(changeRatingFilter(ALL_FILTER_PRESET));
    }, [teamsFilter, selectedDateRange.from, selectedDateRange.to]);

    return (
        <div css={ [flex_container__vertical__full_height, page__container, dashboard__container] }>
            <DashboardHeader/>
            <div css={ [container__full_width, container__full_height, css(css({
                display: 'flex',
                flexDirection: 'column',
                gap: '1em',

                [mobileDevices]: { gap: '0.5em' },
            }))] }>
                <div css={ [container__full_width, dashboard__happiness_score_charts_area] }>
                    <HappinessScoreCard/>
                    <TopRatedCategories/>
                </div>
                <ReviewsStackedHistogram/>
            </div>
        </div>
    );
};
