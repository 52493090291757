/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import React, { ChangeEvent, MouseEvent } from "react";
import { TextInput } from "../common/inputs/text-input";
import { SecondaryButton } from "../common/buttons/secondary-button";
import {
    appendCategory,
    changeNewCategoryEmoji,
    changeNewCategoryLabel,
    changePickerVisibility,
} from "../../store/features/workspace-config-slice";
import { ClickAwayListener, Fade, Paper, Popper } from "@mui/material";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
    category__add_button,
    category__emoji_picker,
    category__input,
    category__input_container,
    category__name,
} from './categories-list.styles';
import { configuration__footer } from './widget-configuration.style';
import { skeleton__preloader } from '../../common-styles/skeleton';
import { BORDER_RADIUS_SMALL } from '../../common-styles/borders';
import { selectWorkspaceConfig } from '../../store/selectors/workspace-config-selectors';
import { NotificationType, showNotification } from '../../store/features/notification-bar-slice';
import { WidgetConfigurationSaveButton } from './widget-configuration-save-button';

export const FOOTER_HEIGHT: string = '7em';

export const ConfigurationFooter = () => {
    const {
        isPickerVisible,
        newCategoryEmoji,
        newCategoryLabel,
        categories,
        isDataLoading,
    } = useAppSelector(selectWorkspaceConfig);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const dispatch = useAppDispatch();

    const onEmojiPickerStateHandler = (e: MouseEvent<HTMLDivElement>) => {
        dispatch(changePickerVisibility(!isPickerVisible));
        setAnchorEl(anchorEl ? null : e.currentTarget);
    };

    const onNewCategoryEmojiHandler = (e: EmojiClickData) => {
        dispatch(changeNewCategoryEmoji(e.emoji));
    };

    const onNewCategoryLabelHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(changeNewCategoryLabel(e.target.value));
    };

    const onAddCategoryHandler = () => {
        if(categories.find(x => x.label === newCategoryLabel)) {
            dispatch(showNotification({
                type: NotificationType.ERROR,
                title: 'Category with the same name already exists',
            }));
            return;
        }

        dispatch(appendCategory({
            uuid: 'uuid-' + Date.now(),
            order: categories.length,
            label: newCategoryLabel || '',
            icon: newCategoryEmoji,
            isActive: true,
        }));
        dispatch(changeNewCategoryLabel(''));
        dispatch(changeNewCategoryEmoji('✋'));
    };

    return (
        <div css={ configuration__footer }>
            <div css={ css({ width: '80%', height: '3em' }) }>
                <div css={ category__emoji_picker }>
                    <ClickAwayListener onClickAway={ () => {
                        isPickerVisible && dispatch(changePickerVisibility(false));
                        isPickerVisible && setAnchorEl(null);
                    } }>
                        <div css={ category__input_container }>
                            { isDataLoading
                                ? <div css={ [skeleton__preloader, css({
                                    height: '100%',
                                    borderRadius: BORDER_RADIUS_SMALL,
                                })] }/>
                                : <div
                                    css={ category__input }
                                    onClick={ onEmojiPickerStateHandler }
                                >
                                    { newCategoryEmoji }
                                </div>
                            }
                            { isPickerVisible && (
                                <Popper
                                    css={ css({ zIndex: 10 }) }
                                    open={ isPickerVisible }
                                    anchorEl={ anchorEl }
                                    placement={ "bottom-start" }
                                    transition
                                >
                                    { ({ TransitionProps }) => (
                                        <Fade { ...TransitionProps } timeout={ 350 }>
                                            <Paper>
                                                <EmojiPicker
                                                    onEmojiClick={ onNewCategoryEmojiHandler }
                                                    skinTonesDisabled={ true }
                                                    searchDisabled={ true }
                                                    previewConfig={ { showPreview: false } }
                                                />
                                            </Paper>
                                        </Fade>
                                    ) }

                                </Popper>
                            ) }
                        </div>
                    </ClickAwayListener>
                    <div css={ category__name }>
                        <TextInput
                            onChange={ onNewCategoryLabelHandler }
                            value={ newCategoryLabel }
                            label="Category name"
                            isLoading={ isDataLoading }
                        />
                    </div>
                    <div css={ category__add_button }>
                        <SecondaryButton
                            onClick={ onAddCategoryHandler }
                            disabled={ !newCategoryLabel }
                            label="Add"
                            isLoading={ isDataLoading }
                        />
                    </div>
                </div>
                <WidgetConfigurationSaveButton/>
            </div>
        </div>
    );
};
