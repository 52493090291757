import {API_PATH} from "../request-path-constants";
import {AUTHORIZATION_LS_KEY} from "../../models/localstorage-keys";
import {LoginRequest} from "../../models/api/api";
import {apiHost} from "../api-host-resolver";

// todo: add typings to request-services
// todo: also think about common wrapper to better usages of those
export const login = async (loginRequest: LoginRequest) => {
    let formData = new URLSearchParams(`identity=${loginRequest.email}&password=${loginRequest.password}`);
    return await fetch(`${apiHost}${API_PATH}/sessions`, {
        method: 'POST',
        body: formData,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    })
        .then(resp => {
            const authUserSession = resp.headers.get('auth_user_session');
            if (authUserSession) {
                localStorage.setItem(AUTHORIZATION_LS_KEY, authUserSession);
                return authUserSession;
            }
            else return resp.json().then(x => x);
        })
        .catch(e => console.log(e));
}

export const logout = async () => {
    const authToken = localStorage.getItem(AUTHORIZATION_LS_KEY);
    if (authToken) {
        localStorage.removeItem(AUTHORIZATION_LS_KEY);
    }
    return await fetch(`${apiHost}${API_PATH}/sessions`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            // todo: fix case when there is no authToken presented. See jira: MT-34
            'auth_user_session': authToken || ''
        }
    })
        .then(resp => resp.json().then(x => x))
        .catch(e => console.log(e));
}
