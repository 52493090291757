import { HelperMessage, HelperMessageType } from "../models/helper-message";
import { PhoneNumberError } from "../models/phone-number-error";

export const removeNonDigits = (phoneNumber: string): string => {
    return phoneNumber.replace(/\D/gm, '');
}

export const mapPhoneNumberErrorToHelperMessage = (phoneNumberError?: PhoneNumberError): HelperMessage => {
    const type = phoneNumberError !== undefined ? HelperMessageType.ERROR : HelperMessageType.NONE
    let text = undefined
    if (phoneNumberError === PhoneNumberError.PHONE_NUMBER_IS_TOO_LONG) {
        text = 'Phone number is too long'
    }
    if (phoneNumberError === PhoneNumberError.PHONE_NUMBER_IS_TOO_SHORT) {
        text = 'Phone number is too short'
    }
    return { type, text }
}
