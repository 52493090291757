import { ErrorCode, MagictapResponse } from "../models/magictap-response";
import { LOGIN_BASE_ROUTE } from "../routing/routes";
import { NotificationType, showNotification } from "../store/features/notification-bar-slice";
import { GENERIC_ERROR_MESSAGE } from "../models/common-constants";
import { call, put } from 'redux-saga/effects';
import { navigateTo } from '../store/features/navigation.slice';

export function* handleApiCall<U, T>(
    apiCall: (req: U) => Promise<MagictapResponse<T>>,
    request: U,
    onError?: (resp: MagictapResponse<T>) => Generator,
) {
    try {
        const response: MagictapResponse<T> = yield call(apiCall, request);
        if(response.errorCode) {
            if(response.errorCode === ErrorCode.SESSION_IS_NOT_VALID) {
                yield put(navigateTo(`/${ LOGIN_BASE_ROUTE }`));
            } else if(onError) {
                yield call(onError, response);
            }
            return;
        }
        return response;
    } catch(e) {
        console.error(e);
        yield put(showNotification({ title: GENERIC_ERROR_MESSAGE, type: NotificationType.ERROR }));
    }
}
