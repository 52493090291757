/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { page__container, page__container__header_row } from '../../common-styles/pages';
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { PageNotFound } from "../common/not-found-page/page-not-found";
import { team_page } from './team-page.styles';
import { NEW_ID } from '../../models/common-constants';
import { TeamTextInput } from './configuration/inputs/team-text-input';
import { HelperMessageType } from '../../models/helper-message';
import { changeTeamTableLabel } from '../../store/features/team-table-slice';
import { QrCodeConfiguration } from './qr-code-configuration';
import { PrimaryButton } from '../common/buttons/primary-button';
import { createTableRequestHandler } from '../../services/request-handlers/team/create-table-request-handler';
import { getTableRequestHandler } from '../../services/request-handlers/team/get-table-request-handler';
import { useEffect } from 'react';

export const TeamTableConfiguration = () => {
    const { isDataLoading, label, publicId } = useAppSelector(state => state.teamTable);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const tableId = searchParams.get('tableId') || NEW_ID;
    const teamId = searchParams.get('teamId') || '';

    const changeTeamTableLabelHandler = (e: string) => {
        dispatch(changeTeamTableLabel(e));
    };

    const saveTable = (e: string) => {
        if(tableId === NEW_ID) {
            createTableRequestHandler({
                teamId,
                label,
            }, dispatch, navigate);
        } else {
            console.warn('Editing is not supported yet');
        }
    };

    useEffect(() => {
        if (teamId && tableId && tableId !== NEW_ID) getTableRequestHandler(tableId, dispatch, navigate);
    }, []);

    if (!tableId || !teamId) {
        return (
            <PageNotFound/>
        );
    }

    return (
        <div css={ page__container }>
            <div css={ page__container__header_row }>
                <h2>One team - one QR code</h2>
            </div>
            <div css={ [team_page, css({ justifyContent: 'center' })] }>
                <div css={ css({ display: 'flex', flexDirection: 'column', gap: '1em', maxWidth: '30em', flex: 1 }) }>
                    <div css={ css({ flex: 1 }) }>
                        <TeamTextInput
                            label={ "Label" }
                            value={ label }
                            onChange={ changeTeamTableLabelHandler }
                            required={ true }
                            autoComplete="off"
                            helperMessage={ {
                                type: HelperMessageType.NONE,
                                text: "It will be displayed on order page",
                            } }
                            isLoading={ isDataLoading }
                        />
                    </div>
                    <div css={ css({ flex: 10 }) }>
                        <QrCodeConfiguration id={ tableId } name={ label } isDataLoading={ isDataLoading }
                                             qrHash={ publicId }/>
                    </div>
                    <div>
                        <PrimaryButton label="Save Changes" onClick={ saveTable }/>
                    </div>
                </div>
            </div>
        </div>
    );
};
