/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import LogoWhite from "../../static/logo-white.svg";
import LogoBlack from "../../static/logo-black.svg";
import React from "react";
import { mobileDevices } from '../../common-styles/adaptive-breakpoints';

export enum LogoColor {
    WHITE_LOGO,
    BLACK_LOGO,
}

const app_logo = css({
    maxHeight: '2em',
    [mobileDevices]: {
        maxHeight: '1.5em',
    },
});

export const Logo = (props: { color: LogoColor }) => {
    return (
        <img
            css={ app_logo }
            src={ props.color === LogoColor.WHITE_LOGO ? LogoWhite : LogoBlack }
            alt="Logo"
        />
    );
};
