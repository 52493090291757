import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { Review } from '../../models/review';
import { ALL_FILTER_PRESET, FilterPreset } from '../../models/filters';
import { DetailedFeedbacksSortOption } from '../../models/sort-options';

export interface DetailedFeedbacksState {
    reviews: Review[];
    ratingsFilter: FilterPreset<number[]>;
    categoriesFilter: FilterPreset<string[]>;
    teamsFilter: FilterPreset<string[]>;
    reviewsLoading: boolean;

    sortBy: DetailedFeedbacksSortOption;
}

const initialState: DetailedFeedbacksState = {
    reviews: [],
    ratingsFilter: ALL_FILTER_PRESET,
    categoriesFilter: ALL_FILTER_PRESET,
    teamsFilter: ALL_FILTER_PRESET,
    reviewsLoading: false,

    sortBy: DetailedFeedbacksSortOption.BY_DATE,
};

export const detailedFeedbacksSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        startLoadingReviews: (state: Draft<DetailedFeedbacksState>, action: PayloadAction<undefined>) => {
            state.reviewsLoading = true;
        },
        loadDetailedFeedbacks: (state: Draft<DetailedFeedbacksState>, action: PayloadAction<Review[]>) => {
            state.reviews = action.payload.map((x: Review) => ({
                ...x,
                selectedCategories: [...(x.selectedCategories || [])],
            }));
            state.reviewsLoading = false;
        },
        changeRatingFilter: (state: Draft<DetailedFeedbacksState>, action: PayloadAction<FilterPreset<number>>) => {
            state.ratingsFilter = action.payload === ALL_FILTER_PRESET
                ? ALL_FILTER_PRESET
                : [action.payload];
        },
        changeTeamFilter: (state: Draft<DetailedFeedbacksState>, action: PayloadAction<FilterPreset<string>>) => {
            state.teamsFilter = action.payload === ALL_FILTER_PRESET
                ? ALL_FILTER_PRESET
                : [action.payload];
        },
        changeCategoriesFilter: (state: Draft<DetailedFeedbacksState>, action: PayloadAction<FilterPreset<string>>) => {
            if(action.payload === ALL_FILTER_PRESET) {
                state.categoriesFilter = ALL_FILTER_PRESET;
                return;
            }

            const categoriesFilter = state.categoriesFilter === ALL_FILTER_PRESET ? [] : [...state.categoriesFilter];
            const categoryIndex: number = categoriesFilter.findIndex(x => x === action.payload);
            if(categoryIndex === -1) {
                categoriesFilter.push(action.payload);
            } else {
                categoriesFilter.splice(categoryIndex, 1);
            }

            state.categoriesFilter = [...categoriesFilter];
        },
        openDetailedFeedbacks: (state: Draft<DetailedFeedbacksState>, action: PayloadAction<FilterPreset<string | undefined>>) => {
            if(action.payload) {
                state.categoriesFilter = [action.payload];
            }
        },
        changeDetailedFeedbacksSortOrder: (state: Draft<DetailedFeedbacksState>, action: PayloadAction<DetailedFeedbacksSortOption>) => {
            state.sortBy = action.payload;
        },
    },
});

export const {
    loadDetailedFeedbacks,
    changeRatingFilter,
    changeTeamFilter,
    changeCategoriesFilter,
    openDetailedFeedbacks,
    changeDetailedFeedbacksSortOrder,
} = detailedFeedbacksSlice.actions;

export default detailedFeedbacksSlice.reducer;
