import { TeamConfigState } from "../features/team-config-slice";
import { TemplateEditorState } from "../features/template-editor-slice";
import { RootState } from "../store";
import { TeamListState } from '../features/team-list-slice';
import { TeamTablesListState } from '../features/team-tables-list-slice';

export const selectTeamConfiguration: (state: RootState) => TeamConfigState = state => state.teamConfig;
export const selectTeamTemplate: (state: RootState) => TemplateEditorState = state => state.templateEditor;
export const selectTeamList: (state: RootState) => TeamListState = state => state.teamList;
export const selectTeamTablesList: (state: RootState) => TeamTablesListState = state => state.teamTablesList;
