/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {jsx} from '@emotion/react';
import React, {ChangeEvent} from "react";
import {TextInput} from "./text-input";

export interface EmailProps {
    emailIsValid: boolean;
    onChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    onBlur: () => void;
    errorMessage?: string;
}

const getEmailErrorMessage = (emailIsValid: boolean, errorMessage?: string,): string => {
    return errorMessage || (emailIsValid ? '' : 'Invalid email format');
};

export const EmailInput = (props: EmailProps) => {
    const {onBlur, onChange, emailIsValid, errorMessage} = props;

    return (
        <TextInput
            label={"Enter your email"}
            autocompleteName={"email"}
            autocomplete={"on"}
            required={true}
            type={"email"}
            hasError={!emailIsValid || !!errorMessage}
            errorMessage={getEmailErrorMessage(emailIsValid, errorMessage)}
            onChange={onChange}
            onBlur={onBlur}
        />
    );
};
