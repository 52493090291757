import {verify} from "../../request-services/signup";
import {changeValidationLoadingIndicator, setSessionId, validationRequestFailed} from "../../../store/features/signup-slice";
import {SESSION_ID_LS_KEY, SIGNUP_STEP_LS_KEY} from "../../../models/localstorage-keys";
import {WIZARD_ACCOUNT_CREATION_ROUTE} from "../../../routing/routes";
import {AppDispatch} from "../../../store/store";
import {NavigateFunction} from "react-router-dom";
import {requestHandlerWrapper} from "../../../utils/request-handler-wrapper";
import {ErrorCode, MagictapResponse} from "../../../models/magictap-response";
import {VerificationRequest} from "../../../models/api/api";
import {SignupStep} from "../../../models/signup-step";
import {GENERIC_ERROR_MESSAGE} from "../../../models/common-constants";

const verificationFailure = (response: MagictapResponse<unknown>, dispatch: AppDispatch) => {
    if (response.errorCode === ErrorCode.VERIFICATION_FAILED) {
        dispatch(validationRequestFailed('Invalid validation code'));
    } else {
        dispatch(validationRequestFailed(GENERIC_ERROR_MESSAGE));
    }
    dispatch(changeValidationLoadingIndicator(false));
};

const verificationSuccess = (validationResponse: MagictapResponse<string>, dispatch: AppDispatch, navigate: NavigateFunction) => {
    console.log('Email has been validated. Session UUID: ' + validationResponse.content);
    dispatch(setSessionId(validationResponse.content!!));
    localStorage.setItem(SESSION_ID_LS_KEY, validationResponse.content!!);
    localStorage.setItem(SIGNUP_STEP_LS_KEY, SignupStep.ACCOUNT);
    dispatch(changeValidationLoadingIndicator(false));
    navigate(`/${WIZARD_ACCOUNT_CREATION_ROUTE}`);
};

export const verificationRequestHandler = (request: VerificationRequest, dispatch: AppDispatch, navigate: NavigateFunction) => {
    requestHandlerWrapper(
        verify,
        request,
        verificationSuccess,
        verificationFailure,
        dispatch,
        navigate
    )
};
