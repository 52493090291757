import {css} from "@emotion/react";

export const signup_wizard__form_container = css({
    maxWidth: '450px',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: "column",
    alignItems: "center",
});
