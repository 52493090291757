/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {jsx} from '@emotion/react';
import isSameYear from "date-fns/isSameYear";
import startOfMonth from "date-fns/startOfMonth";
import setMonth from "date-fns/setMonth";
import {CaptionProps, useDayPicker, useNavigation} from "react-day-picker";
import React, {Fragment} from "react";
import {Select} from "../dropdowns/select";
import {css} from "@emotion/react";
import {MenuItem} from "@mui/material";

const getMonthForSelector = (fromDate: Date, toDate: Date) => {
    const dropdownMonths: Date[] = [];
    if (isSameYear(fromDate, toDate)) {
        // only display the months included in the range
        const date = startOfMonth(fromDate);
        for (let month = fromDate.getMonth(); month <= toDate.getMonth(); month++) {
            dropdownMonths.push(setMonth(date, month));
        }
    } else {
        // display all the 12 months
        const date = startOfMonth(new Date()); // Any date should be OK, as we just need the year
        for (let month = 0; month <= 11; month++) {
            dropdownMonths.push(setMonth(date, month));
        }
    }
    return dropdownMonths;
};

export const MonthSelect = (props: CaptionProps) => {
    const {
        onMonthChange,
        fromDate,
        toDate,
        locale,
        formatters: {formatMonthCaption},
    } = useDayPicker();
    const {goToMonth} = useNavigation();

    if (!fromDate) return <Fragment></Fragment>;
    if (!toDate) return <Fragment></Fragment>;

    const dropdownMonths: Date[] = getMonthForSelector(fromDate!, toDate!);

    const handleMonthChange = (e: any) => {
        const selectedMonth = Number(e.target.value);
        const newMonth = setMonth(startOfMonth(props.displayMonth), selectedMonth);
        goToMonth(newMonth);
        onMonthChange?.(newMonth);
    };

    return (
        <Select
            onChange={handleMonthChange}
            value={props.displayMonth.getMonth()}
            css={css({width: '60%'})}
        >
            {dropdownMonths.map(m => (
                <MenuItem key={m.getMonth()} value={m.getMonth()}>
                    {formatMonthCaption(m, {locale})}
                </MenuItem>
            ))}
        </Select>
    )
};
