import { css } from "@emotion/react";
import { mobileDevices } from '../../common-styles/adaptive-breakpoints';

export const dashboard__header__container = css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    [mobileDevices]: {
        flexDirection: 'column',
        alignItems: 'unset',
        padding: '0 0.5em',
    },
});

export const dashboard__header__filter_buttons__filter_button = css({
    height: '3em',
    flex: 3,

    [mobileDevices]: {
        height: '2.5em',
    },
});

export const dashboard_header__filter_panel__date_picker = css({
    height: '3em',
    flex: 3,

    [mobileDevices]: {
        height: '2.5em',
    },
});

export const dashboard_header__filter_panel__filter_buttons__container = css({
    display: 'flex',
    justifyContent: 'space-between',
    height: '3em',
    gap: '0.5em',
    width: '20em',

    [mobileDevices]: {
        height: '2.5em',
        width: 'unset',
    },
});
