import { TemplateResponse } from "../../models/api/api";
//import { AUTHORIZATION_LS_KEY } from "../models/localstorage-keys";
import { MagictapResponse } from "../../models/magictap-response";
// import { apiHost } from "./api-host-resolver";
// import { API_PATH } from "./request-path-constants";

//TODO MO PR2
export const getTemplate: (teamId: string) => Promise<MagictapResponse<TemplateResponse>> = async (teamId: string) => {
    //const authUserToken = localStorage.getItem(AUTHORIZATION_LS_KEY) || '';
    return Promise.resolve({content: {}})
    // return await fetch(`${apiHost}${API_PATH}/teams/${teamId}/template`, {
    //     method: 'GET',
    //     headers: {
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json',
    //         'auth_user_session': authUserToken,
    //     }
    // })
    //     .then(resp => resp.json().then(x => x))
    //     .catch(e => console.log(e));
};
