import {
    ACCOUNT_BASE_ROUTE,
    CONFIGURATION_BASE_ROUTE,
    CONTACT_US,
    DASHBOARD_BASE_ROUTE,
    LOGIN_BASE_ROUTE,
    TEAM_BASE_ROUTE,
} from "./routes";
import { ReactNode } from "react";
import { Dashboard } from "../icons/Dashboard";
import { DashboardHover } from "../icons/DashboardHover";
import { Qr } from "../icons/Qr";
import { QrHover } from "../icons/QrHover";
import { Config } from "../icons/Config";
import { ConfigHover } from "../icons/ConfigHover";
import { Account } from "../icons/Account";
import { AccountHover } from "../icons/AccountHover";
import { Logout } from "../icons/Logout";
import { LogoutHover } from "../icons/LogoutHover";
import { ContactUs } from "../icons/ContactUs";
import { ContactUsHover } from "../icons/ContactUsHover";

export enum NavigationItemName {
    DASHBOARD = 'Dashboard',
    TEAM = 'QR\'s & Teams',
    CONFIGURATION = 'Configuration',
    ACCOUNT = 'Account',
    CONTACT_US = 'Contact us',
    LOGOUT = 'Log out',
}

export interface Page {
    name: NavigationItemName;
    route: string;
    icon?: ReactNode;
    hoverIcon?: ReactNode;
    onClickCallback?: () => void;
}

export const getNavigationMenu = (): Page[] => {
    return pages;
};

const pages: Page[] = [
    {
        name: NavigationItemName.DASHBOARD,
        route: DASHBOARD_BASE_ROUTE,
        icon: <Dashboard/>,
        hoverIcon: <DashboardHover/>,
    },
    {
        name: NavigationItemName.TEAM,
        route: TEAM_BASE_ROUTE,
        icon: <Qr/>,
        hoverIcon: <QrHover/>,
    },
    {
        name: NavigationItemName.CONFIGURATION,
        route: CONFIGURATION_BASE_ROUTE,
        icon: <Config/>,
        hoverIcon: <ConfigHover/>,
    },
    {
        name: NavigationItemName.ACCOUNT,
        route: ACCOUNT_BASE_ROUTE,
        icon: <Account/>,
        hoverIcon: <AccountHover/>,
    },
    {
        name: NavigationItemName.CONTACT_US,
        route: CONTACT_US,
        icon: <ContactUs/>,
        hoverIcon: <ContactUsHover/>,
        onClickCallback: () => window.open('mailto:help@magictap.me'),
    },
];

export const logoutPage: Page = {
    name: NavigationItemName.LOGOUT,
    route: LOGIN_BASE_ROUTE,
    icon: <Logout/>,
    hoverIcon: <LogoutHover/>,
};
