import { NavigateFunction } from "react-router-dom";
import { AccountDataResponse, UpdateAccountRequest } from "../../../models/api/api";
import { GENERIC_ERROR_MESSAGE } from "../../../models/common-constants";
import { MagictapResponse } from "../../../models/magictap-response";
import { getAccount, updateAccount } from "../../request-services/account";
import { setAccountConfig, setAccountConfigEdited } from "../../../store/features/account-config-slice";
import { NotificationType, showNotification } from "../../../store/features/notification-bar-slice";
import { AppDispatch } from "../../../store/store";
import { requestHandlerWrapper } from "../../../utils/request-handler-wrapper";

export const updateAccountFailure = (_: any, dispatch: AppDispatch) => {
    dispatch(showNotification({ title: GENERIC_ERROR_MESSAGE, type: NotificationType.ERROR }));
};

function updateAccountSuccess(_: any, dispatch: AppDispatch, navigate: NavigateFunction) {
    getAccount().then((accountResponse: MagictapResponse<AccountDataResponse>)=>{
        dispatch(setAccountConfig(accountResponse.content!!))
        dispatch(showNotification({ title: 'Account settings have been updated!', type: NotificationType.SUCCESS }));
        dispatch(setAccountConfigEdited(false))
    })
}
export const updateAccountRequestHandler = (request: UpdateAccountRequest, dispatch: AppDispatch, navigate: NavigateFunction) => {
    requestHandlerWrapper(
        updateAccount,
        request,
        updateAccountSuccess,
        updateAccountFailure,
        dispatch,
        navigate,
    );
};
