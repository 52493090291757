export const ArrowUp = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g>
                <path fill="currentColor"
                      d="M11.293 9.707a1 1 0 0 1 1.414 0l3.586 3.586c.63.63.184 1.707-.707 1.707H8.414c-.89 0-1.337-1.077-.707-1.707l3.586-3.586Z"/>
            </g>

        </svg>
    );
};