import { RecipientData } from '../recipient-data';
import { Country } from "../../utils/country-utils";

export interface LoginRequest {
    email: string;
    password: string;
}

export interface RegistrationRequest {
    email: string;
}

export enum AccountType {
    COMPANY = 'COMPANY',
}

export interface SignupRequest {
    sessionId: string,
    firstName: string,
    lastName: string,
    password: string,
    country: Country,
    workspaceName?: string,
    accountType?: AccountType,
}

export interface SaveConfigurationRequest {
    workspaceLogo: string | null,
    welcomeMessage: string,
    categories: Category[],
    isCommentariesEnabled: boolean,
    feedbackFor: FeedbackRecipientType,
    isPromoteToSocialEnabled: boolean,
    thankYouPageLogo: string | null,
    minimalFeedbackScoreForPromoteToSocial: number | null,
}

export interface CreateTeamRequest {
    name: string;
    description: string | null;
    address: string | null;
}

export interface CreateTableRequest {
    teamId: string;
    label: string;
}

export interface MenuItemData {
    id: string | null;
    name: string;
    description?: string;
    imageLink: string | null;
}

export interface CreateEMenuRequest {
    teamId: string;
    imageLink: string | null;
    content: MenuItemData[];
}

export interface UpdateMenuItemsRequest {
    menuId: string;
    items: MenuItemData[];
}

export interface DeleteMenuItemRequest {
    menuId: string;
    itemId: string;
}

export interface EMenuResponse {
    id: string;
    teamId: string;
    imageLink: string | null;
    content: MenuItemData[];
}

export interface DashboardRequest {
    from: number,
    to: number,
    teamId?: string,
}

interface HappinessScoreData {
    ratingCSAT: number;
}

interface HappinessScore {
    trend: number;
    data: HappinessScoreData;
}

interface DashboardCategoryData {
    label: string;
    ratingCSAT: number;
    mentions: number;
    icon?: string;
}

interface DashboardCategory {
    trend: number;
    data: DashboardCategoryData;
}

interface DashboardHistogramData {
    moment: number;
    ratingRaw: number;
}

export interface DashboardResponse {
    overallData: HappinessScore;
    categoriesData: DashboardCategory[];
    chartData: DashboardHistogramData[];
}

export enum FeedbackRecipientType {
    TEAM,
    EMPLOYEE
}

export interface TeamResponse {
    qrHash: string;
    team: {
        backgroundImage?: string;
        createdAt: number;
        description?: string;
        location: { address: string } | null;
        name: string;
        updatedAt: number;
        uuid: string;
        workspaceId: string;
        googleMapsLink?: string;
        tripadvisorLink?: string;
        instagramLink?: string;
    };
}

export interface TableResponse {
    id: string;
    label: string;
}

export interface CreateTableResponse {
    publicId: string;
    label: string;
}

export interface GetTableResponse {
    publicId: string;
    label: string;
}

export interface ShortTeamInfo {
    id: string;
    name: string;
}

export interface UpdateTeamRequest {
    teamId: string;
    name: string;
    description: string | null;
    address: string | null;
    backgroundImage: string | null;
    googleMapsLink: string | null;
    tripadvisorLink: string | null;
    instagramLink: string | null;
}

interface CategoryData {
    mentions: number;
    ratingCSAT: number;
    label: string;
    category?: Category;
}

export interface CategoryDashboardData {
    data: CategoryData;
    trend: number;
}

export interface VerificationRequest {
    email: string;
    code: string;
}

export interface Category {
    label: string;
    uuid?: string;
    icon?: string;
    order: number;
    excellenceLabel?: string;
    isActive: boolean;
}

export interface WorkspaceResponse {
    workspaceLogo: string | null;
    welcomeMessage: string;
    feedbackFor: FeedbackRecipientType;
    categories: Category[];
    isCommentariesEnabled: boolean;
    isPromoteToSocialEnabled: boolean;
    thankYouPageLogo: string | null;
    minimalFeedbackScoreForPromoteToSocial: number | null;
}

export interface RecipientInfo {
    type: FeedbackRecipientType,
    name: string;
    info?: string;
    backgroundImage?: string;
}

export interface UpdateAccountRequest {
    firstName: string;
    lastName: string;
    email: string;
    companyName: string;
    country: Country;
    phoneNumber: string | null;
    timeZone: string | null;
}

export interface AccountDataResponse {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    timeZone: string;
    companyName: string;
    country: Country;
}

export interface DetailedFeedbackRequest {
    teamIds?: string[];
    from: number;
    to: number;
    rating?: number[];
    categories?: string[];
}

export interface DetailedFeedbackResponse {
    rating: number;
    createdAt: number;
    comment?: string;
    selectedCategories?: Category[];
    recipient: RecipientData;
}

export interface TemplateResponse {
    header?: string,
    headerColor?: string,
    subheader?: string,
    subheaderColor?: string,
    qrBackgroundColor?: string,
    qrPixelsColor?: string,
    backgroundImage?: string,
}
