import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";
import {NavigationItemName} from "../../routing/pages";

export interface NavigationBarState {
    mobileNavbarOpened: boolean;
    desktopNavbarOpened: boolean;

    reviewsFeedBarOpened: boolean;

    selectedSideNavigationItem: NavigationItemName;
    hoveredSideNavigationItem?: NavigationItemName;

    isMobile: boolean;
}

const initialState: NavigationBarState = {
    desktopNavbarOpened: true,
    mobileNavbarOpened: false,

    reviewsFeedBarOpened: false,

    selectedSideNavigationItem: NavigationItemName.DASHBOARD,

    isMobile: true,
};

export const navigationBarSlice = createSlice({
    name: 'navigationBar',
    initialState,
    reducers: {
        toggleMobileNavbar: (state: Draft<NavigationBarState>, action: PayloadAction<boolean>) => {
            state.mobileNavbarOpened = action.payload;
        },
        toggleDesktopNavbar: (state: Draft<NavigationBarState>, action: PayloadAction<boolean>) => {
            state.desktopNavbarOpened = action.payload;
        },

        toggleReviewsFeedBar: (state: Draft<NavigationBarState>, action: PayloadAction<boolean>) => {
            state.reviewsFeedBarOpened = action.payload;
        },

        selectSideNavigationItem: (state: Draft<NavigationBarState>, action: PayloadAction<NavigationItemName>) => {
            state.selectedSideNavigationItem = action.payload;
        },
        hoverSideNavigationItem: (state: Draft<NavigationBarState>, action: PayloadAction<NavigationItemName | undefined>) => {
            state.hoveredSideNavigationItem = action.payload;
        },

        resizeScreen: (state: Draft<NavigationBarState>, action: PayloadAction<boolean>) => {
            state.isMobile = action.payload;
        },
    },
});

export const {
    toggleMobileNavbar,
    toggleDesktopNavbar,
    toggleReviewsFeedBar,
    selectSideNavigationItem,
    hoverSideNavigationItem,
    resizeScreen,
} = navigationBarSlice.actions;

export default navigationBarSlice.reducer;
