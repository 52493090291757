import { WHITE } from "../common-styles/color-palette";

export interface ColorPickerIconProps {
    fill?: string;
    stroke?: string;
}

export const ColorPickerIcon = ({ fill, stroke }: ColorPickerIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 14 16">
            <path stroke={ stroke } strokeLinecap="round" fill={ fill || WHITE } strokeLinejoin="round"
                  strokeWidth="1.6"
                  d="m6.996 1 4.245 4.102c1.717 1.657 2.23 4.151 1.303 6.318C11.614 13.587 9.427 15 7 15c-2.427 0-4.615-1.413-5.543-3.58-.929-2.167-.415-4.66 1.302-6.318L6.996 1Z"
                  clipRule="evenodd"/>
        </svg>
    );
};
