/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectWorkspaceConfig } from '../../store/selectors/workspace-config-selectors';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { SaveConfigurationRequest } from '../../models/api/api';
import { workspaceConfigurationRequestHandler } from '../../services/request-handlers/workspace-configuration-request-handler';
import { PrimaryButton } from '../common/buttons/primary-button';

export const WidgetConfigurationSaveButton = () => {
    const {
        isDataLoading,
        isConfigurationEdited,
        categories,
        workspaceLogo,
        welcomeMessage,
        enableMessageInput,
        feedbackFor,
        minimalFeedbackScoreForPromoteToSocial,
        thankYouPageLogo,
        isPromoteToSocialEnabled,
    } = useAppSelector(selectWorkspaceConfig);

    const dispatch = useAppDispatch();
    const navigate: NavigateFunction = useNavigate();

    const onSaveButtonHandler = () => {
        const request: SaveConfigurationRequest = {
            workspaceLogo,
            welcomeMessage,
            categories: categories.map(
                (x) => {
                    return {
                        label: x.label,
                        isActive: x.isActive,
                        order: x.order,
                        icon: x.icon,
                        excellenceLabel: x.excellenceLabel,
                    };
                }),
            feedbackFor,
            isCommentariesEnabled: enableMessageInput,
            isPromoteToSocialEnabled,
            thankYouPageLogo,
            minimalFeedbackScoreForPromoteToSocial: minimalFeedbackScoreForPromoteToSocial || null,
        };
        workspaceConfigurationRequestHandler(request, dispatch, navigate);
    };

    return (
        <div css={ css({ height: '3.5em', width: '100%' }) }>
            <PrimaryButton
                label="Save changes"
                onClick={ onSaveButtonHandler }
                disabled={ !isConfigurationEdited }
                isLoading={ isDataLoading }
            />
        </div>
    );
};