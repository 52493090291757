import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { ShortTeamInfo, TeamResponse } from "../../models/api/api";
import { Team, TEAM_STATUS } from "../../models/team";
import { TeamListSortOption } from '../../models/sort-options';

export interface TeamListState {
    allTeams: Team[];
    allTeamsShort: ShortTeamInfo[];
    workspaceId: string;
    isLoading?: boolean;
    sortBy: TeamListSortOption;
}

const initialState: TeamListState = {
    allTeams: [],
    allTeamsShort: [],
    workspaceId: '',
    sortBy: TeamListSortOption.BY_NAME,
}

export const teamListSlice = createSlice({
    name: 'teamConfig',
    initialState,
    reducers: {
        loadAllTeams: (state: Draft<TeamListState>, action: PayloadAction<TeamResponse[]>) => {
            state.allTeams = action.payload
                .sort(x => x.team.createdAt)
                .map(x => {
                    const team: Team = {
                        id: x.team.uuid,
                        name: x.team.name,
                        description: x.team.description,
                        location: x.team.location?.address,
                        status: TEAM_STATUS.ACTIVE,
                        modifiedAt: x.team.updatedAt,
                        backgroundImage: x.team.backgroundImage,
                        tripadvisorLink: x.team.tripadvisorLink,
                        googleMapsLink: x.team.googleMapsLink,
                        instagramLink: x.team.instagramLink,
                    }
                    return team;
                });
        },
        loadAllTeamsShortInfo: (state: Draft<TeamListState>, action: PayloadAction<ShortTeamInfo[]>) => {
            state.allTeamsShort = [...action.payload]
        },
        setWorkspaceId: (state: Draft<TeamListState>, action: PayloadAction<string>) => {
            state.workspaceId = action.payload;
        },
        setTeamListLoader: (state: Draft<TeamListState>, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        changeSortOrder: (state: Draft<TeamListState>, action: PayloadAction<TeamListSortOption>) => {
            state.sortBy = action.payload;
        },
    }
});

export const {
    loadAllTeams,
    loadAllTeamsShortInfo,
    setWorkspaceId,
    setTeamListLoader,
    changeSortOrder,
} = teamListSlice.actions;

export default teamListSlice.reducer;
