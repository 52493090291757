import styled from "@emotion/styled";
import { Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { BLUE_BASIC } from "../../common-styles/color-palette";
import { BORDER_RADIUS_SMALL } from "../../common-styles/borders";
import React from "react";

export const InputHelperTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip { ...props } arrow placement={ props.placement || "right" } classes={ { popper: className } }/>
))(() => ({
        [`& .${ tooltipClasses.arrow }`]: {
            color: BLUE_BASIC,
        },
        [`& .${ tooltipClasses.tooltip }`]: {
            backgroundColor: BLUE_BASIC,
            height: '2.875em',
            borderRadius: BORDER_RADIUS_SMALL,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'Varela, sans-serif',
            fontSize: '0.9em',
            maxWidth: 'none',
            paddingRight: '1em',
            paddingLeft: '1em',
        },
        '@media (max-width: 555px)': {
            display: 'none',
        },
    }),
);