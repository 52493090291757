/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { FormControlLabel, RadioGroup as MuiRadioGroup, RadioGroupProps } from '@mui/material';
import { skeleton__preloader } from '../../../common-styles/skeleton';
import { radio_group__option, radio_group__option__container } from './radio-group.styles';
import { Radio, RadioCheckedIcon, RadioIcon } from './radio';

interface CustomRadioGroupProps {
    options: RadioOption[];
    onRadioOptionClick: (clickedRadioOptionValue: any) => void;
    isLoading?: boolean;
    isRow?: boolean;
}

interface RadioOption {
    value: unknown;
    label: string;
    disabled?: boolean;
}

export const RadioGroup = (props: RadioGroupProps & CustomRadioGroupProps) => {
    const { options, onRadioOptionClick, isRow, isLoading } = props;
    const onChange = (_: React.ChangeEvent<HTMLInputElement>, arg: string) => onRadioOptionClick(arg);
    return (
        <MuiRadioGroup
            css={ css({ gap: '1.5em' }) }
            row={ isRow }
            onChange={ onChange }
            value={ props.value }
            defaultValue={ props.defaultValue }
        >
            { options.map((option, index) => {
                if(isLoading) {
                    return <div key={ index } css={ [radio_group__option__container, skeleton__preloader] }/>;
                } else {
                    return <div key={ index } css={ radio_group__option__container }>
                        <FormControlLabel
                            value={ option.value }
                            control={
                                <Radio
                                    icon={ <RadioIcon/> }
                                    checkedIcon={ <RadioCheckedIcon/> }
                                />
                            }
                            label={ option.label }
                            css={ radio_group__option }
                            disabled={ option.disabled }
                        />
                    </div>;
                }
            }) }
        </MuiRadioGroup>
    );
};
