import { numberCompareDesc, stringCompareAsc } from '../utils/functors';
import { Team } from './team';
import { Review } from './review';

export enum TeamListSortOption {
    BY_NAME = 'BY_NAME',
    BY_MODIFIED_TIME = 'BY_MODIFIED_TIME',
}

export const sortTeamListOptions: TeamListSortOption[] = [
    TeamListSortOption.BY_NAME,
    TeamListSortOption.BY_MODIFIED_TIME,
];

export const TeamListSortOptionsStringRepresentation = new Map([
    [TeamListSortOption.BY_NAME, 'Sort by name'],
    [TeamListSortOption.BY_MODIFIED_TIME, 'Sort by recent update time'],
]);

export const TeamListSortFunctions = {
    [TeamListSortOption.BY_NAME]: (a: Team, b: Team) => stringCompareAsc(a.name, b.name),
    [TeamListSortOption.BY_MODIFIED_TIME]: (a: Team, b: Team) => numberCompareDesc(a.modifiedAt, b.modifiedAt),
};

export enum DetailedFeedbacksSortOption {
    BY_DATE = 'BY_DATE',
    BY_RATING = 'BY_RATING',
}

export const sortDetailedFeedbacksListOptions: DetailedFeedbacksSortOption[] = [
    DetailedFeedbacksSortOption.BY_DATE,
    DetailedFeedbacksSortOption.BY_RATING,
];

export const DetailedFeedbacksSortOptionsStringRepresentation = new Map([
    [DetailedFeedbacksSortOption.BY_DATE, 'Sort by date'],
    [DetailedFeedbacksSortOption.BY_RATING, 'Sort by rating'],
]);

export const DetailedFeedbacksSortFunctions = {
    [DetailedFeedbacksSortOption.BY_DATE]: (a: Review, b: Review) => numberCompareDesc(a.createdAt, b.createdAt),
    [DetailedFeedbacksSortOption.BY_RATING]: (a: Review, b: Review) => numberCompareDesc(a.rating, b.rating),
};
