/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { css } from "@emotion/react";
import { RED_BASIC } from "../../common-styles/color-palette";
import React from "react";

export const ErrorMessage = (props: { message: string }) => (
    <div css={ css({ width: "100%", height: "1em", display: 'flex', color: RED_BASIC }) }>
        { props.message }
    </div>
);