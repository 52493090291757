/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getTeamMenuRequestHandler } from '../../../services/request-handlers/team/get-menu-request-handler';
import { selectTeam } from '../../../store/selectors/detailed-feedbacks.selector';
import { PrimaryButton } from '../../common/buttons/primary-button';
import { page__container, page__container__header_row } from '../../../common-styles/pages';
import { team_page } from '../team-page.styles';
import { FileUploader } from '../configuration/inputs/file-uploader/file-uploader';
import {
    addNewItem,
    changeEMenuImage,
    changeNewItemDescription,
    changeNewItemImage,
    changeNewItemName,
} from '../../../store/features/e-menu-slice';
import { IconButton } from '../../common/buttons/icon-button';
import { Add } from '../../../icons/Add';
import { TeamTextInput } from '../configuration/inputs/team-text-input';
import { container__full_width } from '../../../common-styles/containers';
import { createTeamMenuRequestHandler } from '../../../services/request-handlers/team/create-menu-request-handler';

export const EMenuConfiguration = () => {
    const { id: teamId } = useAppSelector(selectTeam);
    const { image, content, newItem } = useAppSelector(x => x.eMenu);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();

    const id = teamId || searchParams.get('teamId') || '';

    useEffect(() => {
        getTeamMenuRequestHandler(id, dispatch, navigate);
    }, []);

    const onBackgroundChange = (value: string | undefined) => {
        dispatch(changeEMenuImage(value));
    };

    const onAddNewItem = () => {
        dispatch(addNewItem());
    };

    const onItemNameChange = (value: string) => {
        dispatch(changeNewItemName(value));
    };
    const onItemDescriptionChange = (value: string) => {
        dispatch(changeNewItemDescription(value));
    };
    const onItemImageChange = (value: string | undefined) => {
        dispatch(changeNewItemImage(value));
    };

    const onMenuCreate = () => {
        createTeamMenuRequestHandler({
            teamId: id,
            imageLink: image || null,
            content: [
                ...content.map(x => ({ ...x, id: x.id || null, imageLink: x.imageLink || null })),
            ],
        }, dispatch, navigate);
    };

    return (
        <div css={ [page__container, container__full_width] }>
            <div css={ page__container__header_row }>
                <h2>One team - one QR code</h2>
            </div>
            <div css={ [team_page, css({ justifyContent: 'center' })] }>
                <div css={ css({ display: 'flex', flexDirection: 'column', gap: '1em', maxWidth: '70em', flex: 1 }) }>
                    <div css={ css({ height: '16em' }) }>
                        <FileUploader
                            onChange={ onBackgroundChange }
                            value={ image || '' }
                            emptyStateTitle={ "Background image" }
                            emptyStateDescription={ "IMG, PNG, JPEG, JPG, 500kb max." }
                        />
                    </div>
                    <div>
                        { content.map(x => (
                            <div css={ css({
                                height: '4em',
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                p: {
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                },
                            }) }>
                                <div>{ x.name }</div>
                                <div>{ x.description }</div>
                                <img css={ css({ width: '3em', height: '3em' }) } src={ x.imageLink || '' } alt=""/>
                            </div>
                        )) }
                    </div>
                    <div css={ css({ display: 'flex', justifyContent: 'space-between' }) }>
                        <span>New Item: </span>
                        <TeamTextInput
                            label="Label"
                            autoComplete="off"
                            value={ newItem.name }
                            onChange={ onItemNameChange }
                        />
                        <TeamTextInput
                            label="Description"
                            value={ newItem.description }
                            onChange={ onItemDescriptionChange }
                        />
                        <div css={ css({ width: '15em', height: '3em' }) }>
                            <FileUploader
                                onChange={ onItemImageChange }
                                value={ newItem.imageLink || '' }
                                emptyStateTitle="Image"
                                emptyStateDescription="Background image"
                            />
                        </div>
                        <IconButton onClick={ onAddNewItem }>
                            <Add/>
                        </IconButton>
                    </div>
                    <div css={ css({ display: 'flex', justifyContent: 'center' }) }>
                        <div css={ css({ width: '20em' }) }>
                            <PrimaryButton
                                label="Publish E-Menu"
                                onClick={ onMenuCreate }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
