/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import React, { ChangeEvent, Fragment } from "react";
import {
    changePassword,
    changeRepeatedPassword,
    toggleShowPassword,
    toggleShowPasswordRepeated,
} from "../../store/features/signup-slice";
import { flex_container__horizontal__full_width } from "../../common-styles/containers";
import { PasswordInput } from "../common/inputs/password-input";

export const ChangePasswordForm = () => {
    const {
        passwordsAreInMatch,
        showPassword,
        showRepeatedPassword,
    } = useAppSelector(state => state.signup.newAccount);
    const dispatch = useAppDispatch();

    const passwordInputHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(changePassword(e.target.value));
    };
    const repeatedPasswordInputHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(changeRepeatedPassword(e.target.value));
    };

    const toggleShowPasswordHandler = () => {
        dispatch(toggleShowPassword(!showPassword));
    };
    const toggleShowPasswordRepeatedHandler = () => {
        dispatch(toggleShowPasswordRepeated(!showRepeatedPassword));
    };

    return (
        <Fragment>
            <div css={ [flex_container__horizontal__full_width] }>
                <div css={ css({ width: "90%", marginTop: "0.5em" }) }>
                    <PasswordInput
                        label={ "Password" }
                        onChange={ passwordInputHandler }
                        autocompleteName={ "password" }
                        autocomplete={ "new-password" }
                        hasError={ !passwordsAreInMatch }
                        onShowPasswordClick={ toggleShowPasswordHandler }
                        passwordVisible={ showPassword }
                    />
                </div>
            </div>
            <div css={ [flex_container__horizontal__full_width] }>
                <div css={ css({ width: "90%", height: "3.5em", marginTop: "0.5em" }) }>
                    <PasswordInput
                        label={ "Repeat password" }
                        onChange={ repeatedPasswordInputHandler }
                        hasError={ !passwordsAreInMatch }
                        errorMessage={ "Passwords does not match" }
                        onShowPasswordClick={ toggleShowPasswordRepeatedHandler }
                        passwordVisible={ showRepeatedPassword }
                    />
                </div>
            </div>
        </Fragment>
    );
};
