/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {jsx} from '@emotion/react';
import {flex_container__horizontal, flex_container__vertical} from "../../../common-styles/containers";
import {Logo, LogoColor} from "../../common/logo";
import React from "react";
import {signup_wizard__header} from "./wizard-product-logo.styles";

export const WizardProductLogo = () => {
    return (
        <div css={[signup_wizard__header, flex_container__horizontal, flex_container__vertical]}>
            <Logo color={LogoColor.BLACK_LOGO}/>
        </div>
    );
};
