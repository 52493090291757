/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {jsx} from '@emotion/react';
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {toggleDesktopNavbar} from "../../store/features/navigation-bar-slice";
import {Drawer} from "@mui/material";
import {DARK_BASIC} from "../../common-styles/color-palette";
import {sideNavigationWidthExpanded, sideNavigationWidthShrunk} from "./navbar.styles";
import {DrawerHeader} from "./drawer-header";
import {NavbarMenu} from "./navbar-menu";
import LogoWhite from "../../static/logo-white.svg"
import React from "react";
import {navigation_bar__toggle_button} from "./desktop-navbar.styles";
import { Menu } from '../../icons/Menu';
import { IconButton } from '../common/buttons/icon-button';

export const DesktopNavbar = () => {
    const {desktopNavbarOpened} = useAppSelector(state => state.navigationBar);
    const dispatch = useAppDispatch();

    const handleDesktopNavbarToggle = () => {
        dispatch(toggleDesktopNavbar(!desktopNavbarOpened));
    };

    return (
        <Drawer
            variant="permanent"
            sx={{
                display: {xs: 'none', sm: 'block'},
                height: '100%',
                "& .MuiDrawer-paper": {
                    backgroundColor: DARK_BASIC,
                    whiteSpace: "nowrap",
                    width: sideNavigationWidthExpanded,
                    transition: theme => theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                    boxSizing: "border-box",
                    ...(!desktopNavbarOpened && {
                        overflowX: "hidden",
                        transition: theme => theme.transitions.create('width', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen,
                        }),
                        width: sideNavigationWidthShrunk,
                    })
                },
            }}
            open={desktopNavbarOpened}
        >
            <DrawerHeader>
                {desktopNavbarOpened && <img src={LogoWhite} alt="Logo"/>}
                <IconButton css={navigation_bar__toggle_button} onClick={handleDesktopNavbarToggle}>
                    <Menu/>
                </IconButton>
            </DrawerHeader>
            <NavbarMenu opened={desktopNavbarOpened}/>
        </Drawer>
    )
};
