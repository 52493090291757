/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { happiness_score__comments_button } from './happiness-score-card.styles';
import Comments from '../../../static/icons/chat-1-fill.svg';

export const TextualMentionsButton = ({ numberOfResponses, onClick }: {
    onClick: () => void,
    numberOfResponses: any
}) => {
    return (
        <div css={ happiness_score__comments_button } onClick={ onClick }>
            <img css={ css({ maxHeight: '0.85em' }) } src={ Comments } alt=""/>
            <span>{ numberOfResponses + ' ›' }</span>
        </div>
    );
};
