import { SESSION_ID_LS_KEY, SIGNUP_STEP_LS_KEY } from '../../models/localstorage-keys';
import { Link } from 'react-router-dom';
import { SIGN_UP_BASE_ROUTE } from '../../routing/routes';

export const SignupResetLink = () => {
    const resetSignUpProcess = () => {
        localStorage.removeItem(SESSION_ID_LS_KEY);
        localStorage.removeItem(SIGNUP_STEP_LS_KEY);
    };

    return <Link onClick={ resetSignUpProcess } to={ `/${ SIGN_UP_BASE_ROUTE }` }>link</Link>;
};
