import { css } from "@emotion/react";
import { BORDER_RADIUS_EXTRA_SMALL, BORDER_RADIUS_SMALL } from "../../../../../common-styles/borders";
import { DARK_BASIC, GREY_BASIC, WHITE } from "../../../../../common-styles/color-palette";

export const file_uploader__preview__container = css({
    height: '100%',
    borderRadius: BORDER_RADIUS_SMALL,
    backgroundColor: GREY_BASIC,
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    boxSizing: 'border-box',
    padding: '0.25em',
})

export const file_uploader__preview = css({
    objectFit: 'contain',
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: BORDER_RADIUS_EXTRA_SMALL
});

export const file_uploader__preview__close_icon = css({
    ':hover': {
        color: DARK_BASIC,
        backgroundColor: 'unset',
    },
});

export const file_uploader__preview__close_icon__container = css({
    position: 'absolute',
    top: '0.75em',
    right: '0.75em',
    width: '2em',
    height: '2em',
    backgroundColor: WHITE,
    borderRadius: BORDER_RADIUS_EXTRA_SMALL,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});
