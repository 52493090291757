import {AUTHORIZATION_LS_KEY} from "../../models/localstorage-keys";
import {apiHost} from "../api-host-resolver";
import {API_PATH} from "../request-path-constants";
import {WorkspaceResponse, SaveConfigurationRequest} from "../../models/api/api";
import {MagictapResponse} from "../../models/magictap-response";

export const getWorkspaceData: () => Promise<MagictapResponse<WorkspaceResponse>> = async () => {
    const authUserToken = localStorage.getItem(AUTHORIZATION_LS_KEY) || '';
    return await fetch(`${apiHost}${API_PATH}/workspaces/configs`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'auth_user_session': authUserToken,
        }
    })
        .then(resp => resp.json().then(x => x))
        .catch(e => console.log(e));
};

export const saveConfiguration = async (saveConfigurationRequest: SaveConfigurationRequest) => {
    const authUserToken = localStorage.getItem(AUTHORIZATION_LS_KEY) || '';
    return await fetch(`${apiHost}${API_PATH}/workspaces/configs/update`, {
        method: 'POST',
        body: JSON.stringify(saveConfigurationRequest),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'auth_user_session': authUserToken,
        }
    })
        .then(resp => resp.json().then(x => x))
        .catch(e => console.log(e));
}