/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { openDetailedFeedbacks } from '../../../store/features/detailed-feedbacks-slice';
import { flex_container__horizontal__full_width } from '../../../common-styles/containers';
import { mobileDevices } from '../../../common-styles/adaptive-breakpoints';
import { NumberOfCommentsPreloader } from '../../common/skeleton-preloaders/number-of-comments-preloader';
import { selectNavbar } from '../../../store/selectors/navbar-selectors';
import { Trend } from './trend';
import { TextualMentionsButton } from './textual-mentions-button';

export const OverallFeedbacksButton = () => {
    const {
        happinessScoreIsLoading,
        numberOfResponses,
        happinessScoreTrend,
    } = useAppSelector(state => state.happinessScore);
    const { isMobile } = useAppSelector(selectNavbar);

    const dispatch = useAppDispatch();

    const handleOnCommentsClick = () => {
        dispatch(openDetailedFeedbacks());
    };

    const trend = happinessScoreTrend / 100;

    return (
        <div css={ [flex_container__horizontal__full_width, css({
            paddingBottom: '1em',

            [mobileDevices]: {
                justifyContent: 'flex-start',
                paddingBottom: 'unset',
            },
        })] }>
            { happinessScoreIsLoading && <NumberOfCommentsPreloader/> }
            { !happinessScoreIsLoading &&
                <div css={ css({
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',

                    [mobileDevices]: {
                        justifyContent: 'space-between',
                    },
                }) }>
                    <TextualMentionsButton onClick={ handleOnCommentsClick } numberOfResponses={ numberOfResponses }/>
                    { isMobile && <Trend trend={ trend }/> }
                </div>
            }
        </div>
    );
};
