import styled from "@emotion/styled";
import {IconButton as IconButtonMui} from "@mui/material";
import {GREY_DARK} from "../../../common-styles/color-palette";
import {BORDER_RADIUS_SMALL} from "../../../common-styles/borders";

export const IconButton = styled(IconButtonMui)({
    width: '2em',
    height: '2em',
    color: GREY_DARK,
    borderRadius: BORDER_RADIUS_SMALL,
});
