import {css} from "@emotion/react";
import {
    DARK_BASIC,
    GREEN_BASIC,
    GREY_BASIC,
    GREY_DARK,
    GREY_LIGHT,
    GREY_MEDIUM,
    RED_BASIC,
    RED_LIGHT,
    RED_LIGHT_OPAQUE,
    WHITE
} from "../../../common-styles/color-palette";
import {BORDER_RADIUS_SMALL} from "../../../common-styles/borders";
import {FONT_SIZE_M, FONT_SIZE_XXS} from "../../../common-styles/font-sizes";

export const text__input = css({
    width: '100%',

    ':hover': {
        '& .MuiInputLabel-root': {
            color: DARK_BASIC,
        },
    },

    '& .MuiInputLabel-root': {
        color: GREY_DARK,
        fontSize: FONT_SIZE_M,
        '&.Mui-disabled': {
            color: GREY_MEDIUM,
        },
    },

    '& .MuiInputLabel-root.Mui-focused': {
        color: GREY_DARK,
        fontSize: FONT_SIZE_XXS,
    },

    '& .MuiInputLabel-root.Mui-error': {
        '& .MuiInputLabel-asterisk': {
            color: RED_LIGHT,
        },
        color: RED_LIGHT,
    },

    '& .MuiFilledInput-root': {
        fontFamily: 'Varela, sans-serif',
        fontSize: FONT_SIZE_M,
        backgroundColor: GREY_LIGHT,
        borderRadius: BORDER_RADIUS_SMALL,

        ':hover': {
            backgroundColor: GREY_LIGHT,
        },

        '&.Mui-focused': {
            backgroundColor: GREY_LIGHT,
        },
        '&.Mui-error': {
            backgroundColor: RED_LIGHT_OPAQUE,
        },
        '&.Mui-disabled': {
            backgroundColor: WHITE,
            ':before': {
                borderBottom: 'none',
            },

            '& .MuiFilledInput-input': {
                ':hover': {
                    border: `${GREY_BASIC} 1px solid`,
                },
            },
        },

        ':before': {
            borderBottom: 'none',
        },
        ':after': {
            border: 'none',
        },
        ':hover:not(Mui-disabled):before': {
            border: 'none',
        },
    },

    '& .Mui-error': {
        '& .MuiFilledInput-input': {
            border: `${RED_LIGHT_OPAQUE} 1px solid`,
            color: RED_BASIC,
        },
    },

    '& .MuiFilledInput-input': {
        border: `${GREY_BASIC} 1px solid`,
        borderRadius: BORDER_RADIUS_SMALL,
        color: DARK_BASIC,
        height: '100%',
        boxSizing: 'border-box',

        ':focus': {
            border: `${DARK_BASIC} 1px solid`,
        },
        ':hover': {
            border: `${GREEN_BASIC} 1px solid`,
        },
    },

    '& .MuiFormHelperText-root': {
        marginLeft: 0,
        marginTop: 0,
    },

    '& .MuiFormHelperText-root.Mui-error': {
        color: RED_BASIC,
    }
});

export const text_input__preloader = css({
    height: '100%',
    borderRadius: BORDER_RADIUS_SMALL,
});
