/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Link, useNavigate } from "react-router-dom";
import React, { ChangeEvent, Fragment } from "react";
import {
    changeEmail,
    changePassword,
    changePasswordVisibility,
    changeRequestIsProcessing,
    validateEmail,
} from "../../store/features/login-slice";
import { flex_container__horizontal__full_width } from "../../common-styles/containers";
import { signup__welcome_message } from "../sign-up/sign-up.styles";
import { EmailInput } from "../common/inputs/email-input";
import { SubmitButton } from "../common/buttons/submit-button";
import { signup__redirect_to_login__link } from "../sign-up/wizard-steps/wizard-signup-form.styles";
import { PASSWORD_RESET_ROUTE, SIGN_UP_BASE_ROUTE } from "../../routing/routes";
import { loginRequestHandler } from "../../services/request-handlers/login-request-handler";
import { ErrorMessage } from "../common/error-message";
import { FONT_SIZE_M } from "../../common-styles/font-sizes";
import { PasswordInput } from "../common/inputs/password-input";

export const LoginForm = () => {
    const {
        email,
        password,
        passwordVisible,
        emailIsValid,
        requestIsProcessing,
        requestError,
    } = useAppSelector(state => state.login);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onEmailInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(changeEmail(e.target.value));
    };

    const onPasswordInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(changePassword(e.target.value));
    };

    const onShowPasswordClick = () => {
        dispatch(changePasswordVisibility(!passwordVisible));
    };

    const onEmailInputBlur = () => {
        dispatch(validateEmail());
    };

    const onLoginButtonHandler = () => {
        dispatch(changeRequestIsProcessing(true));
        loginRequestHandler(email, password, dispatch, navigate);
    };

    return (
        <Fragment>
            <div css={ flex_container__horizontal__full_width }>
                <h1 css={ signup__welcome_message }>
                    Hurry up and come in!
                </h1>
            </div>
            <div>
                <div css={ [flex_container__horizontal__full_width] }>
                    <div css={ css({ width: "90%", height: '4.5em' }) }>
                        <EmailInput
                            emailIsValid={ emailIsValid }
                            onChange={ onEmailInputChange }
                            onBlur={ onEmailInputBlur }
                        />
                    </div>
                </div>
                <div css={ [flex_container__horizontal__full_width] }>
                    <div css={ css({ width: "90%", height: '4em' }) }>
                        <PasswordInput
                            label={ "Password" }
                            autocompleteName={ "password" }
                            autocomplete={ "on" }
                            passwordVisible={ passwordVisible }
                            onChange={ onPasswordInputChange }
                            onShowPasswordClick={ onShowPasswordClick }
                        />
                    </div>
                </div>
                <div css={ [flex_container__horizontal__full_width] }>
                    <div css={ css({ width: "90%", display: 'flex', justifyContent: 'end', fontSize: FONT_SIZE_M }) }>
                        <Link to={ `/${ PASSWORD_RESET_ROUTE }` }>Forgot password?</Link>
                    </div>
                </div>
                <div css={ [flex_container__horizontal__full_width] }>
                    <div css={ css({ width: "90%" }) }>
                        <ErrorMessage message={ requestError }/>
                    </div>
                </div>
                <div css={ [flex_container__horizontal__full_width, css({ margin: "1em 0" })] }>
                    <div css={ css({ width: "90%", height: '3.5em' }) }>
                        <SubmitButton
                            label={ "Log in" }
                            requestLoadingIndicator={ requestIsProcessing }
                            disabled={ !email || !emailIsValid || requestIsProcessing }
                            onClick={ onLoginButtonHandler }
                        />
                    </div>
                </div>
            </div>
            <div css={ [flex_container__horizontal__full_width] }>
                <p css={ signup__redirect_to_login__link }>Have no account yet? <Link
                    to={ `/${ SIGN_UP_BASE_ROUTE }` }>Register</Link> here
                </p>
            </div>
        </Fragment>
    );
};
