/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { AgGridCanvasStyle, AgGridContainerStyle } from '../../../common-styles/grid-style';
import { AgGridReact } from 'ag-grid-react';
import { detailedReviewsColumnDefinitions } from './column-definitions';
import { RowClassParams, RowStyle } from 'ag-grid-community/dist/lib/entities/gridOptions';
import { GREY_BASIC } from '../../../common-styles/color-palette';
import { DetailedFeedbackResponse } from '../../../models/api/api';

const getFeedbacksGridRowStyle = (params: RowClassParams): RowStyle => {
    if(params.rowIndex === params.api.getDisplayedRowCount() - 1) {
        return {
            borderBottom: 'none',
        };
    }

    return {
        borderBottom: `${ GREY_BASIC } 1px solid`,
    };
};

export const DetailedFeedbacksTable = (props: { rowData: DetailedFeedbackResponse[] }) => {
    return (
        <div css={ [AgGridContainerStyle] }>
            <AgGridReact
                css={ AgGridCanvasStyle }
                className="ag-theme-material"
                rowData={ props.rowData }
                getRowStyle={ getFeedbacksGridRowStyle }
                animateRows={ true }
                columnDefs={ detailedReviewsColumnDefinitions }
            />
        </div>
    );
};
