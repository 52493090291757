import { css } from "@emotion/react";
import { GREEN_DARK, GREY_BASIC, RED_BASIC } from "../../../common-styles/color-palette";
import {
    FONT_SIZE_EXTRA,
    FONT_SIZE_MOBILE_L,
    FONT_SIZE_SEMI_EXTRA,
    FONT_SIZE_XXL,
} from '../../../common-styles/font-sizes';
import { mobileDevices, smallLaptops } from '../../../common-styles/adaptive-breakpoints';
import { HEADERS_FONT } from '../../../common-styles/font-styles';

export const top_rated_categories__category__container = css({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRight: `1px solid ${ GREY_BASIC }`,

    ':last-of-type': {
        borderRight: 'none',
        borderBottom: `none`,
    },

    [mobileDevices]: {
        flexDirection: 'row',
        borderRight: 'none',
        borderBottom: `1px solid ${ GREY_BASIC }`,
        padding: '0.5em 0',
    },
});

export const top_rated_categories__category__score__container = css({
    height: '90%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    [mobileDevices]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 'unset',
        width: '100%',
    },
});

export const top_rated_categories__category__label__container = css({
    display: 'flex',
    justifyContent: 'center',

    [mobileDevices]: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
});

export const top_rated_categories__category__label__icon = css({ maxWidth: '2em', maxHeight: '2em' });

export const top_rated_categories__category__score = css({
    fontSize: FONT_SIZE_EXTRA,
    fontWeight: '600',
    display: 'flex',
    justifyContent: 'center',
    fontFamily: HEADERS_FONT,

    [smallLaptops]: {
        fontSize: FONT_SIZE_SEMI_EXTRA,
    },

    [mobileDevices]: {
        fontSize: FONT_SIZE_MOBILE_L,
        justifyContent: 'flex-end',
    },
});

export const top_rated_categories__category__score__value = css({
    display: 'flex',
    alignItems: 'end',
});

export const top_rated_categories__category__score__percent_sign = css({
    fontSize: FONT_SIZE_XXL,
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
    height: '85%',
    width: '0.75em',

    [mobileDevices]: {
        height: 'unset',
        width: 'unset',
        fontSize: 'inherit',
        fontWeight: 600,
    },
});

export const top_rated_categories__category__trend = css({
    display: 'flex',
    justifyContent: 'center',
    fontSize: '0.9em',
});

export const top_rated_categories__category__trend__value = (trend: number) => css({ color: trend > 0 ? GREEN_DARK : RED_BASIC });

export const top_rated_categories__category__footer = css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center,',
});

export const top_rated_categories__category__footer__icon = css({ width: '0.8em' });

export const top_rated_categories__category__number_of_comments = css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '0.25em',
});
