/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { FeedbackWidget } from "@magictap-me/widget-component";
import { SelectChangeEvent } from '@mui/material';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import { useState } from "react";
import { BORDER_RADIUS_SEMI_LARGE } from '../../common-styles/borders';
import { skeleton__preloader__histogram } from '../../common-styles/skeleton';
import { FeedbackRecipientType } from '../../models/api/api';
import { setRecipientTeam } from '../../store/features/widget-preview-slice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectWidgetPreview } from '../../store/selectors/widget-preview-selectors';
import { selectWorkspaceConfig } from '../../store/selectors/workspace-config-selectors';
import { noop } from '../../utils/noop';
import { Select } from '../common/dropdowns/select';
import { BlockHeader } from '../common/headers/block-header';
import {
    widget_preview__container,
    widget_preview__header,
    widget_preview__header__team_select,
    widget_preview__widget,
} from './widget-preview.styles';
import { selectTeamList } from '../../store/selectors/team-selectors';

interface WidgetPreviewProps {
    welcomeMessage: string;
    logo: string | null;
    commentariesEnabled: boolean;
    categories: any;

    isPromoteToSocialEnabled: boolean;
    isThankYouPagePreview: boolean;
}

export const WidgetPreview = ({
                                  categories,
                                  commentariesEnabled,
                                  isPromoteToSocialEnabled,
                                  isThankYouPagePreview,
                                  logo,
                                  welcomeMessage,
                              }: WidgetPreviewProps) => {

    const [showAll, setShowAll] = useState(false);

    const dispatch = useAppDispatch();
    const { allTeams } = useAppSelector(selectTeamList);
    const { isDataLoading } = useAppSelector(selectWorkspaceConfig);
    const { recipientTeam } = useAppSelector(selectWidgetPreview);

    const onTeamChangeHandler = (event: SelectChangeEvent<any>) => {
        const selectedTeam = allTeams.find(team => team.id === event.target.value);
        dispatch(setRecipientTeam(selectedTeam));
    };

    return (
        <div css={ widget_preview__container }>
            <div css={ widget_preview__header }>
                <BlockHeader isLoading={ isDataLoading } title="Widget preview"/>
                <div css={ widget_preview__header__team_select }>
                    <Select
                        value={ recipientTeam?.id || '' }
                        onChange={ onTeamChangeHandler }
                    >
                        { allTeams
                            .map((team, index) => (
                                <MenuItem key={ index } value={ team.id }>
                                    { team.name }
                                </MenuItem>
                            )) }
                    </Select>
                </div>
            </div>
            <div css={ widget_preview__widget }>
                { isDataLoading
                    ? <div css={ [skeleton__preloader__histogram, css({
                        width: '20em',
                        height: '100%',
                        borderRadius: BORDER_RADIUS_SEMI_LARGE,
                    })] }/>
                    : <FeedbackWidget
                        isPreview={ true }
                        welcomeMessage={ welcomeMessage }
                        isCommentariesEnabled={ commentariesEnabled }
                        categories={ categories }
                        showCategories={ true }
                        appLoading={ false }
                        recipient={ {
                            type: FeedbackRecipientType.TEAM,
                            name: recipientTeam?.name || '',
                            backgroundImage: recipientTeam?.backgroundImage || '',
                            info: recipientTeam?.location,
                        } }
                        borderRadius="28px"
                        logo={ logo || '' }
                        onShowAllCategories={ () => setShowAll(true) }
                        showAllCategories={ showAll }
                        // todo: change to real data
                        comment=""
                        rating={ 0 }
                        selectedCategories={ [] }
                        onSubmitFeedbackHandler={ noop }
                        onCommentChange={ noop }
                        onCategorySelect={ noop }
                        onRatingChange={ noop }
                        onShowCategories={ noop }
                        phrases={ {
                            feedback_question_negative: 'What would you like to improve?',
                            feedback_question_positive: 'What did you notice?',
                            message_placeholder: 'Message (Optional)',
                            send_button: 'Send',
                            excellent_label: 'Excellent',
                            good_label: 'Good',
                            average_label: 'Average',
                            poor_label: 'Poor',
                            terrible_label: 'Terrible',
                            show_more_categories_button: 'Show More',
                            thank_you_message: 'Thank you!',
                            thank_you_message_subheader: 'We appreciate you share the feedback on social',
                        } }
                        isFeedbackSubmitted={ isThankYouPagePreview }
                        thankYouPageLogo={ null }
                        isPromoteToSocialEnabled={ isPromoteToSocialEnabled }
                        googleMapsLink={ recipientTeam?.googleMapsLink }
                        tripadvisorLink={ recipientTeam?.tripadvisorLink }
                        instagramLink={ recipientTeam?.instagramLink }
                    />
                }
            </div>
        </div>
    );
};
