/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { container__full_width, flex_container__vertical__full_height } from '../../../common-styles/containers';
import {
    page,
    page__container,
    page__container__header_row,
    page__header_preloader,
    page__header_row,
} from '../../../common-styles/pages';
import { getAllTeamsRequestHandler } from '../../../services/request-handlers/team/team-list-request-handler';
import { getTeamConfigurationRoute } from "../../../routing/routes";
import { resetTeamConfiguration, setTeamId } from '../../../store/features/team-config-slice';
import { setTeamListLoader } from '../../../store/features/team-list-slice';
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { PrimaryButton } from '../../common/buttons/primary-button';
import { TeamListPreloader } from './team-list-preloader';
import { selectNavbar } from '../../../store/selectors/navbar-selectors';
import { NavigationBarState } from '../../../store/features/navigation-bar-slice';
import { TeamListTable } from './team-list-table';
import { NEW_ID } from '../../../models/common-constants';
import { TeamSortSelector } from './team-sort-selector';
import { TeamListCards } from './team-list-cards';
import { team_list__create_team_button, team_list__header__container } from './team-list.styles';
import { selectTeamList } from '../../../store/selectors/team-selectors';

export const TeamList = () => {
    const { allTeams, isLoading } = useAppSelector(selectTeamList);
    const { isMobile }: NavigationBarState = useAppSelector(selectNavbar);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setTeamListLoader(true));
        getAllTeamsRequestHandler(dispatch, navigate);
    }, []);

    const onCreateNewTeam = () => {
        dispatch(resetTeamConfiguration());
        dispatch(setTeamId(NEW_ID));
        navigate(getTeamConfigurationRoute(NEW_ID));
    };

    return (
        <div css={ page__container }>
            <div css={ page__container__header_row }>
                <h2>One team - one QR code</h2>
            </div>
            <div css={ page }>
                <div css={ [flex_container__vertical__full_height, container__full_width] }>
                    <div css={ [page__header_row, container__full_width, team_list__header__container] }>
                        <h2 css={ isLoading ? page__header_preloader : null }>
                            { !isLoading ? "Create and manage your teams" : null }
                        </h2>
                        <div css={ team_list__create_team_button }>
                            <PrimaryButton
                                label="Create new team"
                                onClick={ onCreateNewTeam }
                                disabled={ isLoading }
                            />
                        </div>
                    </div>
                    { isLoading && <TeamListPreloader/> }
                    { (!isLoading && !isMobile) && <TeamListTable/> }
                    { (!isLoading && isMobile) && <TeamSortSelector/> }
                    { (!isLoading && isMobile) && <TeamListCards teams={ allTeams }/> }
                </div>
            </div>
        </div>
    );
};
