import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";
import {TeamResponse} from "../../models/api/api";
import { NEW_ID } from '../../models/common-constants';

export interface TeamConfigState {
    id: string;
    name: string;
    description?: string;
    location?: string;
    backgroundPhoto?: string;
    qrHash: string;
    isDataLoading: boolean;
    isDataEdited: boolean;
    googleMapsLink?: string;
    tripadvisorLink?: string;
    instagramLink?: string;
}

const initialState: TeamConfigState = {
    id: '',
    name: '',
    description: undefined,
    location: undefined,
    backgroundPhoto: undefined,
    qrHash: '',
    isDataLoading: true,
    isDataEdited: false,
};

export const teamConfigSlice = createSlice({
    name: 'teamConfig',
    initialState,
    reducers: {
        setTeamId: (state: Draft<TeamConfigState>, action: PayloadAction<string>) => {
            state.id = action.payload;
        },
        setTeamName: (state: Draft<TeamConfigState>, action: PayloadAction<string>) => {
            state.name = action.payload;
            state.isDataEdited = true;
        },
        setTeamDescription: (state: Draft<TeamConfigState>, action: PayloadAction<string>) => {
            state.description = action.payload;
            state.isDataEdited = true;
        },
        setTeamLocation: (state: Draft<TeamConfigState>, action: PayloadAction<string>) => {
            state.location = action.payload;
            state.isDataEdited = true;
        },
        changeGoogleMapsLink: (state: Draft<TeamConfigState>, action: PayloadAction<string>) => {
            state.googleMapsLink = action.payload;
            state.isDataEdited = true;
        },
        changeTripadvisorLink: (state: Draft<TeamConfigState>, action: PayloadAction<string>) => {
            state.tripadvisorLink = action.payload;
            state.isDataEdited = true;
        },
        changeInstagramLink: (state: Draft<TeamConfigState>, action: PayloadAction<string>) => {
            state.instagramLink = action.payload;
            state.isDataEdited = true;
        },
        setTeamBackgroundPhoto: (state: Draft<TeamConfigState>, action: PayloadAction<string | undefined>) => {
            state.backgroundPhoto = action.payload;
            state.isDataEdited = true;
        },
        setTeamDataLoader: (state: Draft<TeamConfigState>, action: PayloadAction<boolean>) => {
            state.isDataLoading = action.payload;
        },
        setTeamDataEdited: (state: Draft<TeamConfigState>, action: PayloadAction<boolean>) => {
            state.isDataEdited = action.payload;
        },
        loadTeamConfiguration: (state: Draft<TeamConfigState>, action: PayloadAction<TeamResponse>) => {
            state.id = action.payload.team.uuid;
            state.name = action.payload.team.name;
            state.description = action.payload.team.description;
            state.location = action.payload.team.location?.address;
            state.backgroundPhoto = action.payload.team.backgroundImage;
            state.qrHash = action.payload.qrHash;
            state.googleMapsLink = action.payload.team.googleMapsLink;
            state.tripadvisorLink = action.payload.team.tripadvisorLink;
            state.instagramLink = action.payload.team.instagramLink;
        },
        resetTeamConfiguration: (state: Draft<TeamConfigState>) => {
            state.id = NEW_ID
            state.name = ''
            state.description = ''
            state.location = ''
            state.backgroundPhoto = undefined
            state.qrHash = ''
        },
    }
});

export const {
    setTeamId,
    setTeamName,
    setTeamDescription,
    setTeamLocation,
    setTeamBackgroundPhoto,
    setTeamDataLoader,
    setTeamDataEdited,
    loadTeamConfiguration,
    resetTeamConfiguration,
    changeGoogleMapsLink,
    changeTripadvisorLink,
    changeInstagramLink,
} = teamConfigSlice.actions;

export default teamConfigSlice.reducer;
