export enum BackgroundType {
    OWN = 'OWN',
    ARTHOUSE_V1 = 'ARTHOUSE_V1',
    ADVENTURE_V1 = 'ADVENTURE_V1',
    FANTASY_V1 = 'FANTASY_V1',
}

//TODO MO PR3: add colors adjusting according selected BackgroundType
export const getBackgroundTypes = (): string[] => {
    return Object.keys(BackgroundType).filter(type => isNaN(Number(type)))
}

const backgroundTypeToUrl = new Map<BackgroundType, string|undefined>([
    [BackgroundType.OWN, undefined],
    [BackgroundType.ARTHOUSE_V1, 'https://gist.githubusercontent.com/MagicMaxO/a25a5517854c2b6a61d976f7c099d281/raw/8e22679ae32491456d211270ba977aca0d68ba2d/arthouse_v1.svg'],
    [BackgroundType.ADVENTURE_V1, 'https://gist.githubusercontent.com/MagicMaxO/a61bd3d5abba4b10fa2de0e7ac38434f/raw/9d9884aba22e9ea547c7c65daeb6811df281fc61/adventure_v1.svg'],
    [BackgroundType.FANTASY_V1, 'https://gist.githubusercontent.com/MagicMaxO/f654918333d84cf7c28d4b39cc301866/raw/1ed51173b8ee8bc3ea310744f9a75e1db3fc4813/fantasy_v1.svg']
])

export const getUrlByBackgroundType = (backgroundType: BackgroundType):  string|undefined => {
    return backgroundTypeToUrl.get(backgroundType)
}

const backgroundTypeToDisplayName = {
    OWN : "Own",
    ARTHOUSE_V1: "Arthouse v1",
    ADVENTURE_V1: "Adventure v1",
    FANTASY_V1: "Fantasy v1",
}

export const getBackgroundTypeDisplayName = (backgroundType: string) : string => {
    return backgroundTypeToDisplayName[backgroundType as keyof typeof BackgroundType] || ''
}
