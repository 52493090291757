import { DateRange } from "../../../models/date-range";
import { AppDispatch } from "../../../store/store";
import { DashboardRequest, DashboardResponse } from "../../../models/api/api";
import { getDashboardData } from "../../request-services/dashboard";
import {
    changeDashboardDataLoader,
    setCategoriesData,
    setTimelineData,
} from "../../../store/features/dashboard-slice";
import {
    changeHappinessScore,
    changeHappinessScoreDataLoader,
    changeHappinessScoreTrend,
    changeNumberOfResponses,
} from "../../../store/features/happiness-score-slice";
import dayjs from "dayjs";
import { isDailyTimeRange } from "../../../utils/date-utils";
import { sum } from "lodash-es";
import { one } from "../../../utils/functors";
import utc from "dayjs/plugin/utc";
import { NavigateFunction } from "react-router-dom";
import { requestHandlerWrapper } from "../../../utils/request-handler-wrapper";
import { MagictapResponse } from "../../../models/magictap-response";
import { TimelineData } from '../../../models/timeline-data';
import { ALL_FILTER_PRESET, FilterPreset } from '../../../models/filters';

dayjs.extend(utc);

interface ChartData {
    moment: number,
    ratingRaw: number,
}

const isNeutralFeedbackInInterval = (data: ChartData, currentTimestamp: number, nextTimestamp: number) =>
    ((data.moment >= currentTimestamp) && (data.moment < nextTimestamp)) && (data.ratingRaw === 3);
const isNegativeFeedbackInInterval = (data: ChartData, currentTimestamp: number, nextTimestamp: number) =>
    ((data.moment >= currentTimestamp) && (data.moment < nextTimestamp)) && (data.ratingRaw < 3);
const isPositiveFeedbackInInterval = (data: ChartData, currentTimestamp: number, nextTimestamp: number) =>
    ((data.moment >= currentTimestamp) && (data.moment < nextTimestamp)) && (data.ratingRaw > 3);

const getHistogramGranularTicks = ({ from, to }: DashboardRequest) => {
    const histogramTicks = [];
    if(isDailyTimeRange(from, to)) {
        const startOfTheDay = dayjs().startOf('day').toDate();
        for(let i = 0; i < 24; i++) {
            histogramTicks.push(startOfTheDay.setHours(i));
        }
    } else {
        const countOfDays = dayjs(to).diff(from, "days") + 1;
        console.log('Count of days: ' + countOfDays);
        const fromFormatted = dayjs(from);
        for(let i = 0; i < countOfDays; i++) {
            histogramTicks.push(fromFormatted.add(i, 'day').toDate().getTime());
        }
    }
    return histogramTicks;
};

const prepareHistogramDashboardData = (histogramTicks: any[], dashboardRequest: DashboardRequest, chartData: ChartData[]) =>
    histogramTicks.map(tick => {
            const nextTick = isDailyTimeRange(dashboardRequest.from, dashboardRequest.to)
                ? dayjs(+tick).add(1, 'hour').toDate().getTime()
                : dayjs(+tick).add(1, 'day').toDate().getTime();
            const currentTimestamp = +tick;

            return {
                date: currentTimestamp,
                neutral: sum(chartData.filter((d: ChartData) => isNeutralFeedbackInInterval(d, currentTimestamp, nextTick)).map(one)),
                negative: sum(chartData.filter((d: ChartData) => isNegativeFeedbackInInterval(d, currentTimestamp, nextTick)).map(one)),
                positive: sum(chartData.filter((d: ChartData) => isPositiveFeedbackInInterval(d, currentTimestamp, nextTick)).map(one)),
            };
        },
    );

export const failedDashboardRequest = (response: MagictapResponse<unknown>, dispatch: AppDispatch) => {
    console.log('Error during dashboard request. ' + response.errorCode);

    // dispatch(dashboardRequestFailed(GENERIC_ERROR_MESSAGE));
    dispatch(changeHappinessScoreDataLoader(false));
    dispatch(changeDashboardDataLoader(false));
};

const successfulDashboardRequest = (request: DashboardRequest, response: MagictapResponse<DashboardResponse>, dispatch: AppDispatch, navigate: NavigateFunction) => {
    if(!response.content!!.chartData) {
        console.log('There is no data for selected dates.');
        return;
    }
    console.log('Dashboard response: ' + JSON.stringify(response));

    const chartData: ChartData[] = response.content!!.chartData.map((y: any) => {
        return ({
            moment: dayjs.utc(y.moment).toDate().getTime(),
            ratingRaw: y.ratingRaw,
        });
    });

    const histogramTicks = getHistogramGranularTicks(request);
    const dashboardData: TimelineData[] = prepareHistogramDashboardData(histogramTicks, request, chartData);

    console.log('Map : ' + JSON.stringify(dashboardData));
    dispatch(setCategoriesData(response.content!!.categoriesData));
    dispatch(setTimelineData(dashboardData));
    dispatch(changeNumberOfResponses(chartData.length));
    console.log('CSAT: ' + JSON.stringify(response.content!!.overallData.data.ratingCSAT));
    dispatch(changeHappinessScore(response.content!!.overallData.data.ratingCSAT));
    dispatch(changeHappinessScoreTrend(response.content!!.overallData.trend));
    dispatch(changeHappinessScoreDataLoader(false));
    dispatch(changeDashboardDataLoader(false));
};

export const dashboardRequestHandler = (selectedDateRange: DateRange,
                                        teamId: FilterPreset<string[]>,
                                        dispatch: AppDispatch,
                                        navigate: NavigateFunction) => {
    const dashboardRequest: DashboardRequest = {
        from: selectedDateRange.from!!,
        to: selectedDateRange.to!!,
        teamId: teamId === ALL_FILTER_PRESET ? undefined : teamId[0],
    };
    requestHandlerWrapper(
        getDashboardData,
        dashboardRequest,
        (resp, dispatch, navigate) =>
            successfulDashboardRequest(dashboardRequest, resp, dispatch, navigate),
        failedDashboardRequest,
        dispatch,
        navigate,
    );
};
