/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { flex_container__horizontal } from "../../../common-styles/containers";
import { signup__terms_of_usage_disclaimer } from "../sign-up.styles";
import Mail from "../../../static/mail.svg";
import React from "react";
import { AccountFormContainer } from "../common/account-form-container";
import { WizardValidationForm } from "./wizard-validation-form";
import { SignupResetLink } from '../../common/signup-reset-link';

const WizardValidationDisclaimer = () => {
    return (
        <div css={ [flex_container__horizontal, signup__terms_of_usage_disclaimer] }>
            <p>
                If you didn't receive email, follow the <SignupResetLink/> to reset your email address in sign up
                process
            </p>
        </div>
    );
};

export const WizardValidationStep = () => {
    return (
        <AccountFormContainer
            form={ <WizardValidationForm/> }
            disclaimer={ <WizardValidationDisclaimer/> }
            wizardStepImage={ Mail }
        />
    );
};
