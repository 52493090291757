/* ATTENTION!
* if you're about to change this Enum, please consider adjusting the according Enum in BE repo. Thanks!
*/
export enum Country {
    ALBANIA = 'ALBANIA',
    ANDORRA = 'ANDORRA',
    AUSTRIA = 'AUSTRIA',
    BELARUS = 'BELARUS',
    BELGIUM = 'BELGIUM',
    BOSNIA_AND_HERZEGOVINA = 'BOSNIA_AND_HERZEGOVINA',
    BULGARIA = 'BULGARIA',
    CROATIA = 'CROATIA',
    CZECH_REPUBLIC = 'CZECH_REPUBLIC',
    DENMARK = 'DENMARK',
    ESTONIA = 'ESTONIA',
    FINLAND = 'FINLAND',
    FRANCE = 'FRANCE',
    GERMANY = 'GERMANY',
    GREECE = 'GREECE',
    HOLY_SEE = 'HOLY_SEE',
    HUNGARY = 'HUNGARY',
    ICELAND = 'ICELAND',
    IRELAND = 'IRELAND',
    ITALY = 'ITALY',
    LATVIA = 'LATVIA',
    LIECHTENSTEIN = 'LIECHTENSTEIN',
    LITHUANIA = 'LITHUANIA',
    LUXEMBOURG = 'LUXEMBOURG',
    MALTA = 'MALTA',
    MOLDOVA = 'MOLDOVA',
    MONACO = 'MONACO',
    MONTENEGRO = 'MONTENEGRO',
    NETHERLANDS = 'NETHERLANDS',
    NORTH_MACEDONIA = 'NORTH_MACEDONIA',
    NORWAY = 'NORWAY',
    POLAND = 'POLAND',
    PORTUGAL = 'PORTUGAL',
    ROMANIA = 'ROMANIA',
    RUSSIA = 'RUSSIA',
    SAN_MARINO = 'SAN_MARINO',
    SERBIA = 'SERBIA',
    SLOVAKIA = 'SLOVAKIA',
    SLOVENIA = 'SLOVENIA',
    SPAIN = 'SPAIN',
    SWEDEN = 'SWEDEN',
    SWITZERLAND = 'SWITZERLAND',
    UKRAINE = 'UKRAINE',
    UK = 'UK',
    USA = 'USA'
}

export const getCountries = () => {
    return Object.keys(Country).filter(key => isNaN(Number(key)))
}

const countryToDisplayName = {
    ALBANIA: "Albania",
    ANDORRA: 'Andorra',
    AUSTRIA: 'Austria',
    BELARUS: 'Belarus',
    BELGIUM: 'Belgium',
    BOSNIA_AND_HERZEGOVINA: 'Bosnia and Herzegovina',
    BULGARIA: 'Bulgaria',
    CROATIA: 'Croatia',
    CZECH_REPUBLIC: 'Czech Republic',
    DENMARK: 'Denmark',
    ESTONIA: 'Estonia',
    FINLAND: 'Finland',
    FRANCE: 'France',
    GERMANY: 'Germany',
    GREECE: 'Greece',
    HOLY_SEE: 'Holy See',
    HUNGARY: 'Hungary',
    ICELAND: 'Iceland',
    IRELAND: 'Ireland',
    ITALY: 'Italy',
    LATVIA: 'Latvia',
    LIECHTENSTEIN: 'Liechtenstein',
    LITHUANIA: 'Lithuania',
    LUXEMBOURG: 'Luxembourg',
    MALTA: 'Malta',
    MOLDOVA: 'Moldova',
    MONACO: 'Monaco',
    MONTENEGRO: 'Montenegro',
    NETHERLANDS: 'Netherlands',
    NORTH_MACEDONIA: 'North Macedonia',
    NORWAY: 'Norway',
    POLAND: 'Poland',
    PORTUGAL: 'Portugal',
    ROMANIA: 'Romania',
    RUSSIA: 'Russia',
    SAN_MARINO: 'San Marino',
    SERBIA: 'Serbia',
    SLOVAKIA: 'Slovakia',
    SLOVENIA: 'Slovenia',
    SPAIN: 'Spain',
    SWEDEN: 'Sweden',
    SWITZERLAND: 'Switzerland',
    UKRAINE: 'Ukraine',
    UK: 'United Kingdom',
    USA: 'United States'
}

export const getCountryDisplayName = (country: string): string => {
    return countryToDisplayName[country as keyof typeof Country] || ''
}