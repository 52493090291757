import { NavigateFunction } from "react-router-dom";
import { CreateTeamRequest, TeamResponse } from "../../../models/api/api";
import { GENERIC_ERROR_MESSAGE } from "../../../models/common-constants";
import { MagictapResponse } from "../../../models/magictap-response";
import { getTeamConfigurationRoute, TEAM_BASE_ROUTE } from "../../../routing/routes";
import { createTeam } from "../../request-services/team";
import { NotificationType, showNotification } from "../../../store/features/notification-bar-slice";
import { loadTeamConfiguration, setTeamDataEdited } from "../../../store/features/team-config-slice";
import { AppDispatch } from "../../../store/store";
import { requestHandlerWrapper } from "../../../utils/request-handler-wrapper";

export const createTeamFailure = (MagictapResponse: any, dispatch: AppDispatch) => {
    dispatch(showNotification({ title: GENERIC_ERROR_MESSAGE, type: NotificationType.ERROR }));
};

function createTeamSuccess(creationResponse: MagictapResponse<TeamResponse>, dispatch: AppDispatch, navigate: NavigateFunction) {
    dispatch(loadTeamConfiguration(creationResponse.content!!));
    navigate(`/${TEAM_BASE_ROUTE}/${getTeamConfigurationRoute(creationResponse.content!!.team.uuid)}`)
    dispatch(showNotification({ title: "Team has been created", type: NotificationType.SUCCESS }))
    dispatch(setTeamDataEdited(false))
}
export const createTeamRequestHandler = (request: CreateTeamRequest, dispatch: AppDispatch, navigate: NavigateFunction) => {
    requestHandlerWrapper(
        createTeam,
        request,
        createTeamSuccess,
        createTeamFailure,
        dispatch,
        navigate,
    );
};
