/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { List } from "@mui/material";
import { getNavigationMenu, logoutPage, NavigationItemName } from "../../routing/pages";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { navigation_menu__list_items__dark_theme } from "./desktop-navbar.styles";
import { WHITE } from "../../common-styles/color-palette";
import { sideNavigationWidthExpanded, sideNavigationWidthShrunk } from "./navbar.styles";
import { container__full_height } from "../../common-styles/containers";
import { logout } from "../../services/request-services/login";
import { setAuthorised } from "../../store/features/authorisation-slice";
import { InputHelperTooltip } from '../common/input-helper-tooltip';
import { toggleHighlightDashboard } from '../../store/features/signup-slice';
import { NavbarMenuItem } from './navbar-menu-item';
import { mobileDevices, mobileDevicesLandscape } from '../../common-styles/adaptive-breakpoints';

interface Props {
    opened: boolean;
    isMobile?: boolean;
    mobileClickHandler?: () => void;
}

const navigation_menu__account_button = (isNavbarOpened: boolean, isMobile?: boolean) => css({
    display: 'flex',
    justifyContent: 'center',
    width: isNavbarOpened ? sideNavigationWidthExpanded : sideNavigationWidthShrunk,
    color: WHITE,
    position: isMobile ? undefined : 'fixed',
    bottom: '0',
    marginBottom: '2em',

    [mobileDevices]: {
        marginBottom: 0,
    },
    [mobileDevicesLandscape]: {
        marginBottom: 0,
    },
});

export const NavbarMenu = ({ opened, mobileClickHandler, isMobile }: Props) => {
    const { highlightDashboard } = useAppSelector(state => state.signup);
    const dispatch = useAppDispatch();

    const dashboardItemHandler = () => {
        dispatch(toggleHighlightDashboard(false));
        mobileClickHandler && mobileClickHandler();
    };

    const logoutHandler = () => {
        logout().then(x => {
                if(!x.errorCode) {
                    dispatch(setAuthorised(false));
                    console.log('Logged out!');
                }
            },
        );
    };

    return (
        <div css={ container__full_height }>
            <List css={ navigation_menu__list_items__dark_theme }>
                { getNavigationMenu().map((item, index) => {
                        if(item.name === NavigationItemName.DASHBOARD) {
                            return (
                                <InputHelperTooltip
                                    title="Here are all the reviews analytics"
                                    open={ !!(opened && highlightDashboard) }
                                    key={ index }
                                >
                                    <div key={ index }>
                                        <NavbarMenuItem
                                            item={ item }
                                            key={ index }
                                            navbarOpened={ opened }
                                            onMenuItemClick={ dashboardItemHandler }
                                            isMobile={ isMobile }
                                        />
                                    </div>
                                </InputHelperTooltip>
                            );
                        }
                        return (
                            <NavbarMenuItem
                                item={ item }
                                key={ index }
                                navbarOpened={ opened }
                                onMenuItemClick={ mobileClickHandler }
                                isMobile={ isMobile }
                            />
                        );
                    },
                ) }
            </List>
            <div css={ navigation_menu__account_button(opened, isMobile) }>
                <List css={ [navigation_menu__list_items__dark_theme, css({ width: '100%' })] }>
                    <NavbarMenuItem
                        item={ logoutPage }
                        navbarOpened={ opened }
                        onMenuItemClick={ logoutHandler }
                        isMobile={ isMobile }
                    />
                </List>
            </div>
        </div>
    );
};
