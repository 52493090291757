import { css, SerializedStyles } from '@emotion/react';
import { BORDER_RADIUS_SMALL } from '../../common-styles/borders';
import { GREY_BASIC, GREY_LIGHT } from '../../common-styles/color-palette';

export const category__input: SerializedStyles = css({
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.5em',
    cursor: 'pointer',
    borderRadius: BORDER_RADIUS_SMALL,
    border: `1px solid ${ GREY_BASIC }`,
    backgroundColor: GREY_LIGHT,
});

export const category__input_container: SerializedStyles = css({
    marginRight: '0.25em',
    flex: '1.5',
});

export const category__name: SerializedStyles = css({
    width: '100%',
    marginRight: '0.25em',
    flex: '3',
    '& .MuiFormControl-root': { height: '100%' },
    '& .MuiFilledInput-root': { height: '100%' },
});

export const category__add_button: SerializedStyles = css({ flex: '2' });

export const category__emoji_picker: SerializedStyles = css({
    paddingBottom: '1.5em',
    flexDirection: 'row',
    display: 'flex',
    width: '100%',
    height: '100%',
});
