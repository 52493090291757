/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {css, jsx} from '@emotion/react';
import {flex_container__horizontal__full_width} from "../../../common-styles/containers";
import React from "react";

interface WizardStepImageProps {
    image: string;
}

const signup_wizard__step_image = css({
    width: '95%',
    height: '16em',
    maxHeight: '16em',
    maxWidth: '20em',
    '@media (max-width: 555px) or (max-height: 780px)': {
        maxWidth: '12em',
        maxHeight: '12em',
    }
});

export const WizardStepImage = ({image}: WizardStepImageProps) => {
    return (
        <div css={flex_container__horizontal__full_width}>
            <img css={signup_wizard__step_image} src={image} alt="SignupStep"/>
        </div>
    );
};
