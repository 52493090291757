import {css} from "@emotion/react";
import {DARK_BASIC, GREEN_BASIC, GREY_DARK, RED_BASIC} from "../../../../../common-styles/color-palette";
import {FONT_SIZE_M, FONT_SIZE_XXS, FONT_SIZE_XXXXS} from "../../../../../common-styles/font-sizes";
import {REGULAR_FONT} from "../../../../../common-styles/font-styles";

export const file_uploader__frame_accept = css({
    border: `1px dashed ${GREEN_BASIC}`,
})
export const file_uploader__frame_reject = css({
    border: `1px dashed ${RED_BASIC}`,
})

export const file_uploader__dropzone__title = css({
    color: GREY_DARK,
    fontSize: FONT_SIZE_M,
    marginBottom: '0.7em',
    fontFamily: REGULAR_FONT,
    textAlign: 'center',
    fontWeight: '400',
});
export const file_uploader__dropzone__title_reject = css({color: RED_BASIC})
export const file_uploader__dropzone__title_accept = css({color: DARK_BASIC})

const file_uploader__frame_hover__title = css({
    ':hover': {
        '.file-uploader__dropzone__title': {...file_uploader__dropzone__title_accept},
    }
})
const file_uploader__frame__hover_workaround = css({
    ':hover': {
        ...file_uploader__frame_accept
    }
})

export const file_uploader__frame_hover = css([file_uploader__frame_hover__title, file_uploader__frame__hover_workaround])

export const file_uploader__dropzone__description = css({
    color: GREY_DARK,
    fontFamily: REGULAR_FONT,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: FONT_SIZE_XXXXS,
    fontWeight: '400',
    letterSpacing: '0.24em',
});

export const file_uploader__dropzone__description_reject = css({color: RED_BASIC})

export const file_uploader__dropzone__error_explanation = css({
    fontSize: FONT_SIZE_XXS,
    color: RED_BASIC,
})
