/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {css, jsx} from '@emotion/react';
import {skeleton__preloader} from "../../../common-styles/skeleton";
import {BORDER_RADIUS_EXTRA_SMALL, BORDER_RADIUS_SMALL} from "../../../common-styles/borders";
import {GREY_BASIC} from "../../../common-styles/color-palette";

export const CategoryPreloader = () => {
    return (
        <div css={css({
            padding: "1em 0 0.5em 0.5em",
            display: "flex",
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
        })}>
            <div css={css({display: 'flex', justifyContent: 'center'})}>
                <div css={[skeleton__preloader, css({
                    width: '1em',
                    height: '1em',
                    marginRight: '0.5em',
                    borderRadius: BORDER_RADIUS_EXTRA_SMALL,
                })]}>
                </div>
                <div css={[skeleton__preloader, css({
                    width: '3em',
                    height: '1em',
                    borderRadius: BORDER_RADIUS_EXTRA_SMALL,
                })]}>
                </div>
            </div>
            <div css={[skeleton__preloader, css({
                padding: "1em 0 0 0.5em",
                width: "4.5em",
                height: '1.5em',
                marginTop: '1em',
                borderRadius: BORDER_RADIUS_SMALL,
                backgroundColor: GREY_BASIC,
            })]}/>
            <div css={[skeleton__preloader, css({
                width: '3em',
                height: '1em',
                marginTop: '1em',
                borderRadius: BORDER_RADIUS_EXTRA_SMALL,
            })]}>
            </div>
        </div>
    );
};
