/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { GREY_DARK } from '../../common-styles/color-palette';
import { FONT_SIZE_XXS } from '../../common-styles/font-sizes';

export const FormHint = ({ label }: { label: string }) => (
    <div css={ css({ color: GREY_DARK, fontSize: FONT_SIZE_XXS }) }>
        { label }
    </div>
);
