export enum ErrorCode {
    INVALID_REQUEST_PARAMETER = 1,
    ENTITY_NOT_FOUND = 2,
    ENTITY_ALREADY_EXIST = 3,
    ACCOUNT_ALREADY_EXIST = 4,
    ACCOUNT_DOES_NOT_EXIST = 5,
    SESSION_IS_NOT_VALID = 6,
    INCORRECT_CREDENTIALS = 7,
    VERIFICATION_FAILED = 8,
    LINK_EXPIRED = 9,
    BE_ERROR = -1,
}

export interface MagictapResponse<T> {
    errorCode?: ErrorCode;
    message?: string;
    content?: T;

    // todo: revise if this attribute is needed or not
    // type: any;
}
