import { css } from "@emotion/react";
import { WHITE } from "../../../common-styles/color-palette";
import { BORDER_RADIUS_MEDIUM } from "../../../common-styles/borders";
import { PAGE_CONTAINER_BOX_SHADOW } from "../../../common-styles/shadows";
import { mobileDevices } from '../../../common-styles/adaptive-breakpoints';

export const top_rated_categories__container = css({
    backgroundColor: WHITE,
    borderRadius: BORDER_RADIUS_MEDIUM,
    boxShadow: PAGE_CONTAINER_BOX_SHADOW,
    width: '85%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

    [mobileDevices]: {
        width: 'unset',
        height: 'unset',
        borderRadius: 'unset',
    },
});

export const top_rated_categories__scores_container = css({
    display: 'flex',
    justifyContent: 'space-around',
    height: '100%',

    [mobileDevices]: {
        height: 'unset',
        flexDirection: 'column',
    }
});
