import {css} from '@emotion/react';
import {BORDER_RADIUS_SMALL} from '../../../common-styles/borders';
import {DARK_BASIC, GREEN_BASIC, GREEN_LIGHT, GREY_BASIC, GREY_MEDIUM} from '../../../common-styles/color-palette';

export const primary_button = css({
    backgroundColor: GREEN_BASIC,
    textTransform: 'none',
    height: '3.5em',
    boxSizing: 'border-box',
    boxShadow: 'none',
    width: '100%',
    color: DARK_BASIC,
    borderRadius: BORDER_RADIUS_SMALL,
    '&:hover': {
        backgroundColor: GREEN_LIGHT,
        boxShadow: 'none',
    },

    '&:active': {
        boxShadow: 'none',
        backgroundColor: GREEN_BASIC,
    },
    '& span:first-of-type': {
        display: 'none',
    },

    ':disabled': {
        backgroundColor: GREY_BASIC,
        color: GREY_MEDIUM,
    },
});

