import { css } from "@emotion/react";
import { DROP_SHADOW_BLACK } from "../../../../common-styles/shadows";

export const color_picker = css({ position: "relative" })
export const color_picker__icon = css({
    position: "absolute",
    top: "0.90625em",
    right: "0.90625em",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1.5em',
    height: '1.5em'
})
export const color_picker__palette = { 
    boxShadow: DROP_SHADOW_BLACK, 
    bgcolor: 'white', 
    padding: '1em', 
    borderRadius: '0.5em' 
}
