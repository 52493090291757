/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {css, jsx} from '@emotion/react';
import {CaptionProps, useDayPicker} from "react-day-picker";
import {useAppDispatch} from "../../../store/hooks";
import {toggleCalendarPopup} from "../../../store/features/score-period-slice";
import React, {Fragment} from "react";
import {SimpleIconButton} from "../buttons/simple-icon-button";
import {Close} from "../../../icons/Close";
import {GREY_BASIC} from "../../../common-styles/color-palette";
import {YearSelect} from "./year-select";
import {MonthSelect} from "./month-select";
import {DateRangePickerPresetButtons} from "./date-range-picker-preset-buttons";

export const DatePickerCaption = (props: CaptionProps) => {
    const {
        classNames,
        styles,
        fromDate,
        toDate,
    } = useDayPicker();

    const dispatch = useAppDispatch();

    const closeCalendar = () => {
        dispatch(toggleCalendarPopup(false));
    };

    if (!fromDate) return <Fragment></Fragment>;
    if (!toDate) return <Fragment></Fragment>;

    return (
        <div
            css={css({})}
        >
            <div css={css({display: 'flex', justifyContent: 'space-between', marginBottom: '1em'})}>
                <h2>Period Settings</h2>
                <SimpleIconButton onClick={closeCalendar}>
                    <Close/>
                </SimpleIconButton>
            </div>
            <DateRangePickerPresetButtons/>
            <div
                className={classNames.caption_dropdowns}
                style={styles.caption_dropdowns}
                css={css({
                    display: 'flex',
                    flexDirection: 'column',
                    borderTop: `1px solid ${GREY_BASIC}`,
                    paddingTop: '0.5em'
                })}
            >
                <div css={css({display: 'flex', justifyContent: 'space-between'})}>
                    <MonthSelect displayMonth={props.displayMonth}/>
                    <YearSelect displayMonth={props.displayMonth}/>
                </div>
            </div>
        </div>
    );
};
