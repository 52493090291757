import { css } from '@emotion/react';
import { GREY_DARK, GREY_LIGHT, WHITE } from './color-palette';
import { FONT_SIZE_XXS, FONT_SIZE_XXXXS } from './font-sizes';
import { REGULAR_FONT } from './font-styles';

export const AgGridContainerStyle = css({
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    minHeight: '20em',

    '&.ag-theme-alpine': {
        '--ag-header-foreground-color': GREY_DARK,
        '--ag-header-background-color': WHITE,
        '--ag-icon-font-family': 'agGridAlpine',
        '.ag-header': {
            fontFamily: REGULAR_FONT,
            textTransform: 'uppercase',
            fontSize: FONT_SIZE_XXXXS,
            fontWeight: '400',
            letterSpacing: '0.24em',
            border: 'none',
        },
        '.ag-root-wrapper': {
            border: 'none',
        },
        '.ag-row-hover': {
            backgroundColor: `${ GREY_LIGHT }!important`,
        },
        '.ag-center-cols-viewport': {
            overflowX: 'hidden',
        },
    },

    '& .ag-theme-material': {
        '--ag-icon-font-family': 'agGridMaterial',

        '& .ag-header': {
            color: GREY_DARK,
            fontSize: FONT_SIZE_XXS,
            textTransform: 'uppercase',
            marginLeft: '1em',
        },
    },

    '.ag-theme-material .ag-header-cell': {
        fontSize: FONT_SIZE_XXXXS,
    },

    '& .ag-header-cell-text': {
        letterSpacing: '0.25em',
    },

});

export const AgGridCanvasStyle = css({
    width: '100%',
    height: '100%',
});
