import { css } from "@emotion/react";
import { BORDER_RADIUS_EXTRA_SMALL } from "../../../../common-styles/borders";

export const template_preview = (backgroundImage?: string) => css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '13cm',
    aspectRatio: '0.71',
    backgroundImage: backgroundImage? `url(${backgroundImage})` : 'transparent',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: BORDER_RADIUS_EXTRA_SMALL,
})

export const template_preview__container = css({
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center'
})

export const template_preview__upper_section = css({
    width: '100%',
    flex: '1 1 45%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
})

export const template_preview__lower_section = css({
    width: '100%',
    flex: '1 1 55%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center'
})

export const template_preview__qr_invitation = (color?: string) => css({
    marginTop: '1.5em', 
    color : color ? color : "initial"
})
