import {ErrorCode, MagictapResponse} from "../../models/magictap-response";
import {AppDispatch} from "../../store/store";
import {NavigateFunction} from "react-router-dom";
import {changeRequestError, changeRequestIsProcessing} from "../../store/features/login-slice";
import {requestHandlerWrapper} from "../../utils/request-handler-wrapper";
import {resetPassword} from "../request-services/password-reset";
import {GENERIC_ERROR_MESSAGE} from "../../models/common-constants";
import {PASSWORD_RESET_SUCCESS_ROUTE} from "../../routing/routes";

const successfulPasswordReset = (response: MagictapResponse<null>, dispatch: AppDispatch, navigate: NavigateFunction) => {
    dispatch(changeRequestIsProcessing(false));
    navigate(`/${PASSWORD_RESET_SUCCESS_ROUTE}`);
};

const failedPasswordReset = (response: MagictapResponse<unknown>, dispatch: AppDispatch) => {
    if (response.errorCode === ErrorCode.ENTITY_NOT_FOUND) {
        dispatch(changeRequestError('This email is not registered'));
    } else if (response.errorCode === ErrorCode.INVALID_REQUEST_PARAMETER) {
        dispatch(changeRequestError('Invalid email provided'));
    } else {
        dispatch(changeRequestError(GENERIC_ERROR_MESSAGE));
    }
    dispatch(changeRequestIsProcessing(false));
};

export const passwordResetRequestHandler = (email: string, dispatch: AppDispatch, navigate: NavigateFunction) => {
    requestHandlerWrapper(
        resetPassword,
        {email},
        successfulPasswordReset,
        failedPasswordReset,
        dispatch,
        navigate
    );
};
