/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { TextField } from "@mui/material";
import { HTMLInputTypeAttribute, useState } from 'react';
import { HelperMessage, HelperMessageType } from '../../../../models/helper-message';
import { text__input } from '../../../common/inputs/text-input.styles';
import {
    team_configuration_form__item_helper_message__loader,
    team_configuration_form__item_input,
    team_configuration_form__item_input__loader,
} from '../team-configuration-form.styles';

interface TeamTextInputProps {
    onChange?: (e: string) => void;
    label?: string;
    placeholder?: string;
    value?: string;
    required?: boolean;
    disabled?: boolean;
    helperMessage?: HelperMessage;
    autoComplete?: string;
    isLoading?: boolean;
    type?: HTMLInputTypeAttribute;
}

export const TeamTextInput =
    ({
         onChange,
         autoComplete,
         required,
         disabled,
         label,
         placeholder,
         value,
         helperMessage,
         isLoading,
         type,
     }: TeamTextInputProps) => {
        const [isFocused, setFocused] = useState(false);
        const hasRequiredError = !value && required;
        return <div css={ team_configuration_form__item_input }>
            { isLoading && <div>
                <div css={ team_configuration_form__item_input__loader }></div>
                { !!helperMessage?.text && <div css={ team_configuration_form__item_helper_message__loader }/> }
            </div> }
            { !isLoading &&
                <TextField
                    InputLabelProps={ { shrink: !!value || isFocused } }
                    css={ text__input }
                    variant={ "filled" }
                    type={ type || "text" }
                    label={ label }
                    placeholder={ placeholder }
                    value={ value }
                    required={ required }
                    disabled={ disabled }
                    autoComplete={ autoComplete }
                    error={ hasRequiredError || helperMessage?.type === HelperMessageType.ERROR }
                    onChange={ (e) => onChange?.(e.target.value) }
                    onBlur={ () => setFocused(false) }
                    onFocus={ () => setFocused(true) }
                    helperText={ hasRequiredError ? `${ label } is required` : helperMessage?.text }
                />
            }
        </div>;
    };
