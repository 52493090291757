import { css } from '@emotion/react';
import { REGULAR_FONT } from '../../../common-styles/font-styles';

//TODO MO PR3: align design according Figma
export const radio_group__option = css({
    margin: 0,
    gap: '0.5em',
    '& .MuiFormControlLabel-label': {
        fontFamily: REGULAR_FONT,
        lineHeight: 'unset',
    },
});

export const radio_group__option__container = css({
    // minHeight: '1.25em',
});
