/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {jsx} from '@emotion/react';
import setYear from "date-fns/setYear";
import startOfYear from "date-fns/startOfYear";
import {CaptionProps, useDayPicker, useNavigation} from "react-day-picker";
import React, {Fragment} from "react";
import startOfMonth from "date-fns/startOfMonth";
import {Select} from "../dropdowns/select";
import {css} from "@emotion/react";
import {MenuItem} from "@mui/material";

const getYearsForSelector = (fromDate: Date, toDate: Date) => {
    const years: Date[] = [];
    const fromYear = fromDate.getFullYear();
    const toYear = toDate.getFullYear();
    for (let year = fromYear; year <= toYear; year++) {
        years.push(setYear(startOfYear(new Date()), year));
    }
    return years;
};

export const YearSelect = (props: CaptionProps) => {
    const {
        onMonthChange,
        fromDate,
        toDate,
        locale,
        formatters: {formatYearCaption},
    } = useDayPicker();
    const {goToMonth} = useNavigation();

    if (!fromDate) return <Fragment></Fragment>;
    if (!toDate) return <Fragment></Fragment>;

    const years: Date[] = getYearsForSelector(fromDate, toDate);

    const handleYearChange: any = (e: any) => {
        const newMonth = setYear(
            startOfMonth(props.displayMonth),
            Number(e.target.value)
        );
        goToMonth(newMonth);
        onMonthChange?.(newMonth);
    };

    return (
        <Select
            onChange={handleYearChange}
            value={props.displayMonth.getFullYear()}
            css={css({width: '37%'})}
        >
            {years.map(m => (
                <MenuItem key={m.getFullYear()} value={m.getFullYear()}>
                    {formatYearCaption(m, {locale})}
                </MenuItem>
            ))}
        </Select>
    )
};
