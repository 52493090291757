/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {jsx} from '@emotion/react';
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {Link, useNavigate} from "react-router-dom";
import React, {ChangeEvent, Fragment, useCallback} from "react";
import {changeEmail, changeRequestIsProcessing, validateEmail} from "../../store/features/login-slice";
import {flex_container__horizontal__full_width} from "../../common-styles/containers";
import {signup__welcome_message} from "../sign-up/sign-up.styles";
import {css} from "@emotion/react";
import {EmailInput} from "../common/inputs/email-input";
import {LOGIN_BASE_ROUTE} from "../../routing/routes";
import {ErrorMessage} from "../common/error-message";
import {SubmitButton} from "../common/buttons/submit-button";
import {signup__redirect_to_login__link} from "../sign-up/wizard-steps/wizard-signup-form.styles";
import {passwordResetRequestHandler} from "../../services/request-handlers/password-reset-request-handler";
import {debounce} from "lodash-es";

export const PasswordResetForm = () => {
    const {email, emailIsValid, requestError, requestIsProcessing} = useAppSelector(state => state.login);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const debouncedEmailValidationChecker = useCallback(debounce(() => dispatch(validateEmail()), 700), []);
    const onEmailInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(changeEmail(e.target.value));
        debouncedEmailValidationChecker();
    }

    const onEmailInputBlur = () => {
        dispatch(validateEmail());
    }

    const onSubmitButtonHandler = () => {
        dispatch(changeRequestIsProcessing(true));
        passwordResetRequestHandler(email, dispatch, navigate);
    };

    return (
        <Fragment>
            <div css={flex_container__horizontal__full_width}>
                <h1 css={signup__welcome_message}>
                    Ok, let's <br/> reset this
                </h1>
            </div>
            <div>
                <div css={[flex_container__horizontal__full_width]}>
                    <div css={css({width: "90%", height: '3.5em'})}>
                        <EmailInput
                            emailIsValid={emailIsValid}
                            onChange={onEmailInputChange}
                            onBlur={onEmailInputBlur}
                        />
                    </div>
                </div>
                <div css={[flex_container__horizontal__full_width]}>
                    <div css={css({width: "90%"})}>
                        <ErrorMessage message={requestError}/>
                    </div>
                </div>
                <div css={[flex_container__horizontal__full_width, css({margin: "1em 0"})]}>
                    <div css={css({width: "90%", height: '3.5em'})}>
                        <SubmitButton
                            label={"Send me link"}
                            requestLoadingIndicator={requestIsProcessing}
                            disabled={!email || !emailIsValid || requestIsProcessing}
                            onClick={onSubmitButtonHandler}
                        />
                    </div>
                </div>
            </div>
            <div css={[flex_container__horizontal__full_width]}>
                <p css={signup__redirect_to_login__link}>Recall your password? <Link
                    to={`/${LOGIN_BASE_ROUTE}`}>Return to login</Link>
                </p>
            </div>
        </Fragment>
    )
};
