import { css } from "@emotion/react";
import { BORDER_RADIUS_SMALL } from "../../../../common-styles/borders";
import { skeleton__preloader } from "../../../../common-styles/skeleton";

export const template_editor__section = css({ marginBottom: "1.25em" })
export const template_editor__section__label = css({ margin: "0 0 1em" })
export const template_editor__section__label_loading = css([
    template_editor__section__label,
    skeleton__preloader,
    {
        borderRadius: BORDER_RADIUS_SMALL,
        minHeight: '1.28125em',
        width: '10em'
    }
])
