/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import { RowClassParams, RowClickedEvent } from 'ag-grid-community';
import { REGULAR_FONT } from '../../../common-styles/font-styles';
import { GREY_BASIC, WHITE } from '../../../common-styles/color-palette';
import { getTeamTableConfigurationRoute } from '../../../routing/routes';
import { AgGridCanvasStyle, AgGridContainerStyle } from '../../../common-styles/grid-style';
import { container__full_width } from '../../../common-styles/containers';
import { AgGridReact } from 'ag-grid-react';
import { selectTeamTablesList } from '../../../store/selectors/team-selectors';
import { teamTablesColumnDefinitions } from './team-tables-column-definitions';
import { getTableRequestHandler } from '../../../services/request-handlers/team/get-table-request-handler';

export const TeamTablesTable = () => {
    const { allTables } = useAppSelector(selectTeamTablesList);
    const navigate: NavigateFunction = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();

    const teamId = searchParams.get('teamId') || '';

    const getRowStyle = (row: RowClassParams) => {
        const isLastRow = row.rowIndex === (allTables.length - 1);
        return {
            fontFamily: REGULAR_FONT,
            backgroundColor: WHITE,
            borderBottom: isLastRow ? 'none' : `1px solid ${ GREY_BASIC }`,
        };
    };
    const onRowClicked = (row: RowClickedEvent) => {
        navigate(getTeamTableConfigurationRoute(teamId, row.data.id));
        getTableRequestHandler(row.data.id || '', dispatch, navigate);
    };

    return (
        <div
            css={ [AgGridContainerStyle, container__full_width] }
            // TODO: replace with material theme
            className="ag-theme-alpine"
        >
            <AgGridReact
                css={ AgGridCanvasStyle }
                getRowStyle={ getRowStyle }
                rowData={ allTables }
                suppressCellFocus={ true }
                onRowClicked={ onRowClicked }
                animateRows={ true }
                columnDefs={ teamTablesColumnDefinitions }
            />
        </div>
    );
};
