import { NavigateFunction } from "react-router-dom";
import { UpdateTeamRequest } from "../../../models/api/api";
import { GENERIC_ERROR_MESSAGE } from "../../../models/common-constants";
import { MagictapResponse } from "../../../models/magictap-response";
import { updateTeamData } from "../../request-services/team";
import { NotificationType, showNotification } from "../../../store/features/notification-bar-slice";
import { setTeamDataEdited } from "../../../store/features/team-config-slice";
import { AppDispatch } from "../../../store/store";
import { requestHandlerWrapper } from "../../../utils/request-handler-wrapper";

export const updateTeamFailure = (response: MagictapResponse<unknown>, dispatch: AppDispatch) => {
    dispatch(showNotification({ title: GENERIC_ERROR_MESSAGE, type: NotificationType.ERROR }));
};

function updateTeamSuccess(response: any, dispatch: AppDispatch, navigate: NavigateFunction) {
    dispatch(showNotification({ title: 'Team has been updated!', type: NotificationType.SUCCESS }));
    dispatch(setTeamDataEdited(false))
}

export const updateTeamRequestHandler = (request: UpdateTeamRequest, dispatch: AppDispatch, navigate: NavigateFunction) => {
    requestHandlerWrapper(
        updateTeamData,
        request,
        updateTeamSuccess,
        updateTeamFailure,
        dispatch,
        navigate,
    );
};
