import {css} from "@emotion/react";
import {GREY_DARK, WHITE} from "../../common-styles/color-palette";
import {BORDER_RADIUS_MEDIUM} from "../../common-styles/borders";
import {FONT_SIZE_EXTRA} from "../../common-styles/font-sizes";

export const signup_wizard__container = css({
    marginTop: '2em',
    width: '100%',
    height: 'max-content',
    '@media (max-height: 780px)': {
        height: 'max-content',
        justifyContent: 'flex-start',
    },
    maxHeight: '867px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: BORDER_RADIUS_MEDIUM,
    backgroundColor: WHITE,
});

export const signup__terms_of_usage_disclaimer = css({
    textAlign: 'center',
    fontSize: '0.85em',
    color: GREY_DARK
});

export const signup__welcome_message = css({
    fontSize: FONT_SIZE_EXTRA,
    lineHeight: '1em',
    textAlign: 'center',
    marginBlock: '0.4em',
    '@media (max-width: 555px) or (max-height: 780px)': {
        fontSize: '2em',
    },
});
