export const LOGIN_BASE_ROUTE = 'login';

export const ERROR_PAGE = '404';

export const ANCHOR_ROUTE = '#';

export const DASHBOARD_BASE_ROUTE = 'dashboard';
export const DETAILED_FEEDBACK_BASE_ROUTE = 'details';
export const DETAILED_FEEDBACK_ROUTE = `${ DASHBOARD_BASE_ROUTE }/${ DETAILED_FEEDBACK_BASE_ROUTE }`;

export const CONFIGURATION_BASE_ROUTE = 'configuration';

export const ACCOUNT_BASE_ROUTE = 'account';

export const SIGN_UP_BASE_ROUTE = 'signup';
export const WIZARD_BASE_ROUTE = `${ SIGN_UP_BASE_ROUTE }/wizard-steps`;
export const WIZARD_VERIFICATION_ROUTE = `${ WIZARD_BASE_ROUTE }/verification`;
export const WIZARD_ACCOUNT_CREATION_ROUTE = `${ WIZARD_BASE_ROUTE }/account`;
export const WIZARD_TEAM_CREATION_ROUTE = `${ WIZARD_BASE_ROUTE }/team`;

export const PASSWORD_RESET_ROUTE = 'password';
export const PASSWORD_UPDATE_ROUTE = `${ PASSWORD_RESET_ROUTE }/reset`;
export const PASSWORD_RESET_SUCCESS_ROUTE = `${ PASSWORD_RESET_ROUTE }/success`;

export const TEAM_BASE_ROUTE = 'team';
export const TEAM_CONFIGURATION_ROUTE = 'configuration';
export const TEAM_TABLES_ROUTE = 'tables';
export const TEAM_TABLE_ROUTE = 'table';
export const TEMPLATE_EDITOR_ROUTE = 'template-editor';
export const E_MENU_ROUTE = 'emenu';
export const E_MENU_CONFIGURATION_ROUTE = 'configuration';
export const getTeamConfigurationRoute = (teamId?: string) => `${ TEAM_CONFIGURATION_ROUTE }?teamId=${ teamId }`;
export const getTeamTableConfigurationRoute = (teamId?: string, tableId?: string) => `${ TEAM_TABLE_ROUTE }?teamId=${ teamId }&tableId=${ tableId }`;
export const getTeamTablesConfigurationRoute = (teamId?: string) =>
    `/${ TEAM_BASE_ROUTE }/${ TEAM_CONFIGURATION_ROUTE }/${ TEAM_TABLES_ROUTE }?teamId=${ teamId }`;
export const getEMenuRoute = (teamId?: string) =>
    `/${ TEAM_BASE_ROUTE }/${ TEAM_CONFIGURATION_ROUTE }/${ E_MENU_ROUTE }?teamId=${ teamId }`;

export const getEMenuConfigurationRoute = (teamId?: string) =>
    `/${ TEAM_BASE_ROUTE }/${ TEAM_CONFIGURATION_ROUTE }/${ E_MENU_ROUTE }/${ E_MENU_CONFIGURATION_ROUTE }?teamId=${ teamId }`;

export const getTemplateEditorRoute = (teamId?: string) => `${ TEMPLATE_EDITOR_ROUTE }?teamId=${ teamId }`;

export const CONTACT_US = 'contactus';
