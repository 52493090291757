/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { GREEN_DARK, RED_BASIC, YELLOW_BASIC } from '../../common-styles/color-palette';

export const ratingTextMap: any = {
    1: 'Terrible',
    2: 'Poor',
    3: 'Average',
    4: 'Good',
    5: 'Excellent',
};
export const RatingTextLabel = ({ value }: { value: any }) => {
    let color = YELLOW_BASIC;
    if(value > 3) {
        color = GREEN_DARK;
    } else if(value < 3) {
        color = RED_BASIC;
    }

    return <div css={ css({ display: 'flex', alignItems: 'center', color: color }) }>
        { ratingTextMap[value] }
    </div>;
};
