/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {jsx} from '@emotion/react';
import {Close} from "../../../../../icons/Close";
import {SimpleIconButton} from "../../../../common/buttons/simple-icon-button";
import {file_uploader__preview, file_uploader__preview__close_icon, file_uploader__preview__close_icon__container, file_uploader__preview__container} from "./file-uploader-preview.styles";

interface FileUploaderPreviewProps {
    value?: string;
    onRemoveUpload: () => void;
}

export const FileUploaderPreview = ({value, onRemoveUpload}: FileUploaderPreviewProps) => {
    return (
        <div css={file_uploader__preview__container}>
            <img css={file_uploader__preview} src={value} alt="" />
            <div css={file_uploader__preview__close_icon__container}>
                <SimpleIconButton css={file_uploader__preview__close_icon} onClick={onRemoveUpload}>
                    <Close/>
                </SimpleIconButton>
            </div>
        </div>
    )
}