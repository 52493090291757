/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import omit from 'lodash-es/omit';
import { useNavigate } from 'react-router-dom';
import { UpdateTeamRequest } from '../../../models/api/api';
import { createTeamRequestHandler } from '../../../services/request-handlers/team/create-team-request-handler';
import { updateTeamRequestHandler } from '../../../services/request-handlers/team/update-team-request-handler';
import { getEMenuRoute, getTeamTablesConfigurationRoute, TEAM_BASE_ROUTE } from '../../../routing/routes';
import {
    changeGoogleMapsLink,
    changeInstagramLink,
    changeTripadvisorLink,
    setTeamBackgroundPhoto,
    setTeamDataEdited,
    setTeamDescription,
    setTeamLocation,
    setTeamName,
} from '../../../store/features/team-config-slice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { HelperMessageType } from '../../../models/helper-message';
import { PrimaryButton } from '../../common/buttons/primary-button';
import { SecondaryButton } from '../../common/buttons/secondary-button';
import { FileUploader } from './inputs/file-uploader/file-uploader';
import { TeamTextInput } from './inputs/team-text-input';
import {
    team_configuration_form,
    team_configuration_form__item_button,
    team_configuration_form__item_group,
} from './team-configuration-form.styles';
import { NEW_ID } from '../../../models/common-constants';
import { clearEMenuState } from '../../../store/features/e-menu-slice';

export const TeamConfigurationForm = () => {
    const navigate = useNavigate();
    const {
        id,
        name,
        description,
        location,
        backgroundPhoto,
        isDataLoading,
        isDataEdited,
        googleMapsLink,
        instagramLink,
        tripadvisorLink,
    } = useAppSelector((state) => state.teamConfig);
    const dispatch = useAppDispatch();
    const isNewTeam = !id || id === NEW_ID;

    // isFeatureEnabled('MENU').then(x => {
    //
    // });

    const onBackgroundPhotoChange = (value: string | undefined) => {
        dispatch(setTeamBackgroundPhoto(value));
    };
    const onTeamNameChange = (value: string) => {
        dispatch(setTeamName(value));
    };
    const onTeamDescriptionChange = (value: string) => {
        dispatch(setTeamDescription(value));
    };
    const onTeamLocationChange = (value: string) => {
        dispatch(setTeamLocation(value));
    };
    const onGoogleMapsLinkChange = (value: string) => {
        dispatch(changeGoogleMapsLink(value));
    };
    const onInstagramLinkChange = (value: string) => {
        dispatch(changeInstagramLink(value));
    };
    const onTripadvisorLinkChange = (value: string) => {
        dispatch(changeTripadvisorLink(value));
    };
    const onTablesListClick = () => {
        dispatch(setTeamDataEdited(false));
        navigate(`${getTeamTablesConfigurationRoute(id)}`);
    };
    const onEMenuClick = () => {
        dispatch(setTeamDataEdited(false));
        navigate(`${getEMenuRoute(id)}`);
    };
    const onCancelClick = () => {
        dispatch(setTeamDataEdited(false));
        dispatch(clearEMenuState());
        navigate(`/${ TEAM_BASE_ROUTE }`);
    };

    const onSaveClick = () => {
        const request: UpdateTeamRequest = {
            teamId: id,
            name: name,
            description: description || null,
            address: location || null,
            backgroundImage: backgroundPhoto || null,
            googleMapsLink: googleMapsLink || null,
            tripadvisorLink: tripadvisorLink || null,
            instagramLink: instagramLink || null,
        };
        if(isNewTeam) {
            createTeamRequestHandler(omit(request, 'teamId'), dispatch, navigate);
        } else {
            updateTeamRequestHandler(request, dispatch, navigate);
        }
    };

    return (
        <div css={ team_configuration_form }>
            <div css={ css({
                height: '8em', marginBottom: '1.5em',
            }) }>
                <FileUploader
                    onChange={ onBackgroundPhotoChange }
                    value={ backgroundPhoto }
                    emptyStateTitle={ "Background photo" }
                    emptyStateDescription={ "IMG, PNG, JPEG, JPG, 500kb max." }
                    isLoading={ isDataLoading }
                />
            </div>
            <TeamTextInput
                label={ "Team name" }
                value={ name }
                onChange={ onTeamNameChange }
                required={ true }
                autoComplete="organization"
                helperMessage={ {
                    type: HelperMessageType.NONE,
                    text: "The name will be visible in public over feedback widget",
                } }
                isLoading={ isDataLoading }
            />
            <TeamTextInput
                label={ "Description" }
                value={ description }
                onChange={ onTeamDescriptionChange }
                autoComplete="organization-title"
                helperMessage={ {
                    type: HelperMessageType.NONE,
                    text: "Use description for your internal name for the team",
                } }
                isLoading={ isDataLoading }
            />
            <TeamTextInput
                label={ "Location" }
                value={ location }
                onChange={ onTeamLocationChange }
                autoComplete="street-address"
                helperMessage={ {
                    type: HelperMessageType.NONE,
                    text: "Location will be visible in the feedback widget",
                } }
                isLoading={ isDataLoading }
            />

            <TeamTextInput
                label={ "Google Maps link" }
                value={ googleMapsLink }
                onChange={ onGoogleMapsLinkChange }
                isLoading={ isDataLoading }
                type="url"
            />
            <TeamTextInput
                label={ "Instagram link" }
                value={ instagramLink }
                onChange={ onInstagramLinkChange }
                isLoading={ isDataLoading }
                type="url"
            />
            <TeamTextInput
                label={ "Tripadvisor link" }
                value={ tripadvisorLink }
                onChange={ onTripadvisorLinkChange }
                helperMessage={ {
                    type: HelperMessageType.NONE,
                    text: "These links will help the customer share their feedback",
                } }
                isLoading={ isDataLoading }
                type="url"
            />

            <div css={ team_configuration_form__item_group }>
                <div css={ team_configuration_form__item_button }>
                    <PrimaryButton
                        label={ isNewTeam ? "Create team" : "Save changes" }
                        disabled={ !name || isDataLoading || !isDataEdited }
                        onClick={ onSaveClick }
                    />
                </div>
                <div css={ team_configuration_form__item_button }>
                    <SecondaryButton
                        label="Tables list"
                        disabled={ isDataLoading }
                        onClick={ onTablesListClick }
                    />
                </div>
                <div css={ team_configuration_form__item_button }>
                    <SecondaryButton
                        label="E-Menu"
                        disabled={ isDataLoading }
                        onClick={ onEMenuClick }
                    />
                </div>
                <div css={ team_configuration_form__item_button }>
                    <SecondaryButton
                        label="Cancel and go to list"
                        disabled={ isDataLoading }
                        onClick={ onCancelClick }
                    />
                </div>
            </div>
        </div>
    );
};
