import { css, SerializedStyles } from "@emotion/react";
import { mobileDevices } from '../../common-styles/adaptive-breakpoints';

export const dashboard__happiness_score_charts_area = css({
    height: '42%',
    display: 'flex',
    gap: '1em',

    [mobileDevices]: {
        flexDirection: 'column',
        gap: '0.5em',
        height: 'unset',
    },
});

export const dashboard__container: SerializedStyles = css({
    [mobileDevices]: {
        gap: '0.5em',
    },
});
