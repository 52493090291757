export const DashboardHover = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <g>
                <path fill="currentColor"
                      d="M21 20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9.314a1 1 0 0 1 .38-.785l8-6.311a1 1 0 0 1 1.24 0l8 6.31a1 1 0 0 1 .38.786V20ZM7 12a5 5 0 1 0 10 0h-2a3 3 0 0 1-6 0H7Z"/>
            </g>

        </svg>
    );
};