/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import React from 'react';
import { skeleton__preloader } from '../../common-styles/skeleton';
import { BORDER_RADIUS_EXTRA_SMALL } from '../../common-styles/borders';
import Stack from '@mui/material/Stack';
import { AntSwitch } from './ant-switch';
import { FONT_SIZE_M } from '../../common-styles/font-sizes';

interface SwitchProps {
    checked: boolean;
    label: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;

    isLoading?: boolean;
}

export const Switch = ({ checked, onChange, isLoading, label }: SwitchProps) => {
    if(isLoading) {
        return (
            <div css={ css({ display: 'flex' }) }>
                <div css={ [skeleton__preloader, css({
                    width: '2em',
                    height: '1em',
                    borderRadius: BORDER_RADIUS_EXTRA_SMALL,
                    marginRight: '1em',
                })] }/>
                <div css={ [skeleton__preloader, css({
                    width: '100%',
                    height: '1em',
                    borderRadius: BORDER_RADIUS_EXTRA_SMALL,
                })] }/>
            </div>
        );
    }

    return (
        <Stack
            direction="row"
            spacing={ 1.25 }
            alignItems="center"
        >
            <AntSwitch
                checked={ checked }
                inputProps={ { 'aria-label': 'ant design' } }
                onChange={ onChange }
            />
            <span css={ css({ fontSize: FONT_SIZE_M }) }>{ label }</span>
        </Stack>
    );
};