import { put, select, takeLatest } from 'redux-saga/effects';
import {
    changeCompanyName,
    changeTeamName,
    finishSignup,
    toggleHighlightDashboard,
    toggleHighlightQrCode,
} from '../store/features/signup-slice';

function* setDefaultFirstTeamName() {
    const { newAccount: { companyName } } = yield select(state => state.signup);
    yield put(changeTeamName(`${ companyName } Team`));
}

function* highlightNecessaryInformation() {
    yield put(toggleHighlightQrCode(true));
    yield put(toggleHighlightDashboard(true));
}

function* signupSaga() {
    yield takeLatest(changeCompanyName, setDefaultFirstTeamName);
    yield takeLatest(finishSignup, highlightNecessaryInformation);
}

export default signupSaga;
