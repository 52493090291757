/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {jsx} from '@emotion/react';
import Team from "../../../static/team.svg";
import React from "react";
import {AccountFormContainer} from "../common/account-form-container";
import {WizardTeamForm} from "./wizard-team-form";

export const WizardTeamStep = () => {
    return (
        <AccountFormContainer form={<WizardTeamForm/>} wizardStepImage={Team}/>
    );
};
