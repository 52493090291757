import {AUTHORIZATION_LS_KEY, SESSION_ID_LS_KEY, SIGNUP_STEP_LS_KEY} from "../../../models/localstorage-keys";
import {setAuthorised} from "../../../store/features/authorisation-slice";
import {
    changeNewAccountLoadingIndicator,
    newAccountRequestFailed,
    setWorkspaceId
} from "../../../store/features/signup-slice";
import {WIZARD_TEAM_CREATION_ROUTE} from "../../../routing/routes";
import {signup} from "../../request-services/signup";
import {SignupRequest} from "../../../models/api/api";
import {AppDispatch} from "../../../store/store";
import {NavigateFunction} from "react-router-dom";
import {requestHandlerWrapper} from "../../../utils/request-handler-wrapper";
import {GENERIC_ERROR_MESSAGE} from "../../../models/common-constants";
import {ErrorCode, MagictapResponse} from "../../../models/magictap-response";
import {SignupStep} from "../../../models/signup-step";

export const createAccountFailure = (response: MagictapResponse<unknown>, dispatch: AppDispatch) => {
    if (response.errorCode === ErrorCode.INVALID_REQUEST_PARAMETER) {
        dispatch(newAccountRequestFailed('Your signup process expired. Please, try to start it again'));
    } else {
        dispatch(newAccountRequestFailed(GENERIC_ERROR_MESSAGE));
    }
    dispatch(changeNewAccountLoadingIndicator(false));
}

const createAccountSuccess = (response: MagictapResponse<string>, dispatch: AppDispatch, navigate: NavigateFunction) => {
    if (!localStorage.getItem(AUTHORIZATION_LS_KEY)) {
        dispatch(newAccountRequestFailed('Authorisation failed. Please, contact support.'));
        dispatch(changeNewAccountLoadingIndicator(false));
        return;
    }
    console.log('Account has been created!');
    dispatch(setAuthorised(true));
    dispatch(setWorkspaceId(response.content!!));
    localStorage.removeItem(SESSION_ID_LS_KEY);
    localStorage.setItem(SIGNUP_STEP_LS_KEY, SignupStep.TEAM);
    changeNewAccountLoadingIndicator(false);
    navigate(`/${WIZARD_TEAM_CREATION_ROUTE}`);
};

export const createAccountRequestHandler = (request: SignupRequest, dispatch: AppDispatch, navigate: NavigateFunction) => {
    requestHandlerWrapper(
        signup,
        request,
        createAccountSuccess,
        createAccountFailure,
        dispatch,
        navigate,
    )
};
