/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {css, jsx} from '@emotion/react';
import {PrimaryButton} from "./primary-button";
import {container__full_height, flex_container__horizontal__full_width} from "../../../common-styles/containers";
import {Preloader} from "../preloader";

interface SignUpSubmitButtonProps {
    label: string;
    onClick: (e?: any) => void;
    requestLoadingIndicator: any;

    disabled?: boolean;
}

export const SubmitButton = ({disabled, onClick, requestLoadingIndicator, label}: SignUpSubmitButtonProps) => {
    return (
        <div css={css({height: '3em'})}>
            {requestLoadingIndicator && (
                <div css={[flex_container__horizontal__full_width, container__full_height]}>
                    <Preloader/>
                </div>
            )}
            {!requestLoadingIndicator && (
                <PrimaryButton
                    label={label}
                    disabled={disabled}
                    onClick={onClick}
                    type="submit"
                />
            )}
        </div>
    );
};
