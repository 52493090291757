import styled from "@emotion/styled";
import {IconButton as IconButtonMui} from "@mui/material";
import {DARK_BASIC, GREY_DARK} from "../../../common-styles/color-palette";

export const SimpleIconButton = styled(IconButtonMui)({
    color: GREY_DARK,
    ':hover' : {
        backgroundColor: 'unset',
        color: DARK_BASIC
    }
});
