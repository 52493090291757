import { css } from "@emotion/react";
import { DARK_BASIC, GREY_BASIC, GREY_MEDIUM } from "../../../common-styles/color-palette";
import { BORDER_RADIUS_SMALL } from "../../../common-styles/borders";

export const secondary_button = (hoverBackgroundColor: string) => css({
    backgroundColor: GREY_BASIC,
    textTransform: 'none',
    height: '3.5em',
    boxShadow: 'none',
    width: '100%',
    color: DARK_BASIC,
    borderRadius: BORDER_RADIUS_SMALL,
    ':hover': {
        backgroundColor: hoverBackgroundColor,
        boxShadow: 'none',
    },

    ':disabled': {
        backgroundColor: GREY_BASIC,
        color: GREY_MEDIUM,
    },
});
