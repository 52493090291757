/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {css, jsx} from '@emotion/react';
import {useAppSelector} from "../../store/hooks";
import React, {Fragment} from "react";
import {AppTopBarMobile} from "./app-top-bar-mobile";
import {Box} from "@mui/material";
import {DARK_BASIC} from "../../common-styles/color-palette";
import {Navbar} from "./navbar";
import {sideNavigationWidthExpanded, sideNavigationWidthShrunk} from "./navbar.styles";

export const NavigationContainer = ({showSideNavbar}: { showSideNavbar: boolean }) => {
    const {userAuthorised} = useAppSelector(state => state.authorisation);
    const {desktopNavbarOpened} = useAppSelector(state => state.navigationBar);

    const showNavigation = showSideNavbar && userAuthorised;

    return (
        <Fragment>
            {showNavigation && (
                <Fragment>
                    <AppTopBarMobile/>
                    <Box
                        component="nav"
                        css={css({
                            backgroundColor: DARK_BASIC,
                        })}
                        sx={{
                            height: "100%",
                            width: {
                                sm: desktopNavbarOpened ? sideNavigationWidthExpanded : sideNavigationWidthShrunk,
                                xs: '0',
                            },
                        }}
                    >
                        <Navbar/>
                    </Box>
                </Fragment>
            )}
        </Fragment>
    );
};
