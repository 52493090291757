/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {jsx} from '@emotion/react';
import {flex_container__horizontal__full_width} from "../../../common-styles/containers";
import {signup__terms_of_usage_disclaimer} from "../sign-up.styles";
import React from "react";

export const TermsAndConditions = () => {
    return (
        <div css={[flex_container__horizontal__full_width, signup__terms_of_usage_disclaimer]}>
            <p>By creating an account, you agree to our <a href="/">Terms Of Service</a> and <a href="/">Privacy
                Policy</a></p>
        </div>
    );
};
