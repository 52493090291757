/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { useNavigate } from "react-router-dom";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Fragment, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { page, page__container, page__container__header_row } from "../../../common-styles/pages";
import { PageHeader } from "../../common/headers/page-header";
import { Select } from "../../common/dropdowns/select";
import { MenuItem } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import {
    detailed_feedbacks__header,
    detailed_feedbacks__inner_container,
    detailed_feedbacks__page_container,
} from './detailed-feedbacks.style';
import { DetailedFeedbacksTable } from './detailed-feedbacks-table';
import { FilterPreset, ratingFilter } from '../../../models/filters';
import { changeRatingFilter } from '../../../store/features/detailed-feedbacks-slice';
import { mapRatingsFilter } from '../../../utils/filter-mappers';
import { DateRangePicker } from '../../common/date-picker/date-range-picker';
import {
    loadWorkspaceConfigurationRequestHandler,
} from '../../../services/request-handlers/workspace-configuration-request-handler';
import { DARK_BASIC, GREY_BASIC, GREY_DARK, GREY_LIGHT } from '../../../common-styles/color-palette';
import { BORDER_RADIUS_SMALL } from '../../../common-styles/borders';
import { FONT_SIZE_M } from '../../../common-styles/font-sizes';
import { TeamSelector } from '../../common/team-selector';
import { mobileDevices } from '../../../common-styles/adaptive-breakpoints';
import { CategoriesFilter } from './categories-filter';
import { selectNavbar } from '../../../store/selectors/navbar-selectors';
import { NavigationBarState } from '../../../store/features/navigation-bar-slice';
import { DetailedFeedbacksCards } from './detailed-feedbacks-cards';
import { DetailedFeedbacksSortSelector } from './detailed-feedbacks-sort-selector';
import { BackButton } from '../../../icons/BackButton';
import { SimpleIconButton } from '../../common/buttons/simple-icon-button';

export const category__chips = (isSelected: boolean) => css({
    borderRadius: BORDER_RADIUS_SMALL,
    minWidth: '7em',
    boxSizing: 'border-box',
    color: isSelected ? DARK_BASIC : GREY_DARK,
    fontSize: FONT_SIZE_M,
    height: '3em',
    backgroundColor: isSelected ? GREY_BASIC : 'unset',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.25em 1em',
    textWrap: 'nowrap',
    border: `${ isSelected ? GREY_LIGHT : GREY_BASIC } 1px solid`,
    flexShrink: 0,

    [mobileDevices]: {
        height: '100%',
    },
});

export const DetailedFeedbacks = () => {
    const { reviews, ratingsFilter } = useAppSelector(state => state.detailedFeedbacks);
    const { isMobile }: NavigationBarState = useAppSelector(selectNavbar);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        loadWorkspaceConfigurationRequestHandler(dispatch, navigate);
    }, []);

    const onRatingSelect = (e: SelectChangeEvent<unknown>) => {
        dispatch(changeRatingFilter(e.target.value as FilterPreset<number>));
    };

    const onBackClick = () => {
        navigate(-1);
    };

    return (
        <div css={ [page__container] }>
            <div css={ css({ display: 'flex' }) }>
                <SimpleIconButton css={ css({ padding: '0 0 0 0.5em' }) } onClick={ onBackClick }>
                    <BackButton/>
                </SimpleIconButton>
                <PageHeader isLoading={ false } title={ "Reviews" }/>
            </div>
            <div css={ [page, detailed_feedbacks__page_container] }>
                <div css={ detailed_feedbacks__inner_container }>
                    <div css={ [page__container__header_row, detailed_feedbacks__header] }>
                        <Fragment>
                            <h2 css={ css({ textAlign: 'left' }) }>
                                Here all your { reviews.length } feedback{ reviews.length > 1 ? 's' : '' }
                            </h2>
                            <div css={ css({
                                display: 'flex',
                                gap: '0.5em',
                                height: '3em',

                                [mobileDevices]: { height: '2.5em', width: '100%' },
                            }) }>
                                <div css={ css({
                                    flex: 1,
                                    'button': { backgroundColor: GREY_LIGHT },
                                }) }>
                                    <DateRangePicker/>
                                </div>
                                <div css={ css({ flex: 1 }) }><TeamSelector/></div>
                                <Select
                                    value={ ratingsFilter }
                                    onChange={ onRatingSelect }
                                    css={ css({ flex: 1 }) }
                                >
                                    {
                                        ratingFilter
                                            .map(mapRatingsFilter)
                                            .map((x, i) =>
                                                <MenuItem key={ i } value={ x.value }>{ x.name }</MenuItem>)
                                    }
                                </Select>
                            </div>
                        </Fragment>
                    </div>
                    { isMobile && <DetailedFeedbacksSortSelector/> }
                    <CategoriesFilter/>
                    { isMobile && <DetailedFeedbacksCards/> }
                    { !isMobile && <DetailedFeedbacksTable rowData={ reviews }/> }
                </div>
            </div>
        </div>
    );
};
