import { css, SerializedStyles } from "@emotion/react";
import { GREY_BASIC, WHITE } from '../../common-styles/color-palette';
import { BORDER_RADIUS_SMALL } from '../../common-styles/borders';
import { DROP_SHADOW_BLACK } from '../../common-styles/shadows';
import { FOOTER_HEIGHT } from './configuration-footer';

export const widget_configuration__container = css({
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    boxSizing: 'border-box',
    position: 'relative',
});

export const widget_configuration: SerializedStyles = css({
    display: 'flex',
    '@media (max-width: 1000px)': {
        flexDirection: 'column',
    },
    boxSizing: 'border-box',
    backgroundColor: WHITE,
    borderRadius: BORDER_RADIUS_SMALL,
    boxShadow: DROP_SHADOW_BLACK,
    flex: ' 1 1 auto'
});

export const widget_configuration__form_container: SerializedStyles = css({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '1em',
});

export const widget_configuration__form_scrollable_container: SerializedStyles = css({
    width: '100%',
    overflowY: 'auto',
    height: `calc(100% - ${ FOOTER_HEIGHT } - 3em)`,
    display: 'flex',
    justifyContent: 'center',
});

export const configuration__footer: SerializedStyles = css({
    position: 'absolute',
    bottom: '2em',
    borderTop: `1px ${ GREY_BASIC } solid`,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1em 0 0',
    height: FOOTER_HEIGHT,
    '& .MuiButton-root': { height: '100%' },
});
