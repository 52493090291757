import { css, SerializedStyles } from '@emotion/react';
import { FONT_SIZE_MOBILE_XXXXS } from '../../../common-styles/font-sizes';
import { GREY_DARK } from '../../../common-styles/color-palette';

export const team_card__time: SerializedStyles = css({
    fontSize: FONT_SIZE_MOBILE_XXXXS,
    color: GREY_DARK,
    letterSpacing: '0.24em',
});

export const team_card__container: SerializedStyles = css({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
});
