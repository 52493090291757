/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import {
    DetailedFeedbacksSortOption,
    DetailedFeedbacksSortOptionsStringRepresentation,
    sortDetailedFeedbacksListOptions,
} from '../../../models/sort-options';
import { Select } from '../../common/dropdowns/select';
import { MenuItem } from '@mui/material';
import { changeDetailedFeedbacksSortOrder } from '../../../store/features/detailed-feedbacks-slice';
import { selectDetailedFeedbacks } from '../../../store/selectors/detailed-feedbacks.selector';

export const DetailedFeedbacksSortSelector = () => {
    const { sortBy } = useAppSelector(selectDetailedFeedbacks);
    const dispatch = useAppDispatch();

    const onSortOrderChange = (e: SelectChangeEvent<unknown>) => {
        dispatch(changeDetailedFeedbacksSortOrder(e.target.value as DetailedFeedbacksSortOption));
    };

    return (
        <div css={ css({ height: '2.5em', width: '100%' }) }>
            <Select
                value={ sortBy }
                onChange={ onSortOrderChange }
            >
                { sortDetailedFeedbacksListOptions.map((x, i) => (
                    <MenuItem key={ i } value={ x }>
                        { DetailedFeedbacksSortOptionsStringRepresentation.get(x) }
                    </MenuItem>
                )) }
            </Select>
        </div>
    );
};
