/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { ICellRendererParams } from "ag-grid-community";
import { AgGridColumnProps } from "ag-grid-react";
import dayjs from "dayjs";
import { FONT_SIZE_M } from "../../../common-styles/font-sizes";
import { RatingIconChips } from '../../common/rating-icon-chips';
import { RatingTextLabel } from '../../common/rating-text-label';
import { TableCategoryChips } from '../../common/table-category-chips';

const ratingCellRenderer = ({ value }: ICellRendererParams) => {
    return (
        <RatingIconChips rating={ value }/>
    );
};

const ratingTextRenderer = ({ value }: ICellRendererParams) => {
    return (
        <RatingTextLabel value={ value }/>
    );
};

const categoriesCellRenderer = (x: ICellRendererParams) => {
    return (
        <TableCategoryChips categories={ x.value }/>
    );
};

const recipientCellRenderer = (x: ICellRendererParams) => {
    return (
        <p>
            { x.value.name }
        </p>
    );
};

const detailed_feedbacks__cell = {
    fontSize: FONT_SIZE_M,
    display: 'flex',
    alignItems: 'center',
};

const cell = {
    paddingLeft: '1em',
    boxSizing: 'border-box',
};

const comments = {
    padding: '0.5em 0 0.5em 0.5em',
    boxSizing: 'border-box',
};

export const detailedReviewsColumnDefinitions: AgGridColumnProps[] = [
    {
        field: 'rating',
        sortable: true,
        flex: 1,
        headerName: '',
        cellRenderer: ratingCellRenderer,
        cellStyle: detailed_feedbacks__cell,
    },
    {
        field: 'rating',
        sortable: true,
        flex: 2,
        filter: 'agNumberColumnFilter',
        cellRenderer: ratingTextRenderer,
        cellStyle: { ...detailed_feedbacks__cell, ...cell },
    },
    {
        field: 'createdAt',
        sortable: true,
        initialSort: 'desc',
        flex: 4,
        filter: 'date',
        wrapText: true,
        autoHeight: true,
        cellRenderer: (x: ICellRendererParams) => dayjs(x.value).format('HH:mm • DD MMM.'),
        cellStyle: { ...detailed_feedbacks__cell, ...cell },
    },
    {
        field: 'comment',
        flex: 8,
        filter: 'agTextColumnFilter',
        sortable: false,
        wrapText: true,
        autoHeight: true,
        cellStyle: { ...detailed_feedbacks__cell, ...cell, ...comments },
    },
    {
        field: 'selectedCategories',
        flex: 8,
        filter: 'agTextColumnFilter',
        sortable: false,
        wrapText: true,
        autoHeight: true,
        cellRenderer: categoriesCellRenderer,
        cellStyle: { ...detailed_feedbacks__cell, ...cell },
    },
    {
        field: 'recipient',
        flex: 4,
        filter: 'agTextColumnFilter',
        sortable: false,
        wrapText: true,
        autoHeight: true,
        cellRenderer: recipientCellRenderer,
        cellStyle: { ...detailed_feedbacks__cell, ...cell },
    },
];
