import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";
import {AccountDataResponse} from "../../models/api/api";
import {removeNonDigits} from "../../utils/phone-number-utils";
import {PhoneNumberError} from "../../models/phone-number-error";
import {Country} from "../../utils/country-utils";

interface AccountConfig {
    firstName: string,
    lastName: string,
    email: string,
    companyName: string,
    country: Country,
    phoneNumber?: string,
    timezone?: string,

    isLoading: boolean,
    isEdited: boolean,
    phoneNumberError?: PhoneNumberError
}

const initialState: AccountConfig = {
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    phoneNumber: '',
    timezone: '',
    country: Country.USA,
    isLoading: true,
    isEdited: false,
}

export const accountConfigSlice = createSlice({
    name: 'accountConfigSlice',
    initialState,
    reducers: {
        setFirstName: (state: Draft<AccountConfig>, action: PayloadAction<string>) => {
            state.firstName = action.payload
            state.isEdited = true
        },
        setLastName: (state: Draft<AccountConfig>, action: PayloadAction<string>) => {
            state.lastName = action.payload
            state.isEdited = true
        },
        setCompanyName: (state: Draft<AccountConfig>, action: PayloadAction<string>) => {
            state.companyName = action.payload
            state.isEdited = true
        },
        setPhoneNumber: (state: Draft<AccountConfig>, action: PayloadAction<string | undefined>) => {
            if (!action.payload) {
                state.phoneNumber = undefined
                state.phoneNumberError = undefined
                state.isEdited = true
                return
            }
            const sanitizedPhoneNumber = "+" + removeNonDigits(action.payload)
            /* https://www.quora.com/What-is-maximum-and-minimum-length-of-any-mobile-number-across-the-world
            * TL;DR: 
            - min digits count in phone number across the world - 7
            - max digits count - 15
            */
            if (sanitizedPhoneNumber.length < 8) {
                state.phoneNumberError = PhoneNumberError.PHONE_NUMBER_IS_TOO_SHORT
            } else if (sanitizedPhoneNumber.length > 16) {
                state.phoneNumberError = PhoneNumberError.PHONE_NUMBER_IS_TOO_LONG
            } else {
                state.phoneNumberError = undefined
            }
            state.phoneNumber = sanitizedPhoneNumber
            state.isEdited = true
        },
        setTimezone: (state: Draft<AccountConfig>, action: PayloadAction<string>) => {
            state.timezone = action.payload
            state.isEdited = true
        },
        setAccountConfig: (state: Draft<AccountConfig>, action: PayloadAction<AccountDataResponse>) => {
            state.firstName = action.payload.firstName
            state.lastName = action.payload.lastName
            state.email = action.payload.email
            state.companyName = action.payload.companyName
            state.phoneNumber = action.payload.phoneNumber
            state.timezone = action.payload.timeZone
            state.country = action.payload.country
        },
        setAccountConfigLoading: (state: Draft<AccountConfig>, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        setAccountConfigEdited: (state: Draft<AccountConfig>, action: PayloadAction<boolean>) => {
            state.isEdited = action.payload
        },
    }
});

export const {
    setFirstName,
    setLastName,
    setCompanyName,
    setPhoneNumber,
    setTimezone,
    setAccountConfig,
    setAccountConfigLoading,
    setAccountConfigEdited,
} = accountConfigSlice.actions;

export default accountConfigSlice.reducer;
