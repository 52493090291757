/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { RowClassParams, RowClickedEvent } from 'ag-grid-community';
import { REGULAR_FONT } from '../../../common-styles/font-styles';
import { GREY_BASIC, WHITE } from '../../../common-styles/color-palette';
import { setTeamDataLoader } from '../../../store/features/team-config-slice';
import { getTeamConfigurationRoute } from '../../../routing/routes';
import { AgGridCanvasStyle, AgGridContainerStyle } from '../../../common-styles/grid-style';
import { container__full_width } from '../../../common-styles/containers';
import { AgGridReact } from 'ag-grid-react';
import { teamsColumnDefinitions } from '../column-definitions';

export const TeamListTable = () => {
    const { allTeams } = useAppSelector(state => state.teamList);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const getRowStyle = (row: RowClassParams) => {
        const isLastRow = row.rowIndex === (allTeams.length - 1);
        return {
            fontFamily: REGULAR_FONT,
            backgroundColor: WHITE,
            borderBottom: isLastRow ? 'none' : `1px solid ${ GREY_BASIC }`,
        };
    };
    const onRowClicked = (row: RowClickedEvent) => {
        dispatch(setTeamDataLoader(true));
        navigate(getTeamConfigurationRoute(row.data.id));
    };

    return (
        <div
            css={ [AgGridContainerStyle, container__full_width] }
            // TODO: replace with material theme
            className="ag-theme-alpine"
        >
            <AgGridReact
                css={ AgGridCanvasStyle }
                getRowStyle={ getRowStyle }
                rowData={ allTeams }
                suppressCellFocus={ true }
                onRowClicked={ onRowClicked }
                animateRows={ true }
                columnDefs={ teamsColumnDefinitions }
            />
        </div>
    );
};
