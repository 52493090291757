/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectWorkspaceConfig } from '../../store/selectors/workspace-config-selectors';
import { Fragment } from 'react';
import {
    changeMinimalFeedbackScoreForPromoteToSocial,
    changeThankYouPageLogo,
    togglePromoteToSocial,
} from '../../store/features/workspace-config-slice';
import { widget_configuration__form_scrollable_container } from './widget-configuration.style';
import { SectionHeader } from '../common/headers/section-header';
import { FormGroup } from '@mui/material';
import { FileUploader } from '../team/configuration/inputs/file-uploader/file-uploader';
import { Switch } from '../common/switch';
import { FormHint } from '../common/form-hint';
import { RadioGroup } from '../common/buttons/radio-group';
import { WidgetConfigurationSaveButton } from './widget-configuration-save-button';

export const ThankYouPageConfiguration = () => {
    const {
        isDataLoading,
        minimalFeedbackScoreForPromoteToSocial,
        thankYouPageLogo,
        isPromoteToSocialEnabled,
    } = useAppSelector(selectWorkspaceConfig);

    const dispatch = useAppDispatch();

    const enablePromoteToSocialHandler = () => {
        dispatch(togglePromoteToSocial(!isPromoteToSocialEnabled));
    };
    const onLogoChangeHandler = (base64?: string) => {
        dispatch(changeThankYouPageLogo(base64));
    };

    const onMinimalFeedbackScoreForPromoteToSocial = (minimalFeedbackScore: number) => {
        dispatch(changeMinimalFeedbackScoreForPromoteToSocial(minimalFeedbackScore));
    };

    return (
        <Fragment>
            <div css={ widget_configuration__form_scrollable_container }>
                <div css={ css({ width: '80%' }) }>
                    <SectionHeader isLoading={ isDataLoading } title="Content"/>
                    <FormGroup
                        css={ {
                            paddingTop: '0.75em',
                            paddingBottom: '1.5em',
                            minHeight: '18em',
                            display: 'flex',
                            justifyContent: 'space-between',
                        } }
                    >
                        <div>
                            <div css={ css({ height: '5em', marginBottom: '0.5em' }) }>
                                <FileUploader
                                    value={ thankYouPageLogo || '' }
                                    onChange={ onLogoChangeHandler }
                                    emptyStateTitle={ 'Promo banner' }
                                    emptyStateDescription={ 'IMG, PNG, JPEG, JPG, 5MB MAX.' }
                                    isLoading={ isDataLoading }
                                />
                            </div>
                            { !isDataLoading &&
                                <FormHint label="If not present, a standard illustration will be used"/>
                            }
                            <div css={ css({ padding: '0.75em 0' }) }>
                                <Switch
                                    checked={ isPromoteToSocialEnabled }
                                    onChange={ enablePromoteToSocialHandler }
                                    isLoading={ isDataLoading }
                                    label="Opportunity to share feedback"
                                />
                            </div>
                            <div css={ css({ paddingBottom: '0.75em' }) }>
                                <FormHint label="You can set up which feedback to allow the client to distribute"/>
                            </div>
                            { isPromoteToSocialEnabled && (
                                <div css={ css({ paddingBottom: '0.75em' }) }>
                                    <RadioGroup
                                        isRow
                                        value={ minimalFeedbackScoreForPromoteToSocial || 0 }
                                        options={ [
                                            { label: "Only 5 stars", value: 5, disabled: !isPromoteToSocialEnabled },
                                            { label: "From 4 stars", value: 4, disabled: !isPromoteToSocialEnabled },
                                            { label: "From 3 stars", value: 3, disabled: !isPromoteToSocialEnabled },
                                        ] }
                                        onRadioOptionClick={ onMinimalFeedbackScoreForPromoteToSocial }
                                    />
                                </div>
                            ) }
                        </div>
                        <WidgetConfigurationSaveButton/>
                    </FormGroup>
                </div>
            </div>
        </Fragment>
    );
};