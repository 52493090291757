/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {css, jsx} from '@emotion/react';
import React, {Fragment} from "react";
import {flex_container__horizontal__full_width, flex_container__vertical} from "../../common-styles/containers";
import {signup__welcome_message} from "../sign-up/sign-up.styles";
import {FONT_SIZE_XL} from "../../common-styles/font-sizes";
import Star from "../../static/icons/star.svg"
import {PrimaryButton} from "../common/buttons/primary-button";
import {useNavigate} from "react-router-dom";
import {LOGIN_BASE_ROUTE} from "../../routing/routes";

function StarIcon() {
    return <img css={css({width: '100%', height: '100%'})} src={Star} alt={""}/>;
}

export const PasswordResetSuccessForm = () => {
    const navigate = useNavigate();
    const backToLoginHandler = () => navigate(`/${LOGIN_BASE_ROUTE}`);

    return (
        <Fragment>
            <div css={[flex_container__horizontal__full_width, flex_container__vertical, css({position: 'relative'})]}>
                <h1 css={signup__welcome_message}>
                    Successfully!
                </h1>
                <p css={css({marginBlock: '0.3em', fontSize: FONT_SIZE_XL, textAlign: 'center'})}>
                    We sent a link to reset your password <br/> to your email
                </p>
                <div css={css({width: '1em', position: 'absolute', top: '-1em', left: '1em'})}>
                    <StarIcon/>
                </div>
                <div css={css({
                    width: '2em',
                    position: 'absolute',
                    top: '-1em',
                    left: '3em',
                    transform: 'rotate(-180deg)'
                })}>
                    <StarIcon/>
                </div>
                <div css={css({
                    width: '2em',
                    position: 'absolute',
                    top: '3em',
                    right: '1em',
                    transform: 'rotate(-180deg)',
                    '@media (max-width: 555px)': {
                        top: '-1em',
                    }
                })}>
                    <StarIcon/>
                </div>
                <div css={css({
                    width: '1em',
                    position: 'absolute',
                    top: '2em',
                    right: '-0.5em',
                    '@media (max-width: 555px)': {
                        top: '-2em',
                    }
                })}>
                    <StarIcon/>
                </div>
                <div css={[flex_container__horizontal__full_width, css({margin: "1em 0"})]}>
                    <div css={css({width: "90%", height: '3.5em'})}>
                        <PrimaryButton
                            label={"Back to login"}
                            onClick={backToLoginHandler}
                        />
                    </div>
                </div>
            </div>
            <div>
                <div css={[flex_container__horizontal__full_width]}>
                    <div css={css({width: "90%", height: '1em'})}>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};
