/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { PropsWithChildren } from 'react';
import { template_editor__section, template_editor__section__label, template_editor__section__label_loading } from './template-editor-section.styles';

interface TemplateEditorSectionProps {
    label: string
    isLoading?: boolean
}

export const TemplateEditorSection = ({ isLoading, label, children }: PropsWithChildren<TemplateEditorSectionProps>) => {
    return (
        <div css={ template_editor__section }>
            { isLoading && <div css={ template_editor__section__label_loading }/> }
            { !isLoading && <p css={ template_editor__section__label }>{ label }</p>}
            { children }
        </div>
    )
}
