import { AUTHORIZATION_LS_KEY } from "../../models/localstorage-keys";
import { API_PATH } from "../request-path-constants";
import { CreateTableRequest } from "../../models/api/api";
import { apiHost } from "../api-host-resolver";

export const getAllTables = async (teamId: string) => {
    const authUserToken = localStorage.getItem(AUTHORIZATION_LS_KEY) || '';
    return await fetch(`${apiHost}${API_PATH}/teams/${teamId}/tables`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'auth_user_session': authUserToken,
        }
    })
        .then(resp => resp.json().then(x => x))
        .catch(e => console.log(e));
}

export const createTable = async (createTableRequest: CreateTableRequest) => {
    const authUserToken = localStorage.getItem(AUTHORIZATION_LS_KEY) || '';
    return await fetch(`${apiHost}${API_PATH}/tables`, {
        method: 'POST',
        body: JSON.stringify(createTableRequest),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'auth_user_session': authUserToken,
        }
    })
        .then(resp => resp.json().then(x => x))
        .catch(e => console.log(e));
}

export const getTable = async (tableId: string) => {
    const authUserToken = localStorage.getItem(AUTHORIZATION_LS_KEY) || '';
    return await fetch(`${apiHost}${API_PATH}/tables/${tableId}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'auth_user_session': authUserToken,
        }
    })
        .then(resp => resp.json().then(x => x))
        .catch(e => console.log(e));
}
