import {Draft, PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {Team} from "../../models/team";

export interface WidgetPreviewState {
    recipientTeam?: Team
}

export const widgetPreviewConfigSlice = createSlice({
    name: 'widgetPreview',
    initialState: {},
    reducers: {
        setRecipientTeam: (state: Draft<WidgetPreviewState>, action: PayloadAction<Team | undefined>) => {
            state.recipientTeam = action.payload
        },
    }
});

export const {
    setRecipientTeam
} = widgetPreviewConfigSlice.actions;

export default widgetPreviewConfigSlice.reducer;