/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {css, jsx} from '@emotion/react';
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {useNavigate} from "react-router-dom";
import {ChangeEvent, Fragment} from "react";
import {changeValidationCode, changeValidationLoadingIndicator} from "../../../store/features/signup-slice";
import {verificationRequestHandler} from "../../../services/request-handlers/signup/verification-request-handler";
import {flex_container__horizontal__full_width, flex_container__vertical} from "../../../common-styles/containers";
import {signup__welcome_message} from "../sign-up.styles";
import {TextInput} from "../../common/inputs/text-input";
import {SubmitButton} from "../../common/buttons/submit-button";

export const WizardValidationForm = () => {
    const {
        validationCode,
        requestIsProcessing,
        requestError,
    } = useAppSelector(state => state.signup.emailValidation);
    const {email} = useAppSelector(state => state.signup.registration);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const verificationCodeInputHandler = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
        dispatch(changeValidationCode(e.target.value));

    const onVerificationCodeConfirmation = () => {
        dispatch(changeValidationLoadingIndicator(true));
        verificationRequestHandler({email, code: validationCode}, dispatch, navigate);
    };

    return (
        <Fragment>
            <div
                css={[flex_container__horizontal__full_width, flex_container__vertical, css({marginBottom: "2em"})]}>
                <h1 css={[signup__welcome_message, css({marginBlock: "unset"})]}>
                    Confirm your email
                </h1>
                <p css={css({fontSize: "1.15em", marginBlock: "unset", marginTop: "1rem", textAlign: "center"})}>
                    Enter verification code from email <br/>{email}
                </p>
            </div>
            <div css={[flex_container__horizontal__full_width]}>
                <div css={css({width: "90%", height: '2.8125em'})}>
                    <TextInput
                        onChange={verificationCodeInputHandler}
                        label={"Enter verification code"}
                        autocompleteName={"verification-code"}
                        hasError={!!requestError}
                        errorMessage={requestError}
                    />
                </div>
            </div>
            <div css={[flex_container__horizontal__full_width, css({margin: "1.5em 0"})]}>
                <div css={css({width: "90%"})}>
                    <SubmitButton
                        label={"Confirm"}
                        disabled={!validationCode}
                        requestLoadingIndicator={requestIsProcessing}
                        onClick={onVerificationCodeConfirmation}
                    />
                </div>
            </div>
        </Fragment>
    );
};
