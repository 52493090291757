/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { skeleton__preloader } from '../../../common-styles/skeleton';
import { button_preloader } from './button-preloader.styles';

export const ButtonPreloader = () => {
    return <div css={ [skeleton__preloader, button_preloader] }/>;
};
