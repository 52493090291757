/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { Team } from '../../../models/team';
import { Fragment } from 'react';
import dayjs from 'dayjs';
import { team_card__container, team_card__time } from './team_card.styles';

export const TeamCard = ({ data: { location, name, modifiedAt } }: { data: Team }) => {
    return (
        <Fragment>
            <div css={ css({ width: '2em' }) }></div>
            <div css={ team_card__container }>
                <div>{ name }</div>
                <div>{ location }</div>
                <div
                    css={ team_card__time }
                >
                    UPD { dayjs(modifiedAt).format('DD MMM. YYYY, HH:mm') }
                </div>
            </div>
        </Fragment>
    );
};
