import {AppDispatch} from "../../../store/store";
import {NavigateFunction} from "react-router-dom";
import {requestHandlerWrapper} from "../../../utils/request-handler-wrapper";
import {getAllTeams, getAllTeamsLight} from "../../request-services/team";
import {MagictapResponse} from "../../../models/magictap-response";
import {ShortTeamInfo, TeamResponse} from "../../../models/api/api";
import { setTeamId } from "../../../store/features/team-config-slice";
import {loadAllTeams, loadAllTeamsShortInfo, setTeamListLoader} from "../../../store/features/team-list-slice";
import { GENERIC_ERROR_MESSAGE } from "../../../models/common-constants";
import { showNotification, NotificationType } from "../../../store/features/notification-bar-slice";

const teamsFailure = (_: MagictapResponse<unknown>, dispatch: AppDispatch) => {
    dispatch(showNotification({ title: GENERIC_ERROR_MESSAGE, type: NotificationType.ERROR }));
};

const teamsSuccess = (response: MagictapResponse<TeamResponse[]>, dispatch: AppDispatch) => {
    dispatch(loadAllTeams(response.content!!))
    dispatch(setTeamId(response.content!![0].team.uuid));
    dispatch(setTeamListLoader(false))
};

const teamsShortInfoSuccess = (response: MagictapResponse<ShortTeamInfo[]>, dispatch: AppDispatch) => {
    dispatch(loadAllTeamsShortInfo(response.content!!))
    dispatch(setTeamListLoader(false))
};

export const getAllTeamsRequestHandler = (dispatch: AppDispatch, navigate: NavigateFunction): void => {
    requestHandlerWrapper(
        getAllTeams,
        {},
        teamsSuccess,
        teamsFailure,
        dispatch,
        navigate
    );
};

export const getLightTeamListRequestHandler = (dispatch: AppDispatch, navigate: NavigateFunction): void => {
    requestHandlerWrapper(
        getAllTeamsLight,
        {},
        teamsShortInfoSuccess,
        teamsFailure,
        dispatch,
        navigate
    );
};
