/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { flex_container__horizontal } from "../../../common-styles/containers";
import { skeleton__preloader } from "../../../common-styles/skeleton";
import { BORDER_RADIUS_EXTRA_SMALL } from "../../../common-styles/borders";
import { GREY_BASIC } from "../../../common-styles/color-palette";

export const NumberOfCommentsPreloader = () => {
    return (
        <div css={ [flex_container__horizontal, css({ width: "100%", height: "100%" })] }>
            <div css={ [skeleton__preloader, css({
                width: '100%',
                height: '1em',
                margin: '1em 0',
                borderRadius: BORDER_RADIUS_EXTRA_SMALL,
                backgroundColor: GREY_BASIC,
            })] }>
            </div>
        </div>
    );
};
