import { put, select, takeLatest } from 'redux-saga/effects';
import { Team } from '../models/team';
import { loadAllTeams } from '../store/features/team-list-slice';
import { setRecipientTeam } from '../store/features/widget-preview-slice';

function* setWidgetRecipientId() {
    const { allTeams }: { allTeams: Team[] } = yield select(state => state.teamList);
    yield put(setRecipientTeam(allTeams[0]));
}

function* widgetPreviewSaga() {
    yield takeLatest(loadAllTeams, setWidgetRecipientId);
}

export default widgetPreviewSaga;