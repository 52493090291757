/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {css, jsx} from '@emotion/react';
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {Link, useNavigate} from "react-router-dom";
import {ChangeEvent, Fragment, useCallback} from "react";
import {changeEmail, changeRegistrationLoadingIndicator, validateEmail} from "../../../store/features/signup-slice";
import {debounce} from "lodash-es";
import {signup__welcome_message} from "../sign-up.styles";
import {flex_container__horizontal__full_width} from "../../../common-styles/containers";
import {EmailInput} from "../../common/inputs/email-input";
import {SubmitButton} from "../../common/buttons/submit-button";
import {signup__redirect_to_login__link} from "./wizard-signup-form.styles";
import {LOGIN_BASE_ROUTE} from "../../../routing/routes";
import {registerRequestHandler} from "../../../services/request-handlers/signup/register-request-handler";

export const WizardSignupForm = () => {
    const {
        email,
        emailIsValid,
        requestIsProcessing,
        requestError,
    } = useAppSelector(state => state.signup.registration);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const debouncedEmailValidationChecker = useCallback(debounce(() => dispatch(validateEmail()), 700), []);
    const onEmailInputChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        dispatch(changeEmail(e.target.value));
        debouncedEmailValidationChecker();
    };

    const onEmailInputBlur = () => {
        dispatch(validateEmail());
    }

    const onSignupButtonHandler = () => {
        dispatch(changeRegistrationLoadingIndicator(true))
        registerRequestHandler(email, dispatch, navigate);
    };

    return (
        <Fragment>
            <div css={[flex_container__horizontal__full_width, css({marginBottom: "2em"})]}>
                <h1 css={[signup__welcome_message, css({marginBlock: "unset"})]}>
                    You are very welcome!
                </h1>
            </div>
            <div>
                <div css={[flex_container__horizontal__full_width]}>
                    <div css={css({width: "90%", height: '2.8125em'})}>
                        <EmailInput
                            emailIsValid={emailIsValid}
                            onChange={onEmailInputChange}
                            onBlur={onEmailInputBlur}
                            errorMessage={requestError}
                        />
                    </div>
                </div>
                <div css={[flex_container__horizontal__full_width, css({margin: "1.5em 0"})]}>
                    <div css={css({width: "90%"})}>
                        <SubmitButton
                            label={"Sign up"}
                            requestLoadingIndicator={requestIsProcessing}
                            disabled={!email || !emailIsValid || requestIsProcessing}
                            onClick={onSignupButtonHandler}
                        />
                    </div>
                </div>
            </div>
            <div css={[flex_container__horizontal__full_width]}>
                <p css={signup__redirect_to_login__link}>Already have an account? <Link to={`/${LOGIN_BASE_ROUTE}`}>Log in</Link> here</p>
            </div>
        </Fragment>
    );
};
