import { useAppSelector } from '../../../store/hooks';
import { DetailedFeedbacksSortFunctions } from '../../../models/sort-options';
import { CardElement } from '../../common/card-element';
import { CardsList } from '../../common/cards-list';

import { DetailedFeedbackCard } from './detailed-feedback-card';

export const DetailedFeedbacksCards = () => {
    const { reviews, sortBy } = useAppSelector(state => state.detailedFeedbacks);

    const cards = [...reviews]
        .sort(DetailedFeedbacksSortFunctions[sortBy])
        .map((x, i) => (
            <CardElement
                key={ i }
                element={ <DetailedFeedbackCard data={ x }/> }
            />
        ));

    return (
        <CardsList cards={ cards }/>
    );
};
