/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {jsx} from '@emotion/react';
import {Button} from "@mui/material";
import {date_preset__preset_button} from "./date-preset-button.styles";

interface DatePresetButtonProps {
    label: string;
    onClick: () => void;
    selected?: boolean;
}

export const DatePresetButton = ({label, onClick, selected}: DatePresetButtonProps) => {
    return (
        <Button
            variant={"contained"}
            css={date_preset__preset_button(selected)}
            onClick={onClick}
        >
            {label}
        </Button>
    );
};
