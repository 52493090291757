/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { useAppSelector } from "../../../store/hooks";
import { PolarAngleAxis, RadialBar, RadialBarChart, ResponsiveContainer } from 'recharts';
import { GREEN_BASIC, GREY_LIGHT } from "../../../common-styles/color-palette";
import {
    happiness_score__gauge__trend,
    happiness_score__gauge__trend__icon,
    happiness_score__gauge__trend__percentage,
} from "./happiness-score-gauge-chart.styles";
import { getTrendIcon } from "../../../utils/trend-icon-utils";
import { FONT_SIZE_EXTRA, FONT_SIZE_SEMI_EXTRA } from '../../../common-styles/font-sizes';
import { HEADERS_FONT } from '../../../common-styles/font-styles';
import { smallLaptops } from '../../../common-styles/adaptive-breakpoints';

export const HappinessScoreGaugeChart = () => {
    const { happinessScore, happinessScoreTrend } = useAppSelector(state => state.happinessScore);

    const data = [
        { name: 'Happiness Score', value: ((happinessScore / 100) / 5) * 100 },
    ];

    const trend = happinessScoreTrend / 100;

    return (
        <ResponsiveContainer width="99%" height="99%">
            <RadialBarChart
                innerRadius="100%"
                barSize={ 14 }
                data={ data }
                startAngle={ 90 }
                endAngle={ -270 }
                css={ css({
                    'text': {
                        fontSize: FONT_SIZE_EXTRA,
                        fontFamily: HEADERS_FONT,

                        [smallLaptops]: {
                            fontSize: FONT_SIZE_SEMI_EXTRA,
                        }
                    },
                }) }
            >
                <PolarAngleAxis type="number" domain={ [0, 100] } tick={ false }/>
                <RadialBar
                    background={ {
                        fill: GREY_LIGHT,
                    } }
                    dataKey="value"
                    cornerRadius={ 4 }
                    fill={ GREEN_BASIC }
                />
                <text
                    x="50%"
                    y="50%"
                    textAnchor="middle"
                    dominantBaseline="middle"
                >
                    { (happinessScore / 100).toFixed(1) }
                </text>
                <foreignObject x="0" y="57.5%" width="100%" height="2em">
                    <div css={ happiness_score__gauge__trend }>
                        <img css={ happiness_score__gauge__trend__icon } src={ getTrendIcon(trend) } alt={ "" }/>
                        <p css={ happiness_score__gauge__trend__percentage(trend) }>{ trend.toFixed() }%</p>
                    </div>
                </foreignObject>
            </RadialBarChart>
        </ResponsiveContainer>
    );
};
