/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectWorkspaceConfig } from '../../store/selectors/workspace-config-selectors';
import { ChangeEvent, Fragment } from 'react';
import {
    changeEnableMessageInput,
    changeWelcomeMessage,
    changeWorkspaceLogo,
} from '../../store/features/workspace-config-slice';
import { widget_configuration__form_scrollable_container } from './widget-configuration.style';
import { SectionHeader } from '../common/headers/section-header';
import { FormGroup } from '@mui/material';
import { FileUploader } from '../team/configuration/inputs/file-uploader/file-uploader';
import { skeleton__preloader } from '../../common-styles/skeleton';
import { BORDER_RADIUS_SMALL } from '../../common-styles/borders';
import { TextInput } from '../common/inputs/text-input';
import { Switch } from '../common/switch';
import { CategoriesList } from './categories-list';
import { ConfigurationFooter } from './configuration-footer';
import { FormHint } from '../common/form-hint';

export const FeedbackScreenConfiguration = () => {
    const {
        isDataLoading,
        workspaceLogo,
        welcomeMessage,
        enableMessageInput,
    } = useAppSelector(selectWorkspaceConfig);

    const dispatch = useAppDispatch();
    const welcomeMessageInputHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(changeWelcomeMessage(e.target.value));
    };

    const onLogoChangeHandler = (base64?: string) => {
        dispatch(changeWorkspaceLogo(base64));
    };

    const enableMessageInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(changeEnableMessageInput(e.target.checked));
    };

    return (
        <Fragment>
            <div css={ widget_configuration__form_scrollable_container }>
                <div css={ css({ width: '80%' }) }>
                    <SectionHeader isLoading={ isDataLoading } title="Content"/>
                    <FormGroup css={ { paddingTop: '0.75em', paddingBottom: '1.5em' } }>
                        <div css={ css({ height: '5em', marginBottom: '0.5em' }) }>
                            <FileUploader
                                value={ workspaceLogo || '' }
                                onChange={ onLogoChangeHandler }
                                emptyStateTitle={ 'Logotype' }
                                emptyStateDescription={ 'IMG, PNG, JPEG, JPG, 5MB MAX.' }
                                isLoading={ isDataLoading }
                            />
                        </div>
                        { !isDataLoading &&
                            <FormHint
                                label="We recommend using a minimalistic version of the establishment logo, just the symbol"
                            />
                        }
                        <div css={ css({
                            height: '3.5em',
                            paddingBottom: '0.5em',
                            margin: '0.75em 0',
                            boxSizing: 'border-box',
                            '& .MuiFormControl-root': { height: '100%' },
                            '& .MuiFilledInput-root': { height: '100%' },
                        }) }>
                            { isDataLoading
                                ? <div css={ [skeleton__preloader, css({
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: BORDER_RADIUS_SMALL,
                                })] }/>
                                : <TextInput
                                    onChange={ welcomeMessageInputHandler }
                                    label={ 'Welcome text' }
                                    value={ welcomeMessage }
                                />
                            }
                        </div>
                        <Switch
                            checked={ enableMessageInput }
                            label="Enable message input"
                            onChange={ enableMessageInputHandler }
                            isLoading={ isDataLoading }
                        />
                    </FormGroup>
                    <CategoriesList/>
                </div>
            </div>
            <ConfigurationFooter/>
        </Fragment>
    );
};