import {
    changeWidgetDataLoader,
    loadWorkspaceConfiguration,
    workspaceConfigurationSaved,
} from "../../store/features/workspace-config-slice";
import { getWorkspaceData, saveConfiguration } from "../request-services/workspace";
import { SaveConfigurationRequest, WorkspaceResponse } from "../../models/api/api";
import { MagictapResponse } from "../../models/magictap-response";
import { AppDispatch } from "../../store/store";
import { NavigateFunction } from "react-router-dom";
import { requestHandlerWrapper } from "../../utils/request-handler-wrapper";
import { NotificationType, showNotification } from "../../store/features/notification-bar-slice";

const saveConfigurationFailure = (response: MagictapResponse<unknown>, dispatch: AppDispatch) => {
    dispatch(showNotification({ type: NotificationType.ERROR, title: 'Something went wrong' }));
};

const saveConfigurationSuccess = (response: MagictapResponse<null>, dispatch: AppDispatch, navigate: NavigateFunction) => {
    dispatch(workspaceConfigurationSaved());
    dispatch(showNotification({ type: NotificationType.SUCCESS, title: 'Configuration has been saved successfully' }));
};

export const workspaceConfigurationRequestHandler = (request: SaveConfigurationRequest, dispatch: AppDispatch, navigate: NavigateFunction) => {
    requestHandlerWrapper(
        saveConfiguration,
        request,
        saveConfigurationSuccess,
        saveConfigurationFailure,
        dispatch,
        navigate,
    );
};

const loadConfigurationFailure = (response: MagictapResponse<WorkspaceResponse>, dispatch: AppDispatch) => {
    dispatch(showNotification({ type: NotificationType.ERROR, title: 'Something went wrong' }));
};

const loadConfigurationSuccess = (response: MagictapResponse<WorkspaceResponse>, dispatch: AppDispatch, navigate: NavigateFunction) => {
    dispatch(changeWidgetDataLoader(false));
    dispatch(loadWorkspaceConfiguration(response.content!!));
};

export const loadWorkspaceConfigurationRequestHandler = (dispatch: AppDispatch, navigate: NavigateFunction) => {
    requestHandlerWrapper(
        getWorkspaceData,
        null,
        loadConfigurationSuccess,
        loadConfigurationFailure,
        dispatch,
        navigate,
    );
};