/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {css, jsx} from '@emotion/react';
import {
    flex_container__horizontal__full_width,
    flex_container__vertical__full_height
} from "../../../common-styles/containers";
import {signup_wizard__container} from "../sign-up.styles";
import React from "react";
import {WizardProductLogo} from "./wizard-product-logo";
import {WizardStepImage} from "./wizard-step-image";
import {signup_wizard__form_container} from "./wizard-step-container.styles";

interface WizardStepContainerProps {
    form: React.ReactElement;
    disclaimer?: React.ReactElement;

    wizardStepImage?: string;
}

export const AccountFormContainer = ({form, disclaimer, wizardStepImage}: WizardStepContainerProps) => {
    return (
        <div css={[flex_container__horizontal__full_width, flex_container__vertical__full_height]}>
            <form css={[signup_wizard__form_container]} onSubmit={(e) => e.preventDefault()}>
                <div css={signup_wizard__container}>
                    <WizardProductLogo/>
                    {wizardStepImage && <WizardStepImage image={wizardStepImage}/>}
                    {form}
                </div>
                <div css={css({margin: '0 1em'})}>{disclaimer}</div>
            </form>
        </div>
    );
};
