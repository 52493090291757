/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { flex_container__horizontal__full_width } from '../../../common-styles/containers';
import { css } from '@emotion/react';
import { FONT_SIZE_XL, FONT_SIZE_XXL } from '../../../common-styles/font-sizes';

export const CategoriesEmptyState = () => {
    return (
        <div css={ [flex_container__horizontal__full_width, css({ height: '80%', flexDirection: 'column' })] }>
            <h3 css={ css({ fontSize: FONT_SIZE_XXL, fontWeight: '700', textAlign: 'center' }) }>
                There's nothing here yet
            </h3>
            <p css={ css({ fontSize: FONT_SIZE_XL, textAlign: 'center' }) }>
                Add categories to the widget <br/>through the configuration section
            </p>
        </div>
    );
};
