import { css } from "@emotion/react";
import {
    APP_BACKGROUND_COLOR,
    BLUE_BASIC,
    DARK_BASIC,
    GREEN_BASIC_OPAQUE,
    GREY_DARK,
    WHITE,
} from "./common-styles/color-palette";
import { BORDER_RADIUS_SMALL } from "./common-styles/borders";
import { DROP_SHADOW_BLACK } from "./common-styles/shadows";
import { REGULAR_FONT } from "./common-styles/font-styles";
import { mobileDevices } from './common-styles/adaptive-breakpoints';
import { FONT_SIZE_MOBILE_M, FONT_SIZE_MOBILE_XXL } from './common-styles/font-sizes';

export const AppContainerStyle = css({
    height: '100vh',

    '& .MuiInputLabel-root': {
        fontFamily: REGULAR_FONT,
    },
    '& .MuiFormHelperText-root': {
        fontFamily: REGULAR_FONT,
    },
    '& .MuiFilledInput-root': {
        fontFamily: REGULAR_FONT,
    },
    '& .MuiButton-root': {
        fontFamily: REGULAR_FONT,
    },

    [mobileDevices]: {
        height: 'unset',

        'span': {
            fontSize: FONT_SIZE_MOBILE_M,
        },

        'h2': {
            fontSize: FONT_SIZE_MOBILE_XXL,
        }
    },
});

export const AppBodyStyle = css`
  body {
    background: ${ APP_BACKGROUND_COLOR };
  }

  a {
    text-decoration: underline;
    color: unset;

    :hover {
      color: ${ BLUE_BASIC };
    }
  }

  p {
    margin-block: 0.5em;
  }

  & .MuiDrawer-root {
    & .MuiPaper-root {
      border: none;
    }
  }

  & .MuiMenu-root {
    & .MuiPaper-root {
      border-radius: ${ BORDER_RADIUS_SMALL };
      background-color: ${ WHITE };
      box-shadow: ${ DROP_SHADOW_BLACK };

      & .MuiMenuItem-root {
        font-family: ${ REGULAR_FONT };
        height: 2.75em;
        color: ${ GREY_DARK };

        :hover {
          color: ${ DARK_BASIC };
          background-color: ${ GREEN_BASIC_OPAQUE };
        }
      }

      & .Mui-focusVisible {
        background-color: unset;
      }

      & .MuiList-root {
        padding: unset;

        & .Mui-selected {
          color: ${ DARK_BASIC };
          background-color: unset;

          :hover {
            background-color: ${ GREEN_BASIC_OPAQUE };
          }
        }
      }
    }
  }
`;
