import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { Country } from "../../utils/country-utils";
import { emailRegexp } from "../../utils/email-utils";

interface RegistrationState {
    email: string;
    emailIsValid: boolean;
    requestIsProcessing: boolean;
    requestError: string;
}

interface SignUpAccountState {
    firstName: string;
    lastName: string;
    companyName: string;
    country?: Country;
    password: string;
    passwordRepeated: string;
    passwordsAreInMatch: boolean;
    showPassword: boolean;
    showRepeatedPassword: boolean;
    requestIsProcessing: boolean;
    requestError: string;
}

interface EmailValidationState {
    validationCode: string;
    emailValidationLinkHash: string;
    requestIsProcessing: boolean;
    requestError: string;
}

interface SignUpTeamState {
    teamName: string;
    teamDescription: string;
    teamAddress: string;
    requestIsProcessing: boolean;
    requestError: string;
}

interface SignUpState {
    registration: RegistrationState;
    emailValidation: EmailValidationState;
    newAccount: SignUpAccountState;
    newTeam: SignUpTeamState;

    workspaceId: string;
    // teamId: string;
    sessionId?: string;

    highlightQrCode?: boolean;
    highlightDashboard?: boolean;
}

const initialState: SignUpState = {
    registration: {
        email: '',
        emailIsValid: true,
        requestIsProcessing: false,
        requestError: '',
    },
    newAccount: {
        firstName: '',
        lastName: '',
        companyName: '',
        password: '',
        passwordRepeated: '',
        country: Country.USA,
        passwordsAreInMatch: true,
        showPassword: false,
        showRepeatedPassword: false,
        requestIsProcessing: false,
        requestError: '',
    },
    emailValidation: {
        emailValidationLinkHash: '',
        validationCode: '',
        requestIsProcessing: false,
        requestError: '',
    },
    newTeam: {
        teamDescription: '',
        teamAddress: '',
        teamName: '',
        requestIsProcessing: false,
        requestError: '',
    },

    workspaceId: '',

    highlightDashboard: false,
    highlightQrCode: false,
};

export const signupSlice = createSlice({
    name: 'signup',
    initialState,
    reducers: {
        changeEmail: (state: Draft<SignUpState>, action: PayloadAction<string>) => {
            state.registration.email = action.payload;
            state.registration.requestError = '';
            state.registration.emailIsValid = true;
        },
        validateEmail: (state: Draft<SignUpState>) => {
            state.registration.emailIsValid = emailRegexp.test(state.registration.email);
        },
        changeRegistrationLoadingIndicator: (state: Draft<SignUpState>, action: PayloadAction<boolean>) => {
            state.registration.requestIsProcessing = action.payload;
        },
        registrationRequestFailed: (state: Draft<SignUpState>, action: PayloadAction<string>) => {
            state.registration.requestError = action.payload;
        },

        changeValidationCode: (state: Draft<SignUpState>, action: PayloadAction<string>) => {
            state.emailValidation.validationCode = action.payload;
            state.emailValidation.requestError = '';
        },
        changeEmailValidationLinkHash: (state: Draft<SignUpState>, action: PayloadAction<string>) => {
            state.emailValidation.emailValidationLinkHash = action.payload;
        },
        changeValidationLoadingIndicator: (state: Draft<SignUpState>, action: PayloadAction<boolean>) => {
            state.emailValidation.requestIsProcessing = action.payload;
        },
        validationRequestFailed: (state: Draft<SignUpState>, action: PayloadAction<string>) => {
            state.emailValidation.requestError = action.payload;
        },

        changeFirstName: (state: Draft<SignUpState>, action: PayloadAction<string>) => {
            state.newAccount.firstName = action.payload;
            state.newTeam.requestError = '';
        },
        changeLastName: (state: Draft<SignUpState>, action: PayloadAction<string>) => {
            state.newAccount.lastName = action.payload;
            state.newAccount.requestError = '';
        },
        changeCompanyName: (state: Draft<SignUpState>, action: PayloadAction<string>) => {
            state.newAccount.companyName = action.payload;
            state.newAccount.requestError = '';
        },
        changeCountry: (state: Draft<SignUpState>, action: PayloadAction<Country>) => {
            state.newAccount.country = action.payload;
            state.newAccount.requestError = '';
        },
        changePassword: (state: Draft<SignUpState>, action: PayloadAction<string>) => {
            state.newAccount.password = action.payload;
            state.newAccount.requestError = '';
            state.newAccount.passwordsAreInMatch = true;
        },
        changeRepeatedPassword: (state: Draft<SignUpState>, action: PayloadAction<string>) => {
            state.newAccount.passwordRepeated = action.payload;
            state.newAccount.requestError = '';
            state.newAccount.passwordsAreInMatch = true;
        },
        matchPasswords: (state: Draft<SignUpState>) => {
            state.newAccount.passwordsAreInMatch = state.newAccount.password === state.newAccount.passwordRepeated;
        },
        toggleShowPassword: (state: Draft<SignUpState>, action: PayloadAction<boolean>) => {
            state.newAccount.showPassword = action.payload;
        },
        toggleShowPasswordRepeated: (state: Draft<SignUpState>, action: PayloadAction<boolean>) => {
            state.newAccount.showRepeatedPassword = action.payload;
        },
        changeNewAccountLoadingIndicator: (state: Draft<SignUpState>, action: PayloadAction<boolean>) => {
            state.newAccount.requestIsProcessing = action.payload;
        },
        newAccountRequestFailed: (state: Draft<SignUpState>, action: PayloadAction<string>) => {
            state.newAccount.requestError = action.payload;
        },

        changeTeamName: (state: SignUpState, action: PayloadAction<string>) => {
            state.newTeam.teamName = action.payload;
            state.newTeam.requestError = '';
        },
        changeTeamDescription: (state: SignUpState, action: PayloadAction<string>) => {
            state.newTeam.teamDescription = action.payload;
            state.newTeam.requestError = '';
        },
        changeTeamAddress: (state: SignUpState, action: PayloadAction<string>) => {
            state.newTeam.teamAddress = action.payload;
            state.newTeam.requestError = '';
        },
        changeTeamCreationLoadingIndicator: (state: Draft<SignUpState>, action: PayloadAction<boolean>) => {
            state.newTeam.requestIsProcessing = action.payload;
        },
        teamCreationRequestFailed: (state: Draft<SignUpState>, action: PayloadAction<string>) => {
            state.newTeam.requestError = action.payload;
        },

        setWorkspaceId: (state: SignUpState, action: PayloadAction<string>) => {
            state.workspaceId = action.payload;
        },

        setSessionId: (state: Draft<SignUpState>, action: PayloadAction<string>) => {
            state.sessionId = action.payload;
        },

        finishSignup: (state: Draft<SignUpState>) => {},

        toggleHighlightQrCode: (state: Draft<SignUpState>, action: PayloadAction<boolean>) => {
            state.highlightQrCode = action.payload;
        },

        toggleHighlightDashboard: (state: Draft<SignUpState>, action: PayloadAction<boolean>) => {
            state.highlightDashboard = action.payload;
        },
    },
});

export const {
    changeEmail,
    validateEmail,
    changeRegistrationLoadingIndicator,
    registrationRequestFailed,

    changeValidationCode,
    changeEmailValidationLinkHash,
    changeValidationLoadingIndicator,
    validationRequestFailed,

    changeFirstName,
    changeLastName,
    changeCompanyName,
    changeCountry,
    changePassword,
    changeRepeatedPassword,
    toggleShowPassword,
    toggleShowPasswordRepeated,
    changeNewAccountLoadingIndicator,
    newAccountRequestFailed,

    changeTeamName,
    changeTeamAddress,
    changeTeamDescription,
    changeTeamCreationLoadingIndicator,
    teamCreationRequestFailed,

    setWorkspaceId,
    matchPasswords,
    setSessionId,
    finishSignup,
    toggleHighlightQrCode,
    toggleHighlightDashboard,
} = signupSlice.actions;

export default signupSlice.reducer;
