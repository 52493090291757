/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {jsx} from '@emotion/react';
import {css} from "@emotion/react";
import {skeleton__preloader} from "../../../common-styles/skeleton";
import {GREY_BASIC} from "../../../common-styles/color-palette";

export const CircleSkeletonPreloader = () => {
    return (
        <div css={css({
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        })}>
            <div css={[skeleton__preloader, css({
                width: "65%",
                height: "auto",
                paddingTop: "65%",
                borderRadius: "50%",
                backgroundColor: GREY_BASIC
            })]}>
            </div>
        </div>
    );
};
