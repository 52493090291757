import { css } from "@emotion/react";
import { page } from "../../../../common-styles/pages";

export const template_editor__double_column_layout = css([page, { display: 'flex', flexWrap: "wrap" }]) 
export const template_editor__column = css({
    flex: '1 1 50%', 
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center',
}) 
