import { UpdateAccountRequest } from "../../models/api/api";
import { AUTHORIZATION_LS_KEY } from "../../models/localstorage-keys";
import { apiHost } from "../api-host-resolver";
import { API_PATH } from "../request-path-constants";

export const updateAccount = async (updateAccountRequest: UpdateAccountRequest) => {
    const authUserToken = localStorage.getItem(AUTHORIZATION_LS_KEY) || '';
    return await fetch(`${apiHost}${API_PATH}/accounts/configs/update`, {
        method: 'POST',
        body: JSON.stringify(updateAccountRequest),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'auth_user_session': authUserToken,
        }
    })
        .then(resp => resp.json().then(x => x))
        .catch(e => console.log(e));
}

export const getAccount = async () => {
    const authUserToken = localStorage.getItem(AUTHORIZATION_LS_KEY) || '';
    return await fetch(`${apiHost}${API_PATH}/accounts/configs`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'auth_user_session': authUserToken,
        }
    })
        .then(resp => resp.json().then(x => x))
        .catch(e => console.log(e));
}