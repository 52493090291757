/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx, keyframes } from '@emotion/react';
import { flex_container__horizontal__full_width } from "../../common-styles/containers";
import { BLUE_BASIC, RED_BASIC, WHITE } from "../../common-styles/color-palette";
import { FONT_SIZE_L } from "../../common-styles/font-sizes";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { closeNotification, NotificationType } from "../../store/features/notification-bar-slice";
import { useEffect } from "react";
import { IconButton } from './buttons/icon-button';
import { Close } from '../../icons/Close';

const notification_bar__type_theme = (type: NotificationType) => {
    let backgroundColor;
    switch(type) {
        case NotificationType.SUCCESS:
            backgroundColor = BLUE_BASIC;
            break;
        case NotificationType.ERROR:
            backgroundColor = RED_BASIC;
            break;
    }

    return css({
        backgroundColor,
    });
};

const notification_appear_animation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const NotificationBar = () => {
    const { show, type, title } = useAppSelector(state => state.notificationBar);
    const dispatch = useAppDispatch();

    const closeNotificationHandler = () => {
        dispatch(closeNotification());
    };
    useEffect(() => {
        const timer = setTimeout(closeNotificationHandler, 5000);
        return () => clearTimeout(timer);
    }, [title, show, type]);

    return (
        <div css={ [flex_container__horizontal__full_width,
            css({ display: show ? 'flex' : 'none' }),
            css({
                position: 'fixed',
                alignItems: 'center',
                bottom: 0,
                zIndex: '1999',
                height: '3em',
                animation: `${ notification_appear_animation } 0.25s linear`,
            }),
            notification_bar__type_theme(type),
        ] }
        >
            <div css={ css({ color: WHITE, fontSize: FONT_SIZE_L }) }>
                { title }
            </div>
            <IconButton
                css={ css({
                    marginLeft: '1em',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: WHITE,
                }) }
                onClick={ closeNotificationHandler }
            >
                <Close/>
            </IconButton>
        </div>
    );
};
