import { fork } from 'redux-saga/effects';
import detailedFeedbacksSaga from './detailed-feedbacks.saga';
import widgetPreviewSaga from './widget-preview.saga';
import signupSaga from './signup.saga';
import metricsSaga from './metrics.saga';

export function* rootSaga() {
    yield fork(widgetPreviewSaga);
    yield fork(signupSaga);
    yield fork(detailedFeedbacksSaga);
    yield fork(metricsSaga);
}
