/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import QRCodeSVG from "qrcode.react";
import { useAppSelector } from '../../../../store/hooks';
import { getFeedbackLink } from '../../../../utils/feedback-utils';
import { qr_code__container } from '../../qr-code-configuration.styles';
import { template_preview, template_preview__lower_section, template_preview__upper_section, template_preview__qr_invitation, template_preview__container } from './template-preview.styles';
import { Logo, LogoColor } from '../../../common/logo';
import { skeleton__preloader } from '../../../../common-styles/skeleton';

//TODO MO PR3: DESIGN intermediate background state 
export const TemplatePreview = () => {
    const { header,
        subheader,
        qrBackgroundColor,
        headerColor,
        subheaderColor,
        qrPixelsColor,
        backgroundImage,
        isLoading,
    } = useAppSelector(state => state.templateEditor)
    const { qrHash } = useAppSelector(state => state.teamConfig);
    return (
        <div css={ template_preview__container }>
            { isLoading && <div css={ [template_preview(), skeleton__preloader] } /> }
            { !isLoading &&
                <div id='template-preview' css={ template_preview(backgroundImage) }>
                    <div css={ template_preview__upper_section }>
                        <h1 css={ css({ color: headerColor, textAlign: 'center' }) }>{ header }</h1>
                        <h2 css={ css({ color: subheaderColor, textAlign: 'center' }) }>{ subheader }</h2>
                    </div>
                    <div css={ template_preview__lower_section }>
                        <div css={ qr_code__container(qrBackgroundColor) }>
                            <QRCodeSVG
                                value={ getFeedbackLink(qrHash) }
                                fgColor={ qrPixelsColor }
                                bgColor={ 'transparent' }
                                style={ { width: '11em', height: '11em' } }
                                level={ "H" }
                                renderAs={ "svg" }
                            />
                        </div>
                        <p css={ template_preview__qr_invitation(subheaderColor) }>
                            Scan the QR code to send us your feedback
                        </p>
                        <Logo color={ LogoColor.WHITE_LOGO } />
                    </div>
                </div>
            }
        </div>
    )
}
