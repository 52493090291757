import { useEffect, useState } from "react";
import { gsap } from "gsap";
import { IconButton } from "../components/common/buttons/icon-button";
import styled from "@emotion/styled";
import { DARK_BASIC, GREEN_LIGHT_OPAQUE, GREY_BASIC } from "../common-styles/color-palette";
import { mobileDevices } from '../common-styles/adaptive-breakpoints';

const IconButtonDark = styled(IconButton)({
    backgroundColor: GREY_BASIC,

    [mobileDevices]: {
        width: '1.67em',
        height: '1.67em',
    },

    ':hover': {
        backgroundColor: GREEN_LIGHT_OPAQUE,
        color: DARK_BASIC,
    },

    ':disabled': {
        color: DARK_BASIC,
        backgroundColor: GREY_BASIC,
    },
});

export const RefreshButton = ({ isLoading, onClick }: { isLoading: boolean, onClick: () => void }) => {
    const [tween, setTween] = useState<gsap.core.Tween>();
    useEffect(() => {
        const tweenCreated = gsap.to("#circle-arrows", {
            rotation: 360,
            repeat: -1,
            ease: "none",
            duration: 1,
            transformOrigin: "50% 50%",
        });
        setTween(tweenCreated);
    }, []);

    useEffect(() => {
        if(isLoading) {
            tween?.restart();
        } else {
            tween?.pause(0);
        }
    }, [isLoading, tween]);

    return (
        <IconButtonDark onClick={ onClick } disabled={ isLoading }>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <g id={ "circle-arrows" }>
                    <path fill="currentColor" fillRule="evenodd"
                          d="M5.463 4.433A9.961 9.961 0 0 1 12 2c5.523 0 10 4.477 10 10h2l-3 6-3-6h2A8 8 0 0 0 6.46 6.228l-.997-1.795Zm13.074 15.134A9.96 9.96 0 0 1 12 22C6.477 22 2 17.523 2 12H0l3-6 2.999 5.998L6 12H4a8 8 0 0 0 13.54 5.772l.997 1.795Z"
                          clipRule="evenodd"/>
                </g>
            </svg>
        </IconButtonDark>
    );
};
