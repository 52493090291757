// default font sizes
export const FONT_SIZE_XXXXS = '0.5rem';
export const FONT_SIZE_XXS = '0.75rem';
export const FONT_SIZE_M = '0.875rem';
export const FONT_SIZE_L = '1rem';
export const FONT_SIZE_XL = '1.125rem';
export const FONT_SIZE_XXL = '1.25rem';
export const FONT_SIZE_XXXL = '1.5rem';
export const FONT_SIZE_SEMI_EXTRA = '2.5rem';
export const FONT_SIZE_EXTRA = '3.5rem';

// mobile font sizes
export const FONT_SIZE_MOBILE_XXXXS = '0.5rem';
export const FONT_SIZE_MOBILE_XXS = '0.75rem';
export const FONT_SIZE_MOBILE_M = '0.875rem';
export const FONT_SIZE_MOBILE_L = '1rem';
export const FONT_SIZE_MOBILE_XL = '1.125rem';
export const FONT_SIZE_MOBILE_XXL = '1.25rem';
export const FONT_SIZE_MOBILE_XXXL = '1.5rem';
export const FONT_SIZE_MOBILE_SEMI_EXTRA = '2.5rem';
export const FONT_SIZE_MOBILE_EXTRA = '3.5rem';
