import * as client from '@amplitude/analytics-browser';
import { pageViewTrackingPlugin } from '@amplitude/plugin-page-view-tracking-browser';
import { call } from 'redux-saga/effects';
import { getWorkspaceId } from '../utils/workspace-utils';

const { add, init } = client;

const KEY = process.env.REACT_APP_AMPLITUDE_KEY || '';

function trackPageView() {
    add(pageViewTrackingPlugin(client, {
        trackOn: undefined,
        trackHistoryChanges: 'pathOnly',
    }));
}

function* metricsSaga() {
    if(process.env.NODE_ENV === 'development' && !KEY) {
        console.warn('Amplitude analytics turned off. Check API connection and credentials.');
        return;
    }

    yield call(trackPageView);

    init(KEY, getWorkspaceId());
}

export default metricsSaga;
