import {ErrorCode, MagictapResponse} from "../models/magictap-response";
import {AppDispatch} from "../store/store";
import {NavigateFunction} from "react-router-dom";
import {LOGIN_BASE_ROUTE} from "../routing/routes";
import {NotificationType, showNotification} from "../store/features/notification-bar-slice";
import {GENERIC_ERROR_MESSAGE} from "../models/common-constants";

export const requestHandlerWrapper = <U, T>(
    requestHandler: (req: U) => Promise<MagictapResponse<T>>,
    request: U,
    successCallback: (resp: MagictapResponse<T>, dispatch: AppDispatch, navigate: NavigateFunction) => void,
    failureCallback: (resp: MagictapResponse<T>, dispatch: AppDispatch, navigate?: NavigateFunction) => void,
    dispatch: AppDispatch,
    navigate: NavigateFunction): void => {

    requestHandler(request)
        .then((response: MagictapResponse<T>) => {
            if (response.errorCode) {
                if (response.errorCode === ErrorCode.SESSION_IS_NOT_VALID) {
                    navigate(`/${LOGIN_BASE_ROUTE}`);
                } else {
                    failureCallback(response, dispatch, navigate);
                }
                return;
            }
            successCallback(response, dispatch, navigate);
        })
        .catch((reason) => {
                console.error(reason);
                dispatch(showNotification({title: GENERIC_ERROR_MESSAGE, type: NotificationType.ERROR}));
            }
        );
};
