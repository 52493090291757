/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {css, jsx} from '@emotion/react';

export const ContactUs = () => {
    return (
        <div
            css={css({
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: '3em',
            })}
        >
            <h2 css={css({textAlign: 'center', maxWidth: '65%'})}>In case if your email client doesn't open automatically you can send an email to the next address</h2>
            <p>Email: help@magictap.me</p>
        </div>
    )
}
