import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

interface NavigationState {
    destination?: string;
}

const initialState: NavigationState = {};

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        navigateTo: (state: Draft<NavigationState>, action: PayloadAction<string | undefined>) => {
            state.destination = action.payload;
        },
    },
});

export const {
    navigateTo,
} = navigationSlice.actions;

export default navigationSlice.reducer;
