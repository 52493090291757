export enum TEAM_STATUS {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
}

export interface Team {
    id: string;
    name: string;
    description?: string;
    location?: string;
    status: TEAM_STATUS;
    modifiedAt: number;
    backgroundImage?: string;
    tripadvisorLink?: string;
    googleMapsLink?: string;
    instagramLink?: string;
}

export interface Table {
    id: string;
    label: string;
}
