/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { Fragment, useCallback } from "react";
import { BORDER_RADIUS_SMALL } from '../../common-styles/borders';
import { skeleton__preloader } from '../../common-styles/skeleton';
import { changeCategoriesOrder, removeCategory } from "../../store/features/workspace-config-slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectWorkspaceConfig } from '../../store/selectors/workspace-config-selectors';
import { DragAndDropItem, DragAndDropList } from '../common/drag-and-drop/drag-and-drop';
import { SectionHeader } from '../common/headers/section-header';

export const CategoriesList = () => {
    const {
        categories,
        isDataLoading,
    } = useAppSelector(selectWorkspaceConfig);

    const dispatch = useAppDispatch();

    const moveCategory = useCallback((source: number, target: number) => {
        dispatch(changeCategoriesOrder({ source, target }));
    }, []);

    const deleteCategory = useCallback((id: number) => {
        dispatch(removeCategory(id));
    }, []);

    const categoryItems: DragAndDropItem[] = categories.map((x, i) => ({
        id: x.label,
        index: i,
        element: (
            <div
                css={ css({
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: '12em',
                }) }
            >
                { `${ x.icon || '' } ${ x.label }` }
            </div>
        ),
    }));

    return (
        <Fragment>
            <SectionHeader isLoading={ isDataLoading } title="Categories"/>
            { isDataLoading
                ? <div css={ [skeleton__preloader, css({
                    height: '7em',
                    width: '100%',
                    borderRadius: BORDER_RADIUS_SMALL,
                    marginTop: '1.5em',
                })] }/>
                :
                <DragAndDropList
                    items={ categoryItems }
                    onMove={ moveCategory }
                    onDelete={ deleteCategory }
                />
            }
        </Fragment>
    );
};
