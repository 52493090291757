import { styled } from "@mui/material/styles";
import { Switch as MuiSwitch } from "@mui/material";
import { BLACK, GREEN_BASIC, GREY_BASIC, GREY_DARK, GREY_LIGHT, WHITE } from "../../common-styles/color-palette";
import { BORDER_RADIUS_LARGE, BORDER_RADIUS_SMALL } from "../../common-styles/borders";


// there is a fucking 'frame' over inner content in checked state 🤬,
// but it's only visible with 200% scale on 4k screens so don't mind
export const AntSwitch = styled(MuiSwitch)(({ theme, checked }) => (
    {
        width: '1.5em',
        height: '1em',
        padding: 0,
        display: 'flex',
        border: `1px solid ${ checked ? GREEN_BASIC : GREY_BASIC }`,
        boxSizing: 'border-box',
        borderRadius: BORDER_RADIUS_LARGE,
        color: 'unset',
        '&:hover': {
            border: `1px solid ${ GREEN_BASIC }`,
            backgroundColor: 'unset',
        },

        '&.MuiTouchRipple-root': {
            color: 'unset',
        },

        '& .MuiSwitch-switchBase': {
            padding: '0.1875em',
            '&:hover': {
                padding: '0.25em',
                backgroundColor: 'unset',

                '& .MuiSwitch-thumb': {
                    width: '0.375em',
                    height: '0.375em',
                    color: GREEN_BASIC,
                },
            },
            '&.Mui-checked': {
                transform: 'translateX(0.55em)',
                '& .MuiSwitch-thumb': {
                    color: BLACK,
                },
                '&:active': {
                    backgroundColor: 'unset',
                    color: WHITE,
                },
                '&:hover': {
                    '& .MuiSwitch-thumb': {
                        color: GREEN_BASIC,
                    },
                    '& + .MuiSwitch-track': {
                        backgroundColor: 'unset',
                    },
                },
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: GREEN_BASIC,
                },
            },
        },
        '& .MuiSwitch-thumb': {
            boxShadow: '0 0.25em 0.5em 0 rgb(0 35 11 / 20%)',
            width: '0.5em',
            height: '0.5em',
            borderRadius: BORDER_RADIUS_SMALL,
            color: GREY_DARK,
        },
        '& .MuiSwitch-track': {
            opacity: 1,
            borderRadius: 0,
            backgroundColor: GREY_LIGHT,
        },
    }),
);
