/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {jsx} from '@emotion/react';
import React, {Fragment} from "react";
import {MobileNavbar} from "./mobile-navbar";
import {DesktopNavbar} from "./desktop-navbar";

export const Navbar = () => {
    return (
        <Fragment>
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <MobileNavbar/>
            <DesktopNavbar/>
        </Fragment>
    );
};
