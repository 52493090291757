/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { team_list__preloader, team_list__preloader__header, team_list__preloader__item__container, team_list__preloader__item, team_list__preloader__row } from './team-list-preloader.styles';

export const TeamListPreloader = () => {
    return (
        <div css={team_list__preloader}>
            <div css={team_list__preloader__header}>
                <div css={team_list__preloader__item__container}>
                    <div css={team_list__preloader__item('1em')}></div>
                </div>
            </div>
            {Array(3).fill(null).map((_, i, array) =>
                <div key ={i} css={team_list__preloader__row(i !== array.length - 1)}>
                    <div css={team_list__preloader__item__container}>
                        <div css={team_list__preloader__item('1.25em')}></div>
                    </div>
                </div>
            )}
        </div>
    )
}
