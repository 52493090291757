import { RootState } from '../store';
import { createSelector, Selector } from '@reduxjs/toolkit';
import { FilterPreset } from '../../models/filters';

export const selectTeam = (state: RootState) => state.teamConfig;
export const selectDetailedFeedbacks = (state: RootState) => state.detailedFeedbacks;
export const selectScorePeriod = (state: RootState) => state.scorePeriod;

// todo: set correct first type here
export const feedbacksFilterSelector: Selector<any, FeedbacksFilterSelectorState> = createSelector(
    selectDetailedFeedbacks,
    selectScorePeriod,
    (feedbacks, scorePeriod): FeedbacksFilterSelectorState => {
        return {
            teamIds: feedbacks.teamsFilter,
            ratingFilter: feedbacks.ratingsFilter,
            categoriesFilter: feedbacks.categoriesFilter,
            from: scorePeriod.selectedDateRange.from,
            to: scorePeriod.selectedDateRange.to,
        };
    },
);

export interface FeedbacksFilterSelectorState {
    teamIds: FilterPreset<string[]>;
    ratingFilter: FilterPreset<number[]>;
    categoriesFilter: FilterPreset<string[]>;
    from?: number;
    to?: number;
}
