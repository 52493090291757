/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import React, { useRef, useState } from "react";
import { DateRange as DR, DayPicker, SelectRangeEventHandler } from "react-day-picker";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { usePopper } from "react-popper";
import { changeDateRange, toggleCalendarPopup } from "../../../store/features/score-period-slice";
import {
    date_picker__calendar,
    date_picker__calendar__container,
    date_picker__calendar_button,
    date_picker__input__container,
    date_picker__input_field,
    day_picker__modifier_styles,
} from "./date-range-picker.styles";
import { container__full_height } from "../../../common-styles/containers";
import { DatePickerCaption } from "./date-range-picker-caption";
import { Calendar } from '../../../icons/Calendar';

export enum DateRangePreset {
    TODAY = 'TODAY',
    THIS_WEEK = 'THIS_WEEK',
    PREVIOUS_WEEK = 'PREVIOUS_WEEK',
    THIS_MONTH = 'THIS_MONTH',
}

const minCalendarDate = new Date(1980, 1, 1);
const maxCalendarDate = new Date(2077, 11, 31);

const getDateRangePresetFormattedString = (selectedDateRangePreset?: DateRangePreset) => {
    switch (selectedDateRangePreset) {
        case DateRangePreset.TODAY:
            return 'Today';
        case DateRangePreset.THIS_WEEK:
            return 'This week';
        case DateRangePreset.PREVIOUS_WEEK:
            return 'Previous week';
        case DateRangePreset.THIS_MONTH:
            return 'This Month';
        default:
            return 'Custom';
    }
};

export const DateRangePicker = () => {
    const dispatch = useAppDispatch();
    const {
        selectedDateRange,
        selectedDateRangePreset,
        isCalendarOpened,
    } = useAppSelector(state => state.scorePeriod);

    const popperRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
        null
    );

    const popper = usePopper(popperRef.current, popperElement, {
        placement: 'bottom-start'
    });

    const handleCalendarToggle = () => {
        dispatch(toggleCalendarPopup(!isCalendarOpened));
    };

    const handleRangeSelect: SelectRangeEventHandler = (range: DR | undefined) => {
        const from: number = range?.from?.getTime() || selectedDateRange.from!!;
        const to: number = range?.to?.getTime() || selectedDateRange.to!!;
        dispatch(changeDateRange({from, to}));
    };

    return (
        <div css={container__full_height}>
            <div
                css={[date_picker__input__container]}
                ref={popperRef}
                onClick={handleCalendarToggle}
            >
                <div css={date_picker__input_field}>
                    {getDateRangePresetFormattedString(selectedDateRangePreset)}
                </div>
                <div css={ css({ width: '3em' }) }>
                    <button
                        css={ date_picker__calendar_button }
                        ref={ buttonRef }
                        type="button"
                        aria-label="Pick a date"
                    >
                        <Calendar/>
                    </button>
                </div>
            </div>
            {isCalendarOpened && (
                // todo: make sure that FocusTrap is not needed here
                <div
                    tabIndex={-1}
                    style={popper.styles.popper}
                    className="dialog-sheet"
                    css={date_picker__calendar__container}
                    {...popper.attributes.popper}
                    ref={setPopperElement}
                    role="dialog"
                >
                    <DayPicker
                        css={date_picker__calendar}
                        modifiersStyles={
                            day_picker__modifier_styles
                        }
                        weekStartsOn={1}
                        initialFocus={isCalendarOpened}
                        mode="range"
                        captionLayout="dropdown"
                        fromDate={minCalendarDate}
                        toDate={maxCalendarDate}
                        defaultMonth={selectedDateRange?.to ? new Date(selectedDateRange?.to) : new Date()}
                        showOutsideDays={true}
                        selected={{
                            to: (selectedDateRange && selectedDateRange.to) ? new Date(selectedDateRange?.to) : undefined,
                            from: (selectedDateRange && selectedDateRange.from) ? new Date(selectedDateRange?.from) : undefined,
                        }}
                        components={{
                            Caption: DatePickerCaption
                        }}
                        max={365}
                        onSelect={handleRangeSelect}
                    />
                </div>
            )}
        </div>
    );
};
