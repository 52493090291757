import {css} from "@emotion/react";

export const signup_wizard__header = css({
    height: '3em',
    marginTop: '1.5em',
    '@media (max-width: 555px) or (max-height: 780px)': {
        height: '4em',
        marginTop: 'unset',
    }
});

