import {css} from "@emotion/react";
import {DARK_BASIC, GREY_BASIC, GREY_LIGHT, GREY_MEDIUM, WHITE} from "../../../common-styles/color-palette";
import {BORDER_RADIUS_SMALL} from "../../../common-styles/borders";

export const date_preset__preset_button = (selected?: boolean) => css({
    backgroundColor: selected ? GREY_BASIC : WHITE,
    textTransform: 'none',
    height: '3.5em',
    boxShadow: 'none',
    width: '49%',
    marginBottom: '0.5em',
    color: DARK_BASIC,
    borderRadius: BORDER_RADIUS_SMALL,
    border: `1px solid ${GREY_BASIC}`,
    ':hover': {
        backgroundColor: GREY_LIGHT,
        boxShadow: 'none',
    },

    ':disabled': {
        backgroundColor: GREY_BASIC,
        color: GREY_MEDIUM,
    },
});
