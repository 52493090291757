import {API_PATH} from "../request-path-constants";
import {AUTHORIZATION_LS_KEY} from "../../models/localstorage-keys";
import {apiHost} from "../api-host-resolver";
import {DashboardRequest, DashboardResponse} from "../../models/api/api";
import {MagictapResponse} from "../../models/magictap-response";

export const getDashboardData: (req: DashboardRequest) => Promise<MagictapResponse<DashboardResponse>> =
    async ({
               from,
               teamId,
               to,
           }: DashboardRequest) => {
        const authUserSession = localStorage.getItem(AUTHORIZATION_LS_KEY) || '';
        return await fetch(`${apiHost}${API_PATH}/aggregations/dashboard?teamId=${teamId}&from=${from}&to=${to}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'auth_user_session': authUserSession,
            }
        })
            .then(resp => resp.json().then(x => x))
            .catch(e => console.log(e))
    };
