/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { Category } from '../../models/api/api';
import { categories__chips_container, category__chips } from '../dashboard/detailed-feedbacks/detailed-feedbacks.style';
import { Stack } from '@mui/material';

export const TableCategoryChips = ({ categories }: { categories: Category[] }) => {
    return (
        <div css={ categories__chips_container }>
            { categories.map((y: Category, i: number) => (
                <Stack
                    css={ category__chips }
                    alignItems="center"
                    direction="row"
                    key={ i }
                >
                    <p>{ `${ y.icon || '' } ${ y.label }${ i === categories.length - 1 ? '' : ',' }` }</p>
                </Stack>
            )) }
        </div>
    );
};
