/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx, SerializedStyles } from '@emotion/react';
import { mobileDevices } from '../../../common-styles/adaptive-breakpoints';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectWorkspaceConfig } from '../../../store/selectors/workspace-config-selectors';
import { Fragment } from 'react';
import Stack from '@mui/material/Stack';
import { ALL_FILTER_PRESET } from '../../../models/filters';
import { changeCategoriesFilter } from '../../../store/features/detailed-feedbacks-slice';
import { category__chips } from './detailed-feedbacks';

const detailed_feedbacks__categories_outer_container: SerializedStyles = css({
    display: 'flex',
    width: '100%',
});

const detailed_feedbacks__categories_container: SerializedStyles = css({
    display: 'flex',
    justifyContent: 'start',
    gap: '0.5em',
    flexWrap: 'wrap',

    [mobileDevices]: {
        flexWrap: 'nowrap',
        display: 'box',
        height: '2.5em',
        overflow: 'auto',

        scrollbarWidth: 'none', // Firefox

        '::-webkit-scrollbar': {
            display: 'none',  /* Safari and Chrome */
        },
    },
});

export const CategoriesFilter = () => {
    const { categoriesFilter } = useAppSelector(state => state.detailedFeedbacks);
    const { categories } = useAppSelector(selectWorkspaceConfig);

    const dispatch = useAppDispatch();

    return (
        <div css={ detailed_feedbacks__categories_outer_container }>
            <div css={ detailed_feedbacks__categories_container }>
                <Fragment>
                    <Stack css={ category__chips(categoriesFilter === ALL_FILTER_PRESET) }
                           alignItems="center"
                           direction="row"
                           onClick={ () => {
                               dispatch(changeCategoriesFilter(ALL_FILTER_PRESET));
                           } }
                    >
                        <p css={ css({ marginLeft: '0.25em' }) }>All</p>
                    </Stack>
                    { [...categories].map((x, i) => {
                        const isSelected = categoriesFilter.includes(x.label);
                        return (
                            // todo: replace with common component from widget library
                            <Stack
                                key={ i }
                                css={ category__chips(isSelected) }
                                alignItems="center"
                                direction="row"
                                onClick={ () => {
                                    dispatch(changeCategoriesFilter(x.label));
                                } }
                            >
                                <p>{ x.icon }</p>
                                <p css={ css({ marginLeft: '0.25em' }) }>{ x.label }</p>
                            </Stack>
                        );
                    }) }
                </Fragment>
            </div>
        </div>
    );
};
