/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { container__full_width } from "../../common-styles/containers";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { DateRangePicker } from "../common/date-picker/date-range-picker";
import {
    dashboard__header__container,
    dashboard__header__filter_buttons__filter_button,
    dashboard_header__filter_panel__date_picker,
    dashboard_header__filter_panel__filter_buttons__container,
} from "./dashboard-header.styles";
import { PageHeader } from "../common/headers/page-header";
import { select__container } from "../common/dropdowns/select";
import { RefreshButton } from "../../icons/RefreshButton";
import { dashboardRequestHandler } from "../../services/request-handlers/dashboard/dashboard-charts-request-handler";
import { refreshDashboardData } from "../../store/features/dashboard-slice";
import { useNavigate } from "react-router-dom";
import { TeamSelector } from '../common/team-selector';

export const DashboardHeader = () => {
    const { teamsFilter } = useAppSelector(state => state.detailedFeedbacks);
    const { dashboardDataLoading, dashboardDataRefreshing } = useAppSelector(state => state.dashboard);
    const { selectedDateRange } = useAppSelector(state => state.scorePeriod);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onRefreshHandler = () => {
        dispatch(refreshDashboardData());
        dashboardRequestHandler(selectedDateRange, teamsFilter, dispatch, navigate);
    };

    return (
        <div css={ [container__full_width] }>
            <div css={ [dashboard__header__container] }>
                <PageHeader isLoading={ dashboardDataLoading } title="Results of your work"/>
                <div css={ dashboard_header__filter_panel__filter_buttons__container }>
                    <div css={ dashboard_header__filter_panel__date_picker }>
                        <DateRangePicker/>
                    </div>
                    <div css={ dashboard__header__filter_buttons__filter_button }>
                        <div css={ select__container }>
                            <TeamSelector dark={ true }/>
                        </div>
                    </div>
                    <div css={ css({ height: '100%' }) }>
                        <RefreshButton isLoading={ dashboardDataRefreshing } onClick={ onRefreshHandler }/>
                    </div>
                </div>
            </div>
        </div>
    );
};
