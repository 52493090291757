import { NavigateFunction } from "react-router-dom";
import { TemplateResponse } from "../../../models/api/api";
import { GENERIC_ERROR_MESSAGE } from "../../../models/common-constants";
import { MagictapResponse } from "../../../models/magictap-response";
import { getTemplate } from "../../request-services/template";
import { NotificationType, showNotification } from "../../../store/features/notification-bar-slice";
import { loadTemplateEditorData, setTemplateEditorLoading } from "../../../store/features/template-editor-slice";
import { AppDispatch } from "../../../store/store";
import { requestHandlerWrapper } from "../../../utils/request-handler-wrapper";

function getTemplateFailure(_: any, dispatch: AppDispatch) {
    dispatch(showNotification({ title: GENERIC_ERROR_MESSAGE, type: NotificationType.ERROR }));
}

const getTemplateSuccess = (response: MagictapResponse<TemplateResponse>, dispatch: AppDispatch) => {
    dispatch(loadTemplateEditorData(response.content!!));
    dispatch(setTemplateEditorLoading(false))
};

export const getTemplateRequestHandler = (id: string, dispatch: AppDispatch, navigate: NavigateFunction): void => {
    requestHandlerWrapper(
        getTemplate,
        id,
        getTemplateSuccess,
        getTemplateFailure,
        dispatch,
        navigate
    );
};
