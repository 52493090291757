/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { css } from "@emotion/react";
import { skeleton__preloader__histogram } from "../../../common-styles/skeleton";
import { BORDER_RADIUS_EXTRA_SMALL } from "../../../common-styles/borders";
import { NumberOfCommentsPreloader } from "./number-of-comments-preloader";

export const HistogramPreloader = () => {
    return (
        <div css={ css({
            height: '90%',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around',
            gap: '0.5em',
        }) }>
            { [5, 10, 8, 7, 5, 10, 8, 7, 5, 10, 8, 7, 5].map((x, i) =>
                <div
                    css={ css({
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        flex: 1,
                        maxWidth: '3em',
                    }) }
                    key={ i }
                >
                    <div css={ css({ height: `80%`, display: 'flex', justifyContent: 'center', alignItems: 'end' }) }>
                        <div
                            css={ [skeleton__preloader__histogram, css({
                                height: `${ x * 10 }%`,
                                flex: 1,
                                borderRadius: BORDER_RADIUS_EXTRA_SMALL,
                            })] }
                        />
                    </div>
                    <div css={ css({ height: '10%' }) }>
                        <NumberOfCommentsPreloader/>
                    </div>
                </div>,
            ) }
        </div>
    );
};
