export const ContactUsHover = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <g>
                <path fill="currentColor" fillRule="evenodd"
                      d="M11 5a3 3 0 1 1-5.236-2H3a1 1 0 0 0-1 1v18.5L6.455 19H21a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H10.236A2.99 2.99 0 0 1 11 5Zm-4 5a5 5 0 1 0 10 0h-2a3 3 0 0 1-6 0H7Zm11-5h-4v2h4V5Z"
                      clipRule="evenodd"/>
                <path fill="currentColor" fillRule="evenodd"
                      d="M8 3a2 2 0 1 0 0 4 2 2 0 0 0 0-4ZM4 5a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z" clipRule="evenodd"/>
            </g>

        </svg>
    );
};
