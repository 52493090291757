/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { CircleSkeletonPreloader } from '../../common/skeleton-preloaders/circle-skeleton-preloader';
import { HappinessScoreGaugeChart } from './happiness-score-gauge-chart';

export const HappinessScoreGaugeBlock = (props: { happinessScoreIsLoading: any }) => {
    return (
        <div css={ css({ height: "65%" }) }>
            { props.happinessScoreIsLoading &&
                <CircleSkeletonPreloader/>
            }
            { !props.happinessScoreIsLoading &&
                <div css={ css({ width: "100%", height: "100%", display: "flex", justifyContent: "center" }) }>
                    <HappinessScoreGaugeChart/>
                </div>
            }
        </div>
    );
};