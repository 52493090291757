import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { toggleMobileNavbar } from "../../store/features/navigation-bar-slice";
import { AppBar, IconButton, Toolbar } from "@mui/material";
import LogoWhite from "../../static/logo-white.svg";
import { DARK_BASIC } from "../../common-styles/color-palette";
import { Menu } from '../../icons/Menu';

export const AppTopBarMobile = () => {
    const { mobileNavbarOpened } = useAppSelector(state => state.navigationBar);
    const dispatch = useAppDispatch();

    const handleDrawerToggle = () => {
        dispatch(toggleMobileNavbar(!mobileNavbarOpened));
    };

    return (
        <AppBar
            position="fixed"
            component="nav"
            sx={ {
                backgroundColor: DARK_BASIC,
                display: { sm: "none" },
                width: { sm: `calc(100% - 20%)` },
                ml: { sm: `20%` },
                height: '55px',
                zIndex: 1300,
                boxShadow: 'none',
            } }
        >
            <Toolbar sx={ {
                display: { sm: "flex" },
                justifyContent: 'space-between',
            } }>
                <img src={ LogoWhite } alt="Logo"/>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={ handleDrawerToggle }
                    sx={ { display: { sm: "none" } } }
                >
                    <Menu/>
                </IconButton>
            </Toolbar>
        </AppBar>
    );
};
