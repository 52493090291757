import { AppDispatch } from "../../../store/store";
import { NavigateFunction } from "react-router-dom";
import { requestHandlerWrapper } from "../../../utils/request-handler-wrapper";
import { MagictapResponse } from "../../../models/magictap-response";
import { TableResponse, UpdateMenuItemsRequest } from "../../../models/api/api";
import { addMenuItem } from '../../request-services/e-menu';
import { addNewItem, clearEMenuNewItemState } from '../../../store/features/e-menu-slice';

const menuFailure = (_: MagictapResponse<unknown>, dispatch: AppDispatch) => {
    // dispatch(showNotification({ title: GENERIC_ERROR_MESSAGE, type: NotificationType.ERROR }));
    console.warn('Error inside e-menu request');
};

const menuSuccess = (response: MagictapResponse<TableResponse[]>, dispatch: AppDispatch) => {
    dispatch(addNewItem());
    dispatch(clearEMenuNewItemState());
};

export const addMenuItemRequestHandler = (addMenuItemRequest: UpdateMenuItemsRequest, dispatch: AppDispatch, navigate: NavigateFunction): void => {
    requestHandlerWrapper(
        addMenuItem,
        addMenuItemRequest,
        menuSuccess,
        menuFailure,
        dispatch,
        navigate
    );
};
