/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { css } from '@emotion/react';
import { MenuItem } from '@mui/material';
import { ALL_FILTER_PRESET } from '../../models/filters';
import { Select } from './dropdowns/select';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { changeTeamFilter } from '../../store/features/detailed-feedbacks-slice';
import { useEffect } from 'react';
import { getLightTeamListRequestHandler } from '../../services/request-handlers/team/team-list-request-handler';
import { useNavigate } from 'react-router-dom';
import { GREY_BASIC, GREY_LIGHT } from '../../common-styles/color-palette';

interface TeamSelectorProps {
    dark?: boolean;
}

export const TeamSelector = ({ dark }: TeamSelectorProps) => {
    const { allTeamsShort } = useAppSelector(state => state.teamList);
    const { teamsFilter } = useAppSelector(state => state.detailedFeedbacks);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onTeamSelect = (e: SelectChangeEvent<unknown>) => {
        dispatch(changeTeamFilter(e.target.value as string));
    };

    useEffect(() => {
        getLightTeamListRequestHandler(dispatch, navigate);
    }, []);

    return (
        <Select
            value={ teamsFilter }
            onChange={ onTeamSelect }
            css={ css({ backgroundColor: dark ? GREY_BASIC : GREY_LIGHT }) }
        >
            <MenuItem key={ 'some' } value={ ALL_FILTER_PRESET }>All teams</MenuItem>
            {
                allTeamsShort.map((x, i) =>
                    <MenuItem key={ i } value={ x.id }>{ x.name }</MenuItem>)
            }
        </Select>
    );
};