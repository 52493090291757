/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { TextField } from "@mui/material";
import { text__input, text_input__preloader } from "./text-input.styles";
import React from 'react';
import { skeleton__preloader } from '../../../common-styles/skeleton';

interface TextInputProps {
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;

    value?: string;
    defaultValue?: string;
    label?: string;
    type?: React.InputHTMLAttributes<unknown>['type'];
    required?: boolean;
    disabled?: boolean;
    hasError?: boolean;
    errorMessage?: string;
    autocomplete?: string;
    autocompleteName?: string;
    onBlur?: () => void;

    endAdornment?: React.ReactNode;

    isLoading?: boolean;
}

export const TextInput =
    ({
         hasError,
         onBlur,
         onChange,
         autocomplete,
         autocompleteName,
         type,
         required,
         disabled,
         label,
         value,
         defaultValue,
         errorMessage,
         isLoading,
     }: TextInputProps) => {
        if(isLoading) {
            return (
                <div css={ [skeleton__preloader, text_input__preloader] }/>
            );
        }

        return (
            <TextField
                css={ text__input }
                variant="filled"
                value={ value }
                defaultValue={ defaultValue }
                type={ type }
                label={ label }
                required={ required }
                disabled={ disabled }
                name={ autocompleteName }
                error={ hasError }
                onChange={ onChange }
                onBlur={ onBlur }
                helperText={ hasError ? errorMessage : undefined }
                autoComplete={ autocomplete }
                size="small"
            />
        );
    };
