/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { MenuItem } from '@mui/material';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { WHITE } from '../../../../common-styles/color-palette';
import {
    page__container,
    page__container__header_row,
    page__header_preloader,
    page__header_row
} from '../../../../common-styles/pages';
import { getTemplateRequestHandler } from '../../../../services/request-handlers/team/get-team-template-request-handler';
import { getTeamConfigurationRoute, TEAM_BASE_ROUTE } from '../../../../routing/routes';
import { selectTeam } from '../../../../store/selectors/detailed-feedbacks.selector';
import { changeBackgroundImage, changeBackgroundType, changeHeader, changeHeaderColor, changeQrBackgroundColor, changeQrPixelsColor, changeSubheader, changeSubheaderColor, setTemplateEditorLoading } from '../../../../store/features/template-editor-slice';
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { selectTeamTemplate } from '../../../../store/selectors/team-selectors';
import { BackgroundType, getBackgroundTypeDisplayName, getBackgroundTypes } from '../../../../utils/background-type-utils';
import { noop } from '../../../../utils/noop';
import { PrimaryButton } from '../../../common/buttons/primary-button';
import { RadioGroup } from '../../../common/buttons/radio-group';
import { SecondaryButton } from '../../../common/buttons/secondary-button';
import { PrimarySelect } from '../../../common/dropdowns/primary-select';
import { PageNotFound } from "../../../common/not-found-page/page-not-found";
import { FileUploader } from '../../configuration/inputs/file-uploader/file-uploader';
import { TeamTextInput } from '../../configuration/inputs/team-text-input';
import { ColorPicker } from './color-picker';
import { TemplateEditorSection } from './template-editor-section';
import { template_editor__column, template_editor__double_column_layout } from './template-editor.styles';
import { TemplatePreview } from './template-preview';

export enum QR_CODE_COLOR_SCHEME {
    TRANSPARENT = 'Transparent',
    WITH_BACKGROUND = 'With background'
}

//TODO MO PR2: Align layout according Figma
export const TemplateEditor = () => {

    const { isLoading,
        header,
        headerColor,
        subheader,
        subheaderColor,
        qrPixelsColor,
        qrBackgroundColor,
        backgroundType,
        backgroundImage
    } = useAppSelector(selectTeamTemplate)
    const {name} = useAppSelector(selectTeam)
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get('teamId');

    const onDownloadFileClick = () => {
        const input = document.getElementById("template-preview");
        html2canvas(input!!, { useCORS: true })
            .then((canvas) => {
                const imgData = canvas.toDataURL('img/png');
                const pdf = new jsPDF('p', 'mm', 'a6');
                pdf.addImage(imgData, 'PNG', 0, -1, 105, 150);
                pdf.save(`Team ${name} card.pdf`);
            })
    }

    const onCancelClick = () => navigate(`/${TEAM_BASE_ROUTE}/${getTeamConfigurationRoute(id || undefined)}`) 

    useEffect(() => {
        dispatch(setTemplateEditorLoading(true));
        if (id) {
            getTemplateRequestHandler(id, dispatch, navigate);
        }
        return () => { dispatch(setTemplateEditorLoading(true)); }
    }, [id]);

    if (!id) {
        return (
            <PageNotFound/>
        );
    }
    return (
        <div css={ page__container }>
            <div css={ page__container__header_row }>
                <h2>One team - one QR code</h2>
            </div>
            <div css={ template_editor__double_column_layout }>
                <div css={ template_editor__column }>
                    <div css={ css({ margin: '0 5.75em ', display: 'flex', flexDirection: 'column' }) }>
                        <div css={ [page__header_row, { marginBottom: '0.755em' }, isLoading ? page__header_preloader : null] }>
                            <h2>{ isLoading ? "" : "Customize the card to your taste" }</h2>
                        </div>
                        <TemplateEditorSection label="Message to customer" isLoading={ isLoading }>
                            <TeamTextInput
                                label={ "Header" }
                                value={ header }
                                onChange={ arg => dispatch(changeHeader(arg)) }
                                isLoading={ isLoading }
                            />
                            <TeamTextInput
                                label={ "Subheader" }
                                value={ subheader }
                                onChange={ arg => dispatch(changeSubheader(arg)) }
                                isLoading={ isLoading }
                            />
                        </TemplateEditorSection>
                        <TemplateEditorSection label="QR code color scheme" isLoading={ isLoading }>
                            <RadioGroup
                                isRow
                                value={ !qrBackgroundColor ? QR_CODE_COLOR_SCHEME.TRANSPARENT : QR_CODE_COLOR_SCHEME.WITH_BACKGROUND }
                                options={ [
                                    { label: "With background", value: QR_CODE_COLOR_SCHEME.WITH_BACKGROUND },
                                    { label: "Transparent", value: QR_CODE_COLOR_SCHEME.TRANSPARENT }
                                ] }
                                onRadioOptionClick={ (value) => {
                                    if (value === QR_CODE_COLOR_SCHEME.TRANSPARENT) {
                                        dispatch(changeQrBackgroundColor(undefined))
                                    } else {
                                        dispatch(changeQrBackgroundColor(WHITE))
                                    }
                                } }
                                isLoading={ isLoading }
                            />
                            <div css={ css({ display: 'flex' }) }>
                                <div css={ css({ flex: '1 1 auto' }) }>
                                    <ColorPicker
                                        label='Pixels'
                                        color={ qrPixelsColor }
                                        onChangeColor={ (newColor) => { dispatch(changeQrPixelsColor(newColor)) } }
                                        isLoading={ isLoading }
                                    />
                                </div>
                                <div css={ css({ flex: '1 1 auto' }) }>
                                    <ColorPicker
                                        label='Background'
                                        color={ qrBackgroundColor }
                                        disabled={ !qrBackgroundColor }
                                        onChangeColor={ (newColor) => { dispatch(changeQrBackgroundColor(newColor)) } }
                                        isLoading={ isLoading }
                                    />
                                </div>
                            </div>
                        </TemplateEditorSection>
                        <TemplateEditorSection label="Text color scheme" isLoading={ isLoading }>
                            <div css={ css({ display: 'flex' }) }>
                                <div css={ css({ flex: '1 1 auto' }) }>
                                    <ColorPicker
                                        label='Header'
                                        color={ headerColor }
                                        onChangeColor={ (newColor) => { dispatch(changeHeaderColor(newColor)) } }
                                        isLoading={ isLoading }
                                    />
                                </div>
                                <div css={ css({ flex: '1 1 auto' }) }>
                                    <ColorPicker
                                        label='Subheader'
                                        color={ subheaderColor }
                                        onChangeColor={ (newColor) => { dispatch(changeSubheaderColor(newColor)) } }
                                        isLoading={ isLoading }
                                    />
                                </div>
                            </div>
                        </TemplateEditorSection>
                        <PrimarySelect
                            value={ backgroundType || '' }
                            isLoading={ isLoading }
                            onChange={ (event) => { dispatch(changeBackgroundType(event.target.value as BackgroundType)) } }
                        >
                            { getBackgroundTypes().map(type =>
                                <MenuItem key={ type } value={ type }>
                                    { getBackgroundTypeDisplayName(type) }
                                </MenuItem>
                            ) }
                        </PrimarySelect>
                        <div css={ css({ height: '3.5em', padding: '0.5em 0' }) }>
                            { backgroundType === BackgroundType.OWN &&
                                <FileUploader
                                    value={ backgroundImage }
                                    onChange={ (newOwnBackgroundImage) => { dispatch(changeBackgroundImage(newOwnBackgroundImage)) } }
                                    emptyStateTitle={ 'Drag file or click here' }
                                    emptyStateDescription={ 'IMG, PNG, JPEG, JPG, 5MB MAX.' }
                                    isLoading={ isLoading }
                                />
                            }
                        </div>
                        <PrimaryButton
                            label='Download file'
                            onClick={ onDownloadFileClick }
                        />
                        <div css={ css({ marginTop: '0.5em' }) }>
                            <SecondaryButton
                                label='Cancel'
                                onClick={ onCancelClick }
                            />
                        </div>
                        <div css={ css({ marginTop: '0.5em' }) }>
                            <SecondaryButton
                                label='"Save template" will be enabled soon'
                                disabled={ true }
                                onClick={ noop }
                            />
                        </div>
                    </div>
                </div>
                <div css={ template_editor__column }>
                    <TemplatePreview/>
                </div>
            </div>
        </div>
    );
};
