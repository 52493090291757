/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import {
    top_rated_categories__category__container,
    top_rated_categories__category__footer,
    top_rated_categories__category__label__container,
    top_rated_categories__category__score,
    top_rated_categories__category__score__container,
    top_rated_categories__category__score__percent_sign,
    top_rated_categories__category__score__value,
} from "./category-score-card.styles";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { NumberOfCommentsPreloader } from "../../common/skeleton-preloaders/number-of-comments-preloader";
import { Fragment } from "react";
import { CategoryPreloader } from "../../common/skeleton-preloaders/category-preloader";
import { openDetailedFeedbacks } from '../../../store/features/detailed-feedbacks-slice';
import { Trend } from '../happiness-score/trend';
import { TextualMentionsButton } from '../happiness-score/textual-mentions-button';
import { selectNavbar } from '../../../store/selectors/navbar-selectors';
import { NavigationBarState } from '../../../store/features/navigation-bar-slice';
import { HEADERS_FONT } from '../../../common-styles/font-styles';
import { mobileDevices } from '../../../common-styles/adaptive-breakpoints';

export const CategoryScoreCard = ({ category: { data, trend } }: { category: any }) => {
    const { dashboardDataLoading } = useAppSelector(state => state.dashboard);
    const { isMobile }: NavigationBarState = useAppSelector(selectNavbar);
    const dispatch = useAppDispatch();
    const onTextualMentionsClick = () => {
        dispatch(openDetailedFeedbacks(data.label));
    };

    return (
        <div css={ top_rated_categories__category__container }>
            <div css={ top_rated_categories__category__score__container }>
                { dashboardDataLoading && <CategoryPreloader/> }
                { !dashboardDataLoading &&
                    <Fragment>
                        <div css={ top_rated_categories__category__label__container }>
                            <div css={ css({ display: 'flex', alignItems: 'center' }) }>
                                { data.category?.icon && <div>{ data.category?.icon + ' ' }</div> }
                                <div css={ css({ fontFamily: HEADERS_FONT, fontWeight: 600 }) }>{ data.label }</div>
                            </div>
                            { isMobile && (
                                <TextualMentionsButton
                                    onClick={ onTextualMentionsClick }
                                    numberOfResponses={ data.mentions }
                                />
                            ) }
                        </div>
                        <div css={ css({
                            display: 'flex',
                            flexDirection: 'column',

                            [mobileDevices]: {
                                justifyContent: 'flex-end',
                            },
                        }) }>
                            <div css={ top_rated_categories__category__score }>
                                <div css={ top_rated_categories__category__score__value }>
                                    { (data.ratingCSAT / 100).toFixed(1) }
                                </div>
                                <div css={ top_rated_categories__category__score__percent_sign }>
                                    %
                                </div>
                            </div>
                            <Trend trend={ (trend / 100) } precision={ 1 }/>
                        </div>
                    </Fragment>
                }
            </div>
            { !isMobile && (
                <div css={ top_rated_categories__category__footer }>
                    { dashboardDataLoading && <NumberOfCommentsPreloader/> }
                    { !dashboardDataLoading &&
                        <TextualMentionsButton onClick={ onTextualMentionsClick } numberOfResponses={ data.mentions }/>
                    }
                </div>
            ) }
        </div>
    );
};
