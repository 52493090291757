import {primary_select, primary_select__input} from "./primary-select.styles";
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {FormControl, InputLabel, SelectProps} from '@mui/material';
import {text__input} from '../inputs/text-input.styles';
import {Select} from './select';
import { skeleton__preloader } from "../../../common-styles/skeleton";
import { BORDER_RADIUS_SMALL } from "../../../common-styles/borders";

const PrimarySelectInput = styled(Select)(primary_select__input);


//TODO: optional error state
export const PrimarySelect = (props: SelectProps & {isLoading?: boolean}) => {
    const {isLoading, ...selectProps} = props
    if (isLoading) {
        return <div css={css([skeleton__preloader, { borderRadius: BORDER_RADIUS_SMALL, minHeight: '2.9375em'}])}/>
    }
    if (selectProps.label) {
        return <FormControl css={ [text__input, primary_select] } fullWidth>
            <InputLabel variant="filled" required={ selectProps.required }>
                { selectProps.label }
            </InputLabel>
            <PrimarySelectInput{ ...selectProps } required={ selectProps.required } />
        </FormControl>
    } else {
        return <PrimarySelectInput{ ...selectProps } required={ selectProps.required } />
    }
}
