import { styled } from "@mui/material/styles";
import { Radio as MuiRadio } from "@mui/material";
import { BLACK, GREEN_BASIC, GREY_BASIC, GREY_LIGHT } from '../../../common-styles/color-palette';

// there is a fucking 'frame' over inner content in checked state 🤬,
// but it's only visible with 200% scale on 4k screens so don't mind
export const Radio = styled(MuiRadio)(({ theme, checked }) => (
    {
        padding: 0,
        color: GREY_BASIC,
        backgroundColor: GREY_LIGHT,
        boxSizing: 'border-box',
        height: '1em',
        width: '1em',

        border: `1px solid ${ GREY_BASIC }`,

        ':hover': {
            backgroundColor: 'transparent',
            border: `1px solid ${ GREEN_BASIC }`,

            '&.Mui-checked > input + span': {
                backgroundColor: GREEN_BASIC,
                width: '0.375em',
                height: '0.375em',
            },
        },

        '&.Mui-checked': {
            color: GREEN_BASIC,
            backgroundColor: GREEN_BASIC,
            border: `1px solid ${ GREEN_BASIC }`,

            ':hover': {
                backgroundColor: 'transparent',
            },
        },
    }),
);

export const RadioIcon = styled('span')({});

export const RadioCheckedIcon = styled(RadioIcon)({
    borderRadius: '50%',
    width: '0.5em',
    height: '0.5em',
    backgroundColor: BLACK,
});
