import { css, SerializedStyles } from '@emotion/react';
import { FONT_SIZE_MOBILE_M } from '../../common-styles/font-sizes';
import { GREY_BASIC } from '../../common-styles/color-palette';

export const card_element__container: SerializedStyles = css({
    minHeight: '4.387em',
    width: '100%',
    padding: '0.5em 0',
    display: 'flex',
    gap: '1em',
    fontSize: FONT_SIZE_MOBILE_M,
    borderBottom: `1px solid ${ GREY_BASIC }`,
});
