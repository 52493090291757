/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { widget_configuration__form_container } from '../widget/widget-configuration.style';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            css={ [widget_configuration__form_container, css({ display: value !== index ? 'none' : '' })] }
            { ...other }
        >
            { value === index && (
                children
            ) }
        </div>
    );
};
