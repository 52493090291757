/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { ChangeEvent, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { flex_container__horizontal__full_width, flex_container__vertical } from '../../../common-styles/containers';
import { AccountType, SignupRequest } from '../../../models/api/api';
import {
    changeCompanyName,
    changeCountry,
    changeFirstName,
    changeLastName,
    changeNewAccountLoadingIndicator,
    matchPasswords,
} from "../../../store/features/signup-slice";
import { signup__welcome_message } from "../sign-up.styles";
import { TextInput } from "../../common/inputs/text-input";
import { SubmitButton } from "../../common/buttons/submit-button";
import { InputHelperTooltip } from "../../common/input-helper-tooltip";
import { createAccountRequestHandler } from "../../../services/request-handlers/signup/create-account-request-handler";
import { ErrorMessage } from "../../common/error-message";
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Country, getCountries, getCountryDisplayName } from '../../../utils/country-utils';
import { PrimarySelect } from '../../common/dropdowns/primary-select';
import { ChangePasswordForm } from '../../password-reset/change-password-form';

export const WizardAccountForm = () => {
    const {
        password,
        passwordRepeated,
        firstName,
        lastName,
        companyName,
        country,
        passwordsAreInMatch,
        requestIsProcessing,
        requestError,
    } = useAppSelector(state => state.signup.newAccount);
    const { sessionId } = useAppSelector(state => state.signup);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const firstNameInputHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(changeFirstName(e.target.value));
    };
    const lastNameInputHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(changeLastName(e.target.value));
    };

    const companyNameInputHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(changeCompanyName(e.target.value));
    };

    const countryInputHandler = (event: SelectChangeEvent<unknown>) => {
        dispatch(changeCountry(event.target.value as Country));
    };

    const primaryButtonEnabled = firstName.length > 0
        && lastName.length > 0
        && country !== undefined
        && password.length > 0
        && passwordRepeated.length > 0
        && passwordsAreInMatch;

    const onAccountCreationButtonClick = () => {
        if(password !== passwordRepeated) {
            dispatch(matchPasswords());
            return;
        }

        const request: SignupRequest = {
            password: btoa(password),
            firstName,
            lastName,
            country: country!!,
            // todo: fix this nullability
            sessionId: sessionId!,
            // todo: fix this default value
            workspaceName: companyName || 'Personal',
            accountType: AccountType.COMPANY,
        };
        dispatch(changeNewAccountLoadingIndicator(true));
        createAccountRequestHandler(request, dispatch, navigate);
    };

    return (
        <Fragment>
            <div
                css={ [flex_container__horizontal__full_width, flex_container__vertical, css({ marginBottom: "1em" })] }>
                <h1 css={ [signup__welcome_message, css({ marginBlock: "unset", marginBottom: "0.3em" })] }>
                    Create your account
                </h1>
            </div>
            <div css={ [flex_container__horizontal__full_width, flex_container__vertical] }>
                <div css={ css({ width: "90%", marginTop: "0.5em", display: 'flex' }) }>
                    <div css={ css({ width: '50%' }) }>
                        <TextInput
                            type={ "text" }
                            onChange={ firstNameInputHandler }
                            label={ "First name" }
                            required={ true }
                            autocompleteName={ "first-name" }
                        />
                    </div>
                    <InputHelperTooltip title={ "Visible only for you" } open={ !firstName && !lastName }>
                        <div css={ css({ marginLeft: '0.5em', width: '50%' }) }>
                            <TextInput
                                type={ "text" }
                                onChange={ lastNameInputHandler }
                                label={ "Last name" }
                                required={ true }
                                autocompleteName={ "last-name" }
                            />
                        </div>
                    </InputHelperTooltip>
                </div>
                <div css={ css({ width: "90%", marginTop: "0.5em" }) }>
                    <InputHelperTooltip
                        title={ "Leave empty if you want to set your personal account" }
                        open={ !companyName }
                    >
                        <div>
                            <TextInput
                                type={ "text" }
                                onChange={ companyNameInputHandler }
                                label={ "Company name" }
                                autocompleteName={ "company-name" }
                            />
                        </div>
                    </InputHelperTooltip>
                </div>
                <div css={ css({ width: "90%", marginTop: "0.5em" }) }>
                    <PrimarySelect
                        label="Country"
                        onChange={ countryInputHandler }
                        defaultValue={ Country.USA }
                        value={ country }
                        required={ true }
                    >
                        { getCountries()
                            .map(key => (
                                <MenuItem key={ key } value={ key }>
                                    { getCountryDisplayName(key) }
                                </MenuItem>
                            )) }
                    </PrimarySelect>
                </div>
                <ChangePasswordForm/>
                <div css={ [flex_container__horizontal__full_width] }>
                    <div css={ css({ width: "90%" }) }>
                        <ErrorMessage message={ requestError }/>
                    </div>
                </div>
            </div>
            <div css={ [flex_container__horizontal__full_width, css({ margin: "1em 0" })] }>
                <div css={ css({ width: "90%" }) }>
                    <SubmitButton
                        label={ "Get started free" }
                        onClick={ onAccountCreationButtonClick }
                        requestLoadingIndicator={ requestIsProcessing }
                        disabled={ !primaryButtonEnabled }
                    />
                </div>
            </div>
        </Fragment>
    );
};