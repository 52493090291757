/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { Fragment } from "react";
import { section__header_preloader } from './section-header.styles';
import { skeleton__preloader } from '../../../common-styles/skeleton';

export const SectionHeader = ({ isLoading, title }: { isLoading: boolean, title: string, }) => {
    return (
        <Fragment>
            { isLoading ? <div css={[skeleton__preloader, section__header_preloader]}/> : <h3>{ title }</h3> }
        </Fragment>
    );
};
