import { css } from "@emotion/react";

export const account_config_form__container = css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'auto'
})

export const account_config_form = css({
    display: 'flex',
    flexDirection: 'column',
    minWidth: '33%',
    maxWidth: '90%'
})

export const account_config__first_name = css({ flex: 'auto' })

export const account_config__names_container = css({ display: 'flex' })

export const account_config__last_name = css({
    flex: 'auto', 
    marginLeft: '0.5em'
})
