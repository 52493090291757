/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {jsx} from '@emotion/react';
import React from "react";
import {AccountFormContainer} from "./common/account-form-container";
import {WizardSignupForm} from "./wizard-steps/wizard-signup-form";
import {TermsAndConditions} from "./common/terms-and-conditions";
import WizardWelcomeImage from "../../static/character_4.svg";

export const SignUp = () => {
    return (
        <AccountFormContainer
            form={<WizardSignupForm/>}
            disclaimer={<TermsAndConditions/>}
            wizardStepImage={WizardWelcomeImage}
        />
    )
};
