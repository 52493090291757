import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";

interface AuthorisationState {
    userAuthorised: boolean;
    loginFailed: boolean;
    isLoading: boolean;
}

const initialState: AuthorisationState = {
    userAuthorised: false,
    loginFailed: false,
    isLoading: true,
}

export const authorisationSlice = createSlice({
    name: 'authorisationSlice',
    initialState,
    reducers: {
        setAuthorised: (state: Draft<AuthorisationState>, action: PayloadAction<boolean>) => {
            state.userAuthorised = action.payload;
        },
        setLoginFailed: (state: Draft<AuthorisationState>, action: PayloadAction<boolean>) => {
            state.loginFailed = action.payload;
        },
        setLoadingIndicator: (state: Draft<AuthorisationState>, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        }
    }
});

export const {
    setAuthorised,
    setLoginFailed,
    setLoadingIndicator,
} = authorisationSlice.actions;

export default authorisationSlice.reducer;
