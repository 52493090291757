/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { Fragment, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getTeamMenuRequestHandler } from '../../../services/request-handlers/team/get-menu-request-handler';
import { selectTeam } from '../../../store/selectors/detailed-feedbacks.selector';
import { team_list__create_team_button } from '../team-tables-list/team-tables-list.styles';
import { PrimaryButton } from '../../common/buttons/primary-button';
import { page } from '../../../common-styles/pages';
import { getEMenuConfigurationRoute } from '../../../routing/routes';
import { FileUploader } from '../configuration/inputs/file-uploader/file-uploader';
import {
    changeLoadingIndicator,
    changeMenuImage,
    changeMenuItem,
    changeNewItemDescription,
    changeNewItemImage,
    changeNewItemName,
    EMenuItem,
    removeItem,
} from '../../../store/features/e-menu-slice';
import { TeamTextInput } from '../configuration/inputs/team-text-input';
import { IconButton } from '../../common/buttons/icon-button';
import { Add } from '../../../icons/Add';
import { updateTeamMenuRequestHandler } from '../../../services/request-handlers/team/update-menu-request-handler';
import { addMenuItemRequestHandler } from '../../../services/request-handlers/team/add-menu-request-handler';
import { createTeamMenuRequestHandler } from '../../../services/request-handlers/team/create-menu-request-handler';
import { Delete } from '@mui/icons-material';
import { deleteMenuItemRequestHandler } from '../../../services/request-handlers/team/delete-menu-request-handler';
import { GREY_BASIC } from '../../../common-styles/color-palette';

export const EMenu = () => {
    const { id: teamId } = useAppSelector(selectTeam);
    const { isDataLoading, id: eMenuId, content, image, newItem } = useAppSelector(x => x.eMenu);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();

    const id = teamId || searchParams.get('teamId') || '';

    useEffect(() => {
        dispatch(changeLoadingIndicator(true));
        getTeamMenuRequestHandler(id, dispatch, navigate);
    }, []);

    const onCreateEMenu = () => {
        createTeamMenuRequestHandler({
            teamId: id,
            imageLink: null,
            content: [{
                name: '',
                id: null,
                imageLink: null,
                description: '',
            }],
        }, dispatch, navigate);
        navigate(getEMenuConfigurationRoute(id));
    };

    const onAddNewItem = () => {
        addMenuItemRequestHandler({
            menuId: eMenuId,
            items: [newItem],
        }, dispatch, navigate);
    };

    const onDeleteItem = (id: string) => {
        deleteMenuItemRequestHandler({
            menuId: eMenuId,
            itemId: id,
        }, dispatch, navigate);
        dispatch(removeItem(id));
    };

    const onNewItemNameChange = (value: string) => {
        dispatch(changeNewItemName(value));
    };
    const onNewItemDescriptionChange = (value: string) => {
        dispatch(changeNewItemDescription(value));
    };
    const onNewItemImageChange = (value: string | undefined) => {
        dispatch(changeNewItemImage(value));
    };

    const onItemNameChange = (item: EMenuItem, value: string) => {
        dispatch(changeMenuItem({ ...item, name: value }));
    };

    const onItemDescriptionChange = (item: EMenuItem, value: string) => {
        dispatch(changeMenuItem({ ...item, description: value }));
    };

    const onItemImageChange = (item: EMenuItem, value: string | undefined) => {
        dispatch(changeMenuItem({ ...item, imageLink: value || null }));
    };

    const onMenuImageChange = (value: string | undefined) => {
        dispatch(changeMenuImage(value));
    };

    const onEMenuSave = () => {
        updateTeamMenuRequestHandler({
            menuId: eMenuId,
            items: [
                ...content.map(x => ({ ...x, id: x.id || null, imageLink: x.imageLink || null })),
            ],
        }, dispatch, navigate);
    };

    return (
        <div css={ [page] }>
            <div css={ css({
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
            }) }>
                { eMenuId && (
                    <Fragment>
                        <div css={ css({ width: '20em', height: '15em', marginBottom: '2em' }) }>
                            <FileUploader
                                onChange={ onMenuImageChange }
                                value={ image || '' }
                                emptyStateTitle="Image"
                                emptyStateDescription="Background image"
                            />
                        </div>
                        <div css={ css({
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '0.5em',
                            overflowY: 'auto',
                            borderBottom: `1px solid ${ GREY_BASIC }`,
                        }) }>
                            { content.map((x, i) => (
                                <div
                                    css={ css({
                                        height: '4em',
                                        display: 'flex',
                                        justifyContent: 'space-around',
                                        gap: '1em',
                                        p: {
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                        },
                                    }) }
                                    key={ i }
                                >
                                    <div css={ css({ flex: 2 }) }>
                                        <TeamTextInput
                                            label="Label"
                                            autoComplete="off"
                                            value={ x.name }
                                            onChange={ (v) => onItemNameChange(x, v) }
                                        />
                                    </div>
                                    <div css={ css({ flex: 4 }) }>
                                        <TeamTextInput
                                            label="Description"
                                            autoComplete="off"
                                            value={ x.description }
                                            onChange={ (v) => onItemDescriptionChange(x, v) }
                                        />
                                    </div>
                                    <div css={ css({ flex: 1, height: '3.2em' }) }>
                                        <FileUploader
                                            onChange={ (v) => onItemImageChange(x, v) }
                                            value={ x.imageLink || '' }
                                            emptyStateTitle="Image"
                                            emptyStateDescription="Item image"
                                        />
                                    </div>
                                    <div css={ css({ height: '3.2em' }) }>
                                        <IconButton onClick={ () => onDeleteItem(x.id!!) }>
                                            <Delete/>
                                        </IconButton>
                                    </div>
                                </div>
                            )) }
                        </div>
                        <div css={ css({
                            display: 'flex',
                            marginTop: '1.5em',
                            marginBottom: '1em',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'center',

                            '& div': {
                                marginBottom: 0
                            }
                        }) }>
                            <span css={ css({
                                height: '100%',
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }) }>Add: </span>
                            <TeamTextInput
                                label="Label"
                                autoComplete="off"
                                value={ newItem.name }
                                onChange={ onNewItemNameChange }
                            />
                            <TeamTextInput
                                label="Description"
                                value={ newItem.description }
                                onChange={ onNewItemDescriptionChange }
                            />
                            <div css={ css({ width: '15em', height: '3em' }) }>
                                <FileUploader
                                    onChange={ onNewItemImageChange }
                                    value={ newItem.imageLink || '' }
                                    emptyStateTitle="Image"
                                    emptyStateDescription="Background image"
                                />
                            </div>
                            <IconButton onClick={ onAddNewItem }>
                                <Add/>
                            </IconButton>
                        </div>
                        <div css={css({width: '20em'})}>
                            <PrimaryButton label="Publish E-Menu" onClick={ onEMenuSave }/>
                        </div>
                    </Fragment>) }
                { (!eMenuId && !isDataLoading) && (
                    <div css={ team_list__create_team_button }>
                        <PrimaryButton
                            label="Create E-Menu"
                            onClick={ onCreateEMenu }
                        />
                    </div>
                ) }
                { (!eMenuId && isDataLoading) && (
                    <div css={ team_list__create_team_button }>
                        Loading...
                    </div>
                ) }
            </div>
        </div>
    );
};
