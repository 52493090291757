import { Select as SelectMui } from "@mui/material";
import styled from "@emotion/styled";
import {
    DARK_BASIC,
    GREEN_LIGHT_OPAQUE,
    GREY_LIGHT,
    RED_BASIC,
    RED_LIGHT_OPAQUE,
} from "../../../common-styles/color-palette";
import { BORDER_RADIUS_SMALL } from "../../../common-styles/borders";
import { REGULAR_FONT } from "../../../common-styles/font-styles";
import { css } from "@emotion/react";
import { mobileDevices } from '../../../common-styles/adaptive-breakpoints';

export const select__container = css({
    height: '100%',

    '& .Mui-error': {
        backgroundColor: RED_LIGHT_OPAQUE,
        color: RED_BASIC,
        '& .MuiOutlinedInput-notchedOutline': {
            border: `${RED_LIGHT_OPAQUE} 1px solid`,
            color: RED_BASIC,
        },
    },
});

export const Select = styled(SelectMui)({
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    fontFamily: REGULAR_FONT,
    color: DARK_BASIC,
    backgroundColor: GREY_LIGHT,
    borderRadius: BORDER_RADIUS_SMALL,

    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },

    '& .MuiSelect-select': {
        [mobileDevices]: {
            padding: '0.5em 1em',
        },
    },

    ':hover': {
        backgroundColor: GREEN_LIGHT_OPAQUE,
    },
});
