/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { Fragment } from 'react';
import { RatingIconChips } from '../../common/rating-icon-chips';
import { RatingTextLabel } from '../../common/rating-text-label';
import { Category, DetailedFeedbackResponse } from '../../../models/api/api';
import { FONT_SIZE_MOBILE_XXS, FONT_SIZE_MOBILE_XXXXS } from '../../../common-styles/font-sizes';
import { GREY_DARK } from '../../../common-styles/color-palette';
import dayjs from 'dayjs';
import { categories__chips_container } from './detailed-feedbacks.style';

export const DetailedFeedbackCard = ({ data: { createdAt, rating, recipient, selectedCategories, comment } }: {
    data: DetailedFeedbackResponse
}) => {
    return (
        <Fragment>
            <div css={ css({ flex: 1 }) }>
                <RatingIconChips rating={ rating }/>
            </div>
            <div css={ css({
                flex: 9,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                gap: '0.25em',
            }) }>
                <RatingTextLabel value={ rating }/>
                <div css={ css({ paddingRight: '0.5em' }) }>
                    { comment }
                </div>
                { selectedCategories && (
                    <div css={ categories__chips_container }>
                        { selectedCategories.map((y: Category, i: number) => (
                            <div
                                css={ css({
                                    fontSize: FONT_SIZE_MOBILE_XXS,
                                    color: GREY_DARK,
                                }) }
                                key={ i }
                            >
                                <p>{ `${ y.icon || '' } ${ y.label }${ i === selectedCategories.length - 1 ? '' : ',' }` }</p>
                            </div>
                        )) }
                    </div>
                ) }
                <div css={ css({
                    fontSize: FONT_SIZE_MOBILE_XXXXS,
                    color: GREY_DARK,
                    letterSpacing: '0.24em',
                }) }>
                    { dayjs(createdAt).format('HH:mm • DD MMM.') }
                </div>
            </div>
        </Fragment>
    );
};
