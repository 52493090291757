/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { HeaderPreloader } from "../skeleton-preloaders/header-preloader";
import { page__container__header_row } from "../../../common-styles/pages";

export const PageHeader = ({ isLoading, title }: { isLoading: boolean, title: string, }) => {
    return (
        <div css={ page__container__header_row }>
            {
                isLoading
                    ? <HeaderPreloader/>
                    : <h2>{ title }</h2>
            }
        </div>
    );
};
