/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useNavigate } from "react-router-dom";
import React, { ChangeEvent, Fragment } from "react";
import {
    changeTeamAddress,
    changeTeamCreationLoadingIndicator,
    changeTeamDescription,
    changeTeamName,
    finishSignup,
    teamCreationRequestFailed,
} from "../../../store/features/signup-slice";
import { CreateTeamRequest } from "../../../models/api/api";
import { wizardCreateTeamRequestHandler } from "../../../services/request-handlers/wizard-create-team-request-handler";
import { flex_container__horizontal__full_width, flex_container__vertical } from "../../../common-styles/containers";
import { signup__welcome_message } from "../sign-up.styles";
import { TextInput } from "../../common/inputs/text-input";
import { SubmitButton } from "../../common/buttons/submit-button";
import { signup_wizard__input_container } from "./wizard-team-form.styles";
import { FONT_SIZE_XL } from "../../../common-styles/font-sizes";
import { ErrorMessage } from "../../common/error-message";
import { InputHelperTooltip } from "../../common/input-helper-tooltip";

export const WizardTeamForm = () => {
    const {
        teamName,
        teamAddress,
        teamDescription,
        requestError,
        requestIsProcessing,
    } = useAppSelector(state => state.signup.newTeam);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const teamNameInputHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(changeTeamName(e.target.value));
        dispatch(teamCreationRequestFailed(''));
    };

    const teamDescriptionInputHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(changeTeamDescription(e.target.value));
        dispatch(teamCreationRequestFailed(''));
    };

    const teamLocationInputHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(changeTeamAddress(e.target.value));
        dispatch(teamCreationRequestFailed(''));
    };

    const saveButtonEnabled = teamName.length > 0;

    const onTeamCreationButtonClick = () => {
        const request: CreateTeamRequest = {
            name: teamName,
            description: teamDescription || null,
            address: teamAddress || null,
        };
        dispatch(changeTeamCreationLoadingIndicator(true));
        dispatch(finishSignup());
        wizardCreateTeamRequestHandler(request, dispatch, navigate);
    };

    return (
        <Fragment>
            <div
                css={ [flex_container__horizontal__full_width, flex_container__vertical, css({ marginBottom: "1em" })] }>
                <h1 css={ [signup__welcome_message, css({ marginBlock: "unset", marginBottom: "0.3em" })] }>
                    Add your first team
                </h1>
                <p css={ css({ marginBlock: '0.3em', fontSize: FONT_SIZE_XL, textAlign: 'center' }) }>
                    It's your business branch for your <br/> customers to leave feedbacks
                </p>
            </div>
            <div css={ [flex_container__horizontal__full_width, flex_container__vertical] }>
                <div css={ signup_wizard__input_container }>
                    <InputHelperTooltip title={ "Visible in the feedback widget" } open={ !teamName }>
                        <div>
                            <TextInput
                                type={ "text" }
                                defaultValue={ teamName }
                                onChange={ teamNameInputHandler }
                                required={ true }
                                label={ "Team name" }
                            />
                        </div>
                    </InputHelperTooltip>
                </div>
                <div css={ signup_wizard__input_container }>
                    <InputHelperTooltip
                        title={ "Use description for your internal name for your team" }
                        open={ !teamDescription }
                    >
                        <div>
                            <TextInput
                                type={ "text" }
                                onChange={ teamDescriptionInputHandler }
                                label={ "Description" }
                            />
                        </div>
                    </InputHelperTooltip>
                </div>
                <div css={ [signup_wizard__input_container, css({ height: '4em' })] }>
                    <InputHelperTooltip title={ "Visible in the feedback widget" } open={ !teamAddress }>
                        <div>
                            <TextInput
                                type={ "text" }
                                onChange={ teamLocationInputHandler }
                                label={ "Location" }
                            />
                        </div>
                    </InputHelperTooltip>
                </div>
                <div css={ [flex_container__horizontal__full_width] }>
                    <div css={ css({ width: "90%" }) }>
                        <ErrorMessage message={ requestError }/>
                    </div>
                </div>
            </div>
            <div css={ [flex_container__horizontal__full_width, css({ margin: "1em 0" })] }>
                <div css={ css({ width: "90%" }) }>
                    <SubmitButton
                        label={ "Save & generate QR code" }
                        onClick={ onTeamCreationButtonClick }
                        disabled={ !saveButtonEnabled }
                        requestLoadingIndicator={ requestIsProcessing }
                    />
                </div>
            </div>
        </Fragment>
    );
};