/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { Button } from "@mui/material";
import { GREY_LIGHT, GREY_MEDIUM } from '../../../common-styles/color-palette';
import { secondary_button } from "./secondary-button.styles";
import { ButtonPreloader } from '../skeleton-preloaders/button-preloader';

interface SecondaryButtonProps {
    label: string;
    onClick: () => void;
    disabled?: boolean;

    isLoading?: boolean;
}

const secondaryButton = (onClick: () => void, label: string, hoverBackgroundColor: string, disabled?: boolean, isLoading?: boolean) => {
    if(isLoading) {
        return (
            <ButtonPreloader/>
        );
    }

    return (
        <Button
            variant="contained"
            css={ [secondary_button(hoverBackgroundColor)] }
            disabled={ disabled }
            onClick={ onClick }
        >
            { label }
        </Button>
    );
};

// todo: extract common functional component "Button" from there
export const SecondaryButton = ({ label, onClick, disabled, isLoading }: SecondaryButtonProps) => {
    return secondaryButton(onClick, label, GREY_LIGHT, disabled, isLoading);
};

export const SecondaryButtonDark = ({ label, onClick, disabled, isLoading }: SecondaryButtonProps) => {
    return secondaryButton(onClick, label, GREY_MEDIUM, disabled, isLoading);
};
