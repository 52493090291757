import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { EMenuResponse } from '../../models/api/api';

export interface EMenuItem {
    id: string | null;
    name: string;
    description?: string;
    imageLink: string | null;
}

export interface EMenuState {
    id: string;
    teamId: string;
    image: string | null;
    content: EMenuItem[];

    newItem: EMenuItem;
    isDataLoading: boolean;
}

const initialState: EMenuState = {
    id: '',
    teamId: '',
    image: null,
    content: [],
    isDataLoading: false,
    newItem: {
        id: null,
        name: '',
        description: '',
        imageLink: '',
    },
};

export const eMenuSlice = createSlice({
    name: 'teamEMenu',
    initialState,
    reducers: {
        changeLoadingIndicator: (state: Draft<EMenuState>, action: PayloadAction<boolean>) => {
            state.isDataLoading = action.payload;
        },
        loadEMenu: (state: Draft<EMenuState>, action: PayloadAction<EMenuResponse>) => {
            const { id, content, imageLink } = action.payload;
            state.id = id;
            state.image = imageLink;
            state.content = [...content.map(x => ({ ...x, id: x.id || null, imageLink: x.imageLink || null }))];
            state.isDataLoading = false;
        },
        changeMenuImage: (state: Draft<EMenuState>, action: PayloadAction<string | undefined>) => {
            state.image = action.payload || null;
        },
        changeEMenuImage: (state: Draft<EMenuState>, action: PayloadAction<string | undefined>) => {
            state.image = action.payload || null;
        },
        changeNewItemName: (state: Draft<EMenuState>, action: PayloadAction<string>) => {
            state.newItem = { ...state.newItem, name: action.payload };
        },
        changeNewItemDescription: (state: Draft<EMenuState>, action: PayloadAction<string>) => {
            state.newItem = { ...state.newItem, description: action.payload };
        },
        changeNewItemImage: (state: Draft<EMenuState>, action: PayloadAction<string | undefined>) => {
            state.newItem = { ...state.newItem, imageLink: action.payload || null };
        },
        changeMenuItem: (state: Draft<EMenuState>, action: PayloadAction<EMenuItem>) => {
            const index: number = state.content.findIndex(x => x.id === action.payload.id);
            const newItems: EMenuItem[] = [...state.content];
            newItems[index] = action.payload;
            state.content = newItems;
        },
        addNewItem: (state: Draft<EMenuState>) => {
            const newContent = [...state.content];
            newContent.push(state.newItem);
            state.content = newContent;
        },
        clearEMenuState: (state: Draft<EMenuState>) => {
            state.id = initialState.id;
            state.image = initialState.image;
            state.teamId = initialState.teamId;
            state.newItem = { ...initialState.newItem };
            state.content = [...initialState.content];
        },
        clearEMenuNewItemState: (state: Draft<EMenuState>) => {
            state.newItem = { ...initialState.newItem };
        },
        removeItem: (state: Draft<EMenuState>, action: PayloadAction<string>) => {
            state.content = [...state.content.filter(x => x.id !== action.payload)]
        },
    },
});

export const {
    changeEMenuImage,
    changeLoadingIndicator,
    changeNewItemImage,
    changeNewItemName,
    changeNewItemDescription,
    addNewItem,
    loadEMenu,
    changeMenuImage,
    clearEMenuState,
    changeMenuItem,
    clearEMenuNewItemState,
    removeItem,
} = eMenuSlice.actions;

export default eMenuSlice.reducer;
