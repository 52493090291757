export const Account = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <g>
                <path fill="currentColor" fillRule="evenodd"
                      d="M4.995 3A1.995 1.995 0 0 0 3 4.995v14.01A1.995 1.995 0 0 0 4.995 21h14.01A1.995 1.995 0 0 0 21 19.005V4.995A1.995 1.995 0 0 0 19.005 3H4.995Zm8.344 9.734a3.5 3.5 0 1 1-2.678-6.467 3.5 3.5 0 0 1 2.678 6.467ZM17 17v-2H7v2h10Z"
                      clipRule="evenodd"/>
            </g>
        </svg>
    );
};