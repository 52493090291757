/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { Global, jsx } from '@emotion/react';
import React, { useEffect } from 'react';
import { AppBodyStyle, AppContainerStyle } from "./AppStyle";
import { Router } from "./routing/router";
import { setAuthorised } from "./store/features/authorisation-slice";
import {
    AUTHORIZATION_LS_KEY,
    SESSION_ID_LS_KEY,
    SIGNUP_EMAIL_LS_KEY,
    SIGNUP_STEP_LS_KEY,
} from "./models/localstorage-keys";
import { useLocation, useNavigate } from "react-router-dom";
import {
    LOGIN_BASE_ROUTE,
    PASSWORD_RESET_ROUTE,
    SIGN_UP_BASE_ROUTE,
    WIZARD_ACCOUNT_CREATION_ROUTE,
    WIZARD_TEAM_CREATION_ROUTE,
    WIZARD_VERIFICATION_ROUTE,
} from "./routing/routes";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { changeEmail, setSessionId } from "./store/features/signup-slice";
import { Box } from "@mui/material";
import { NavigationContainer } from "./components/navbar/navigation-container";
import { ReviewsFeed } from "./components/reviews-feed/reviews-feed";
import { sideNavigationWidthExpanded, sideNavigationWidthShrunk } from "./components/navbar/navbar.styles";
import { SignupStep } from "./models/signup-step";
import { NotificationBar } from "./components/common/notification-bar";
import { resizeScreen, selectSideNavigationItem } from "./store/features/navigation-bar-slice";
import { getNavigationMenu } from "./routing/pages";
import { MOBILE_SCREEN_BREAKPOINT_WIDTH } from './models/common-constants';
import { debounce } from 'lodash-es';

const MainArea = ({ showSidePanel }: { showSidePanel: boolean }) => {
    const { desktopNavbarOpened } = useAppSelector(state => state.navigationBar);

    const navbarWidth = desktopNavbarOpened ? sideNavigationWidthExpanded : sideNavigationWidthShrunk;
    const reviewsBarWidth = '0px';
    // todo: uncomment when Reviews Feed again will be released
    // const reviewsBarWidth = reviewsFeedBarOpened ? reviewsFeedBarWidthExpanded : reviewsFeedBarWidthShrunk;

    return (
        <Box component="main" sx={ {
            marginTop: {
                xs: showSidePanel ? "55px" : "0",
                sm: "0",
            },
            display: "flex",
            justifyContent: {
                xs: "center",
                sm: "normal",
            },
            width: {
                sm: showSidePanel ? `calc(100% - ${ navbarWidth } - ${ reviewsBarWidth })` : '100%',
                xs: '100%',
            },
            height: {
                sm: '100%',
                xs: 'unset',
            },
        } }>
            <Router/>
            <Global styles={ AppBodyStyle }/>
        </Box>
    );
};

export const App = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const isNotSignupPage = !location.pathname.includes(SIGN_UP_BASE_ROUTE);
    const isNotLoginPage = !location.pathname.includes(LOGIN_BASE_ROUTE);
    const isNotPasswordResetPage = !location.pathname.includes(PASSWORD_RESET_ROUTE);

    const checkAuthorization = () => {
        const authToken = localStorage.getItem(AUTHORIZATION_LS_KEY);
        if(authToken) {
            dispatch(setAuthorised(true));
        } else if(isNotSignupPage && isNotPasswordResetPage) {
            console.log('User is not authorized. Navigate to login...');
            navigate(LOGIN_BASE_ROUTE);
        }
    };

    const checkSignUpProcess = () => {
        const sessionId = localStorage.getItem(SESSION_ID_LS_KEY);
        if(sessionId) {
            dispatch(setSessionId(sessionId));
        }
        const signupStep = localStorage.getItem(SIGNUP_STEP_LS_KEY);
        switch(signupStep) {
            case SignupStep.VERIFICATION: {
                const email = localStorage.getItem(SIGNUP_EMAIL_LS_KEY);
                dispatch(changeEmail(email!));
                navigate(WIZARD_VERIFICATION_ROUTE);
                break;
            }
            case SignupStep.ACCOUNT: {
                navigate(WIZARD_ACCOUNT_CREATION_ROUTE);
                break;
            }
            case SignupStep.TEAM: {
                navigate(WIZARD_TEAM_CREATION_ROUTE);
                break;
            }
        }
    };

    const setNavigationState = () => {
        const urlRoute = location.pathname.slice(1);
        const currentPage = getNavigationMenu().find(x => urlRoute.startsWith(x.route));
        if(currentPage) {
            dispatch(selectSideNavigationItem(currentPage.name));
        }
    };

    useEffect(() => {
        checkAuthorization();
        checkSignUpProcess();
    }, []);

    useEffect(() => {
        setNavigationState();
    }, [location]);

    useEffect(() => {
        const resizeListener: () => void = debounce(() => {
            const isMobile: boolean = window.innerWidth <= MOBILE_SCREEN_BREAKPOINT_WIDTH;
            dispatch(resizeScreen(isMobile));
        }, 250);

        resizeListener();

        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, []);

    const showSideNavigation = isNotSignupPage && isNotLoginPage && isNotPasswordResetPage;

    return (
        <div css={ AppContainerStyle }>
            <Box sx={ { display: 'flex', height: '100%', width: '100%' } }>
                <NavigationContainer showSideNavbar={ showSideNavigation }/>
                <ReviewsFeed showReviewsFeedPanel={ false }/>
                <MainArea showSidePanel={ showSideNavigation }/>
                <NotificationBar/>
            </Box>
        </div>
    );
};
