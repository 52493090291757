import { AppDispatch } from "../../../store/store";
import { NavigateFunction } from "react-router-dom";
import { requestHandlerWrapper } from "../../../utils/request-handler-wrapper";
import { MagictapResponse } from "../../../models/magictap-response";
import { DeleteMenuItemRequest, TableResponse } from "../../../models/api/api";
import { deleteMenuItem } from '../../request-services/e-menu';
import { NotificationType, showNotification } from '../../../store/features/notification-bar-slice';
import { GENERIC_ERROR_MESSAGE } from '../../../models/common-constants';

const menuFailure = (_: MagictapResponse<unknown>, dispatch: AppDispatch) => {
    dispatch(showNotification({ title: GENERIC_ERROR_MESSAGE, type: NotificationType.ERROR }));
};

const menuSuccess = (response: MagictapResponse<TableResponse[]>, dispatch: AppDispatch) => {
    if(response.content) {
        console.warn('Deleted');
    } else {
        console.warn('Failed to remove');
    }
};

export const deleteMenuItemRequestHandler = (addMenuItemRequest: DeleteMenuItemRequest, dispatch: AppDispatch, navigate: NavigateFunction): void => {
    requestHandlerWrapper(
        deleteMenuItem,
        addMenuItemRequest,
        menuSuccess,
        menuFailure,
        dispatch,
        navigate
    );
};
