/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { useAppSelector } from "../../../store/hooks";
import { happiness_score__container } from "./happiness-score-card.styles";
import { Fragment } from "react";
import { BlockHeader } from "../../common/headers/block-header";
import { selectNavbar } from '../../../store/selectors/navbar-selectors';
import { SingleBarChart } from './single-bar-chart';
import { OverallFeedbacksButton } from './overall-feedbacks-button';
import { HappinessScoreGaugeBlock } from './happiness-score-gauge-block';
import { mobileDevices } from '../../../common-styles/adaptive-breakpoints';

export const HappinessScoreCard = () => {
    const {
        happinessScoreIsLoading,
        happinessScore,
    } = useAppSelector(state => state.happinessScore);
    const { isMobile } = useAppSelector(selectNavbar);

    return (
        <div css={ happiness_score__container }>
            <Fragment>
                <div css={ css({ [mobileDevices]: { display: 'flex', justifyContent: 'space-between' } }) }>
                    <BlockHeader isLoading={ happinessScoreIsLoading } title="Happiness Score"/>
                    { isMobile && <h2>{ (happinessScore / 100).toFixed(1) }</h2> }
                </div>
                { !isMobile && <HappinessScoreGaugeBlock happinessScoreIsLoading={ happinessScoreIsLoading }/> }
                <OverallFeedbacksButton/>
                { isMobile && <SingleBarChart percent={ ((happinessScore / 100) / 5) * 100 }/> }
            </Fragment>
        </div>
    );
};
