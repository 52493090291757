import {css} from "@emotion/react";
import {FONT_SIZE_XXXL} from "../../../common-styles/font-sizes";
import { mobileDevices } from '../../../common-styles/adaptive-breakpoints';

export const block__header = css({
    padding: '1em 0 0 0.5em',
    fontSize: FONT_SIZE_XXXL,
    fontWeight: '700',

    [mobileDevices]: {
        padding: 'unset',
    }
});
