import {css} from "@emotion/react";
import {
    DARK_BASIC,
    GREEN_BASIC,
    GREEN_BASIC_OPAQUE,
    GREEN_LIGHT,
    GREY_BASIC,
    GREY_DARK,
    GREY_LIGHT,
    GREY_MEDIUM,
    WHITE
} from "../../../common-styles/color-palette";
import {BORDER_RADIUS_MEDIUM, BORDER_RADIUS_SMALL} from "../../../common-styles/borders";
import {ModifiersStyles} from "react-day-picker";

export const date_picker__input__container = css({
    height: '100%',
    maxHeight: '48px',
    boxSizing: 'border-box',
    border: `solid 1px ${GREY_BASIC}`,
    borderRadius: BORDER_RADIUS_SMALL,
    display: 'flex',
    justifyContent: 'end',

    ':hover': {
        borderColor: GREEN_BASIC,

        '& button': {
            backgroundColor: GREEN_LIGHT,
        }
    },
});

export const date_picker__input_field = css({
    height: '100%',
    width: '70%',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
});

export const date_picker__calendar_button = css({
    height: '100%',
    width: '100%',
    border: 'none',
    color: DARK_BASIC,
    borderRadius: `0 ${BORDER_RADIUS_SMALL} ${BORDER_RADIUS_SMALL} 0`,
    backgroundColor: GREY_BASIC,
    ':hover': {
        backgroundColor: GREEN_LIGHT,
    }
});

export const date_picker__calendar = css({
    backgroundColor: WHITE,
    borderRadius: BORDER_RADIUS_MEDIUM,
    width: '22em',
    padding: '1em',
    boxShadow: `rgb(0 0 0 / 15%) 0rem 1.25rem 1.6875rem 0rem`,

    '--rdp-accent-color': GREEN_BASIC_OPAQUE,
    '--rdp-background-color': GREEN_BASIC_OPAQUE,
    /* Switch to dark colors for dark themes */
    '--rdp-accent-color-dark': GREEN_BASIC_OPAQUE,
    '--rdp-background-color-dark': WHITE,
    /* Outline border for focused elements */
    '--rdp-outline': '0px',
    /* Outline border for focused and selected elements */
    '--rdp-outline-selected': '0px',
    '--rdp-cell-size': '48px',

    '& .rdp-month': {
        width: '100%',
    },
    '& .rdp-table': {
        color: GREY_DARK,
        width: '100%',
        maxWidth: '100%',
        borderSpacing: '0 0.25em',
        borderCollapse: 'separate',

        '& .rdp-row': {
            marginBottom: '1em',
            height: '3em',
        },
        '& .rdp-day': {
            backgroundColor: GREY_LIGHT,
            borderRadius: BORDER_RADIUS_SMALL,
        },

        '& .rdp-day_selected': {
            // backgroundColor: GREEN_BASIC_OPAQUE,
            backgroundColor: 'unset',
            color: DARK_BASIC,
            borderRadius: BORDER_RADIUS_SMALL,
        },

        '& .rdp-cell:has(.rdp-day_range_end)': {
            borderRight: `1px solid ${WHITE}`,
            borderTopRightRadius: BORDER_RADIUS_SMALL,
            borderBottomRightRadius: BORDER_RADIUS_SMALL,
        },

        '& .rdp-cell:has(.rdp-day_range_start)': {
            borderLeft: `1px solid ${WHITE}`,
            borderTopLeftRadius: BORDER_RADIUS_SMALL,
            borderBottomLeftRadius: BORDER_RADIUS_SMALL,
        },

        '& .rdp-day_outside': {
            color: GREY_MEDIUM,
        },

        '& .rdp-cell:has(.rdp-day_selected)': {
            backgroundColor: GREEN_BASIC_OPAQUE,
        },
    },

    '& .rdp-day_range_end.rdp-day_range_start': {
        borderRadius: BORDER_RADIUS_SMALL,
    },
});

export const date_picker__calendar__container = css({
    zIndex: 999,
});

export const day_picker__modifier_styles: ModifiersStyles = {
    today: {
        color: DARK_BASIC,
        fontWeight: 700,
    },
    selected: {
        color: DARK_BASIC,
    },
    range_end: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,

        borderTopRightRadius: BORDER_RADIUS_SMALL,
        borderBottomRightRadius: BORDER_RADIUS_SMALL,
    },
    range_start: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,

        borderTopLeftRadius: BORDER_RADIUS_SMALL,
        borderBottomLeftRadius: BORDER_RADIUS_SMALL,
    }
};
