import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { TableResponse } from "../../models/api/api";
import { Table } from '../../models/team';

export interface TeamTablesListState {
    allTables: Table[];
    isDataLoading: boolean;
}

const initialState: TeamTablesListState = {
    // allTables: [],
    // todo: change to default empty data!
    allTables: [
        {
            id: 'some',
            label: 'First table',
        },
        {
            id: '12313413123',
            label: '2 table',
        },
        {
            id: '333333',
            label: 'Third table',
        },
    ],
    isDataLoading: false,
};

export const teamTablesListSlice = createSlice({
    name: 'teamTablesList',
    initialState,
    reducers: {
        loadAllTables: (state: Draft<TeamTablesListState>, action: PayloadAction<TableResponse[]>) => {
            state.allTables = action.payload
                .map(x => (
                    {
                        id: x.id,
                        label: x.label,
                    }
                ));
        },
    },
});

export const {
    loadAllTables,
} = teamTablesListSlice.actions;

export default teamTablesListSlice.reducer;
