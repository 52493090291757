import { AppDispatch } from "../../../store/store";
import { NavigateFunction } from "react-router-dom";
import { requestHandlerWrapper } from "../../../utils/request-handler-wrapper";
import { MagictapResponse } from "../../../models/magictap-response";
import { EMenuResponse } from "../../../models/api/api";
import { getEMenu } from '../../request-services/e-menu';
import { changeLoadingIndicator, clearEMenuState, loadEMenu } from '../../../store/features/e-menu-slice';

const menuFailure = (_: MagictapResponse<unknown>, dispatch: AppDispatch) => {
    console.warn('Error inside e-menu request');
    dispatch(changeLoadingIndicator(false));
    dispatch(clearEMenuState());
};

const menuSuccess = (response: MagictapResponse<EMenuResponse>, dispatch: AppDispatch) => {
    dispatch(clearEMenuState());
    dispatch(loadEMenu(response.content!!));
};

export const getTeamMenuRequestHandler = (teamId: string, dispatch: AppDispatch, navigate: NavigateFunction): void => {
    requestHandlerWrapper(
        getEMenu,
        teamId,
        menuSuccess,
        menuFailure,
        dispatch,
        navigate
    );
};
