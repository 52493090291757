import { css } from "@emotion/react";
import { FONT_SIZE_M } from "../../../common-styles/font-sizes";
import { DARK_BASIC } from "../../../common-styles/color-palette";
import { mobileDevices } from '../../../common-styles/adaptive-breakpoints';

export const categories__chips_container = css({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    gap: '0.25em',
});

export const category__chips = css({
    color: DARK_BASIC,
    fontSize: FONT_SIZE_M,
    margin: '0.25em 0.25em',
    height: '2em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

export const detailed_feedbacks__page_container = css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

export const detailed_feedbacks__inner_container = css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '85%',
    height: '100%',
    gap: '0.5em',

    [mobileDevices]: {
        width: '100%',
    },
});

export const detailed_feedbacks__header = css({
    display: 'flex',
    width: '100%',
    flexDirection: 'row',

    [mobileDevices]: {
        flexDirection: 'column',
        padding: 'unset',
        gap: '1em',
    },
});
