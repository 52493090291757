
import {css} from "@emotion/react";
import {GREY_BASIC, GREY_LIGHT, GREEN_BASIC} from "../../../common-styles/color-palette";
import {FONT_SIZE_M} from "../../../common-styles/font-sizes";

export const primary_select__input = {
    border: `1px solid ${GREY_BASIC}`,
    fontSize: FONT_SIZE_M,
    fontWeight: 400,
    minHeight: '3.37em',
    '& .MuiSvgIcon-root': {
        right: '0.8125em'
    },
    ':hover': {
        backgroundColor: GREY_LIGHT,
        border: `1px solid ${GREEN_BASIC}`
    },
};

export const primary_select = css({
    '& .MuiInputLabel-root': {
        top: '-2px',
        left: '1px',
        '&[data-shrink="true"]': {
            top: '-3px'
        }
    },
    '& .MuiSelect-select': {
        paddingLeft: '0.8571em',
        paddingBottom: '0.0714em',
    }
});