import {AUTHORIZATION_LS_KEY} from "../../models/localstorage-keys";
import {API_PATH} from "../request-path-constants";
import {CreateTeamRequest, ShortTeamInfo, TeamResponse, UpdateTeamRequest} from "../../models/api/api";
import {apiHost} from "../api-host-resolver";
import omit from "lodash-es/omit";
import { MagictapResponse } from "../../models/magictap-response";

export const createTeam = async (createTeamRequest: CreateTeamRequest) => {
    const authUserToken = localStorage.getItem(AUTHORIZATION_LS_KEY) || '';
    return await fetch(`${apiHost}${API_PATH}/teams`, {
        method: 'POST',
        body: JSON.stringify(createTeamRequest),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'auth_user_session': authUserToken,
        }
    })
        .then(resp => resp.json().then(x => x))
        .catch(e => console.log(e));
}

export const getAllTeams = async () => {
    const authUserToken = localStorage.getItem(AUTHORIZATION_LS_KEY) || '';
    return await fetch(`${apiHost}${API_PATH}/teams`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'auth_user_session': authUserToken,
        }
    })
        .then(resp => resp.json().then(x => x))
        .catch(e => console.log(e));
}

export const getAllTeamsLight: () => Promise<MagictapResponse<ShortTeamInfo[]>> = async () => {
    const authUserToken = localStorage.getItem(AUTHORIZATION_LS_KEY) || '';
    return await fetch(`${apiHost}${API_PATH}/teams?fields=name`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'auth_user_session': authUserToken,
        }
    })
        .then(resp => resp.json().then(x => x))
        .catch(e => console.log(e));
}

export const getTeamData : (teamId: string) => Promise<MagictapResponse<TeamResponse>> = async (teamId: string) => {
    const authUserToken = localStorage.getItem(AUTHORIZATION_LS_KEY) || '';
    return await fetch(`${apiHost}${API_PATH}/teams/${teamId}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'auth_user_session': authUserToken,
        }
    })
        .then(resp => resp.json().then(x => x))
        .catch(e => console.log(e));
};

export const updateTeamData: (request: UpdateTeamRequest) => Promise<any> = async (updateTeamRequest: UpdateTeamRequest) => {
    const authUserToken = localStorage.getItem(AUTHORIZATION_LS_KEY) || '';
    return await fetch(`${apiHost}${API_PATH}/teams/${updateTeamRequest.teamId}`, {
        method: 'POST',
        body: JSON.stringify(omit(updateTeamRequest, 'teamId')),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'auth_user_session': authUserToken,
        },
    })
        .then(resp => resp.json().then(x => x))
        .catch(e => console.log(e));
};
