import {RegistrationRequest, SignupRequest, VerificationRequest} from '../../models/api/api';
import {API_PATH, SIGNUP_PATH} from '../request-path-constants';
import {apiHost} from '../api-host-resolver';
import {AUTHORIZATION_LS_KEY} from '../../models/localstorage-keys';
import {MagictapResponse} from '../../models/magictap-response';

export const register: (signUpRequest: RegistrationRequest) => Promise<MagictapResponse<null>> = async (signUpRequest: RegistrationRequest) => {
    return await fetch(`${apiHost}${API_PATH}/${SIGNUP_PATH}/register`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(signUpRequest),
    })
        .then(resp => resp.json().then(x => x))
        .catch(e => console.error('Unexpected error during /register request: ' + e));
}

export const signup = async (wizardRequest: SignupRequest) => {
    return await fetch(`${apiHost}${API_PATH}/${SIGNUP_PATH}`, {
        method: 'POST',
        body: JSON.stringify(wizardRequest),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    })
        .then(resp => {
            const authUserSession = resp.headers.get('auth_user_session');
            if (authUserSession) {
                localStorage.setItem(AUTHORIZATION_LS_KEY, authUserSession);
            }
            return resp.json().then(x => x)
        })
        .catch(e => console.log(e));
}

export const verify = async (verifyRequest: VerificationRequest) => {
    return await fetch(`${apiHost}${API_PATH}/${SIGNUP_PATH}/verify`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(verifyRequest),
    })
        .then(resp => resp.json().then(x => x))
        .catch(e => console.log(e));
}
