/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { ReactNode } from 'react';
import { card_element__container } from './card-element.styles';

interface CardElementProps {
    element: ReactNode;
    onElementCLicked?: (row: any) => void;
}

export const CardElement = ({ onElementCLicked, element }: CardElementProps) => {
    return (
        <div
            css={ card_element__container }
            onClick={ onElementCLicked }
        >
            { element }
        </div>
    );
};
