import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";

interface PasswordResetState {
    hash: string;
}

const initialState: PasswordResetState = {
    hash: '',
};

export const passwordResetSlice = createSlice({
    name: 'passwordReset',
    initialState,
    reducers: {
        changeHash: (state: Draft<PasswordResetState>, action: PayloadAction<string>) => {
            state.hash = action.payload;
        },
    }
});

export const {
    changeHash,
} = passwordResetSlice.actions;

export default passwordResetSlice.reducer;
