import { css } from '@emotion/react';
import { BORDER_RADIUS_SMALL } from '../../../../../common-styles/borders';
import {
    GREY_DARK,
    WHITE
} from '../../../../../common-styles/color-palette';

export const file_uploader = css({
    height: '100%',
    minHeight: '2em',
});

export const file_uploader__frame = css({
    overflow: 'hidden',
    height: '100%',
    borderRadius: BORDER_RADIUS_SMALL,
    border: `1px dashed ${GREY_DARK}`,
    backgroundColor: WHITE,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
})
