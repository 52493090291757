/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import html2canvas from 'html2canvas';
import jsPDF from "jspdf";
import QRCodeSVG from "qrcode.react";
import { Fragment, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { WHITE } from '../../common-styles/color-palette';
import { skeleton__preloader } from '../../common-styles/skeleton';
import { Copy } from '../../icons/Copy';
import { getTemplateEditorRoute } from '../../routing/routes';
import PaperGirl from "../../static/girl_paper.svg";
import { NotificationType, showNotification } from '../../store/features/notification-bar-slice';
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getFeedbackLink } from "../../utils/feedback-utils";
import { PrimaryButton } from '../common/buttons/primary-button';
import { SecondaryButtonDark } from "../common/buttons/secondary-button";
import { SimpleIconButton } from '../common/buttons/simple-icon-button';
import {
    feedback_link,
    feedback_link__container,
    feedback_link_disabled,
    qr_code__container,
    qr_code__page__container,
    qr_code__page__header,
} from './qr-code-configuration.styles';
import { InputHelperTooltip } from '../common/input-helper-tooltip';
import { toggleHighlightQrCode } from '../../store/features/signup-slice';
import { NEW_ID } from '../../models/common-constants';

const printableQrCodeContainerId = "qr_code__container";

const onDownloadQRClick = (teamName: string) => {
    const input = document.getElementById(printableQrCodeContainerId);
    html2canvas(input!!)
        .then((canvas) => {
            const imgWidth = 150;
            const imgHeight = canvas.height * imgWidth / canvas.width;
            const imgData = canvas.toDataURL('img/png');
            const pdf = new jsPDF('l', 'mm', 'a5');
            pdf.addImage(imgData, 'PNG', 35, 0, imgWidth, imgHeight);
            pdf.save(`${ teamName } QR code.pdf`);
        })
    ;
};

interface QrCodeConfigurationProps {
    id: string;
    name: string;
    isDataLoading: boolean;
    qrHash: string;
}

export const QrCodeConfiguration = ({ id, isDataLoading, name, qrHash }: QrCodeConfigurationProps) => {
    const { highlightQrCode } = useAppSelector(state => state.signup);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isNewTeam = !id || id === NEW_ID;

    const onCopyLinkClick = () => {
        navigator.clipboard.writeText(getFeedbackLink(qrHash));
        dispatch(showNotification({
            title: `Feedback link has been copied to the clipboard!`,
            type: NotificationType.SUCCESS,
        }));
    };

    useEffect(() => {
        setTimeout(() => dispatch(toggleHighlightQrCode(false)), 10000);
    }, []);

    const onEditTheTemplateClick = () => {
        navigate(getTemplateEditorRoute(id))
    }

    const conditionalLoader = isDataLoading ? skeleton__preloader : null;
    return (
        <div css={ qr_code__page__container }>
            <div css={ [qr_code__page__header, conditionalLoader] }>
                { !isDataLoading &&
                    <Fragment>
                        <h3>{ isNewTeam ? "Save info and your QR will be here" : "Test your feedback flow" }</h3>
                        { !isNewTeam && (<p>Scan QR with your phone or open the link below</p>) }
                    </Fragment>
                }
            </div>
            <InputHelperTooltip title="Try to scan it!" open={ highlightQrCode } placement="left">
                <div css={ [qr_code__container(WHITE), conditionalLoader] } id={ printableQrCodeContainerId }>
                    { !isNewTeam && !isDataLoading && (
                        <QRCodeSVG
                            value={ getFeedbackLink(qrHash) }
                            style={ { width: '11em', height: '11em' } }
                            level={ "H" }
                            renderAs={ "svg" }
                        />
                    ) }
                </div>
            </InputHelperTooltip>
            <div css={ [feedback_link__container, conditionalLoader] }>
                { !isDataLoading && (
                    <Fragment>
                        <p css={ [feedback_link, isNewTeam ? feedback_link_disabled : null] }>
                            { isNewTeam ? "Link will be here" : getFeedbackLink(qrHash) }
                        </p>
                        <SimpleIconButton
                            disabled={ isNewTeam }
                            css={ css({ paddingRight: 'unset' }) }
                            onClick={ onCopyLinkClick }>
                            <Copy/>
                        </SimpleIconButton>
                    </Fragment>
                ) }
            </div>
            <div css={ css({ width: '100%', height: '13em', marginLeft: '3em' }) }>
                { !isNewTeam && !isDataLoading &&
                    <img css={ css({ height: '100%' }) } src={ PaperGirl } alt={ "Demonstration" }/> }
            </div>
            <div css={css({ marginTop: '2em', width: '100%' })}>
                <div css={ css({ display: 'flex', padding: '0 5em', flexDirection: 'column'})}>
                    <div css={ css({ marginBottom: '0.5em' }) }>
                        <PrimaryButton
                            label={ "Edit the template" }
                            onClick={ onEditTheTemplateClick }
                        />
                    </div>
                    <SecondaryButtonDark
                        label="Download QR"
                        onClick={ () => {
                            onDownloadQRClick(name);
                        } }
                        disabled={ isNewTeam || isDataLoading }
                    />
                </div>
            </div>
        </div>
    );
};
