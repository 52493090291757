/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {jsx} from '@emotion/react';
import React from "react";
import {AccountFormContainer} from "../sign-up/common/account-form-container";
import LoginGirl from "../../static/character_3.svg";
import {LoginForm} from "./login-form";

export const Login = () => {
    return (
        <AccountFormContainer form={<LoginForm/>} wizardStepImage={LoginGirl}/>
    );
};
