/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {jsx} from '@emotion/react';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {page, page__container, page__container__header_row, page__header_row} from '../../common-styles/pages';
import {getAccountRequestHandler} from '../../services/request-handlers/account/get-account-config-request-handler';
import {updateAccountRequestHandler} from '../../services/request-handlers/account/update-account-config-request-handler';
import {PASSWORD_RESET_ROUTE} from '../../routing/routes';
import {
    setAccountConfigEdited,
    setAccountConfigLoading,
    setCompanyName,
    setFirstName,
    setLastName,
    setPhoneNumber
} from '../../store/features/account-config-slice';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {getCountryDisplayName} from '../../utils/country-utils';
import {mapPhoneNumberErrorToHelperMessage} from '../../utils/phone-number-utils';
import {PrimaryButton} from '../common/buttons/primary-button';
import {SecondaryButton} from '../common/buttons/secondary-button';
import {TeamTextInput} from '../team/configuration/inputs/team-text-input';
import {
    team_configuration_form__item_button,
    team_configuration_form__item_group
} from '../team/configuration/team-configuration-form.styles';
import {
    account_config_form,
    account_config_form__container, 
    account_config__first_name,
    account_config__last_name,
    account_config__names_container
} from './account-config.styles';

export const AccountConfig = () => {
    const {
        isLoading,
        firstName,
        lastName,
        email,
        companyName,
        phoneNumber,
        timezone,
        country,
        isEdited,
        phoneNumberError,
    } = useAppSelector((state) => state.accountConfig);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setAccountConfigLoading(true));
        getAccountRequestHandler(dispatch, navigate);
    }, []);

    const onFirstNameChange = (firstName: string) => {
        dispatch(setFirstName(firstName));
    };

    const onLastNameChange = (lastName: string) => {
        dispatch(setLastName(lastName));
    };

    const onCompanyNameChange = (companyName: string) => {
        dispatch(setCompanyName(companyName));
    };

    const onPhoneNumberChange = (phoneNumber: string) => {
        dispatch(setPhoneNumber(phoneNumber));
    };

    // const onTimezoneChange = (timezone: string) => {
    //     dispatch(setTimezone(timezone))
    // }

    const onSaveHandler = () => {
        dispatch(setAccountConfigEdited(false));
        //TODO: remove read-only email and country from payload at both: BE & FE
        updateAccountRequestHandler(
            {
                firstName,
                lastName,
                email,
                companyName,
                country,
                phoneNumber: phoneNumber || null,
                timeZone: timezone || null,
            },
            dispatch,
            navigate
        );
    };

    const onResetPasswordHandler = () => {
        dispatch(setAccountConfigEdited(false));
        navigate('/' + PASSWORD_RESET_ROUTE);
    };

    return (
        <div css={page__container}>
            <div css={page__container__header_row}>
                <h2>Forgot something?</h2>
            </div>
            <div css={page}>
                <div css={account_config_form__container}>
                    <div css={account_config_form}>
                        <div css={page__header_row}>
                            <h2>Personal data</h2>
                        </div>
                        <div css={account_config__names_container}>
                            <div css={account_config__first_name}>
                                <TeamTextInput
                                    label="First Name"
                                    onChange={onFirstNameChange}
                                    value={firstName}
                                    required={true}
                                    isLoading={isLoading}
                                />
                            </div>
                            <div css={account_config__last_name}>
                                <TeamTextInput
                                    label="Last Name"
                                    onChange={onLastNameChange}
                                    value={lastName}
                                    required={true}
                                    isLoading={isLoading}
                                />
                            </div>
                        </div>
                        <TeamTextInput
                            label="Company name"
                            onChange={onCompanyNameChange}
                            value={companyName}
                            required={true}
                            isLoading={isLoading}
                        />
                        <TeamTextInput
                            label="Email"
                            value={email}
                            isLoading={isLoading}
                            disabled={true}
                        />
                        {/* TODO: add code and masking depending on country*/}
                        <TeamTextInput
                            required={false}
                            label="Phone number"
                            placeholder="+X..."
                            onChange={onPhoneNumberChange}
                            helperMessage={mapPhoneNumberErrorToHelperMessage(phoneNumberError)}
                            value={phoneNumber}
                            isLoading={isLoading}
                        />
                        <TeamTextInput
                            label="Country"
                            value={getCountryDisplayName(country.toString())}
                            isLoading={isLoading}
                            disabled={true}
                        />
                        <div css={team_configuration_form__item_group}>
                            <div css={team_configuration_form__item_button}>
                                <PrimaryButton
                                    label={"Update data"}
                                    disabled={
                                        !isEdited
                                        || phoneNumberError !== undefined
                                        || !firstName
                                        || !lastName
                                        || !companyName
                                    }
                                    onClick={onSaveHandler}
                                />
                            </div>
                            <div css={team_configuration_form__item_button}>
                                <SecondaryButton
                                    label={"Reset password"}
                                    onClick={onResetPasswordHandler}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
