/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import Rocket from "../../../static/launch.svg";
import React from "react";
import { AccountFormContainer } from "../common/account-form-container";
import { WizardAccountForm } from "./wizard-account-form";
import { flex_container__horizontal } from "../../../common-styles/containers";
import { signup__terms_of_usage_disclaimer } from "../sign-up.styles";
import { SignupResetLink } from '../../common/signup-reset-link';

const WizardSignUpDisclaimer = () => {
    return (
        <div css={ [flex_container__horizontal, signup__terms_of_usage_disclaimer] }>
            <p>If you have any problems follow the <SignupResetLink/> to reset your sign
                up process
            </p>
        </div>
    );
};

export const WizardAccountStep = () => {
    return (
        <AccountFormContainer form={ <WizardAccountForm/> } disclaimer={ <WizardSignUpDisclaimer/> }
                              wizardStepImage={ Rocket }/>
    );
};