import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";
import {emailRegexp} from "../../utils/email-utils";

interface LoginState {
    password: string;
    email: string;
    emailIsValid: boolean;

    passwordVisible: boolean;
    requestIsProcessing: boolean;
    requestError: string;
}

const initialState: LoginState = {
    email: '',
    emailIsValid: true,
    password: '',
    passwordVisible: false,
    requestError: '',
    requestIsProcessing: false,
};

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        changeEmail: (state: LoginState, action) => {
            state.email = action.payload;
            state.requestError = '';
            state.emailIsValid = true;
        },
        validateEmail: (state: LoginState) => {
            if(!state.email) {
                return;
            }
            state.emailIsValid = emailRegexp.test(state.email);
        },
        changePassword: (state: LoginState, action: PayloadAction<string>) => {
            state.password = action.payload;
            state.requestError = '';
        },

        changeRequestIsProcessing: (state: Draft<LoginState>, action: PayloadAction<boolean>) => {
            state.requestIsProcessing = action.payload;
        },
        changeRequestError: (state: Draft<LoginState>, action: PayloadAction<string>) => {
            state.requestError = action.payload;
        },
        changePasswordVisibility: (state: Draft<LoginState>, action: PayloadAction<boolean>) => {
            state.passwordVisible = action.payload;
        },
    }
});

export const {
    changeEmail,
    validateEmail,
    changePassword,
    changeRequestIsProcessing,
    changeRequestError,
    changePasswordVisibility,
} = loginSlice.actions;

export default loginSlice.reducer;
