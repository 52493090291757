import { configureStore } from "@reduxjs/toolkit";
import happinessScoreReducer from "./features/happiness-score-slice";
import scorePeriodReducer from "./features/score-period-slice";
import dashboardReducer from "./features/dashboard-slice";
import signupReducer from "./features/signup-slice";
import teamConfigReducer from "./features/team-config-slice";
import teamTableReducer from "./features/team-table-slice";
import teamTablesListReducer from "./features/team-tables-list-slice";
import teamListReducer from "./features/team-list-slice";
import workspaceConfigReducer from "./features/workspace-config-slice";
import widgetPreviewReducer from "./features/widget-preview-slice";
import authorisationReducer from "./features/authorisation-slice";
import loginReducer from "./features/login-slice";
import navigationBarReducer from "./features/navigation-bar-slice";
import notificationBarReducer from "./features/notification-bar-slice";
import passwordResetReducer from "./features/password-reset-slice";
import accountConfigReducer from "./features/account-config-slice";
import detailedFeedbacksReducer from './features/detailed-feedbacks-slice';
import navigationReducer from './features/navigation.slice';
import templateEditorReducer from './features/template-editor-slice';
import eMenuReducer from './features/e-menu-slice';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from '../saga/root-saga';

const sagaMiddleware: any = createSagaMiddleware();

export const store = configureStore({
    reducer: {
        navigation: navigationReducer,
        happinessScore: happinessScoreReducer,
        scorePeriod: scorePeriodReducer,
        dashboard: dashboardReducer,
        signup: signupReducer,
        teamConfig: teamConfigReducer,
        teamTable: teamTableReducer,
        teamTablesList: teamTablesListReducer,
        eMenu: eMenuReducer,
        teamList: teamListReducer,
        workspaceConfig: workspaceConfigReducer,
        widgetPreview: widgetPreviewReducer,
        authorisation: authorisationReducer,
        login: loginReducer,
        navigationBar: navigationBarReducer,
        notificationBar: notificationBarReducer,
        passwordReset: passwordResetReducer,
        accountConfig: accountConfigReducer,
        detailedFeedbacks: detailedFeedbacksReducer,
        templateEditor: templateEditorReducer,
    },
    middleware: (defaultMiddleware) => [...defaultMiddleware(), sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
