/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { DateRange } from "../../../models/date-range";
import dayjs from "dayjs";
import { useAppSelector } from "../../../store/hooks";
import { useState } from "react";
import {
    DARK_BASIC,
    GREEN_BASIC,
    GREEN_LIGHT,
    RED_BASIC,
    RED_LIGHT,
    WHITE,
    YELLOW_BASIC,
    YELLOW_LIGHT,
} from "../../../common-styles/color-palette";
import { BORDER_RADIUS_MEDIUM, BORDER_RADIUS_SMALL } from "../../../common-styles/borders";
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';
import { HistogramPreloader } from "../../common/skeleton-preloaders/histogram-preloader";
import { flex_container__horizontal__full_width } from "../../../common-styles/containers";
import { FONT_SIZE_XL, FONT_SIZE_XXL } from "../../../common-styles/font-sizes";
import { BlockHeader } from "../../common/headers/block-header";
import sum from 'lodash-es/sum';
import { PAGE_CONTAINER_BOX_SHADOW } from "../../../common-styles/shadows";
import { MILLIS_IN_DAY } from "../../../utils/date-utils";
import { mobileDevices } from '../../../common-styles/adaptive-breakpoints';

const formatToDateOrTime = (selectedDateRange: DateRange, value: number, index?: number) => {
    const isDailyData = ((selectedDateRange.to || 0) - (selectedDateRange.from || 0)) < MILLIS_IN_DAY;
    const date = new Date(value);
    return isDailyData ? dayjs(date).format('HH:mm') :
        dayjs(date).format('DD MMM');
};

const dashboard__reviews_histogram__container = [css({
    height: '55%',
    backgroundColor: WHITE,
    borderRadius: BORDER_RADIUS_MEDIUM,
    boxShadow: PAGE_CONTAINER_BOX_SHADOW,

    [mobileDevices]: {
        maxHeight: '24em',
        borderRadius: 'unset',
        margin: 'unset',
        height: '24em',
        padding: '1em 0.5em',
    },
})];

const EmptyDashboard = () => {
    return (
        <div css={ [flex_container__horizontal__full_width, css({ height: '80%', flexDirection: 'column' })] }>
            <h3 css={ css({ fontSize: FONT_SIZE_XXL, fontWeight: '700', textAlign: 'center' }) }>
                There's nothing here yet
            </h3>
            <p css={ css({ fontSize: FONT_SIZE_XL, textAlign: 'center' }) }>
                You will be able to watch the changes <br/> as soon as the first reviews appears
            </p>
        </div>
    );
};

export const HistogramTooltip = ({ active, payload, selectedDateRange }: any) => {
    if(active && payload && payload.length) {
        if(!sum(payload.map((x: any) => x.value))) {
            return null;
        }

        const reversedBars = [...payload].reverse();
        return (
            <div
                css={ css({
                    borderRadius: BORDER_RADIUS_SMALL,
                    backgroundColor: DARK_BASIC,
                    padding: '1em',
                    width: '7em',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }) }
            >
                <div>
                    <p
                        css={ css({
                            color: WHITE,
                            textAlign: 'center',
                        }) }
                    >
                        { formatToDateOrTime(selectedDateRange, payload[0].payload.date) }
                    </p>
                </div>
                {
                    reversedBars?.map((x: any, i: number) => {
                        return (
                            <div css={ css({
                                display: 'flex',
                                justifyContent: 'space-between',
                                ':nth-of-type(2)': {
                                    color: GREEN_BASIC,
                                },
                                ':nth-of-type(3)': {
                                    color: YELLOW_BASIC,
                                },
                                ':nth-of-type(4)': {
                                    color: RED_BASIC,
                                },
                            }) } key={ i }>
                                <p css={ css({ marginBlock: '0.25em' }) }>{ x.name }</p>
                                <p css={ css({ marginBlock: '0.25em' }) }>{ x.value }</p>
                            </div>
                        );
                    })
                }
            </div>
        );
    }
    return null;
};

export const ReviewsStackedHistogram = () => {
    const { timelineData, dashboardDataLoading } = useAppSelector(state => state.dashboard);
    const { numberOfResponses } = useAppSelector(state => state.happinessScore);
    const { selectedDateRange } = useAppSelector(state => state.scorePeriod);

    const [index, setIndex] = useState<number | null>();

    const onHistogramMouseMove = (state: CategoricalChartState) => {
        if(state.isTooltipActive) {
            setIndex(state.activeTooltipIndex);
        } else {
            setIndex(null);
        }
    };

    return (
        <div css={ dashboard__reviews_histogram__container }>
            <BlockHeader isLoading={ dashboardDataLoading } title={ "Feedbacks Timeline" }/>
            { (!numberOfResponses && !dashboardDataLoading) && <EmptyDashboard/> }
            { dashboardDataLoading && <HistogramPreloader/> }
            { (!!numberOfResponses && !dashboardDataLoading) &&
                <ResponsiveContainer
                    width="99%"
                    height="85%"
                >
                    <BarChart
                        width={ 500 }
                        height={ 300 }
                        data={ timelineData as any || [] }
                        margin={ {
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        } }
                        maxBarSize={ 70 }
                        onMouseMove={ onHistogramMouseMove }
                    >
                        <XAxis
                            dataKey="date"
                            axisLine={ false }
                            tickLine={ false }
                            tickFormatter={ (v: number, i: number) => formatToDateOrTime(selectedDateRange, v, i) }
                        />
                        <Tooltip cursor={ { fill: 'transparent' } }
                                 content={ <HistogramTooltip selectedDateRange={ selectedDateRange }/> }/>
                        <Bar dataKey="negative" name={ "Terrible" } stackId="a" fill={ RED_LIGHT }>
                            { (timelineData as any).map((entry: any, i: any) => {
                                let radius: number[] = [0, 0, 4, 4];
                                if(!entry.positive && !entry.neutral) {
                                    radius = [4, 4, 4, 4];
                                }
                                // @ts-ignore
                                return <Cell key={ `cell-${ i }` } radius={ radius }
                                             fill={ index === i ? RED_BASIC : RED_LIGHT }/>;
                            }) }
                        </Bar>
                        <Bar dataKey="neutral" name={ "Average" } stackId="a" fill={ YELLOW_BASIC }>
                            { (timelineData as any).map((entry: any, i: any) => {
                                let radius: number[] = [0, 0, 0, 0];
                                if(entry.positive > 0) {
                                    if(entry.negative > 0) {
                                        radius = [0, 0, 0, 0];
                                    } else {
                                        radius = [0, 0, 4, 4];
                                    }
                                }
                                // @ts-ignore
                                return <Cell key={ `cell-${ i }` } radius={ radius }
                                             fill={ index === i ? YELLOW_BASIC : YELLOW_LIGHT }/>;
                            }) }
                        </Bar>
                        <Bar dataKey="positive" name={ "Good" } stackId="a" fill={ GREEN_LIGHT }>
                            { (timelineData as any).map((entry: any, i: any) => {
                                let radius: number[] = [4, 4, 0, 0];
                                if(!entry.neutral && !entry.negative) {
                                    radius = [4, 4, 4, 4];
                                }
                                // @ts-ignore
                                return <Cell key={ `cell-${ i }` } radius={ radius }
                                             fill={ index === i ? GREEN_BASIC : GREEN_LIGHT }/>;
                            }) }
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            }
        </div>
    );
};
