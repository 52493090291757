import { css } from "@emotion/react";
import { BORDER_RADIUS_SMALL } from "../../../common-styles/borders";
import { skeleton__preloader } from "../../../common-styles/skeleton";

export const team_configuration_form = css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '2.75em',
    paddingRight: '4.25em',
    height: '100%',
});

export const team_configuration_form__item_input = css({
    marginBottom: "0.75em",
});

export const team_configuration_form__item_input__loader = css([
    skeleton__preloader, { borderRadius: BORDER_RADIUS_SMALL, minHeight: '3.3125em' }
]);
export const team_configuration_form__item_helper_message__loader = css([
    skeleton__preloader, { borderRadius: BORDER_RADIUS_SMALL, minHeight: '0.75em', margin: '0.25em 0' }
]);

export const team_configuration_form__item_group = css({
    marginTop: "0.75em",
    marginBottom: "1.5em",
})

export const team_configuration_form__item_button = css({
    "&:not(:nth-last-of-type(1))": {
        marginBottom: "0.5em",
    },
});

