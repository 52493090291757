import { NavigateFunction } from "react-router-dom";
import { TeamResponse } from "../../../models/api/api";
import { GENERIC_ERROR_MESSAGE } from "../../../models/common-constants";
import { MagictapResponse } from "../../../models/magictap-response";
import { getTeamData } from "../../request-services/team";
import { NotificationType, showNotification } from "../../../store/features/notification-bar-slice";
import { loadTeamConfiguration, setTeamDataLoader } from "../../../store/features/team-config-slice";
import { AppDispatch } from "../../../store/store";
import { requestHandlerWrapper } from "../../../utils/request-handler-wrapper";

function getTeamFailure(_: any, dispatch: AppDispatch) {
    dispatch(showNotification({ title: GENERIC_ERROR_MESSAGE, type: NotificationType.ERROR }));
}

const getTeamSuccess = (response: MagictapResponse<TeamResponse>, dispatch: AppDispatch) => {
    dispatch(loadTeamConfiguration(response.content!!));
    dispatch(setTeamDataLoader(false))
};

export const getTeamRequestHandler = (id: string, dispatch: AppDispatch, navigate: NavigateFunction): void => {
    requestHandlerWrapper(
        getTeamData,
        id,
        getTeamSuccess,
        getTeamFailure,
        dispatch,
        navigate
    );
};
