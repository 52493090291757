/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { GREEN_BASIC_OPAQUE, RED_BASIC_OPAQUE, YELLOW_BASIC_OPAQUE } from '../../common-styles/color-palette';
import { BORDER_RADIUS_SMALL } from '../../common-styles/borders';
import Terrible from '../../static/rating/terrible.png';
import Poor from '../../static/rating/poor.png';
import Average from '../../static/rating/average.png';
import Good from '../../static/rating/good.png';
import Excellent from '../../static/rating/excellent.png';

export const ratingIconMap: any = {
    1: Terrible,
    2: Poor,
    3: Average,
    4: Good,
    5: Excellent,
};

export const RatingIconChips = ({ rating }: { rating: number }) => {
    let color = YELLOW_BASIC_OPAQUE;
    if(rating > 3) {
        color = GREEN_BASIC_OPAQUE;
    } else if(rating < 3) {
        color = RED_BASIC_OPAQUE;
    }

    return (
        <div css={ css({
            width: '2.5em',
            height: '2.5em',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: color,
            borderRadius: BORDER_RADIUS_SMALL,
        }) }>
            <img css={ css({ width: '2em', height: '2em' }) } src={ ratingIconMap[rating] } alt=""/>
        </div>
    );
};
