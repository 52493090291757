import { css } from "@emotion/react";
import { BORDER_RADIUS_EXTRA_SMALL } from "../../common-styles/borders";
import { GREY_LIGHT, GREY_MEDIUM, WHITE } from "../../common-styles/color-palette";

export const qr_code__page__container = css({
    width: '100%',
    padding: '3em 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: GREY_LIGHT,
    borderRadius: BORDER_RADIUS_EXTRA_SMALL
});

export const qr_code__page__header = css({
    marginBottom: '0.5em',
    textAlign: 'center',
    width: '15em',
    minHeight: '5.375em',
    borderRadius: BORDER_RADIUS_EXTRA_SMALL
});

export const feedback_link__container = css({
    backgroundColor: WHITE,
    minHeight: '4em',
    width: '15em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1em',
    padding: '0.625em 1em',
    borderRadius: BORDER_RADIUS_EXTRA_SMALL,
    boxSizing: 'border-box',
});

export const feedback_link = css({
    marginBlock: '0.3em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

export const feedback_link_disabled = css({
    color: GREY_MEDIUM,
})

export const qr_code__container = (backgroundColor?: string) => css({
    backgroundColor: backgroundColor,
    width: '15em',
    height: '15em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: BORDER_RADIUS_EXTRA_SMALL,
});
