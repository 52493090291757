import { Tab as MuiTab } from '@mui/material';
import { styled } from '@mui/material/styles';
import { REGULAR_FONT } from '../../common-styles/font-styles';
import { BLACK } from '../../common-styles/color-palette';

export const Tab = styled(MuiTab)({
    margin: '0 1em',
    textTransform: 'none',
    fontFamily: REGULAR_FONT,

    '&.Mui-selected': {
        color: BLACK,
    }
});
