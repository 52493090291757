/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {css, jsx} from '@emotion/react';
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {useNavigate, useSearchParams} from "react-router-dom";
import React, {Fragment, useEffect} from "react";
import {flex_container__horizontal__full_width} from "../../common-styles/containers";
import {signup__welcome_message} from "../sign-up/sign-up.styles";
import {ErrorMessage} from "../common/error-message";
import {SubmitButton} from "../common/buttons/submit-button";
import {changeNewAccountLoadingIndicator} from "../../store/features/signup-slice";
import {passwordUpdateRequestHandler} from "../../services/request-handlers/password-update-request-handler";
import {ERROR_PAGE} from "../../routing/routes";
import {changeHash} from "../../store/features/password-reset-slice";
import {ChangePasswordForm} from "./change-password-form";

export const PasswordUpdateForm = () => {
    // todo: think how to move this into proper state
    const {
        password,
        passwordRepeated,
        passwordsAreInMatch,
        requestError,
        requestIsProcessing,
    } = useAppSelector(state => state.signup.newAccount);
    const {hash} = useAppSelector(state => state.passwordReset);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const hashParam = searchParams.get('hash');
        if (!hashParam) {
            navigate(`${ERROR_PAGE}`);
        } else {
            dispatch(changeHash(hashParam));
        }
    }, []);

    const onSubmitButtonHandler = () => {
        dispatch(changeNewAccountLoadingIndicator(true));
        passwordUpdateRequestHandler(password, hash!, dispatch, navigate);
    };

    return (
        <Fragment>
            <div css={flex_container__horizontal__full_width}>
                <h1 css={signup__welcome_message}>
                    This time, make <br/> a note
                </h1>
            </div>
            <div>
                <ChangePasswordForm/>
                <div css={[flex_container__horizontal__full_width]}>
                    <div css={css({width: "90%"})}>
                        <ErrorMessage message={requestError}/>
                    </div>
                </div>
                <div css={[flex_container__horizontal__full_width, css({margin: "1em 0"})]}>
                    <div css={css({width: "90%", height: '3.5em'})}>
                        <SubmitButton
                            label={"Reset password"}
                            requestLoadingIndicator={requestIsProcessing}
                            disabled={!password || !passwordRepeated || !passwordsAreInMatch || requestIsProcessing}
                            onClick={onSubmitButtonHandler}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
};
