/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {css, jsx} from '@emotion/react';
import {Box, Drawer} from "@mui/material";
import React from "react";
import {DARK_BASIC, DARK_LIGHT, GREEN_LIGHT_OPAQUE, GREY_LIGHT, WHITE} from "../../common-styles/color-palette";
import {DrawerHeader} from "../navbar/drawer-header";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {toggleReviewsFeedBar} from "../../store/features/navigation-bar-slice";
import {reviewsFeedBarWidthExpanded, reviewsFeedBarWidthShrunk} from "./reviews-feed.styles";
import {flex_container__horizontal, flex_container__vertical__full_height} from "../../common-styles/containers";
import GirlSearch from "../../static/girl_search.svg";
import {SecondaryButton} from "../common/buttons/secondary-button";
import {sideNavigationWidthExpanded, sideNavigationWidthShrunk} from "../navbar/navbar.styles";
import {IconButton} from '../common/buttons/icon-button';
import {Menu} from '../../icons/Menu';

const reviews_feed__empty_feed__disclaimer__header = css({fontWeight: '700', fontSize: '1.25em', marginTop: '2em'});
const reviews_feed__empty_feed__disclaimer__paragraph = css({
    color: DARK_LIGHT,
    width: '90%',
    fontSize: '1.05em',
    whiteSpace: 'pre-wrap',
    textAlign: 'center'
});

export const ReviewsFeed = ({showReviewsFeedPanel}: { showReviewsFeedPanel: boolean }) => {
    const {reviewsFeedBarOpened, desktopNavbarOpened} = useAppSelector(state => state.navigationBar);
    const dispatch = useAppDispatch();

    const reviewsBarToggleHandler = () => {
        dispatch(toggleReviewsFeedBar(!reviewsFeedBarOpened));
    }

    return (
        <Box component="nav" sx={{
            maxWidth: "350px",
            display: {
                xs: "none",
                sm: (showReviewsFeedPanel) ? "flex" : "none",
            },
            width: reviewsFeedBarOpened ? reviewsFeedBarWidthExpanded : reviewsFeedBarWidthShrunk,
            justifyContent: "center",
            backgroundColor: WHITE,
        }}>
            <Drawer
                variant="permanent"
                sx={{
                    display: {xs: 'none', sm: 'block'},
                    height: '100%',
                    width: desktopNavbarOpened ? reviewsFeedBarWidthExpanded : reviewsFeedBarWidthShrunk,
                    "& .MuiDrawer-paper": {
                        backgroundColor: WHITE,
                        position: "fixed",
                        left: desktopNavbarOpened ? sideNavigationWidthExpanded : sideNavigationWidthShrunk,
                        whiteSpace: "nowrap",
                        width: reviewsFeedBarWidthExpanded,
                        transition: theme => theme.transitions.create(['width', 'left'], {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                        boxSizing: "border-box",
                        ...(!reviewsFeedBarOpened && {
                            overflowX: "hidden",
                            transition: theme => theme.transitions.create(['width', 'left'], {
                                easing: theme.transitions.easing.sharp,
                                duration: theme.transitions.duration.leavingScreen,
                            }),
                            width: reviewsFeedBarWidthShrunk,
                        })
                    },
                }}
                open={reviewsFeedBarOpened}
            >
                <DrawerHeader>
                    {reviewsFeedBarOpened && <h2>Reviews</h2>}
                    <IconButton
                        css={css({
                            backgroundColor: GREY_LIGHT,
                            ':hover': {
                                color: DARK_BASIC,
                                backgroundColor: GREEN_LIGHT_OPAQUE,
                            },
                        })}
                        onClick={reviewsBarToggleHandler}
                    >
                        <Menu/>
                    </IconButton>
                    {reviewsFeedBarOpened &&
                        <div css={css({width: '8em'})}>
                            <SecondaryButton label={"Sort button"} onClick={() => void 0}/>
                        </div>
                    }
                </DrawerHeader>
                {reviewsFeedBarOpened &&
                    <div css={[flex_container__vertical__full_height, flex_container__horizontal]}>
                        <img src={GirlSearch} alt={"Search"}/>
                        <h3 css={reviews_feed__empty_feed__disclaimer__header}>There's nothing here now</h3>
                        <div css={reviews_feed__empty_feed__disclaimer__paragraph}>
                            But the review feed will fill up as you use it
                        </div>
                    </div>
                }
            </Drawer>
        </Box>
    );
};
