/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {css, jsx} from '@emotion/react';
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {changeDateRangePreset} from "../../../store/features/score-period-slice";
import {DateRangePreset} from "./date-range-picker";
import {DatePresetButton} from "../buttons/date-preset-button";

export const DateRangePickerPresetButtons = () => {
    const {selectedDateRangePreset} = useAppSelector(state => state.scorePeriod);
    const dispatch = useAppDispatch();

    return (
        <div css={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            marginBottom: '0.5em',
        })}>
            <DatePresetButton
                label={'Today'}
                onClick={() => dispatch(changeDateRangePreset(DateRangePreset.TODAY))}
                selected={selectedDateRangePreset === DateRangePreset.TODAY}
            />
            <DatePresetButton
                label={'This week'}
                onClick={() => dispatch(changeDateRangePreset(DateRangePreset.THIS_WEEK))}
                selected={selectedDateRangePreset === DateRangePreset.THIS_WEEK}
            />
            <DatePresetButton
                label={'Previous week'}
                onClick={() => dispatch(changeDateRangePreset(DateRangePreset.PREVIOUS_WEEK))}
                selected={selectedDateRangePreset === DateRangePreset.PREVIOUS_WEEK}
            />
            <DatePresetButton
                label={'This month'}
                onClick={() => dispatch(changeDateRangePreset(DateRangePreset.THIS_MONTH))}
                selected={selectedDateRangePreset === DateRangePreset.THIS_MONTH}
            />
        </div>
    );
};
