import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BLACK, BLUE_LIGHTER, WHITE } from "../../common-styles/color-palette";
import { TemplateResponse } from "../../models/api/api";
import { BackgroundType, getUrlByBackgroundType as getBackgroundImageByType } from "../../utils/background-type-utils";

export interface TemplateEditorState {
    header?: string,
    headerColor?: string,
    subheader?: string,
    subheaderColor?: string,
    qrBackgroundColor?: string,
    qrPixelsColor?: string,
    backgroundType?: BackgroundType,
    backgroundImage?: string,
    isLoading: boolean,
}

const initialState: TemplateEditorState = {
    header: "We care about your experience",
    subheader: "Please share any question or opinion",
    backgroundType: BackgroundType.ARTHOUSE_V1,
    backgroundImage: getBackgroundImageByType(BackgroundType.ARTHOUSE_V1),
    headerColor: WHITE,
    subheaderColor: BLUE_LIGHTER,
    qrBackgroundColor: WHITE,
    qrPixelsColor: BLACK,
    isLoading: false,
}

export const templateEditorSlice = createSlice({
    name: 'templateEditor',
    initialState,
    reducers: {
        changeHeader: (state: TemplateEditorState, action: PayloadAction<string>) => {
            state.header = action.payload
        },
        changeSubheader: (state: TemplateEditorState, action: PayloadAction<string>) => {
            state.subheader = action.payload
        },

        changeQrBackgroundColor: (state: TemplateEditorState, action: PayloadAction<string|undefined>) => {
            state.qrBackgroundColor = action.payload
        },

        changeQrPixelsColor: (state: TemplateEditorState, action: PayloadAction<string>) => {
            state.qrPixelsColor = action.payload
        },

        changeHeaderColor: (state: TemplateEditorState, action: PayloadAction<string|undefined>) => {
            state.headerColor = action.payload 
        },

        changeSubheaderColor: (state: TemplateEditorState, action: PayloadAction<string>) => {
            state.subheaderColor = action.payload
        },

        changeBackgroundType: (state: TemplateEditorState, action: PayloadAction<BackgroundType>) => {
            const newBackgroundType = action.payload
            const oldBackgroundType = state.backgroundType
            const isBackgroundTypeChangedFromPredefinedToOwn = oldBackgroundType !== BackgroundType.OWN && newBackgroundType === BackgroundType.OWN
            if(isBackgroundTypeChangedFromPredefinedToOwn) {
                state.backgroundImage = undefined
            }
            if(newBackgroundType !== BackgroundType.OWN){
                state.backgroundImage = getBackgroundImageByType(newBackgroundType)
            }
            state.backgroundType = newBackgroundType
        },

        changeBackgroundImage: (state: TemplateEditorState, action: PayloadAction<string| undefined>) => {
            state.backgroundImage = action.payload
        },

        setTemplateEditorLoading: (state: TemplateEditorState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        loadTemplateEditorData: (state: TemplateEditorState, action: PayloadAction<TemplateResponse>) => {
            if (!action.payload.header
                && !action.payload.subheader
                && !action.payload.qrBackgroundColor
                && !action.payload.qrPixelsColor
                && !action.payload.headerColor
                && !action.payload.subheaderColor) {
                state.header = initialState.header;
                state.subheader = initialState.subheader;
                state.qrBackgroundColor = initialState.qrBackgroundColor;
                state.qrPixelsColor = initialState.qrPixelsColor;
                state.headerColor = initialState.headerColor;
                state.subheaderColor = initialState.subheaderColor;
                state.backgroundType = initialState.backgroundType;
                state.backgroundImage = initialState.backgroundImage;
            } else {
                state.header = action.payload.header;
                state.subheader = action.payload.subheader;
                state.qrBackgroundColor = action.payload.qrBackgroundColor;
                state.qrPixelsColor = action.payload.qrPixelsColor;
                state.headerColor = action.payload.headerColor;
                state.subheaderColor = action.payload.subheaderColor;
                //TODO MO PR2: add function to derive backgroundType from BE response
                //state.backgroundType = BackgroundType.ARTHOUSE_V1;
                //state.backgroundImage = action.payload.backgroundImage;
            }
            state.isLoading = false;
        },
    }
});

export const {
    changeHeader,
    changeSubheader,
    changeQrBackgroundColor,
    changeQrPixelsColor,
    changeHeaderColor,
    changeSubheaderColor,
    changeBackgroundType,
    changeBackgroundImage,
    setTemplateEditorLoading,
    loadTemplateEditorData,
} = templateEditorSlice.actions;

export default templateEditorSlice.reducer;
