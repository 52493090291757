import {getTeamConfigurationRoute, TEAM_BASE_ROUTE} from "../../routing/routes";
import {CreateTeamRequest, TeamResponse} from "../../models/api/api";
import {AppDispatch} from "../../store/store";
import {NavigateFunction} from "react-router-dom";
import {createTeam} from "../request-services/team";
import {requestHandlerWrapper} from "../../utils/request-handler-wrapper";
import {changeTeamCreationLoadingIndicator, teamCreationRequestFailed} from "../../store/features/signup-slice";
import {GENERIC_ERROR_MESSAGE} from "../../models/common-constants";
import {MagictapResponse} from "../../models/magictap-response";
import {SIGNUP_EMAIL_LS_KEY, SIGNUP_STEP_LS_KEY} from "../../models/localstorage-keys";

export const createTeamFailure = (response: MagictapResponse<unknown>, dispatch: AppDispatch) => {
    dispatch(teamCreationRequestFailed(GENERIC_ERROR_MESSAGE));
    dispatch(changeTeamCreationLoadingIndicator(false));
};

function createTeamSuccess(response: MagictapResponse<TeamResponse>, dispatch: AppDispatch, navigate: NavigateFunction) {
    console.log('Team Created!: ' + response);
    localStorage.removeItem(SIGNUP_STEP_LS_KEY);
    localStorage.removeItem(SIGNUP_EMAIL_LS_KEY);
    navigate(`/${TEAM_BASE_ROUTE}/${getTeamConfigurationRoute(response.content!!.team.uuid)}`);
    dispatch(changeTeamCreationLoadingIndicator(false));
}

// todo: revise if this needed after team page created
export const wizardCreateTeamRequestHandler = (request: CreateTeamRequest, dispatch: AppDispatch, navigate: NavigateFunction) => {
    requestHandlerWrapper(
        createTeam,
        request,
        createTeamSuccess,
        createTeamFailure,
        dispatch,
        navigate,
    );
};
