/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { Button } from "@mui/material";
import { primary_button } from "./primary-button.styles";
import { ButtonPreloader } from '../skeleton-preloaders/button-preloader';

interface PrimaryButtonProps {
    label: string;
    onClick: (e?: any) => void;
    disabled?: boolean;
    type?: "button" | "submit" | "reset" | undefined;

    isLoading?: boolean;
}

export const PrimaryButton = ({ label, onClick, disabled, isLoading, type }: PrimaryButtonProps) => {
    if(isLoading) {
        return (
            <ButtonPreloader/>
        );
    }

    return (
        <Button
            variant="contained"
            type={ type }
            css={ primary_button }
            disabled={ disabled }
            onClick={ onClick }
        >
            { label }
        </Button>
    );
};
