import { AppDispatch } from "../../../store/store";
import { NavigateFunction } from "react-router-dom";
import { requestHandlerWrapper } from "../../../utils/request-handler-wrapper";
import { MagictapResponse } from "../../../models/magictap-response";
import { TableResponse } from "../../../models/api/api";
import { GENERIC_ERROR_MESSAGE } from "../../../models/common-constants";
import { NotificationType, showNotification } from "../../../store/features/notification-bar-slice";
import { getAllTables } from '../../request-services/tables';
import { loadAllTables } from '../../../store/features/team-tables-list-slice';

const tablesFailure = (_: MagictapResponse<unknown>, dispatch: AppDispatch) => {
    dispatch(showNotification({ title: GENERIC_ERROR_MESSAGE, type: NotificationType.ERROR }));
};

const tablesSuccess = (response: MagictapResponse<TableResponse[]>, dispatch: AppDispatch) => {
    dispatch(loadAllTables(response.content!!));
};

export const getAllTablesRequestHandler = (teamId: string, dispatch: AppDispatch, navigate: NavigateFunction): void => {
    requestHandlerWrapper(
        getAllTables,
        teamId,
        tablesSuccess,
        tablesFailure,
        dispatch,
        navigate
    );
};
