export const Close = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g>
                <path fill="currentColor"
                      d="m12 10.586 3.535-3.536 1.415 1.415L13.414 12l3.536 3.535-1.415 1.415L12 13.414 8.465 16.95 7.05 15.535 10.586 12 7.05 8.465 8.465 7.05 12 10.586Z"/>
            </g>
        </svg>
    );
};
