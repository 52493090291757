import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { CategoryDashboardData } from "../../models/api/api";
import { TimelineData } from '../../models/timeline-data';

interface DashboardState {
    timelineData: TimelineData[];
    categories: CategoryDashboardData[];

    dashboardDataLoading: boolean;
    dashboardDataRefreshing: boolean;
}

const initialState: DashboardState = {
    categories: [],
    timelineData: [],

    dashboardDataLoading: false,
    dashboardDataRefreshing: false,
};

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setTimelineData: (state, action: PayloadAction<TimelineData[]>) => {
            state.timelineData = [...action.payload];
            state.dashboardDataRefreshing = false;
        },
        setCategoriesData: (state, action: PayloadAction<CategoryDashboardData[]>) => {
            state.categories = [...action.payload];
        },

        changeDashboardDataLoader: (state: Draft<DashboardState>, action: PayloadAction<boolean>) => {
            state.dashboardDataLoading = action.payload;
        },
        refreshDashboardData: (state: Draft<DashboardState>) => {
            state.dashboardDataRefreshing = true;
        },
    }
});

export const {
    setTimelineData,
    setCategoriesData,
    changeDashboardDataLoader,
    refreshDashboardData,
} = dashboardSlice.actions

export default dashboardSlice.reducer
