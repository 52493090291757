import { AppDispatch } from "../../../store/store";
import { NavigateFunction } from "react-router-dom";
import { requestHandlerWrapper } from "../../../utils/request-handler-wrapper";
import { MagictapResponse } from "../../../models/magictap-response";
import { GetTableResponse } from "../../../models/api/api";
import { GENERIC_ERROR_MESSAGE } from "../../../models/common-constants";
import { NotificationType, showNotification } from "../../../store/features/notification-bar-slice";
import { getTable } from '../../request-services/tables';
import { loadTable } from '../../../store/features/team-table-slice';

const getTableFailure = (_: MagictapResponse<unknown>, dispatch: AppDispatch) => {
    dispatch(showNotification({ title: GENERIC_ERROR_MESSAGE, type: NotificationType.ERROR }));
};

const getTableSuccess = (response: MagictapResponse<GetTableResponse>, dispatch: AppDispatch) => {
    dispatch(loadTable(response.content!!));
};

export const getTableRequestHandler = (tableId: string, dispatch: AppDispatch, navigate: NavigateFunction): void => {
    requestHandlerWrapper(
        getTable,
        tableId,
        getTableSuccess,
        getTableFailure,
        dispatch,
        navigate
    );
};
