/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {css, jsx} from '@emotion/react';
import {skeleton__preloader} from "../../../common-styles/skeleton";
import {BORDER_RADIUS_SMALL} from "../../../common-styles/borders";
import {GREY_BASIC} from "../../../common-styles/color-palette";

export const HeaderPreloader = () => {
    return (
        <div css={css({
            padding: "1em 0 0.5em 0.5em",
            height: "2em",
            display: "flex",
            alignItems: "center",
        })}>
            <div css={[skeleton__preloader, css({
                padding: "1em 0 0 0.5em",
                width: '10em',
                height: '1.5em',
                borderRadius: BORDER_RADIUS_SMALL,
                backgroundColor: GREY_BASIC,
            })]}/>
        </div>
    );
};
