import { css, SerializedStyles } from '@emotion/react';
import { mobileDevices } from '../../../common-styles/adaptive-breakpoints';

export const team_list__header__container: SerializedStyles = css({
    [mobileDevices]: {
        display: 'flex',
        flexDirection: 'column',
        padding: 'unset',
        gap: '1em',
    },
});
export const team_list__create_team_button: SerializedStyles = css({
    width: '17em',
    [mobileDevices]: { width: '100%' },
});
