/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { ReactNode, useCallback } from 'react';
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DropResult,
} from 'react-beautiful-dnd';
import { Close } from '../../../icons/Close';
import { Drag } from '../../../icons/Drag';
import { SimpleIconButton } from '../buttons/simple-icon-button';
import { drag_and_drop__card, drag_and_drop__container, drag_and_drop__delete_icon } from './drag-and-drop.styles';

interface DragDropCardProps {
    snapshot: DraggableStateSnapshot;
    draggable: DraggableProvided;
    item: DragAndDropItem;
    onDelete?: (index: number) => void;
    index: number;
}

const Card = (props: DragDropCardProps) => {
    const { draggable, snapshot, onDelete, item, index } = props;
    const onClickDeleteIconHandler = () => onDelete?.(index);
    return (
        <div css={ drag_and_drop__card(snapshot.isDragging) }
             { ...draggable.draggableProps }
             { ...draggable.dragHandleProps }
             ref={ draggable.innerRef }>
            <SimpleIconButton>
                <Drag/>
            </SimpleIconButton>
            <div>
                { item.element }
            </div>
            { onDelete && (
                <div css={ drag_and_drop__delete_icon }>
                    <SimpleIconButton onClick={ onClickDeleteIconHandler }>
                        <Close/>
                    </SimpleIconButton>
                </div>
            ) }
        </div>
    );
};

export interface DragAndDropListProps {
    items: DragAndDropItem[];
    onMove: (source: number, destination: number) => void;
    onDelete?: (index: number) => void;
}

export interface DragAndDropItem {
    id: any;
    element: ReactNode;
    index: number;
}

export const DragAndDropList = (props: DragAndDropListProps) => {
    const { items, onMove, onDelete } = props;

    const onDragEndHandler = useCallback((dropResult: DropResult) => {
        if(dropResult.destination) {
            const sourceIndex = dropResult.source.index;
            const destinationIndex = dropResult.destination.index;
            const dragged = items.find((dragged) => dragged.id === dropResult.draggableId);
            if(dragged) {
                onMove(sourceIndex, destinationIndex);
            }
        }
    }, [items]);

    return (
        <DragDropContext onDragEnd={ onDragEndHandler }>
            <Droppable droppableId="droppableId">
                { droppable => (
                    <div
                        css={ drag_and_drop__container }
                        ref={ droppable.innerRef }
                        { ...droppable.droppableProps }
                    >
                        { items.map((item, index) => {
                            return (
                                <Draggable
                                    draggableId={ item.id }
                                    index={ index }
                                    key={ item.id }
                                >
                                    { (draggable, snapshot) => {
                                        return (
                                            <Card
                                                snapshot={ snapshot }
                                                draggable={ draggable }
                                                item={ item }
                                                onDelete={ onDelete }
                                                index={ index }
                                            />
                                        );
                                    } }
                                </Draggable>
                            );
                        }) }
                        { droppable.placeholder }
                    </div>
                ) }
            </Droppable>
        </DragDropContext>
    );
};
