export const getWorkspaceId = (): string | undefined => {
    let auth_token: any = {};

    decodeURIComponent(localStorage.authorization_token)
        .split('&')
        .map(x => x.split('=#'))
        .map(x => auth_token[x[0]] = x[1]);

    return auth_token.workspaceId;
};
