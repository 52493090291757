/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { changeWidgetConfigurationTab, changeWidgetDataLoader } from "../../store/features/workspace-config-slice";
import {
    loadWorkspaceConfigurationRequestHandler,
} from "../../services/request-handlers/workspace-configuration-request-handler";
import {
    widget_configuration,
    widget_configuration__container,
    widget_configuration__form_container,
} from "./widget-configuration.style";
import { SyntheticEvent, useEffect } from "react";
import { page__container } from '../../common-styles/pages';
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { WidgetPreview } from "./widget-preview";
import { getAllTeamsRequestHandler } from "../../services/request-handlers/team/team-list-request-handler";
import { PageHeader } from '../common/headers/page-header';
import { BlockHeader } from '../common/headers/block-header';
import { selectWorkspaceConfig } from '../../store/selectors/workspace-config-selectors';
import { TabPanel } from '../common/tab-panel';
import { FeedbackScreenConfiguration } from './feedback-screen-configuration';
import { Tabs } from '../common/tabs';
import { Tab } from '../common/tab';
import { ThankYouPageConfiguration } from './thank-you-page-configuration';

export const WidgetConfiguration = () => {
    const {
        isDataLoading,
        workspaceLogo,
        welcomeMessage,
        categories,
        enableMessageInput,
        isPromoteToSocialEnabled,
        widgetConfigurationTab,
        isThankYouPagePreview,
    } = useAppSelector(selectWorkspaceConfig);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(changeWidgetDataLoader(true));
        loadWorkspaceConfigurationRequestHandler(dispatch, navigate);
        getAllTeamsRequestHandler(dispatch, navigate);
    }, []);

    const handleTabChanging = (e: SyntheticEvent, value: number) => {
        dispatch(changeWidgetConfigurationTab(value));
    };

    return (
        <div css={ page__container }>
            <PageHeader isLoading={ isDataLoading } title="Manage personal page"/>
            <div css={ widget_configuration }>
                <div css={ widget_configuration__container }>
                    <div css={ css({ padding: '0 1.5em' }) }>
                        <BlockHeader isLoading={ isDataLoading } title="Widget configurations"/>
                    </div>
                    <div css={ widget_configuration__form_container }>
                        <Box sx={ { width: '100%', borderBottom: 1, borderColor: 'divider' } }>
                            <Tabs value={ widgetConfigurationTab } onChange={ handleTabChanging }>
                                <Tab label="Feedback Screen"/>
                                <Tab label={ "\"Thank you\" page" }/>
                            </Tabs>
                        </Box>
                        <TabPanel value={ widgetConfigurationTab } index={ 0 }>
                            <FeedbackScreenConfiguration/>
                        </TabPanel>
                        <TabPanel value={ widgetConfigurationTab } index={ 1 }>
                            <ThankYouPageConfiguration/>
                        </TabPanel>
                    </div>
                </div>
                <WidgetPreview
                    welcomeMessage={ welcomeMessage }
                    logo={ workspaceLogo }
                    commentariesEnabled={ enableMessageInput }
                    categories={ categories }
                    isPromoteToSocialEnabled={ isPromoteToSocialEnabled }
                    isThankYouPagePreview={ isThankYouPagePreview }
                />
            </div>
        </div>
    );
};
