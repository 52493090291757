import { css } from "@emotion/react";
import { GREEN_DARK, GREY_DARK, RED_BASIC } from "../../../common-styles/color-palette";
import { HEADERS_FONT } from "../../../common-styles/font-styles";
import { FONT_SIZE_EXTRA, FONT_SIZE_M, FONT_SIZE_MOBILE_M } from "../../../common-styles/font-sizes";
import { mobileDevices } from '../../../common-styles/adaptive-breakpoints';

export const happiness_score__gauge__trend = css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
});

export const happiness_score__gauge__trend__icon = css({ height: '1em' });

export const happiness_score__gauge__trend__percentage = (percentage: number) => {
    let color = GREY_DARK;
    if(percentage > 0) {
        color = GREEN_DARK;
    } else if(percentage < 0) {
        color = RED_BASIC;
    }

    return css({
        color,
        fontSize: FONT_SIZE_M,
        marginBlock: 'unset',

        [mobileDevices]: {
            fontSize: FONT_SIZE_MOBILE_M,
        }
    });
};

export const happiness_score__gauge__score_value = {
    fontSize: FONT_SIZE_EXTRA,
    fontFamily: HEADERS_FONT,
};
