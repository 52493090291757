import { css } from "@emotion/react";
import { BORDER_RADIUS_EXTRA_SMALL } from "../../../common-styles/borders";
import { GREY_BASIC } from "../../../common-styles/color-palette";
import { flex_container__vertical__full_height } from "../../../common-styles/containers";
import { skeleton__preloader } from "../../../common-styles/skeleton";

export const team_list__preloader__row = (isBorderRequired: boolean) => css({ height: '2.565em', borderBottom: isBorderRequired ? `1px solid ${GREY_BASIC}` : "none" })
export const team_list__preloader__header = css({ height: '3.05em' })
export const team_list__preloader = css({ display: 'flex', width: '100%', height: '100%', flexDirection: 'column' });
export const team_list__preloader__item__container = [flex_container__vertical__full_height, { justifyContent: 'center' }]
export const team_list__preloader__item = (height: string) => [skeleton__preloader, { height: height, width: '100%', borderRadius: BORDER_RADIUS_EXTRA_SMALL }]
