import { AUTHORIZATION_LS_KEY } from "../../models/localstorage-keys";
import { API_PATH } from "../request-path-constants";
import { CreateEMenuRequest, DeleteMenuItemRequest, UpdateMenuItemsRequest } from "../../models/api/api";
import { apiHost } from "../api-host-resolver";

export const getEMenu = async (teamId: string) => {
    const authUserToken = localStorage.getItem(AUTHORIZATION_LS_KEY) || '';
    return await fetch(`${apiHost}${API_PATH}/teams/${teamId}/menus`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'auth_user_session': authUserToken,
        }
    })
        .then(resp => resp.json().then(x => x))
        .catch(e => console.log(e));
}

export const createEMenu = async (createEMenuRequest: CreateEMenuRequest) => {
    const authUserToken = localStorage.getItem(AUTHORIZATION_LS_KEY) || '';
    return await fetch(`${apiHost}${API_PATH}/menu`, {
        method: 'POST',
        body: JSON.stringify(createEMenuRequest),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'auth_user_session': authUserToken,
        }
    })
        .then(resp => resp.json().then(x => x))
        .catch(e => console.log(e));
}

export const updateMenuItems = async (updateMenuItemsRequest: UpdateMenuItemsRequest) => {
    const authUserToken = localStorage.getItem(AUTHORIZATION_LS_KEY) || '';
    return await fetch(`${apiHost}${API_PATH}/menu/${updateMenuItemsRequest.menuId}/items`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'auth_user_session': authUserToken,
        },
        body: JSON.stringify(updateMenuItemsRequest.items)
    })
        .then(resp => resp.json().then(x => x))
        .catch(e => console.log(e));
}

export const addMenuItem = async (updateMenuItemsRequest: UpdateMenuItemsRequest) => {
    const authUserToken = localStorage.getItem(AUTHORIZATION_LS_KEY) || '';
    return await fetch(`${apiHost}${API_PATH}/menu/${updateMenuItemsRequest.menuId}/items`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'auth_user_session': authUserToken,
        },
        body: JSON.stringify(updateMenuItemsRequest.items)
    })
        .then(resp => resp.json().then(x => x))
        .catch(e => console.log(e));
}

export const deleteMenuItem = async (deleteMenuItemRequest: DeleteMenuItemRequest) => {
    const authUserToken = localStorage.getItem(AUTHORIZATION_LS_KEY) || '';
    return await fetch(`${apiHost}${API_PATH}/menu/${deleteMenuItemRequest.menuId}/items`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'auth_user_session': authUserToken,
        },
        // todo: FFS, rewrite this API
        body: JSON.stringify([deleteMenuItemRequest.itemId])
    })
        .then(resp => resp.json().then(x => x))
        .catch(e => console.log(e));
}
