/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { ReactNode } from 'react';

export const CardsList = ({ cards }: { cards: ReactNode[] }) => {
    return (
        <div css={ css({ minHeight: '5em', width: '100%', display: 'flex', flexDirection: 'column' }) }>
            { cards }
        </div>
    );
};
