import {ErrorCode, MagictapResponse} from "../../models/magictap-response";
import {AppDispatch} from "../../store/store";
import {NavigateFunction} from "react-router-dom";
import {requestHandlerWrapper} from "../../utils/request-handler-wrapper";
import {updatePassword} from "../request-services/password-reset";
import {GENERIC_ERROR_MESSAGE} from "../../models/common-constants";
import {changeNewAccountLoadingIndicator, newAccountRequestFailed} from "../../store/features/signup-slice";
import {LOGIN_BASE_ROUTE} from "../../routing/routes";
import {NotificationType, showNotification} from "../../store/features/notification-bar-slice";

const successfulPasswordUpdate = (response: MagictapResponse<null>, dispatch: AppDispatch, navigate: NavigateFunction) => {
    dispatch(changeNewAccountLoadingIndicator(false));
    navigate(`/${LOGIN_BASE_ROUTE}`);
    dispatch(showNotification({title: `Cool! Let's try your new password`, type: NotificationType.SUCCESS}));
};

const failedPasswordUpdate = (response: MagictapResponse<unknown>, dispatch: AppDispatch) => {
    if (response.errorCode === ErrorCode.ENTITY_NOT_FOUND) {
        dispatch(newAccountRequestFailed('This email is not registered'));
    } else if (response.errorCode === ErrorCode.INVALID_REQUEST_PARAMETER) {
        dispatch(newAccountRequestFailed('Invalid request'));
    } else {
        dispatch(newAccountRequestFailed(GENERIC_ERROR_MESSAGE));
    }
    dispatch(changeNewAccountLoadingIndicator(false));
};

export const passwordUpdateRequestHandler = (password: string, hash: string, dispatch: AppDispatch, navigate: NavigateFunction) => {
    requestHandlerWrapper(
        updatePassword,
        {password: btoa(password), hash},
        successfulPasswordUpdate,
        failedPasswordUpdate,
        dispatch,
        navigate
    );
};
