import { Navigate, NavigateFunction, Route, Routes, useNavigate } from "react-router-dom";
import { DetailedFeedbacks } from "../components/dashboard/detailed-feedbacks/detailed-feedbacks";
import { SignUp } from "../components/sign-up/sign-up";
import { useEffect } from "react";
import { TeamList } from "../components/team/team-list/team-list";
import { WidgetConfiguration } from "../components/widget/widget-configuration";
import {
    ACCOUNT_BASE_ROUTE,
    CONFIGURATION_BASE_ROUTE,
    CONTACT_US,
    DASHBOARD_BASE_ROUTE,
    DETAILED_FEEDBACK_BASE_ROUTE,
    E_MENU_CONFIGURATION_ROUTE,
    E_MENU_ROUTE,
    LOGIN_BASE_ROUTE,
    PASSWORD_RESET_ROUTE,
    PASSWORD_RESET_SUCCESS_ROUTE,
    PASSWORD_UPDATE_ROUTE,
    SIGN_UP_BASE_ROUTE,
    TEAM_BASE_ROUTE,
    TEAM_CONFIGURATION_ROUTE,
    TEAM_TABLE_ROUTE,
    TEAM_TABLES_ROUTE,
    TEMPLATE_EDITOR_ROUTE,
    WIZARD_ACCOUNT_CREATION_ROUTE,
    WIZARD_TEAM_CREATION_ROUTE,
    WIZARD_VERIFICATION_ROUTE,
} from "./routes";
import { Login } from "../components/login/login";
import { PageNotFound } from "../components/common/not-found-page/page-not-found";
import { WizardValidationStep } from "../components/sign-up/wizard-steps/wizard-validation-step";
import { WizardAccountStep } from "../components/sign-up/wizard-steps/wizard-account-step";
import { WizardTeamStep } from "../components/sign-up/wizard-steps/wizard-team-step";
import { Dashboard } from "../components/dashboard/dashboard";
import { TeamPage } from "../components/team/team-page";
import { PasswordReset } from "../components/password-reset/password-reset";
import { PasswordUpdate } from "../components/password-reset/password-update";
import { PasswordResetSuccess } from "../components/password-reset/password-reset-success";
import { AccountConfig } from "../components/account-config/account-config";
import { ContactUs } from "../components/contact-us/contact-us";
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { navigateTo } from '../store/features/navigation.slice';
import { TemplateEditor } from "../components/team/team-list/template-editor/template-editor";
import { TeamTableConfiguration } from '../components/team/team-table-configuration';
import { TeamTablesList } from '../components/team/team-tables-list/team-tables-list';
import { EMenu } from '../components/team/emenu/e-menu';
import { EMenuConfiguration } from '../components/team/emenu/e-menu-configuration';

export const Router = () => {
    const { destination }: any = useAppSelector(state => state.navigation);
    const navigate: NavigateFunction = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        // todo: probably we should check authorisation here as well
        if(!destination) {
            return;
        }
        navigate(destination);
        dispatch(navigateTo());
    }, [destination]);

    return (
        <Routes>
            <Route path="/" element={ <Navigate to={ `${ DASHBOARD_BASE_ROUTE }` }/> }/>
            <Route path={ `/${ DASHBOARD_BASE_ROUTE }` } element={ <Dashboard/> }/>
            <Route path={ `/${ DASHBOARD_BASE_ROUTE }/${ DETAILED_FEEDBACK_BASE_ROUTE }` }
                   element={ <DetailedFeedbacks/> }/>
            <Route path={ `/${ TEAM_BASE_ROUTE }` } element={ <TeamList/> }/>
            <Route path={ `/${ TEAM_BASE_ROUTE }/${ TEAM_CONFIGURATION_ROUTE }` } element={ <TeamPage/> }/>
            <Route path={ `/${ TEAM_BASE_ROUTE }/${ TEAM_CONFIGURATION_ROUTE }/${ TEAM_TABLES_ROUTE }` }
                   element={ <TeamTablesList/> }/>
            <Route path={ `/${ TEAM_BASE_ROUTE }/${ TEAM_CONFIGURATION_ROUTE }/${ E_MENU_ROUTE }` }
                   element={ <EMenu/> }/>
            <Route
                path={ `/${ TEAM_BASE_ROUTE }/${ TEAM_CONFIGURATION_ROUTE }/${ E_MENU_ROUTE }/${ E_MENU_CONFIGURATION_ROUTE }` }
                element={ <EMenuConfiguration/> }/>
            <Route
                path={ `/${ TEAM_BASE_ROUTE }/${ TEAM_CONFIGURATION_ROUTE }/${ TEAM_TABLES_ROUTE }/${ TEAM_TABLE_ROUTE }` }
                element={ <TeamTableConfiguration/> }/>
            <Route path={ `/${ TEAM_BASE_ROUTE }/${ TEAM_CONFIGURATION_ROUTE }/${ TEMPLATE_EDITOR_ROUTE }` }
                   element={ <TemplateEditor/> }/>
            <Route path={ `/${ CONFIGURATION_BASE_ROUTE }` } element={ <WidgetConfiguration/> }/>
            <Route path={ `/${ SIGN_UP_BASE_ROUTE }` } element={ <SignUp/> }/>
            <Route path={ `/${ WIZARD_VERIFICATION_ROUTE }` } element={ <WizardValidationStep/> }/>
            <Route path={ `/${ WIZARD_ACCOUNT_CREATION_ROUTE }` } element={ <WizardAccountStep/> }/>
            <Route path={ `/${ WIZARD_TEAM_CREATION_ROUTE }` } element={ <WizardTeamStep/> }/>
            <Route path={ `/${ LOGIN_BASE_ROUTE }` } element={ <Login/> }/>
            <Route path={ `/${ PASSWORD_RESET_ROUTE }` } element={ <PasswordReset/> }/>
            <Route path={ `/${ PASSWORD_UPDATE_ROUTE }` } element={ <PasswordUpdate/> }/>
            <Route path={ `/${ PASSWORD_RESET_SUCCESS_ROUTE }` } element={ <PasswordResetSuccess/> }/>
            <Route path={ `/${ ACCOUNT_BASE_ROUTE }` } element={ <AccountConfig/> }/>
            <Route path={ `/${ CONTACT_US }` } element={ <ContactUs/> }/>
            <Route path="*" element={ <PageNotFound/> }/>
        </Routes>
    );
};
