import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";

export enum NotificationType {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    WARNING = 'WARNING',
}

interface NotificationBarState {
    title: string;
    type: NotificationType;
    show: boolean;
}

interface NotificationPayload {
    title: string;
    type: NotificationType;
}

const initialState: NotificationBarState = {
    title: '',
    type: NotificationType.SUCCESS,
    show: false,
};

export const notificationBarSlice = createSlice({
    name: 'notificationBar',
    initialState,
    reducers: {
        closeNotification: (state: Draft<NotificationBarState>) => {
            state.show = false;
        },
        showNotification: (state: Draft<NotificationBarState>, action: PayloadAction<NotificationPayload>) => {
            state.title = action.payload.title;
            state.type = action.payload.type;
            state.show = true;
        },
    },
});

export const {
    closeNotification,
    showNotification,
} = notificationBarSlice.actions

export default notificationBarSlice.reducer;
