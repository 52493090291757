import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { GetTableResponse } from '../../models/api/api';

export interface TeamTableSlice {
    id: string;
    label: string;
    publicId: string;
    isDataEdited: boolean;
    isDataLoading: boolean;
}

const initialState: TeamTableSlice = {
    id: '',
    label: '',
    publicId: '',
    isDataEdited: false,
    isDataLoading: false,
};

export const teamConfigSlice = createSlice({
    name: 'teamTable',
    initialState,
    reducers: {
        setTeamTableId: (state: Draft<TeamTableSlice>, action: PayloadAction<string>) => {
            state.id = action.payload;
        },
        changeTeamTableLabel: (state: Draft<TeamTableSlice>, action: PayloadAction<string>) => {
            state.label = action.payload;
            state.isDataEdited = true;
        },
        loadTable: (state: Draft<TeamTableSlice>, action: PayloadAction<GetTableResponse>) => {
            state.publicId = action.payload.publicId;
            state.label = action.payload.label;
        },
        clearTablesForm: (state: Draft<TeamTableSlice>, action: PayloadAction<void>) => {
            state.label = initialState.label;
            state.publicId = initialState.publicId;
        },
    }
});

export const {
    setTeamTableId,
    changeTeamTableLabel,
    loadTable,
    clearTablesForm,
} = teamConfigSlice.actions;

export default teamConfigSlice.reducer;
